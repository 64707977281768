import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { string, func } from 'prop-types';

const OtpInputBox = ({ errorMessage, handleOtp }) => {
  const [otpVal, setOtpVal] = useState('');

  return (
    <div className=' otp-block'>
      <label htmlFor='mydetail-input'>Enter OTP</label>
      <OtpInput
        value={otpVal}
        onChange={(otp) => {
          setOtpVal(otp);
          handleOtp(otp);
        }}
        numInputs={6}
        separator={<span> </span>}
        isInputNum={true}
      />
      <span className='error-msg'>{errorMessage}</span>
    </div>
  );
};

OtpInputBox.propTypes = {
  errorMessage: string,
  handleOtp: func,
};

export default OtpInputBox;
