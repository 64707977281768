import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import close from '../../../../../assets/images/close.png';

const FinishedPopup = () => {
  const history = useHistory();
  const search = useLocation().search;
  const isOlympiad = new URLSearchParams(search).get('olympiad');

  const closepopup = () => {
    if (isOlympiad) {
      history.goBack();
    } else history.push('/dashboard/subject');
  };
  return (
    <div className='congrats-popup-main'>
      <div className='right-answer-container'>
        <div className='close-top'>
          <button type='button' className='close-btn' onClick={closepopup}>
            <img src={close} alt='close' />
          </button>
        </div>
        <div className='right-answer-modal-bg' />
        <div className='right-answer-modal'>
          <div className='congrats-detail'>
            <h2>Practice Test Completed</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishedPopup;
