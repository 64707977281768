import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import close from '../../../assets/images/close.png';
import Logo from '../../../assets/tempimages/Logo.svg';
import MygenericDetails from './components/MygenericDetails';
import StepsCompleted from './components/stepsCompleted';
import { chevRight } from '../../../assets/icons/IconList';
import { useEffect } from 'react';
import { updateRegistrationData } from '../../../stores/Auth/AuthAction';
import { authServices } from '../../../services/auth.services';
import swal from 'sweetalert';
import Loading from './components/Loading';

const ParentDetails = ({
  // setParentDetails,
  updateRegistrationData,
  registrationData,
}) => {
  const [validation, setValidation] = useState({
    fullName: false,
    emailId: false,
    numberAlreadyUsed: false,
    phoneNumber: false,
    isParentValidated: false,
  });
  const [disableFields, setDisableFields] = useState(false);
  const history = useHistory();
  const [fullnameVal, setFullnameVal] = useState('');
  const [emailVal, setEmailVal] = useState('');
  const [phoneNumVal, setPhoneNumVal] = useState('');
  const [parentId, setParentId] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');
  const [students, setStudents] = useState([]);
  const [showNextLoading, setShowNextLoading] = useState(false);

  const closeStep = () => {
    history.push('/newregister');
  };

  const handleNext = async () => {
    if (registrationData?.isEmailVerified) {
      history.push('/newregister/add-student');
    } else if (parentId) {
      setShowNextLoading(true);
      const checkEmailVerified = await authServices.checkEmailVerified({
        parentId: parentId,
      });
      if (checkEmailVerified) {
        if (checkEmailVerified?.statusCode === 200) {
          updateRegistrationData({
            ...registrationData,
            isEmailVerified: true,
          });
          setShowNextLoading(false);
          history.push('/newregister/add-student');
        } else if (checkEmailVerified?.statusCode === 404) {
          setShowNextLoading(false);
          swal(checkEmailVerified?.message);
        }
      }
    }
  };

  useEffect(() => {
    if (registrationData.parentName) {
      setFullnameVal(registrationData.parentName);
      setEmailVal(registrationData.parentEmailId);
      setPhoneNumVal(registrationData.parentPhoneNumber);
      setDisableFields(true);
    }
  }, []);

  useEffect(() => {
    if (validation.isParentValidated && !registrationData.parentName) {
      const updatedRegistraionData = registrationData;
      // setParentDetails(registraionData);
      setDisableFields(true);
      updateRegistrationData({
        ...updatedRegistraionData,
        parentName: fullnameVal,
        parentEmailId: emailVal,
        parentPhoneNumber: phoneNumVal,
        parentRegisterdId: parentId,
      });
    } else if (
      !validation.isParentValidated &&
      registrationData?.parentRegisterdId
    ) {
      setValidation({ ...validation, isParentValidated: true });
    }
  }, [validation.isParentValidated]);
  return (
    <div>
      <div className='register-main'>
        <div className='container'>
          <div className='register-top'>
            <div className='row'>
              <div className='col-md-4 col-sm-12'>
                <Link to='/'>
                  <img className='logo' src={Logo} alt='website_log' />
                </Link>
              </div>
              <div className='col-md-7 col-sm-12'>
                <h2>
                  You are registering as a <span>Parent</span>
                </h2>
              </div>
              {/* <div className='inline-cross'>
                <Link to='/'>
                  <div aria-hidden onClick={closeStep}>
                    <img className='close' src={close} alt='website_log' />
                  </div>
                </Link>
              </div> */}
            </div>
          </div>

          <StepsCompleted step={1} />

          <div className='step-body'>
            <MygenericDetails
              setFullnameVal={setFullnameVal} //
              fullnameVal={fullnameVal} //
              setEmailVal={setEmailVal} //
              emailVal={emailVal} //
              phoneNumVal={phoneNumVal} //
              setPhoneNumVal={setPhoneNumVal} //
              setValidation={setValidation}
              setStudents={setStudents}
              validation={validation} //
              disableFields={disableFields}
              setDisableFields={setDisableFields}
              setParentId={setParentId}
              setCurrencyCode={setCurrencyCode}
              currencyCode={currencyCode}
              otpDisable={validation.isParentValidated}
            />
            <div className='text-center step-btn-part'>
              <button
                type='button'
                disabled={!validation.isParentValidated}
                className='next-button'
                onClick={handleNext}
              >
                {showNextLoading ? <Loading variant='light' /> : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  registrationData: state.Auth.registrationData,
});

const mapDispatchToProps = (dispatch) => ({
  updateRegistrationData: (data) => dispatch(updateRegistrationData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParentDetails);
