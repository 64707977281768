import React from 'react';
import { array, func, string, number } from 'prop-types';
import bookmarkimg from '../../../../../../../assets/images/bookmark.png';
import bookmarkfilledimg from '../../../../../../../assets/images/bookmarkfilled2.png';
import MultiSelectOptions from './MultiSelectOptions';
import { renderText, readText } from '../textHelper';
import speaker from '../../../../../../../assets/images/speaker.png';
import { startListening } from '../../Exam/startLearning';

const MultiSelectQuestion = ({
  questionInExamData,
  selectedQuestion,
  selectedOption,
  setSelectedOption,
  allQuesAns,
  qIndex,
  itemId,
  description,
  options,
  addBookmark,
}) => {
  const questionObject = questionInExamData[selectedQuestion];
  const allquedata = allQuesAns[selectedQuestion];

  const handleSelect = (index) => {
    var newSelectedOptions =
      selectedOption?.length === 1
        ? selectedOption.includes(-1)
          ? []
          : selectedOption
        : selectedOption;
    if (selectedOption?.includes(index)) {
      newSelectedOptions = selectedOption.filter((ele) => ele !== index);
      setSelectedOption([...newSelectedOptions]);
    } else {
      newSelectedOptions.push(index);
      setSelectedOption([...newSelectedOptions]);
    }
  };

  const handleOptionSpeech = (options) => {
    const speech = options.map((e) => {
      if (e?.image) {
        isText = false;
      }
      return e?.text ? e.text : ' ';
    });

    if (isText) startListening(readText(speech.join(' . ')));
  };

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div className='question-box'>
            <div className='d-flex justify-content-between'>
              <h4>Question - {selectedQuestion + 1}</h4>
              <span>
                <button
                  type='button'
                  id={selectedQuestion}
                  onClick={() =>
                    startListening(
                      readText(
                        questionObject.title
                          ? questionObject.title + '' + '. '
                          : questionObject.description
                      )
                    )
                  }
                >
                  {' '}
                  <img
                    className='span-one speaker-btn'
                    src={speaker}
                    alt='speaker icon'
                  />
                </button>
                <button
                  className='text-right'
                  onClick={() => addBookmark(selectedQuestion)}
                  type='button'
                >
                  <img
                    className={
                      allquedata?.Bookmarked
                        ? 'bookmarkfilled-img'
                        : 'bookmark-img'
                    }
                    src={
                      allquedata?.Bookmarked ? bookmarkfilledimg : bookmarkimg
                    }
                    alt='bookmark'
                  />
                </button>
              </span>
            </div>
            <h4 className='title'>
              {questionObject.title ? renderText(questionObject.title) : ''}
            </h4>
            <h2>
              {' '}
              <span>{renderText(description)}</span>
            </h2>
          </div>

          <div className='d-flex justify-content-between'>
            <span className='answer-title'>Select your answer</span>
            <span className='mt-3 m-5'>
              <button
                type='button'
                id={qIndex}
                onClick={() => handleOptionSpeech(options)}
              >
                {' '}
                <img
                  className='span-one speaker-btn'
                  src={speaker}
                  alt='speaker icon'
                />
              </button>
            </span>
          </div>

          <div
            className={
              questionObject?.questionOrientaion === 'vertical'
                ? 'question-options-wrapper selectTyleType'
                : 'question-options-wrapper selectTyleType'
            }
          >
            {options?.map(
              (item, ind) =>
                (item?.text !== '' || item?.image !== '') && (
                  <MultiSelectOptions
                    key={ind}
                    questionObject={questionObject}
                    handleSelect={handleSelect}
                    item={item}
                    itemId={itemId}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    ind={ind}
                  />
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
MultiSelectQuestion.propTypes = {
  questionInExamData: array.isRequired,
  selectedQuestion: number.isRequired,
  selectedOption: array.isRequired,
  setSelectedOption: func.isRequired,
  allQuesAns: array.isRequired,
  qIndex: string,
  description: string.isRequired,
  options: array.isRequired,
  itemId: string,
  addBookmark: func.isRequired,
};

export default MultiSelectQuestion;
