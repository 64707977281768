import React from 'react';
import { array, object, string } from 'prop-types';
import AnswerOption from './AnswerOption';
import { checkDragDropSnunscramble } from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/questionHelper';

const TestAnswerWrapper = ({
  options,
  ansType,
  questionType,
  questionOrientaion,
  queDetails,
}) => {
  if (checkDragDropSnunscramble(questionType)) return null;

  return (
    <div className='row'>
      <div className='col-12'>
        <span className='answer-title pt-4'>Options</span>
        <div
          className={`question-options-wrapper ${ansType} ${questionOrientaion}`}
        >
          {options?.map(
            (item, i) =>
              (item?.text !== '' || item?.image !== '') && (
                <AnswerOption
                  index={i}
                  key={item?.id}
                  item={item}
                  ansType={ansType}
                  questionType={questionType}
                  questionDetailsData={queDetails}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};

TestAnswerWrapper.propTypes = {
  options: array.isRequired,
  questionType: string.isRequired,
  ansType: string.isRequired,
  questionOrientaion: string.isRequired,
  queDetails: object.isRequired,
};

export default TestAnswerWrapper;
