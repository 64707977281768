import React, { useState, useEffect } from 'react';
import { func, object, array } from 'prop-types';
import { connect } from 'react-redux';
import './calendar.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import CalendarMonth from './CalendarMonth';
import CalendarMonth2 from './CalendarMonth2';
import LeavePopup from './LeavePopup';
import MyLeaves from './MyLeaves';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  applyLeaveAction,
  editLeaveAction,
} from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';

const allViews = Object.keys(BigCalendar.Views).map(
  (k) => BigCalendar.Views[k]
);

moment.locale('es-es', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});
BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

const Calendar = ({ applyLeave, myClasses, myLeaves, editLeave }) => {
  const [calendarView, setCalendarView] = useState(false);
  const [model, setModel] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [myClassesList, setMyClassesList] = useState([]);
  const [isLeaveEdit, setIsLeaveEdit] = useState(false);
  const [leaveData, setLeaveData] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const applyleave = query.get('applyleave');
    const editleave = query.get('editleave');

    if (!applyleave && !editleave) {
      setModel(false);
      history.push('/teacher/calendar');
    }
    //  else if (applyleave) {
    //   setModel(true);
    //   setIsLeaveEdit(true);
    // }
  }, [location.search]);

  useEffect(() => {
    if (myClasses?.data) {
      const cdata = myClasses?.data[0]?.response?.map((data) => ({
        grade: data?.gradeInfo?.name,
        subject: data?.subjectInfo.name,
        numberOfStudent: data?.numOfStudents,
        date: data?.scheduleInfo?.date,
        duration: data?.scheduleInfo?.duration,
        timeslot: data?.scheduleInfo?.timeSlot,
        startTime: data?.scheduleInfo?.startTime,
        endTime: data?.scheduleInfo?.endTime,
        id: data?.scheduleInfo?._id,
        startUrl: data?.scheduleInfo?.startUrl,
      }));
      setMyClassesList(cdata);
    }
  }, [myClasses]);

  useEffect(() => {
    if (myClasses?.status === 'success' || myLeaves?.status === 'success') {
      const classData = myClassesList?.map((item) => ({
        id: item.id,
        title: item.subject,
        start: new Date(item.startTime),
        end: new Date(item.endTime),
      }));

      let leaveData = myLeaves?.data?.leaveList;
      leaveData = leaveData?.filter((data) => data.status === 'approved')
        ? leaveData?.filter((data) => data.status === 'approved')
        : [];
      leaveData = leaveData?.map((data) => ({
        id: data?._id,
        title: 'On Leave',
        start: new Date(data.from),
        end: new Date(data.to),
      }));
      if (leaveData?.length > 0 && classData?.length > 0) {
        setEventData(leaveData?.concat(classData));
      }
      if (leaveData?.length === 0 && classData?.length > 0) {
        setEventData(classData);
      }
      if (leaveData?.length > 0 && classData?.length === 0) {
        setEventData(leaveData);
      }
    }
  }, [myClasses, myLeaves, myClassesList]);

  const customEvent = myClassesList?.map((item) => ({
    id: item.id,
    name: item.subject,
    description: '',
    allDay: true,
    start: moment(item.date).format('MM-DD-YYYY'),
    end: moment(item.date).format('MM-DD-YYYY'),
    time: item.timeslot,
  }));
  const handleCustomLeaveData = (data) => {
    setIsLeaveEdit(true);
    setLeaveData(data);
    setModel(true);
  };

  const events = eventData?.map((item) => ({
    id: item.id,
    title: item.title,
    start: new Date(item.start),
    end: new Date(item.end),
  }));

  const eventOne = events?.map((dte, idx) => ({
    id: idx,
    start: String(
      new Date(
        dte.start.getFullYear(),
        dte.start.getMonth(),
        dte.start.getDate(),
        dte.start.getHours(),
        dte.start.getMinutes()
      )
    ),
    end: String(
      new Date(
        dte.end.getFullYear(),
        dte.end.getMonth(),
        dte.end.getDate(),
        dte.end.getHours(),
        dte.end.getMinutes()
      )
    ),
    title: dte.title,
    allDay: true,
  }));

  const onlyLeave = eventOne.filter((ele) => ele.title === 'On Leave');

  function getUnique(arr, comp) {
    const unique = arr
      .map((e) => new Date(e[comp]).getDate())

      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  let eventTwo = eventOne.filter((ele) => ele.title !== 'On Leave');

  eventTwo = getUnique(eventTwo, 'start');

  eventTwo = eventTwo.map((obj) => {
    if (obj.title !== 'On Leave') {
      return { ...obj, title: 'Class' };
    }
    return { ...obj, title: 'Leave' };
  });

  let halfViewEvent = [...eventTwo, ...onlyLeave];

  halfViewEvent = halfViewEvent.map((obj) => {
    if (obj.title !== 'On Leave') {
      return { ...obj, title: 'Class' };
    }
    return { ...obj, title: 'Leave' };
  });

  const changeApplyLeave = () => {
    applyLeave();
    setModel(true);
    history.push({
      pathname: '/teacher/calendar',
      search: '?applyleave=true',
    });
  };

  const [date, setDate] = useState('');
  const [changeView, setChangeView] = useState(false);

  const renderTodaysClasses = () => {
    if (!changeView) {
      const todaysClasses = customEvent.filter(
        (item) => item.start === moment(new Date()).format('MM-DD-YYYY')
      );
      if (!todaysClasses.length) {
        return (
          <div className='time-table'>
            <div className='time-table-head'>
              <p>
                {new Date().toLocaleDateString('en-us', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </p>
            </div>
            <div className='time-table-body'>
              <p>No classes scheduled for today</p>
            </div>
          </div>
        );
      }

      return (
        <div>
          <div className='time-table'>
            <div className='time-table-head'>
              <p>
                {new Date().toLocaleDateString('en-us', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </p>
            </div>
            <div className='time-table-body'>
              {todaysClasses?.map((data) => (
                <div className='time-table-common'>
                  <h5>{data?.time}</h5>
                  <p>{data?.name}</p>
                  <Link to='/teacher/classes'>View details</Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    const todaysClasses = customEvent.filter(
      (item) => item.start === moment(date).format('MM-DD-YYYY')
    );
    if (!todaysClasses.length) {
      return (
        <div className='time-table'>
          <div className='time-table-head'>
            <p>
              {date.toLocaleDateString('en-us', {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </p>
          </div>
          <div className='time-table-body'>
            <p>No classes scheduled for selected date.</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className='time-table'>
          <div className='time-table-head'>
            <p>
              {date.toLocaleDateString('en-us', {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </p>
          </div>
          <div className='time-table-body'>
            {todaysClasses?.map((data) => (
              <div className='time-table-common'>
                <h5>{data?.time}</h5>
                <p>{data?.name}</p>
                <Link to='/teacher/classes'>View details</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='calendar-main'>
      <div className='row calendar-head'>
        <div className='col-md-7'>
          <p className='desc'>
            Find here your upcoming & completed classes. You can also view &
            apply for leaves.
          </p>
        </div>
        <div className='col-md-5 text-right'>
          <button type='button' className='leave' onClick={changeApplyLeave}>
            APPLY LEAVE
          </button>
        </div>
        <div className='col-md-12 text-right'>
          <div className='view-part'>
            <p>View</p>
            <button
              type='button'
              onClick={() => setCalendarView(false)}
              className='menu'
            >
              menu
            </button>
            <button
              type='button'
              onClick={() => setCalendarView(true)}
              className='grid'
            >
              grid
            </button>
          </div>
        </div>
      </div>
      <div
        className={`row calendar-row ${
          calendarView ? 'full-view' : 'part-view'
        }`}
      >
        <div className={`${calendarView ? 'col-md-12' : 'col-md-8'}`}>
          <div className='calendar-table'>
            {calendarView && <CalendarMonth events={events} views={allViews} />}
            {!calendarView && (
              <CalendarMonth2
                events={halfViewEvent}
                views={['month']}
                setDate={setDate}
                setChangeView={setChangeView}
              />
            )}
          </div>
        </div>
        {!calendarView && (
          <div className='col-md-4'>{renderTodaysClasses()}</div>
        )}
      </div>
      <MyLeaves
        handleEdit={(data) => {
          handleCustomLeaveData(data);
        }}
      />
      <LeavePopup
        model={model}
        handleModel={setModel}
        isLeaveEdit={isLeaveEdit}
        leaveData={leaveData}
        editLeave={editLeave}
        setIsLeaveEdit={setIsLeaveEdit}
      />
    </div>
  );
};
Calendar.propTypes = {
  applyLeave: func.isRequired,
  myClasses: object.isRequired,
  myLeaves: array.isRequired,
};

const mapStateToProps = (state) => ({
  myClasses: state.TeacherDashboard.myClasses,
  myClassesHistory: state.TeacherDashboard.myClassesHistory,
  getProfile: state.TeacherDashboard.getProfile,
  myLeaves: state.TeacherDashboard.myLeavesList,
});

const mapDispatchToProps = (dispatch) => ({
  applyLeave: (data) => dispatch(applyLeaveAction(data)),
  editLeave: (data) => dispatch(editLeaveAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
