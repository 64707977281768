import React, { useState } from 'react';
import close from '../../../../../assets/images/close.png';
import { useHistory } from 'react-router';

const CompletedPopup = ({ completedMessage }) => {
  const history = useHistory();
  const closepopup = () => {
    history.push('/dashboard/exams');
  };
  return (
    <div className='congrats-popup-main'>
      <div className='right-answer-container'>
        <div className='close-top'>
          <button type='button' className='close-btn' onClick={closepopup}>
            <img src={close} alt='close' />
          </button>
        </div>
        <div className='right-answer-modal-bg' />
        <div className='right-answer-modal'>
          <div className='congrats-detail'>
            <h3>{completedMessage}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedPopup;
