import React from 'react';
import Pagination from 'rc-pagination';
import './style.scss';

const PaginationComponent = ({
  length,
  perPage,
  handleCurrentPage,
  currentPage,
  onPageChange,
}) => {
  return (
    <div>
      <ul id='page-numbers'>
        <Pagination
          showLessItems
          total={length}
          pageSize={perPage}
          current={parseInt(currentPage + 1)}
          onChange={(obj) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            handleCurrentPage(parseInt(obj - 1));
            if (onPageChange) {
              onPageChange(obj);
            }
          }}
          className='pagination-section'
          prevIcon={() => (
            <img src='https://img.icons8.com/ios/50/000000/less-than.png' />
          )}
          nextIcon={() => (
            <img src='https://img.icons8.com/ios/50/000000/more-than.png' />
          )}
          jumpPrevIcon={() => (
            <img src='https://img.icons8.com/material-outlined/24/000000/more.png' />
          )}
          jumpNextIcon={() => (
            <img src='https://img.icons8.com/material-outlined/24/000000/more.png' />
          )}
        />
      </ul>
    </div>
  );
};
export default PaginationComponent;
