import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Modal, ModalBody, FormGroup } from 'react-bootstrap';
import { array, bool, func, object } from 'prop-types';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { chevRight } from '../../../../../../../assets/icons/IconList';
import 'react-toastify/dist/ReactToastify.css';
import { assignStudentAssignment } from '../../../../../../../services/teacherAssignment';

const AssignmentDate = ({ modal, setModal, checked, selectedIds }) => {
  const [isLoading, setIsloading] = useState(false);
  const history = useHistory();
  const ld = localStorage.getItem('taut-student');
  const localDataVal = JSON.parse(ld);

  const resultedQue = checked.map((que) => que.name);

  const assignStudent = async (obj) => {
    setIsloading(true);
    const params = {
      student: localStorage.getItem('student'),
      teacher: localDataVal.data._id,
      subject: localStorage.getItem('subject'),
      chapter: selectedIds.chapterid,
      concept: selectedIds.conceptid,
      subconcept: selectedIds.subConceptId,
      questions: resultedQue,
      startedOn: moment(obj.startDate).format('YYYY-MM-DD'),
      dueOn: moment(obj.endDate).format('YYYY-MM-DD'),
      durationInMins: Number(obj.time),
    };

    try {
      if (checked.length === 0) {
        toast.error('Select Atleast one Question');
      } else {
        const res = await assignStudentAssignment(params);
        if (res.status === 200) {
          localStorage.removeItem('student', 'subject');
          toast.success('Assignment added successfully');
          setModal(false);
          history.push('/teacher/students');
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleAddSubmit = (obj) => {
    setModal(false);
    assignStudent(obj);
  };

  const formSchema_Assignment = Yup.object().shape({
    startDate: Yup.string()
      .required('Select a start date')
      .typeError('Select a start date'),
    endDate: Yup.string()
      .required('Select due date')
      .typeError('Select due date'),
    time: Yup.string()
      .trim()
      .required('Add assignment duration')
      .typeError('Add assignment duration'),
  });

  return (
    <Modal
      show={modal}
      size='md'
      aria-labelledby='example-modal-sizes-title-lg'
      centered
      className='assignmentConQue preview-popup'
      onHide={() => setModal(!modal)}
    >
      <ModalHeader closeButton>
        <Modal.Title id='example-modal-sizes-title-lg'>
          <p className='mb-0'>Assignment Timeline</p>
        </Modal.Title>
      </ModalHeader>
      <ModalBody>
        <>
          <Formik
            enableReinitialize
            initialValues={{
              startDate: '',
              endDate: '',
              time: '',
            }}
            validationSchema={formSchema_Assignment}
            onSubmit={(data) => handleAddSubmit(data)}
          >
            {({ errors, touched, setFieldValue, handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup className='assignDatesandtime'>
                  <div className='label'> Start Date</div>
                  <DatePicker
                    name='startDate'
                    id='startDate'
                    minDate={moment().toDate()}
                    selected={values.startDate}
                    onChange={(e) => {
                      setFieldValue('startDate', e);
                    }}
                    className={`form-control tautmore-input-style 
                                        ${
                                          errors.startDate &&
                                          touched.startDate &&
                                          'is-invalid'
                                        }`}
                  />
                  {errors.startDate && touched.startDate ? (
                    <div className='rp-manage-school_error-message mt-25'>
                      {errors.startDate}
                    </div>
                  ) : null}
                </FormGroup>

                <FormGroup className='assignDatesandtime'>
                  <div className='label'> End Date</div>
                  <DatePicker
                    disabled={!values.startDate}
                    name='endDate'
                    id='endDate'
                    onChange={(e) => {
                      setFieldValue('endDate', e);
                    }}
                    selected={values.endDate}
                    minDate={new Date(values.startDate)}
                    className={`form-control tautmore-input-style 
                                        ${
                                          errors.endDate &&
                                          touched.endDate &&
                                          'is-invalid'
                                        }`}
                  />
                  {errors.endDate && touched.endDate ? (
                    <div className='rp-manage-school_error-message mt-25'>
                      {errors.endDate}
                    </div>
                  ) : null}
                </FormGroup>

                <FormGroup className='assignDatesandtime'>
                  <div className='label'>Duration (min)</div>
                  <Field
                    type='number'
                    name='time'
                    id='time'
                    min='0'
                    max='9999'
                    className={`form-control tautmore-input-style 
                                        ${
                                          errors.time &&
                                          touched.time &&
                                          'is-invalid'
                                        }`}
                  />
                  {errors.time && touched.time ? (
                    <div className='rp-manage-school_error-message mt-25'>
                      {errors.time}
                    </div>
                  ) : null}
                </FormGroup>
                <div className='text-center'>
                  <button type='submit' className='button-common-assign'>
                    Done {/* <span>{chevRight}</span> */}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      </ModalBody>
      <ToastContainer />
    </Modal>
  );
};

AssignmentDate.propTypes = {
  modal: bool.isRequired,
  setModal: func.isRequired,
  checked: array.isRequired,
  selectedIds: object.isRequired,
};

export default AssignmentDate;
