import React, { useEffect, useState } from 'react';
import { array, bool, func } from 'prop-types';
import moment from 'moment';
import '../../slider.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from 'react-bootstrap';
import SubjectModel from './CoCurricualarSubjectModel';
import RescheduleCoCurricular from './RescheduleCoCurricular';
import { useHistory, useLocation } from 'react-router';

const ClassSlider = (props) => {
  const { myClassList, showSubjectModal, handleSubjectModal, setSelectedClass, studentList } = props;
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [scheduleId, setScheduleId] = useState('');
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const handleModal = (myClass) => {
    setSelectedClass(myClass)
    handleSubjectModal(true);
    setModalInfo(myClass);
    history.push({
      pathname: '/teacher/co-curricular',
      search: '?viewcurricular=true',
    });
  };

  const handleRescheduleModal = (data) => {
    setModal(true);
    setScheduleId(data);
  };



  useEffect(() => {
    const viewcurricular = query.get('viewcurricular');
  
    // if (!viewcurricular && location.pathname != '/teacher/co-curricular') {
      if (!viewcurricular) {
      handleSubjectModal(false);
      history.push('/teacher/co-curricular');
    }
    // else{
    //   handleSubjectModal(true);
    // }
  }, [location.search]);



  return (
    <>
      <Slider infinite={false} speed={500} slidesToShow={2} slidesToScroll={1}>
        {myClassList?.map((myClass) => {
          const date = moment(myClass?.date);
          return (
            <div className='sliderBox' key={myClass.id}>
              <div className='sub-box-main row'>
                <div className='sub-box-left col-sm-8'>
                  <h3 className='subname'>{myClass.subject}</h3>
                  <p>
                    <span>{date.format('dddd, MMMM Do')}</span> |
                    <span> {myClass.timeSlot}  {`(${myClass.timezone})`} </span>
                  </p>
                </div>
                <div className='sub-box-right col-sm-4'>
                </div>
              </div>
              <div className='row gradesection'>
                <div className='col-sm-4 floatleft'>
                  <p>Grade {myClass.grade} </p>
                </div>
                <div className='col-sm-4 floatleft'>
                  <p> {myClass.numberOfStudents} students </p>
                </div>
                <div className='col-sm-4 floatleft'>
                  <p> {myClass.duration} minutes </p>
                </div>
              </div>
              <div className='buttondiv'>
                <Button
                  className='viewdetailsbutton'
                  onClick={() => handleModal(myClass)}
                >
                  VIEW DETAILS
                </Button>
              </div>
            </div>
          );
        })}
      </Slider>
      <SubjectModel
        handleSubjectModel={handleSubjectModal}
        showSubjectModel={showSubjectModal}
        modalInfo={modalInfo}
        studentList={studentList}
      />
      <RescheduleCoCurricular
        modal={modal}
        setModal={setModal}
        scheduleId={scheduleId}
      />
    </>
  );
};

ClassSlider.propTypes = {
  myClassList: array.isRequired,
  handleSubjectModal: func.isRequired,
  showSubjectModal: bool.isRequired,
};

export default ClassSlider;
