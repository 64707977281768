import React, { useState, useEffect } from 'react';
import { string, bool, object } from 'prop-types';
import Assignment from '../../../../../../assets/images/My assignments.svg';
import close from '../../../../../../assets/images/close.png';
import search from '../../../../../../assets/images/search.png';
import { getQuestionsInSubConcept } from '../../../../../../services/teacherAssignment';
import { renderText } from '../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/textHelper';
import PreviewPopup from './questions-preview/PreviewPopup';
import AssignmentDate from './questions-preview/AssignmentDate';
import { leftIcon } from '../../../../../../assets/icons/IconList';
import { findKeyByValue } from '../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/questionHelper';
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import ReactPaginate from 'react-paginate';

const SelectAssignmentQuestions = ({
  selectedIds,
  selectedTopic,
  selectedConcept,
  selectedSubTopic,
  setAssignmentList,
}) => {
  const [checked, setChecked] = useState([]);
  const [subConceptsQuestions, setSubConceptQuestions] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [datePreview, setDatePreview] = useState(false);
  const [questionDetailsData, setQuestionDetailsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [initialQuestions, setInitialQuestions] = useState([]);
  const [doneBtnError, setDoneBtnError] = useState(false);
  const [pageData, setPageData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const handleClick = (event, value, indexVal) => {
    if (event.target.checked) {
      setChecked([...checked, { name: value, number: indexVal }]);
    } else {
      const filteredValues = checked.filter((val) => val.name !== value);
      setChecked([...filteredValues]);
    }
  };

  const getAssignmentQuestions = async (pageNo) => {
    setIsLoading(false);
    const params = {
      subconcept: selectedIds.subConceptId,
      subject: localStorage.getItem('subject'),
      chapter: selectedIds.chapterid,
      concept: selectedIds.conceptid,
      page_no: pageNo,
    };
    try {
      const res = await getQuestionsInSubConcept(params);
      if (res.status === 200) {
        setIsLoading(res);
        setPageData(res?.data?.data?.[0]?.count?.[0]?.count);
        const indexArray = res?.data?.data?.[0]?.response.map((data, i) => {
          return { ...data, index: i + 1 };
        });

        setSubConceptQuestions(indexArray);
        setInitialQuestions(indexArray);
      }
    } catch (error) {
      setIsLoading(true);
      console.log(error);
    }
  };

  const setSearchVal = (val) => {
    setSearchQuery(val);
    if (searchQuery.length > 3) {
      const searchList = subConceptsQuestions.filter((item) => {
        return item.description.toLowerCase().indexOf(val.toLowerCase()) !== -1;
      });

      setSubConceptQuestions(searchList);
    } else if (searchQuery.length <= 3) {
      setSubConceptQuestions(initialQuestions);
    }
  };

  const setShowPreviewData = (data) => {
    setQuestionDetailsData(data);
    setShowPreview(true);
  };

  const renderTextwithReplace = (desc) => {
    const strDesc = desc.replace(/insert_input/g, '______');

    const finalDesc = renderText(strDesc);
    return finalDesc;
  };

  useEffect(() => {
    getAssignmentQuestions(1);
  }, []);

  const handleDoneBtn = () => {
    if (checked.length > 0) {
      setDoneBtnError(false);
      setDatePreview(true);
    } else {
      setDoneBtnError(true);
      setDatePreview(false);
      toast.error('Please select at least one question.');
    }
  };
  const onPageChange = (pageNo) => {
    setSubConceptQuestions([]);
    setCurrentPage(pageNo);
    getAssignmentQuestions(pageNo);
  };
  return !isLoading ? (
    <div
      style={{
        height: '100vh',
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation='border' variant='warning' />
    </div>
  ) : (
    <div className='teacher-assign-main'>
      <div className='container-body'>
        <div className='heading-and-icon'>
          <div className='row' style={{ width: '100%' }}>
            <div className='col-md-7'>
              <img src={Assignment} alt='Assignment' />
              <h2 className='heading'>Assignments & Homework</h2>
              <h3>
                Assign homeworks to{' '}
                <span className='studentNameAssQue'>
                  {localStorage.getItem('TeachStudentName')}
                </span>{' '}
                by clicking on the sub-topics, Check{' '}
                <span className='studentNameAssQue'>
                  {localStorage.getItem('TeachStudentName')}
                </span>{' '}
                progress on completion of assignments.
              </h3>
            </div>
            <div className='col-md-5 text-right'>
              <button type='button' onClick={() => setAssignmentList(false)}>
                <img src={close} alt='close' />
              </button>
            </div>
          </div>
        </div>
        <div className='container-lists-and-sublists-main'>
          <div className='lists-and-sublists-assign'>
            <div className='questions-list-assignment-main'>
              <button
                type='button'
                className='updatedassignQueBack'
                onClick={() => setAssignmentList(false)}
              >
                <span>{leftIcon}</span>
                <span style={{ paddingTop: '2px', marginLeft: '4px' }}>
                  Go back
                </span>
              </button>
              <h1 className='assignQueTitle'>
                Add questions to the assignment
              </h1>
              <div className='row' style={{ marginBottom: '20px' }}>
                <div className='col-sm-8'>
                  <h4 className='student-name'>{selectedSubTopic}</h4>
                  <p className='assignTopicSubTopic'>
                    {selectedTopic} | {selectedConcept}
                  </p>
                </div>
                <div className='col-sm-4'>
                  {subConceptsQuestions?.length > 0 && (
                    <div className='dashboard-right-search custom-dashboard-right-search row'>
                      <div className='dash-right-search-common'>
                        <img src={search} alt='search' />{' '}
                        <input
                          className='assignQueSearch'
                          type='search'
                          placeholder='Search'
                          onChange={(e) => setSearchVal(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!subConceptsQuestions?.length > 0 ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ color: '#fca834', fontWeight: '500' }}
              >
                We are working to add new questions for this sub-concept.
              </div>
            ) : (
              subConceptsQuestions.map((que, index) => (
                <div className='assignmentQueLayoutMain'>
                  <>
                    <div className='row'>
                      <div className='col-sm-11'>
                        <h4>{que.title ? renderText(que.title) : ''}</h4>
                        <p className='assignQuestion'>
                          <span className='questionNumber'>
                            {currentPage === 1
                              ? index + 1
                              : index + 1 + (currentPage - 1) * 10}
                          </span>
                          {findKeyByValue(que?.solutionType) ===
                            'fill-in-the-blanks' ||
                          findKeyByValue(que?.solutionType) ===
                            'drag-and-match-the-correct-answer' ? (
                            <span>
                              {renderTextwithReplace(que.description)}
                            </span>
                          ) : (
                            <span> {renderText(que.description)}</span>
                          )}
                        </p>
                      </div>
                      <div className='col-sm-1 checkboxAlign'>
                        <button
                          className='preViewAssQue'
                          type='button'
                          id={index}
                          onClick={() => setShowPreviewData(que)}
                        >
                          preview
                        </button>
                        <label htmlFor={que._id} className='round'>
                          <input
                            type='checkbox'
                            id={que._id}
                            checked={
                              checked.some((item) => item.name === que._id)
                                ? checked
                                : ''
                            }
                            onChange={(e) =>
                              handleClick(
                                e,
                                que._id,
                                currentPage === 1
                                  ? index + 1
                                  : index + 1 + (currentPage - 1) * 10
                              )
                            }
                          />
                          <span className='checkmark' />
                        </label>
                      </div>
                    </div>
                  </>
                </div>
              ))
            )}
            {subConceptsQuestions?.length > 0 && (
              <ReactPaginate
                previousLabel={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    class='bi bi-arrow-left'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fill-rule='evenodd'
                      d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'
                    />
                  </svg>
                }
                nextLabel={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    class='bi bi-arrow-right'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fill-rule='evenodd'
                      d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'
                    />
                  </svg>
                }
                breakLabel='...'
                breakClassName='break-me'
                pageCount={Math.ceil(pageData / 10)}
                containerClassName='d-flex w-100 align-items-center justify-content-center mt-5 studentListpagination'
                activeClassName='active'
                forcePage={currentPage - 1}
                onPageChange={(pageNo) => onPageChange(pageNo.selected + 1)}
              />
            )}
            <div className='fixed-assign-bar'>
              <div className='container-fixed-assign-bar'>
                <div className='row'>
                  <div className='heading-and-selected-topics col-md-6'>
                    <div className='student-name-and-grades'>
                      <h2 className='student-name'>Questions selected:</h2>
                      <h4 className='grades'>
                        {checked?.map((che, index) => (
                          <span className='checkedSemicolon'>
                            {che.number}{' '}
                            {index === checked.length - 1 ? '' : '; '}
                          </span>
                        ))}
                      </h4>
                    </div>
                  </div>
                  <div className='student-name-and-assign-button assignmentQueBackground desktop-view col-md-6'>
                    <div className='student-name-and-grades'>
                      <h2 className='student-name'>{selectedSubTopic}</h2>
                      <h4 className='grades'>{selectedTopic}</h4>
                    </div>
                    <button
                      type='button'
                      className='assign-button button-common'
                      onClick={() => handleDoneBtn()}
                    >
                      Add date & time
                    </button>
                  </div>
                  <div className='student-name-and-assign-button assignmentQueBackground mobile-view col-md-6'>
                    <div className='stu-common'>
                      <div className='student-name-and-grades'>
                        <h2 className='student-name'>{selectedSubTopic}</h2>
                        <h4 className='grades'>{selectedTopic}</h4>
                      </div>
                    </div>
                    <button
                      type='button'
                      className='assign-button assignQueBtn button-common'
                    >
                      Done{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PreviewPopup
          modal={showPreview}
          setModal={setShowPreview}
          questionDetailsData={questionDetailsData}
        />
        <AssignmentDate
          modal={datePreview}
          setModal={setDatePreview}
          checked={checked}
          selectedIds={selectedIds}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

SelectAssignmentQuestions.propTypes = {
  selectedTopic: string.isRequired,
  selectedConcept: string.isRequired,
  selectedSubTopic: string.isRequired,
  selectedIds: object.isRequired,
  setAssignmentList: bool.isRequired,
};

export default SelectAssignmentQuestions;
