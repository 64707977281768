import * as actionTypes from './StudentAssignmentTypes';

const initialState = {
  startAssignment: [],
  questionsInAssignment: [],
  allQuesAnsAss: [],
  finishAssignment: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_START_ASSIGNMENT:
      return {
        ...state,
        startAssignment: action.payload,
      };

    case actionTypes.UPDATE_FINISH_ASSIGNMENT:
      return {
        ...state,
        finishAssignment: action.payload,
      };

    case actionTypes.UPDATE_GET_QUESTION_IN_ASSIGNMENT:
      return {
        ...state,
        questionsInAssignment: action.payload,
      };
    case actionTypes.UPDATE_ALL_QUESTION_ANSWER_ASSIGNMENT:
      return {
        ...state,
        allQuesAnsAss: action.payload,
      };
    case actionTypes.ADD_ALL_QUESTION_ANSWER_ASSIGNMENT:
      return {
        ...state,
        allQuesAnsAss: [...state.allQuesAnsAss, action.payload],
      };

    default:
      return { ...state };
  }
}
