export const MY_LEAVES_LIST = 'MY_LEAVES_LIST';
export const UPDATE_MY_LEAVES_LIST = 'UPDATE_MY_LEAVES_LIST';

export const APPLY_LEAVE = 'APPLY_LEAVE';
export const UPDATE_APPLY_LEAVE = 'UPDATE_APPLY_LEAVE';
export const EDIT_LEAVE = 'EDIT_LEAVE';
export const UPDATE_EDIT_LEAVE = 'UPDATE_EDIT_LEAVE';

export const CANCEL_LEAVE = 'CANCEL_LEAVE';
export const UPDATE_CANCEL_LEAVE = 'UPDATE_CANCEL_LEAVE';

export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_GET_PROFILE = 'UPDATE_GET_PROFILE';

export const CHANGE_NAME = 'CHANGE_NAME';
export const UPDATE_CHANGE_NAME = 'UPDATE_CHANGE_NAME';

export const CHANGE_PASSWORD = 'CHANGE_EMAIL';
export const UPDATE_CHANGE_PASSWORD = 'UPDATE_CHANGE_PASSWORD';

export const CHANGE_PHONE_NUM = 'CHANGE_PHONE_NUM';
export const UPDATE_CHANGE_PHONE_NUM = 'UPDATE_CHANGE_PHONE_NUM';

export const CHANGE_QUALIFICATION = 'CHANGE_QUALIFICATION';
export const UPDATE_CHANGE_QUALIFICATION = 'UPDATE_CHANGE_QUALIFICATION';

export const GET_MY_CLASSES = 'GET_MY_CLASSES';
export const UPDATE_GET_MY_CLASSES = 'UPDATE_GET_MY_CLASSES';

export const GET_MY_CO_CURRICULAR_CLASSES = 'GET_MY_CO_CURRICULAR_CLASSES';
export const UPDATE_GET_MY_CO_CURRICULAR_CLASSES =
  'UPDATE_GET_MY_CO_CURRICULAR_CLASSES';

export const GET_CHAPTER_BY_SUBJECT = 'GET_CHAPTER_BY_SUBJECT,';
export const UPDATE_GET_CHAPTER_BY_SUBJECT = 'UPDATE_GET_CHAPTER_BY_SUBJECT';

export const GET_CONCEPT_BY_CHAPTER = 'GET_CONCEPT_BY_CHAPTER';
export const UPDATE_GET_CONCEPT_BY_CHAPTER = 'UPDATE_GET_CONCEPT_BY_CHAPTER';

export const GET_MY_CLASSES_HISTORY = 'GET_MY_CLASSES_HISTORY';
export const UPDATE_GET_MY_CLASSES_HISTORY = 'UPDATE_GET_MY_CLASSES_HISTORY';

export const CHANGE_UNVIERSITY = 'CHANGE_UNVIERSITY';
export const UPDATE_CHANGE_UNVIERSITY = 'UPDATE_CHANGE_UNVIERSITY';

export const RESCHEDULE_CLASS = 'RESCHEDULE_CLASS';
export const UPDATE_RESCHEDULE_CLASS = 'UPDATE_RESCHEDULE_CLASS';

export const RESCHEDULE_COCURRICULAR_CLASS = 'RESCHEDULE_COCURRICULAR_CLASS';
export const UPDATE_RESCHEDULE_COCURRICULAR_CLASS =
  'UPDATE_RESCHEDULE_COCURRICULAR_CLASS';

export const TEACHER_SLOTS_PER_DATE = 'TEACHER_SLOTS_PER_DATE';
export const UPDATE_TEACHER_SLOTS_PER_DATE = 'UPDATE_TEACHER_SLOTS_PER_DATE';

export const COCURRICULAR_TEACHER_SLOTS_PER_DATE =
  'COCURRICULAR_TEACHER_SLOTS_PER_DATE';
export const UPDATE_COCURRICULAR_TEACHER_SLOTS_PER_DATE =
  'UPDATE_COCURRICULAR_TEACHER_SLOTS_PER_DATE';

export const GET_SUB_CONCEPTS = 'GET_SUB_CONCEPTS';
export const UPDATE_GET_SUB_CONCEPTS = 'UPDATE_GET_SUB_CONCEPTS';

export const ADD_HISTORY = 'ADD_HISTORY';
export const UPDATE_ADD_HISTORY = 'UPDATE_ADD_HISTORY';

export const SET_PROFILE_PIC = 'SET_PROFILE_PIC';
export const UPDATE_SET_PROFILE_PIC = 'UPDATE_SET_PROFILE_PIC';

export const SEND_PASSWORD_LINK = 'SEND_PASSWORD_LINK';
export const UPDATE_SEND_PASSWORD_LINK = 'UPDATE_SEND_PASSWORD_LINK';

export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY';
export const UPDATE_GET_PAYMENT_HISTORY = 'UPDATE_GET_PAYMENT_HISTORY';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_UPDATE_PASSWORD = 'UPDATE_UPDATE_PASSWORD';

// export const GET_PHONE_SEND_OTP = 'GET_PHONE_SEND_OTP';
// export const UPDATE_PHONE_SEND_OTP = 'UPDATE_PHONE_SEND_OTP';
// export const UPDATE_PHONE_VERIFY_OTP = 'UPDATE_PHONE_VERIFY_OTP';
