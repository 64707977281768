import React, { useEffect } from 'react';
import { arrayareEqual } from '../../../../../../helpers/utility';
import { renderText } from '../../BrainGym/QueAnswer/textHelper';
import { checkIcon } from '../../../../../../assets/icons/IconList';
import { FailureAudio, SuccessAudio } from '../../../../audioSignal';
import ReactHtmlParser from 'react-html-parser';
import { renderTextNew } from '../../BrainGym/QueAnswer/newTextHelper';

export const MultiSelectAnswer = ({ statusVal }) => {
  // useEffect(() => {
  //   if (
  //     !arrayareEqual(
  //       statusVal.questionInfo.solutionIndex,
  //       statusVal.result.solutionIndex
  //     ) ||
  //     arrayareEqual(
  //       statusVal.questionInfo.solutionIndex,
  //       statusVal.result.solutionIndex
  //     )
  //   ) {
  //     setPlay(true);
  //   }
  // }, []);

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div>
            <div className='question-box custom-question-box'>
              <h4 className='d-flex'>
                {statusVal?.questionInfo?.title == '' ||
                statusVal?.questionInfo?.title == null
                  ? 'Multi select question'
                  : renderText(statusVal?.questionInfo?.title)}
              </h4>
              <h2>
                {' '}
                <span>{renderText(statusVal.questionInfo.description)}</span>
              </h2>
            </div>
          </div>

          <div className='question-box custom-question-box'>
            {!statusVal?.marksObtained ? (
              <>
                {/* {play && <FailureAudio />} */}
                <span className='answer-title customAnswerTitle answer-wrong'>
                  Your below answer is incorrect
                </span>
              </>
            ) : (
              <>
                {/* {play && <SuccessAudio />} */}
                <span className='answer-title answer-correct'>
                  Your answer is correct
                </span>
              </>
            )}

            <div className='col-xl-12'>
              <div
                className={
                  statusVal.questionInfo?.questionOrientaion == 'vertical'
                    ? 'question-options-wrapper selectTyleType '
                    : 'question-options-wrapper selectTyleType'
                }
              >
                {statusVal.questionInfo.options.map((item, ind) => (
                  <div
                    className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                                          ${
                                            statusVal.result.solutionIndex.includes(
                                              ind
                                            ) &&
                                            statusVal.questionInfo?.solutionIndex.includes(
                                              ind
                                            )
                                              ? 'successBack'
                                              : statusVal.result.solutionIndex.includes(
                                                  ind
                                                )
                                              ? 'failureBack'
                                              : ''
                                          }`}
                  >
                    <button
                      type='button'
                      className={`optionsbtn ${
                        statusVal.result.solutionIndex.includes(ind) &&
                        statusVal.questionInfo?.solutionIndex.includes(ind)
                          ? 'successTextColor'
                          : statusVal.result.solutionIndex.includes(ind)
                          ? 'answer-wrong'
                          : ''
                      }`}
                    >
                      <span className='opti'>
                        {String.fromCharCode(item.index + 65)}
                        {' . '}
                      </span>
                      {item?.imageElement === null && (
                        <div className='questionHtlm-right'>
                          <span>{renderText(item?.text)} </span>
                        </div>
                      )}
                      {item?.imageElement !== '' && (
                        <div className='questionHtlm-right'>
                          <span>{renderText(item?.imageElement)}</span>
                        </div>
                      )}

                      {/* <div className='col-sm-4 customAEndBtnPrac'> */}
                      {statusVal.result.solutionIndex.includes(ind) ? (
                        <div className='icon--check-wrapper customIcon--check-wrapper'>
                          {checkIcon}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* </div> */}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!statusVal?.marksObtained ? (
        <>
          <div className='col-12 question-box mb-4'>
            <div className='col-xl-12 question-box'>
              <span className='answer-correct answer-title'>
                Correct Answer
              </span>
              <div
                className={
                  statusVal.questionInfo?.questionOrientaion == 'vertical'
                    ? 'question-options-wrapper selectTyleType '
                    : 'question-options-wrapper selectTyleType'
                }
              >
                {statusVal.questionInfo.options.map((item, i) => (
                  <div
                    className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                                        ${
                                          statusVal.questionInfo.solutionIndex.includes(
                                            i
                                          )
                                            ? 'successBack'
                                            : ''
                                        }`}
                  >
                    <button type='button'>
                      <div
                        className={`col-sm-8 customAlignBtnPrac ${
                          statusVal.questionInfo.solutionIndex.includes(i)
                            ? 'successTextColor'
                            : ''
                        }`}
                      >
                        {String.fromCharCode(item.index + 65)}{' '}
                        <span className='opti'> . </span>
                        {item?.imageElement === null && (
                          <div className='questionHtlm-right'>
                            <span>{renderText(item?.text)} </span>
                          </div>
                        )}
                        {item?.imageElement !== '' && (
                          <div className='questionHtlm-right'>
                            <span>{renderText(item?.imageElement)}</span>
                          </div>
                        )}
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      {statusVal.questionInfo.solutionDescription ? (
        <div className='option-wrapper-solution mb-4 mt-0'>
          <h4>Solution Description</h4>
          <div>{renderTextNew(statusVal.questionInfo.solutionDescription)}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
