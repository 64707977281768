import React from 'react';
import { object } from 'prop-types';
import { findKeyByValue } from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/questionHelper';
import TestHeader from './TestHeader';
import QuestionAnswerWrapper from './QuestionAnswerwrapper';
import QuestionAnswerPassageWrapper from './QuestionAnswerPassageWrapper';

const QuestionPreview = ({ queDetails }) => (
  <div className='pt-4 pb-4 px-3 test-container background-on-success question-preview-main'>
    <TestHeader
      subjectName={queDetails?.subjectName}
      solutionType={queDetails?.solutionType}
      questionType={findKeyByValue(queDetails?.solutionType)}
    />

    <QuestionAnswerWrapper
      description={queDetails?.description}
      options={queDetails?.options}
      queDetails={queDetails}
      questionOrientaion={queDetails?.questionOrientaion}
      questionType={findKeyByValue(queDetails?.solutionType)}
      showOptions={findKeyByValue(queDetails?.solutionType) !== 'passage'}
    />
    {findKeyByValue(queDetails?.solutionType) === 'passage' &&
      queDetails?.passageQuestions?.map((item, i) => (
        <QuestionAnswerPassageWrapper
          key={item?._id}
          qIndex={i + 1}
          solutionIndex={item?.solutionIndex}
          description={item?.description}
          options={item?.options}
          showOptions
        />
      ))}
  </div>
);

QuestionPreview.propTypes = {
  queDetails: object.isRequired,
};
export default QuestionPreview;
