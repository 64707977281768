import * as actionTypes from './MySubscriptionTypes';

const initialState = {
  getStudSubcription: {},
  getStudCurSubcription: {},
  subscriptionList: {},
  renewSubscription: {},
  updateSubscription: {},
  createSubOrder: [],
  subscriptionPayment: [],
  applyDiscount: [],
  appyCuponDiscount: [],
  appyRenewCuponDiscount: [],
  removeStudentSubs: [],
  addNewSubscriptionDetails: {},
  parentStuSub: [],
  parentStuDetails: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_STUDENT_SUBSCRIPTION:
      return {
        ...state,
        getStudSubcription: action.payload,
      };
    case actionTypes.UPDATE_ADD_NEW_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        addNewSubscriptionDetails: action.payload ? action.payload : {},
      };
    case actionTypes.UPDATE_STUDENT_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        getStudCurSubcription: action.payload,
      };
    case actionTypes.UPDATE_STUDENT_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscriptionList: action.payload,
      };
    case actionTypes.UPDATE_STUDENT_RENEW_SUBSCRIPTION:
      return {
        ...state,
        renewSubscription: action.payload,
      };
    case actionTypes.UPDATE_CREATE_SUBSCRIPTION_ORDER:
      return {
        ...state,
        createSubOrder: action.payload,
      };
    case actionTypes.CLEAR_SUBSCRIPTION_ORDER:
      return {
        ...state,
        createSubOrder: {},
      };

    case actionTypes.UPDATE_STUDENT_UPDATE_SUBSCRIPTION:
      return {
        ...state,
        updateSubscription: action.payload,
      };
    case actionTypes.UPDATE_START_SUBSCRIPTION_PAYMENT:
      return {
        ...state,
        subscriptionPayment: action.payload,
      };
    case actionTypes.UPDATE_APPLY_DISCOUNT:
      return {
        ...state,
        applyDiscount: action.payload,
      };

    case actionTypes.UPDATE_NEW_APPLY_DISCOUNT:
      return {
        ...state,
        appyCuponDiscount: action.payload,
      };

    case actionTypes.UPDATE_NEW_RENEW_APPLY_DISCOUNT:
      return {
        ...state,
        appyRenewCuponDiscount: action.payload,
      };

    case actionTypes.UPDATE_REMOVE_STUDENT_SUBSCRIPTION:
      return {
        ...state,
        removeStudentSubs: action.payload,
      };

    case actionTypes.UPDATE_PARENT_STUDENT_SUBSCRIPTION:
      return {
        ...state,
        parentStuSub: action.payload,
      };

    case actionTypes.UPDATE_PARENT_STUDENT_DETAILS:
      return {
        ...state,
        parentStuDetails: action.payload,
      };

    default:
      return { ...state };
  }
}
