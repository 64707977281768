import React, { useEffect, useState } from 'react';
import { renderText } from '../../BrainGym/QueAnswer/textHelper';
import { object } from 'prop-types';
import { arrayareEqual } from '../../../../../../helpers/utility';
import { FailureAudio, SuccessAudio } from '../../../../audioSignal';
import ReactHtmlParser from 'react-html-parser';
import { findKeyByValue } from '../../../Rightsidepart/components/QuestionTypes/questionHelper';
import { renderTextNew } from '../../BrainGym/QueAnswer/newTextHelper';

const FillInTheBlanksDragAndDropPreview = ({
  questionInChest,
  statusVal,
  // play,
  // setPlay,
}) => {
  const [finalQuesText, setFinalQuesText] = useState('');

  const [finalAnsArray, setFinalAnsArray] = useState([]); //options to drag
  const [fillInTheBlankAnswer, setFillInTheBlankAnswer] = useState([]); //answerd ansers

  const [dragSource, setDragSource] = useState(-1);
  const [dragDestination, setDragDestination] = useState(-1);

  useEffect(() => {
    let isAllQuestionsAnswerd = true;
    if (fillInTheBlankAnswer.length > 0) {
      fillInTheBlankAnswer.map((ans) => {
        if (ans?.name === '') {
          isAllQuestionsAnswerd = false;
        }
      });
      if (isAllQuestionsAnswerd) {
      }
    }
  }, [fillInTheBlankAnswer]);

  useEffect(() => {
    if (
      questionInChest?.dragTheTextSolution?.length > 0 &&
      !questionInChest?.dragTheTextSolution?.includes(-1)
    ) {
      var oldAnswers = questionInChest?.dragTheTextSolution.map(
        (eachIndex) => ({ name: finalAnsArray[eachIndex], index: eachIndex })
      );
      setFillInTheBlankAnswer([...oldAnswers]);
    }
  }, [questionInChest]);

  const RenderQuestion = (splitString, type) => {
    let newStr;
    if (splitString) {
      splitString.map((subStr, i) => {
        if (
          i < splitString.length - 1 &&
          questionInChest?.dragTheTextSolution?.includes(-1) &&
          !fillInTheBlankAnswer[i] &&
          !fillInTheBlankAnswer[i]?.name?.length > 0
        ) {
          let ans = [...fillInTheBlankAnswer];
          ans.push({
            name: questionInChest?.dragTheTextSolution?.[i],
            index: questionInChest?.dragTheTextSolution?.[i],
          });
          setFillInTheBlankAnswer([...ans]);
        }
        newStr = (
          <>
            <span>{newStr}</span>
            <span>{subStr}</span>
            {splitString.length - 1 === i ? null : (
              <input
                key={i}
                placeholder=''
                defaultValue={
                  type == 'result'
                    ? statusVal?.result?.dragTheTextSolution?.[i]
                    : questionInChest?.dragTheTextSolution?.[i]
                }
                onChange={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(i)}
                onDragOver={(e) => e.preventDefault()}
                disabled={true}
              />
            )}
          </>
        );
      });
    }
    return <div>{newStr}</div>;
  };
  useEffect(() => {
    //FORMATING THE QUESTION AND ANSWERS
    const quoteadd = questionInChest.descImageElement.replace(
      /(&rsquo;)/gi,
      '’'
    );
    const string = quoteadd.replace(/(&nbsp;|<([^>]+)>)/gi, ''); //REMOVING ALL TAGS
    const splitString = string.split('insert_input');
    setFinalQuesText(splitString);

    //FORMATING ANSWERS
    let shuffeldAns = questionInChest.dragTheTextShuffledElement;
    shuffeldAns = shuffeldAns?.replace(/(&rsquo;)/gi, '’');
    var cleanText = shuffeldAns?.replace(/(&nbsp;|<([^>]+)>)/gi, '');
    cleanText ? setFinalAnsArray([...cleanText.split(',')]) : '';
  }, [fillInTheBlankAnswer]);

  //DRAG AND DROP FUNCTIONALITY

  const handleDrag = (index) => {
    setDragSource(index);
  };
  const handleDrop = (ind) => {
    setDragDestination(ind);
  };

  useEffect(() => {
    //EXICUTED WHEN THE DRAG DROP ACTION IS COMPLETE
    if (dragSource !== -1) {
      var newFinalAns = [...fillInTheBlankAnswer];
      newFinalAns[dragDestination] = { name: '', index: '' };
      newFinalAns[dragDestination].name = finalAnsArray?.[dragSource];
      newFinalAns[dragDestination].index = dragSource;
      setFillInTheBlankAnswer([...newFinalAns]);
    }
  }, [dragSource, dragDestination]);

  //END DRAG AND DROP FUNCTIONALITY
  // useEffect(() => {
  //   if (
  //     !arrayareEqual(
  //       statusVal.questionInfo.dragTheTextSolution,
  //       statusVal.result.dragTheTextSolution
  //     ) ||
  //     arrayareEqual(
  //       statusVal.questionInfo.dragTheTextSolution,
  //       statusVal.result.dragTheTextSolution
  //     )
  //   ) {
  //     setPlay(true);
  //   }
  // }, []);

  const renderTextWithBlanks = (inputString, type) => {
    const regex = /insert_input/g;
    // questionInChest.description;

    const parts = inputString.split(regex);
    const inputCount = parts.length - 1;
    const result = parts.map((part, index) => {
      if (index < inputCount) {
        return (
          <React.Fragment key={index}>
            {renderText(part)}
            <input
              key={index}
              placeholder=''
              defaultValue={
                type == 'result'
                  ? statusVal?.result?.dragTheTextSolution?.[index]
                  : questionInChest?.dragTheTextSolution?.[index]
              }
              // onChange={(e) => e.preventDefault()}
              // onDrop={(e) => handleDrop(index)}
              // onDragOver={(e) => e.preventDefault()}
              disabled={true}
            />
          </React.Fragment>
        );
      }
      return renderText(part);
    });
    // console.log('the lllooo', result);
    return result;
  };

  return (
    <div>
      <div className='question-box custom-question-box'>
        <h4 className='d-flex'>
          {statusVal?.questionInfo?.title == '' ||
          statusVal?.questionInfo?.title == null
            ? findKeyByValue(statusVal?.questionInfo?.solutionType)
            : renderText(statusVal?.questionInfo?.title)}
        </h4>

        {!statusVal?.marksObtained ? (
          <>
            {/* {play && <FailureAudio />} */}
            <span className='answer-title customAnswerTitle answer-wrong'>
              Your below answer is incorrect
            </span>
          </>
        ) : (
          <>
            {/* {play && <SuccessAudio />} */}
            <span className='answer-title answer-correct'>
              Your answer is correct
            </span>
          </>
        )}
        <div className='fill-in-the-blanks'>
          {/* {RenderQuestion(finalQuesText, 'result')} */}
          {renderTextWithBlanks(questionInChest.description, 'result')}
        </div>
      </div>
      {!statusVal?.marksObtained && (
        <>
          <div className='question-box mt-4 mb-4'>
            <span className='answer-correct answer-title'>Correct Answer</span>
            <div className='fill-in-the-blanks'>
              {/* {RenderQuestion(finalQuesText)} */}
              {renderTextWithBlanks(questionInChest.description)}
            </div>
          </div>
        </>
      )}

      {statusVal.questionInfo.solutionDescription ? (
        <div className='option-wrapper-solution mb-4 mt-0'>
          <h4>Solution Description</h4>
          <div>{renderTextNew(statusVal.questionInfo.solutionDescription)}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

FillInTheBlanksDragAndDropPreview.propTypes = {
  questionInChest: object.isRequired,
};

export default FillInTheBlanksDragAndDropPreview;
