import React, { useEffect } from 'react';
import { renderText } from '../../BrainGym/QueAnswer/textHelper';
import { checkIcon } from '../../../../../../assets/icons/IconList';
import { FailureAudio, SuccessAudio } from '../../../../audioSignal';
import ReactHtmlParser from 'react-html-parser';
import { renderTextNew } from '../../BrainGym/QueAnswer/newTextHelper';

export const PassageAnswerPreview = ({
  question,
  resultQuestion,
  play,
  setPlay,
}) => {
  // useEffect(() => {
  //   if (
  //     question.solutionIndex[0] !== resultQuestion.solutionIndex[0] ||
  //     question.solutionIndex[0] == resultQuestion.solutionIndex[0]
  //   ) {
  //     setPlay(true);
  //   }
  // }, []);
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div>
            <div className='question-box custom-question-box'>
              {question?.title && (
                <h4 className='d-flex'>
                  Question: &nbsp; {renderText(question?.title)}
                </h4>
              )}
              <h2>
                {' '}
                <span>{renderText(question.description)}</span>
              </h2>
            </div>
          </div>
          <div className='question-box custom-question-box'>
            {question.solutionIndex[0] !== resultQuestion.solutionIndex[0] ? (
              <>
                {/* {play && <FailureAudio />} */}
                <span className='answer-title customAnswerTitle answer-wrong'>
                  Your below answer is incorrect
                </span>
              </>
            ) : (
              <>
                {/* {play && <SuccessAudio />} */}
                <span className='answer-title answer-correct'>
                  Your answer is correct
                </span>
              </>
            )}
            <div className='col-sm-8'>
              <div className='question-options-wrapper selectTyleType'>
                {question.options.map((item, ind) => (
                  <div
                    className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                                          ${
                                            resultQuestion.solutionIndex[0] ===
                                              ind &&
                                            ind == question.solutionIndex[0]
                                              ? 'successBack'
                                              : resultQuestion
                                                  .solutionIndex[0] === ind
                                              ? 'failureBack'
                                              : ''
                                          }`}
                  >
                    <button type='button'>
                      <div
                        className={`col-sm-8 customAlignBtnPrac ${
                          resultQuestion.solutionIndex[0] === ind &&
                          ind == question.solutionIndex[0]
                            ? 'successTextColor'
                            : resultQuestion.solutionIndex[0] === ind
                            ? 'answer-wrong'
                            : ''
                        }
                        `}
                      >
                        {String.fromCharCode(item.index + 65)}{' '}
                        <span className='opti'> . </span>
                        {item?.imageElement === null && (
                          <div className='questionHtlm-right'>
                            <span>{renderText(item?.text)} </span>
                          </div>
                        )}
                        {item?.imageElement !== '' && (
                          <div className='questionHtlm-right'>
                            <span>{renderText(item?.imageElement)}</span>
                          </div>
                        )}
                      </div>
                      <div className='col-sm-4 customAEndBtnPrac'>
                        {resultQuestion.solutionIndex[0] === ind ? (
                          <div className='icon--check-wrapper customIcon--check-wrapper'>
                            {checkIcon}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {question.solutionIndex[0] !== resultQuestion.solutionIndex[0] ? (
        <>
          <div className='question-box col-12 mb-4'>
            <div className='col-sm-8'>
              <span className='answer-correct answer-title'>
                Correct Answer
              </span>
              <div className='question-options-wrapper selectTyleType'>
                {question.options.map((item, i) => (
                  <div
                    className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                                    ${
                                      question.solutionIndex[0] === i
                                        ? 'successBack'
                                        : ''
                                    }`}
                  >
                    <button type='button'>
                      <div
                        className={`col-sm-8 customAlignBtnPrac ${
                          question.solutionIndex[0] === i
                            ? 'successTextColor'
                            : ''
                        }`}
                      >
                        {String.fromCharCode(item.index + 65)}{' '}
                        <span className='opti'> . </span>
                        {item?.imageElement === null && (
                          <div className='questionHtlm-right'>
                            <span>{renderText(item?.text)} </span>
                          </div>
                        )}
                        {item?.imageElement !== '' && (
                          <div className='questionHtlm-right'>
                            <span>{renderText(item?.imageElement)}</span>
                          </div>
                        )}
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      {question?.solutionDescription ? (
        <div className='option-wrapper-solution mb-4 mt-0'>
          <h4>Solution Description</h4>
          <div>{renderTextNew(question?.solutionDescription)}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
