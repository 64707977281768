import React, { useEffect } from 'react';
import { array, func, string, object, number } from 'prop-types';
import { renderText } from '../textHelper';
import { checkIcon } from '../../../../../../../assets/icons/IconList';

const MultiSelectOptions = ({
  questionObject,
  item,
  ind,
  selectedOption,
  handleSelect,
}) => {
  useEffect(() => {
    // setDragData();
  }, [questionObject]);

  return (
    <div
      className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                 ${selectedOption?.includes(ind) ? 'true' : ''}
                `}
    >
      <button
        type='button'
        className='optionsbtn'
        onClick={() => handleSelect(ind)}
      >
        <span className='opti'>{String.fromCharCode(ind + 65)}. </span>
        {item?.imageElement === null && (
          <div className='questionHtlm-right'>
            <span>{renderText(item?.text)} </span>
          </div>
        )}
        {item?.imageElement !== '' && (
          <div
            className={
              item?.image !== ''
                ? 'questionHtlm-left'
                : 'questionHtlm-right-imageElement'
            }
          >
            <span>{renderText(item?.imageElement)}</span>
          </div>
        )}
        <div className='icon--check-wrapper d-flex align-items-center justify-content-center'>
          {checkIcon}
        </div>
      </button>
    </div>
  );
};

MultiSelectOptions.propTypes = {
  item: object.isRequired,
  ind: number.isRequired,
  selectedOption: array.isRequired,
  setSelectedOption: func.isRequired,
  itemId: string,
  handleSelect: func.isRequired,
};

export default MultiSelectOptions;
