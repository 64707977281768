import React from 'react';
import { object, string, func, array } from 'prop-types';

const MyParentdetailsInput = ({
  label,
  type,
  btncontent,
  name,
  id,
  value,
  setValue,
  placeHolder,
  onBlur,
  disableFields,
}) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className='mydetail-input-part'>
      <label htmlFor='detail-label'>{label}</label>
      <input
        type={type}
        name={name}
        className={disableFields ? 'disable' : ''}
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeHolder}
        disabled={disableFields}
      />{' '}
      {btncontent && <button type='button'>{btncontent}</button>}
    </div>
  );
};

MyParentdetailsInput.propTypes = {
  label: string.isRequired,
  type: string.isRequired,
  btncontent: string.isRequired,
  name: string.isRequired,
  id: string.isRequired,
  value: string.isRequired,
  setValue: func.isRequired,
  placeHolder: string.isRequired,
  onBlur: func.isRequired,
};

export default MyParentdetailsInput;
