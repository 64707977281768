import React from 'react';
import { array, func, number } from 'prop-types';
import DragAndDropAnswer from './DragAndDropAnswer';
import {
  findKeyByValue,
  checkDragDropSnunscramble,
} from '../../../Rightsidepart/components/QuestionTypes/questionHelper';
import { renderText } from '../../../Rightsidepart/components/QuestionTypes/textHelper';
import { startListening } from '../startLearning';
import { readText } from '../../BrainGym/QueAnswer/textHelper';
import speaker from '../../../../../../assets/images/speaker.png';

const DraggAndDropQuestion = ({
  questionInChest,
  setSelect,
  selectedOption,
  setIsSubmitValid,
}) => {
  const questionType = findKeyByValue(questionInChest?.solutionType);
  if (!checkDragDropSnunscramble(questionType)) return null;
  return (
    <div>
      <div className='question-box'>
        <h4 className='d-flex'>
          {questionInChest?.title == '' || questionInChest?.title == null
            ? findKeyByValue(questionInChest?.solutionType)
            : renderText(questionInChest?.title)}
        </h4>
        <div className='col-12 d-flex align-items-center justify-content-between'>
          <h2 className='col-8'>
            {' '}
            <span>{renderText(questionInChest?.description)}</span>
          </h2>
          <button
            type='button'
            className='speaker-btn-wrapper'
            onClick={() =>
              startListening(readText(questionInChest.descImageElement))
            }
          >
            {' '}
            <img
              className='span-one speaker-btn'
              src={speaker}
              alt='speaker icon'
            />
          </button>
        </div>
      </div>

      <DragAndDropAnswer
        questionInChest={questionInChest}
        questionType={questionType}
        setSelect={setSelect}
        selectedOption={selectedOption}
        setIsSubmitValid={setIsSubmitValid}
      />
    </div>
  );
};

DraggAndDropQuestion.propTypes = {
  questionInChest: array.isRequired,
  setSelect: func.isRequired,
  selectedOption: array.isRequired,
  selectedQuestion: number.isRequired,
  allQuesAns: array.isRequired,
};

export default DraggAndDropQuestion;
