import config from '../helpers/config';
import { fetchWrapper } from '../helpers/fetch-wrapper';

const baseUrl = `${config.apiBaseUrl}`;

function startExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/start-exam`,
    params,
    token
  );
}
function questionInExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/questions-in-exam`,
    params,
    token
  );
}
function finishExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/finish-exam`,
    params,
    token
  );
}
function myReport(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/my-report`,
    params,
    token
  );
}
function previousExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/previous-exams-history`,
    params,
    token
  );
}
function getUnattemptedExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/unattempted-exams-history`,
    params,
    token
  );
}
function upcomingOlympiadExam(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/upcoming-exams`,
    params,
    token
  );
}
function myReportAnalytics(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/my-report-analytics`,
    params,
    token
  );
}

export const myOlympiadExamStudentServices = {
  startExam,
  questionInExam,
  finishExam,
  myReport,
  previousExam,
  upcomingOlympiadExam,
  myReportAnalytics,
  getUnattemptedExam,
};

export default {};
