import { bool, object, string } from 'prop-types';
import React from 'react';
import { convertSecsToMin } from '../BrainGym/QueAnswer/textHelper';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom';
import clock from '../../../../../assets/images/clock2.png';
import intelligence from '../../../../../assets/images/intelligence.png';
import clipboard from '../../../../../assets/images/clipboard.png';
import { chevRightIcon } from './icons';
import { useHistory } from 'react-router-dom';

const TestHeader = ({
  takeTestData,
  statusVal,
  topBar,
  timeTakenToAnswer,
  stat,
}) => {
  let history = useHistory();
  const search = useLocation().search;
  const isOlympiad = new URLSearchParams(search).get('olympiad');
  const ChapterName = new URLSearchParams(search).get('chapterName');
  const subjectName = new URLSearchParams(search).get('subjectName');

  const sub = new URLSearchParams(search).get('sub');
  const chap = new URLSearchParams(search).get('chap');
  const conc = new URLSearchParams(search).get('conc');
  const subCon = new URLSearchParams(search).get('subCon');
  return (
    <>
      <div className='col-12 d-flex align-items-center justify-content-flex-start'>
        {takeTestData === '' ? (
          <div
            className={`${
              topBar ? 'col-6' : 'col-12'
            } d-flex align-items-start`}
          >
            <div
              className={`${!topBar ? 'col-6' : ''} mt-4 mb-4 test-header-left`}
            >
              <h3 className='test-header'>
                {isOlympiad ? 'Olympiad practice test' : 'Practice test'}
              </h3>
              <div className='myconcept-head-main'>
                <div
                  className='myconcept-head-top myconcept-head-top-parent'
                  style={{ marginRigth: '2rem' }}
                >
                  {isOlympiad ? (
                    <Breadcrumb>
                      <Breadcrumb.Item>{subjectName}</Breadcrumb.Item>
                      <Breadcrumb.Item active>{ChapterName}</Breadcrumb.Item>
                    </Breadcrumb>
                  ) : (
                    <Breadcrumb>
                      <Breadcrumb.Item>{sub}</Breadcrumb.Item>
                      <Breadcrumb.Item>{chap}</Breadcrumb.Item>
                      <Breadcrumb.Item>{conc}</Breadcrumb.Item>
                      <Breadcrumb.Item active>{subCon}</Breadcrumb.Item>
                    </Breadcrumb>
                  )}
                </div>
              </div>
            </div>
            {!topBar ? (
              <>
                <div
                  className='d-flex align-items-center justify-content-between col-md-4 time'
                  style={{ marginTop: '66px' }}
                >
                  <div className='col-md-3 w-100 d-flex align-items-center'>
                    <div className='congrats-detail-sub-part congrats-detail-sub-part-wrapper'>
                      <img src={clock} alt='stopwatch' />
                      <div className='congrats-detail-sub-part-right'>
                        <p className='m-0 text-center'>Time taken </p>
                        <h5 className='text-center'>
                          {convertSecsToMin(timeTakenToAnswer)}
                        </h5>
                      </div>
                    </div>
                    {stat !== 'COMPLETED' ? (
                      <button
                        type='button'
                        className='submit-answer-button ml-2 mt-0'
                        onClick={() => {
                          if (isOlympiad) {
                            history.goBack();
                          } else history.push('/dashboard/subject');
                        }}
                        style={{ marginLeft: '1.5rem' }}
                        disabled={false}
                      >
                        <div
                          className={`button-wrapper-active d-flex align-items-center justify-content-between`}
                          style={{ width: '229px' }}
                        >
                          <span>End Test</span>
                          <div className='icon-wrapper d-flex align-items-center justify-content-center'>
                            {chevRightIcon()}
                          </div>
                        </div>
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          <h3 className='test-header' style={{ textAlign: 'center' }}>
            {takeTestData}
          </h3>
        )}
        {topBar && takeTestData === '' && (
          <div
            className={`${
              topBar ? 'col-4' : ''
            } col-5 mt-4 mb-4 test-header-right`}
          >
            <div className='row '>
              <div
                className='congrats-detail-sub'
                style={{ marginTop: '58px' }}
              >
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='congrats-detail-sub-part congrats-detail-sub-part-wrapper'>
                      <img src={clock} alt='stopwatch' />
                      <div className='congrats-detail-sub-part-right'>
                        <p className='m-0 text-center'>Time taken </p>
                        <h5 className='text-center'>
                          {convertSecsToMin(statusVal?.totalTimeTakenInSecs)}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='congrats-detail-sub-part congrats-detail-sub-part-wrapper'>
                      <img src={intelligence} alt='stopwatch' />
                      <div className='congrats-detail-sub-part-right'>
                        <p className='m-0 text-center'>Accuracy</p>
                        <h5 className='text-center'>
                          {Math.round(statusVal?.accuracy)} %
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='congrats-detail-sub-part congrats-detail-sub-part-wrapper'>
                      <img src={clipboard} alt='stopwatch' />
                      <div className='congrats-detail-sub-part-right'>
                        <p className='m-0 text-center'>Right answers</p>
                        <h5 className='text-center'>
                          {statusVal?.rightAnswers} out of{' '}
                          {statusVal?.totalQuestions}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    {stat !== 'COMPLETED' ? (
                      <button
                        type='button'
                        className='submit-answer-button ml-2 mt-0 w-100'
                        onClick={() => {
                          if (isOlympiad) {
                            history.goBack();
                          } else history.push('/dashboard/subject');
                        }}
                        disabled={false}
                      >
                        <div
                          className={`button-wrapper-active d-flex align-items-center justify-content-between`}
                          style={{ width: '229px' }}
                        >
                          <span>End Test</span>
                          <div className='icon-wrapper d-flex align-items-center justify-content-center'>
                            {chevRightIcon()}
                          </div>
                        </div>
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

TestHeader.propTypes = {
  takeTestData: string.isRequired,
  statusVal: object.isRequired,
  topBar: bool.isRequired,
};

export default TestHeader;
