import config from '../helpers/config';
import { fetchWrapper } from '../helpers/fetch-wrapper';

const baseUrl = `${config.apiBaseUrl}`;

function getstudentSubcription(params) {
  if (params?.parent) {
    return fetchWrapper.get(
      `${baseUrl}/studentSubscription/get-student-subscription/${params?.student}/${params?.parent}`,
      params
    );
  }

  return fetchWrapper.get(
    `${baseUrl}/studentSubscription/get-student-subscription/${params?.student}/null`, // old URL of get-student-subscription
    params
  );
}
function subscriptionList(params) {
  return fetchWrapper.get(
    `${baseUrl}/subscription/subscription-history/${params?.parent}/${params?.student}/${params?.grade}`
  );
}
function getCurrentSubscription(params) {
  return fetchWrapper.get(
    `${baseUrl}/subscription/get-current-subscription/${params?.parent}/${params?.student}/${params?.grade}`
  );
}
function renewSubscription(params) {
  return fetchWrapper.post(
    `${baseUrl}/studentSubscription/renew-subscription/${params?.student}/${params?.subcriptionId}`,
    params?.reqBodyData
  );
}
function updateSubscription(params) {
  return fetchWrapper.post(
    `${baseUrl}/studentSubscription/update-subscription/${params?.student}/${params?.subcriptionId}`,
    params?.reqBodyData
  );
}

function CreateSubscriptionOrder(params) {
  return fetchWrapper.post(`${baseUrl}/payments/razorpay/create-order`, params);
}
function startSubscriptionPayment(params) {
  return fetchWrapper.get(
    `${baseUrl}/studentSubscription/start_subscription_payment/${params?.student}/${params?.subcriptionId}`
  );
}
function applyDiscount(params) {
  return fetchWrapper.put(
    `${baseUrl}/discountUse/apply-discountcode/${params?.subcriptionId}`,
    params?.reqBodyData
  );
}

function applyCuponCodeDiscount(params) {
  return fetchWrapper.post(
    `${baseUrl}/subscription/apply-coupon/${params?.parentID}/null`,
    params?.reqBodyData
  );
}

function applyRenewCuponCodeDiscount(params) {
  return fetchWrapper.post(
    `${baseUrl}/subscription/apply-coupon/${params?.parentID}/${params?.studentID}`,
    params?.reqBodyData
  );
}

function removeStudentSubscription(params) {
  return fetchWrapper.remove(
    `${baseUrl}/subscription/remove-student-subscription/${params?.StudentID}`
  );
}

function getParentStudentSubscription(params) {
  return fetchWrapper.get(
    `${baseUrl}/subscription/get-student-subscription/${params?.parentId}/null`
  );
}

function getParentStudentDetails(params, token) {
  return fetchWrapper.get(
    `${baseUrl}/parent/students-details/${params?.parentId}`,
    token
  );
}

export const studentSubscription = {
  getstudentSubcription,
  subscriptionList,
  getCurrentSubscription,
  renewSubscription,
  updateSubscription,
  CreateSubscriptionOrder,
  startSubscriptionPayment,
  applyDiscount,
  applyCuponCodeDiscount,
  applyRenewCuponCodeDiscount,
  removeStudentSubscription,
  getParentStudentSubscription,
  getParentStudentDetails,
};

export default {};
