import React from 'react';
import { array, func, number, object, string } from 'prop-types';
import { renderText, readText } from '../textHelper';
import bookmarkimg from '../../../../../../../assets/images/bookmark.png';
import bookmarkfilledimg from '../../../../../../../assets/images/bookmarkfilled2.png';
import { useEffect, useState } from 'react';
import speaker from '../../../../../../../assets/images/speaker.png';
import { startListening } from '../../Exam/startLearning';

const FillInTheBlanks = ({
  key,
  questionInChest,
  setSelect,
  selectedQuestion,
  allQuesAns,
  addBookmark,
}) => {
  const [finalQuesText, setFinalQuesText] = useState('');
  const [fillInTheBlankAnswer, setFillInTheBlankAnswer] = useState([]);
  const inputString = questionInChest.description;

  // const handleFillInTheBlanksChange = (e, index) => {
  //   e.preventDefault();
  //   let newAns = [...fillInTheBlankAnswer];
  //   if (e.target.value !== ' ') {
  //     if (!e.target.value.includes('  ')) {
  //       newAns[index] = e.target.value;
  //       setFillInTheBlankAnswer([...newAns]);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   let isAllQuestionsAnswerd = true;
  //   fillInTheBlankAnswer.map((ans) => {
  //     if (ans === '') {
  //       isAllQuestionsAnswerd = false;
  //     }
  //   });
  //   if (isAllQuestionsAnswerd) {
  //     setSelect(fillInTheBlankAnswer);
  //   } else {
  //     setSelect([]);
  //   }
  //   //  }
  // }, [fillInTheBlankAnswer]);

  // useEffect(() => {
  //   if (allQuesAns[selectedQuestion]?.fillInTheBlankSolution?.length > 0) {
  //     setFillInTheBlankAnswer([
  //       ...allQuesAns[selectedQuestion]?.fillInTheBlankSolution,
  //     ]);
  //   }
  // }, [allQuesAns, selectedQuestion]);

  // const RenderQuestion = (splitString) => {
  //   let newStr;
  //   if (splitString) {
  //     splitString.map((subStr, i) => {
  //       if (
  //         i < splitString.length - 1 &&
  //         !allQuesAns[selectedQuestion]?.fillInTheBlankSolution?.length > 0 &&
  //         fillInTheBlankAnswer[i] !== '' &&
  //         !fillInTheBlankAnswer[i]?.length > 0
  //       ) {
  //         let ans = [...fillInTheBlankAnswer];
  //         ans.push('');
  //         setFillInTheBlankAnswer([...ans]);
  //       }
  //       newStr = (
  //         <>
  //           <span>{newStr}</span>
  //           <span>{subStr}</span>
  //           {splitString.length - 1 === i ? null : (
  //             <input
  //               key={i}
  //               placeholder=''
  //               value={fillInTheBlankAnswer[i]}
  //               onChange={(e) => handleFillInTheBlanksChange(e, i)}
  //             />
  //           )}
  //         </>
  //       );
  //     });
  //   }
  //   return <div>{newStr}</div>;
  // };
  // useEffect(() => {
  //   const quoteadd = questionInChest.descImageElement.replace(
  //     /(&rsquo;)/gi,
  //     '’'
  //   );
  //   const string = quoteadd.replace(/(&nbsp;|<([^>]+)>)/gi, '');
  //   const splitString = string.split('insert_input');
  //   const finalFormate = RenderQuestion(splitString);
  //   setFinalQuesText(finalFormate);
  // }, [questionInChest, fillInTheBlankAnswer]);

  const allquedata = allQuesAns[selectedQuestion];

  const [answers, setAnswers] = useState(
    allQuesAns[selectedQuestion]?.fillInTheBlankSolution || []
  );

  // useEffect(() => {
  //   console.log('the anserss', answers);
  // }, [answers]);

  const countOccurrences = (statement) => {
    const regex = new RegExp('insert_input', 'g');
    const s = statement.match(regex);
    const count = (statement.match(regex) || []).length;
    return count;
  };

  const checkInputValidation = (value) => {
    const noSpacesPattern = RegExp(/[\s][\s]+/);
    if (value) {
      return !noSpacesPattern.test(value);
    } else return true;
  };

  const checkAllBlanksAnswerd = (ans) => {
    const numberOfOccurence = countOccurrences(inputString);
    if (numberOfOccurence === ans.length) {
      if (ans.some((e) => e === ' ')) {
        return false;
      }
      return !ans.some((e) => !!!e);
    }
    return false;
  };

  const handleAnswerSubmit = (event) => {
    const inputIndex = event.target.dataset.index;
    const answer = event.target.value;
    const isinputValid = !!checkInputValidation(answer);
    if (isinputValid) {
      setAnswers((prevAnswers) => {
        const newAnswers = [...prevAnswers];
        newAnswers[inputIndex] = answer;
        const isAnswerdComplete = checkAllBlanksAnswerd(newAnswers);
        // setIsSubmitValid(isAnswerdComplete);
        if (isAnswerdComplete) {
          setSelect(newAnswers);
        } else {
          setSelect([]);
        }
        return newAnswers;
      });
    }
  };

  const renderTextWithBlanks = () => {
    const regex = /insert_input/g;
    // questionInChest.description;

    const parts = inputString.split(regex);
    const inputCount = parts.length - 1;
    const result = parts.map((part, index) => {
      if (index < inputCount) {
        return (
          <React.Fragment key={index}>
            {renderText(part)}
            <input
              type='text'
              // placeholder='insert_input'
              // pattern='^[^\s]+$'
              value={answers[index]}
              data-index={index}
              onChange={handleAnswerSubmit}
            />
          </React.Fragment>
        );
      }
      return renderText(part);
    });
    // console.log('the lllooo', result);
    return result;
  };

  return (
    <div key={key}>
      <div className='question-box mb-4'>
        <div className='d-flex justify-content-between'>
          <h4>Question - {selectedQuestion + 1}</h4>
          <span>
            <button
              type='button'
              id={key}
              onClick={() =>
                startListening(
                  readText(
                    (questionInChest.title
                      ? questionInChest.title + '. '
                      : '') + questionInChest.description
                  )
                )
              }
            >
              {' '}
              <img
                className='span-one speaker-btn'
                src={speaker}
                alt='speaker icon'
              />
            </button>
            <button
              className='text-right'
              onClick={() => addBookmark(selectedQuestion)}
              type='button'
            >
              <img
                className={
                  allquedata?.Bookmarked ? 'bookmarkfilled-img' : 'bookmark-img'
                }
                src={allquedata?.Bookmarked ? bookmarkfilledimg : bookmarkimg}
                alt='bookmark'
              />
            </button>
          </span>
        </div>

        <h4 className='title'>
          {questionInChest.title ? renderText(questionInChest.title) : ''}
        </h4>
        <div className='fill-in-the-blanks'>
          {renderTextWithBlanks()}
          {/* {finalQuesText} */}
        </div>
      </div>
    </div>
  );
};

FillInTheBlanks.propTypes = {
  key: string,
  questionInChest: object.isRequired,
  setSelect: func.isRequired,
  selectedOption: array.isRequired,
  selectedQuestion: number.isRequired,
  allQuesAns: array.isRequired,
  addBookmark: func.isRequired,
};

export default FillInTheBlanks;
