import * as actionTypes from './sullabusTypes';

const ipLocation = localStorage.getItem('ipLocation');

const initialState = {
  countryList: null,
  syllabusData: null,
  ipCountryData: JSON.parse(ipLocation) || null,
  //   gradeList: {},
  //   syllabusList: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ALL_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case actionTypes.UPDATE_SYLLABUS:
      return {
        ...state,
        syllabusData: action.payload,
      };
    case actionTypes.UPDATE_COUNTRY_BASED_ON_IP:
      return {
        ...state,
        ipCountryData: action.payload,
      };
    // case actionTypes.UPDATE_SYLLABUS:
    //   return {
    //     ...state,
    //   };
    default:
      return { ...state };
  }
}
