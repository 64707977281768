export const STUDENT_DASHBOARD = 'STUDENT_DASHBOARD';
export const UPDATE_STUDENT_DASHBOARD = 'UPDATE_STUDENT_DASHBOARD';

export const MY_REPORT_ANALYTICS = 'MY_REPORT_ANALYTICS';
export const UPDATE_MY_REPORT_ANALYTICS = 'UPDATE_MY_REPORT_ANALYTICS';

export const CUMULATIVE_REPORT = 'CUMULATIVE_REPORT';
export const UPDATE_CUMULATIVE_REPORT = 'UPDATE_CUMULATIVE_REPORT';

export const LEADERBOARD_BY_ACCURACY = 'LEADERBOARD_BY_ACCURACY';
export const UPDATE_LEADERBOARD_BY_ACCURACY = 'UPDATE_LEADERBOARD_BY_ACCURACY';

export const LEADERBOARD_BY_ACCURACY_OLYMPIAD =
  'LEADERBOARD_BY_ACCURACY_OLYMPIAD';
export const UPDATE_LEADERBOARD_BY_ACCURACY_OLYMPIAD =
  'UPDATE_LEADERBOARD_BY_ACCURACY_OLYMPIAD';

export const LEADERBOARD_BY_SPEED = 'LEADERBOARD_BY_SPEED';
export const UPDATE_LEADERBOARD_BY_SPEED = 'UPDATE_LEADERBOARD_BY_SPEED';

export const LEADERBOARD_BY_SPEED_OLYMPIAD = 'LEADERBOARD_BY_SPEED_OLYMPIAD';
export const UPDATE_LEADERBOARD_BY_SPEED_OLYMPIAD =
  'UPDATE_LEADERBOARD_BY_SPEED_OLYMPIAD';

export const BRAINGYM_START_TIME = 'BRAINGYM_START_TIME';
export const UPDATE_BRAINGYM_START_TIME = 'UPDATE_BRAINGYM_START_TIME';
