import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../../../assets/tempimages/Logo.svg';
import { parents, teachers } from '../../../../../assets/icons/IconList';
import { useHistory } from 'react-router-dom';

import RegisterOption from '../../register/RegisterOption';

const UserTypeSelectionScreen = (props) => {
  const [userType, setUserType] = useState('');
  const history = useHistory();

  return (
    <div className='login-page'>
      <div className='login-main'>
        <div className='login-head text-center'>
          <Link to='/'>
            <img src={Logo} alt='logo' />
          </Link>
          <h3>Login as</h3>
          <div className='col-12'>
            <RegisterOption
              icon={parents}
              title='Parent/Student'
              setUserType={() => setUserType('Parent/Student')}
              userType={userType}
            />
            <RegisterOption
              icon={teachers}
              title='Teacher'
              setUserType={() => {
                setUserType('Teacher');
              }}
              userType={userType}
            />
          </div>
          <div className='col-12'>
            <button
              className='submit-btn'
              disabled={userType === '' ? true : false}
              onClick={() => {
                history.push({
                  pathname: '/login',
                  search: `?userType=${userType ? userType : ''}`,
                });
              }}
            >
              Next
            </button>
            <p className='not-register'>
              Not registered yet?{' '}
              <Link to='/newregister' className='green'>
                {/* <div className='green ' onClick={handlePush}> */}
                Create an account
                {/* </div>{' '} */}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTypeSelectionScreen;
