import React from 'react';
import { array, func, number } from 'prop-types';
import DragAndDropAnswer from './DragAndDropAnswer';
import { findKeyByValue, checkDragDropSnunscramble } from '../questionHelper';
import { renderText, readText } from '../textHelper';
import bookmarkimg from '../../../../../../../assets/images/bookmark.png';
import speaker from '../../../../../../../assets/images/speaker.png';
import bookmarkfilledimg from '../../../../../../../assets/images/bookmarkfilled2.png';
import { startListening } from '../../Exam/startLearning';

const DraggAndDropQuestion = ({
  questionInChest,
  setSelect,
  selectedOption,
  selectedQuestion,
  allQuesAns,
  addBookmark,
}) => {
  const questionType = findKeyByValue(questionInChest?.solutionType);
  if (!checkDragDropSnunscramble(questionType)) return null;

  const allquedata = allQuesAns[selectedQuestion];
  return (
    <div>
      <div className='question-box'>
        <div className='d-flex justify-content-between'>
          <h4>Question - {selectedQuestion + 1}</h4>
          <span>
            <button
              type='button'
              id={selectedQuestion}
              onClick={() =>
                startListening(
                  readText(
                    questionInChest.title
                      ? questionInChest.title + '' + '. '
                      : questionInChest.description
                  )
                )
              }
            >
              {' '}
              <img
                className='span-one speaker-btn'
                src={speaker}
                alt='speaker icon'
              />
            </button>
            <button
              className='text-right'
              onClick={() => addBookmark(selectedQuestion)}
              type='button'
            >
              <img
                className={
                  allquedata?.Bookmarked ? 'bookmarkfilled-img' : 'bookmark-img'
                }
                src={allquedata?.Bookmarked ? bookmarkfilledimg : bookmarkimg}
                alt='bookmark'
              />
            </button>
          </span>
        </div>

        <h2>
          {' '}
          <span>{renderText(questionInChest?.descImageElement)}</span>
        </h2>
      </div>
      <DragAndDropAnswer
        questionInChest={questionInChest}
        questionType={questionType}
        setSelect={setSelect}
        selectedOption={selectedOption}
        selectedQuestion={selectedQuestion}
        allQuesAns={allQuesAns}
      />
    </div>
  );
};

DraggAndDropQuestion.propTypes = {
  questionInChest: array.isRequired,
  setSelect: func.isRequired,
  selectedOption: array.isRequired,
  selectedQuestion: number.isRequired,
  allQuesAns: array.isRequired,
  addBookmark: func.isRequired,
};

export default DraggAndDropQuestion;
