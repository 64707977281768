/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import { func, string, array } from 'prop-types';
// import { ChevDown } from '../../../../../assets/icons/IconList';

// Value is for controlling th selected value from outside of the component
// OnChange will fire the event whenever ayn other list item is selected.
const Dropdownstu = ({
  options,
  prompt,
  value,
  onChange,
  id,
  label,
  selectedFlag,
  defaultvalue,
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const close = () => {
    // setOpen(e && e.target === ref.current);
    // setOpen(!open);
  };

  useEffect(() => {
    document.addEventListener('click', close);
    return () => document.removeEventListener('click', close);
  }, [ref]);

  // const getClassNames = (selectedObj) => `option ${value === selectedObj ? 'selected' : null}`;

  function filter(GivenArray) {
    return GivenArray.filter(
      (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  }

  function displayValue() {
    if (query.length > 0) return query;
    if (value) return value[label];
    return '';
  }

  // For controlling the if the dropdown is open or not.

  const onKeyPressHandler = () => '';

  return (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <div className='dropdown cust-dropdown' style={{ padding: 0 }}>
      <div
        role='button'
        className='control'
        onClick={() => setOpen((prev) => !prev)}
      >
        {/* To control and open the dropdown */}
        <div
          className='selected-value'
          role='button'
          // onClick={() => setOpen((prev) => !prev)}
          style={{ position: 'relative' }}
        >
          {/* {value ? value[label] : prompt} */}
          {/* {console.log('value', open)} */}
          <div
            className={
              value
                ? 'country-flag-and-selected-value'
                : 'country-flag-and-selected-novalue'
            }
          >
            {selectedFlag ? (
              <img className='country-flag' src={selectedFlag} alt='flag' />
            ) : (
              ''
            )}
            <input
              type='text'
              ref={ref}
              style={{ width: '100%', borderLeft: 0 }}
              defaultValue={defaultvalue}
              placeholder={value ? value[label] : prompt}
              value={displayValue()}
              onChange={(e) => {
                setQuery(e.target.value);
                onChange(null);
                setOpen(e && e.target === ref.current);
              }}
              // onBlur={()=>{
              //   setOpen((prev) => !prev)
              // }}
              // onClick={() => setOpen((prev) => !prev)}
            />
          </div>
          <div
            className={value ? 'chevDown' : 'chevDown-novalue'}
            aria-hidden
            style={{
              top: '20px',
            }}

            // ref={ref}
            // onChange={(e) => {
            //     setQuery(e.target.value);
            //     onChange(null);
            // }}
            //onClick={() => setOpen(true)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='13'
              height='13'
              fill='currentColor'
              className='bi bi-chevron-down'
              viewBox='0 0 16 16'
              // onClick={() => setOpen(() => !open)}
              // ref={ref}
              // onChange={(e) => {
              //     setQuery(e.target.value);
              //     onChange(null);
              // }}
              // onClick={() => setOpen((prev) => !prev)}
              style={{
                transformOrigin: 'center',
                transform: `${open ? 'rotate(180deg)' : 'none'}`,
                transition: 'ease 0.2s',
              }}
            >
              <path
                fillRule='evenodd'
                d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
              />
            </svg>
          </div>
        </div>

        {/* <div className={`arrow  ${open ? 'open' : null}`} /> */}
      </div>
      <div className={`options  ${open ? 'open' : null}`}>
        {filter(options).map((option) => (
          <div
            onClick={() => {
              setQuery('');
              onChange(option);
              setOpen(false);
            }}
            onKeyPress={onKeyPressHandler}
            style={{ textAlign: 'left' }}
            role='button'
            key={option[id]}
            className={`option ${value === option ? 'selected' : null}`}
          >
            {option.flag && (
              <img className='country-flag' src={option.flag} alt='flag' />
            )}

            {option[label]}
          </div>
        ))}
      </div>
    </div>
  );
};

Dropdownstu.propTypes = {
  options: array.isRequired,
  prompt: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  id: string.isRequired,
  label: string.isRequired,
  selectedFlag: string.isRequired,
  defaultvalue: string.isRequired,
};

export default Dropdownstu;
