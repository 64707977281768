import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import config from '../../helpers/config';
import {
  sendOtpParentAction,
  updateRegistrationData,
  verifyEmailAction,
  verifyOtpAction,
  deleteDocument,
  sendOtpBookDemoAction,
  uploadTeacherDocumentUploadAction,
} from '../../stores/Auth/AuthAction';

import { authServices } from '../../services/auth.services';

import { dropdownSingleValueStyles } from '../../views/components/newRegistration/customCssDefination';

import { FileUploader } from 'react-drag-drop-files';

import UploadingIcon from '../../assets/images/upload.svg';
import PhoneInput from 'react-phone-input-2';
import Countdown, { zeroPad } from 'react-countdown';
import MyParentdetailsInput from '../components/MyParentdetailsInput';
import OtpInputBox from '../components/OtpInputBox';
import Loading from '../../views/components/newRegistration/components/Loading';
import swal from 'sweetalert';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import { country } from '../../views/components/dashboard/Rightsidepart/RenewSubscription/mockData/Renewdata';

const baseUrl = config.apiBaseUrl;

const TeacherForm = ({
  setFullnameVal,
  fullnameVal,

  parentnameVal,
  setParentnameVal,

  setGradeVal,
  gradeVal,

  gradeId,
  setGradeId,

  setCountryVal,
  countryVal,

  selectedState,
  setSelectedState,

  validation,
  setValidation,

  emailVal,
  setEmailVal,

  phoneNumVal,
  setPhoneNumVal,

  gender,
  setGender,

  setStudents,
  setParentId,
  setCurrencyCode,

  sendOtp,
  otp,

  verifyEmail,
  verifyEmailData,

  uploadedFiles,
  setUploadedFiles,

  verifyOtpBookDemoRes,

  disableFields,
  setDisableFields,
  otpDisable,

  registrationData,
  updateRegistrationData,

  docDeleteResponse,
  deleteDocumentAction,
  setFileName,
  fileName,
  otpTeacherRes,
  uploadTeacherDocumentUpload,
  disableAllFields,
  setDisableAllFields,
  // countryList,
}) => {
  const [selectedGrade, setSelectedGrade] = useState('');
  // const [disableAllFields, setDisableAllFields] = useState(false);

  const [otpButtonText, setOtpButtonText] = useState('Send OTP');
  const [otpButtonpopup, setOtpButtonpopup] = useState('');
  const [show403, setShow403] = useState(false);
  const [emailvalidate, setEmailValidate] = useState(false);
  const [nameValidate, setNameValidate] = useState('');
  const [parentValidate, setParentValidate] = useState('');
  const [gradeValidate, setGradeValidate] = useState('');
  const [countryValidate, setCountryValidate] = useState('');
  const [OtpTrigAcc, setOtpTrigAcc] = useState(false);
  const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  const numRegex = /^\d+$/;
  const ApplyOtpCondition =
    otpButtonText !== 'Send OTP' && otpButtonText !== 'loading';
  // const [selectedState, setSelectedState] = useState('');

  const [allGradeValues, setAllGradeValues] = useState([]);

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setOtpButtonText('Resend OTP');
    }
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const gradeChange = (selected) => {
    setValidation((prevPerson) => ({
      ...prevPerson,
      grade: false,
      // subjectsEnrolled: [],
    }));

    // setStudentData((prev) => ({ ...prev, grade: selected }));

    // setSubjects([
    //   {
    //     id: uuid(),
    //     subject: '',
    //     subjectValue: '',
    //     months: '',
    //     classSlab: {},
    //     selectedClassSlabIndex: 0,
    //     allClassSlab: [],
    //     examsAvaileble: [],
    //     price: 0,
    //   },
    // ]);
    // setOlympiadSub([]);

    setSelectedGrade(selected);
    setGradeVal(selected?.label);
    setGradeId(selected?.value);
  };

  const getOtpText = () => {
    if (disableFields) {
      return '';
    }
    if (otpButtonText === 'Send OTP') {
      return 'Send OTP';
    }
    if (otpButtonText === 'Timer') {
      return (
        <Countdown
          renderer={renderer}
          date={Date.now() + 30000}
          zeroPadTime={3}
        />
      );
    }
    if (otpButtonText === 'loading') {
      return <Loading />;
    }
    return 'Resend OTP';
  };
  const handleOtpClick = () => {
    if (otpButtonText !== 'Timer') {
      let isFormValidated = true;
      if (fullnameVal === '') {
        setValidation((prevPerson) => ({
          ...prevPerson,
          fullName: true,
        }));
        isFormValidated = false;
      } else if (!nameRegex.test(fullnameVal)) {
        setNameValidate('Name should not contains number and character');
        isFormValidated = false;
      }
      if (parentnameVal === '') {
        setValidation((prevPerson) => ({
          ...prevPerson,
          parentName: true,
        }));
        isFormValidated = false;
      } else if (!nameRegex.test(parentnameVal)) {
        setParentValidate('Name should not contains number and character');
        isFormValidated = false;
      }

      if (!gradeVal) {
        setValidation((prevPerson) => ({
          ...prevPerson,
          grade: true,
        }));
        isFormValidated = false;
      }
      if (!countryVal) {
        setValidation((prevPerson) => ({
          ...prevPerson,
          country: true,
        }));
        isFormValidated = false;
      }
      if (!selectedState) {
        setValidation((prevPerson) => ({
          ...prevPerson,
          state: true,
        }));
        isFormValidated = false;
      }
      if (isFormValidated) {
        const data = {
          studentName: fullnameVal,
          parentName: parentnameVal,
          phone: phoneNumVal,
          type: 'book-a-demo',
        };
        sendOtp(data);
        setDisableAllFields(true);
        setShow403(false);
        setOtpTrigAcc(true);
      }
    }
  };
  useEffect(() => {
    if (OtpTrigAcc) {
      if (otp.status === 'success') {
        setOtpButtonText('Timer');
      } else if (otp?.statusCode === 403) {
        setShow403(true);
        setOtpButtonText('Send OTP');
      } else {
        setOtpButtonText('loading');
      }
    }
  }, [otp]);

  useEffect(() => {
    if (verifyOtpBookDemoRes.statusCode === 200) {
      // let obj = { ...validation };
      // if (gradeVal) obj = { ...obj, isStudentValidated: true, grade: false };
      // if (!gradeVal) obj = { ...obj, isStudentValidated: true, grade: true };
      // if (countryVal)
      //   obj = { ...obj, isStudentValidated: true, country: false };
      // if (!countryVal)
      //   obj = { ...obj, isStudentValidated: true, country: true };
      setValidation((prev) => ({ ...prev, isStudentValidated: true }));
    }
  }, [verifyOtpBookDemoRes]);

  useEffect( ()=>{
    const otpData = async () => {

    if (otp.statusCode === 200) {
      // setDisableAllFields(true);
      // if (registrationData?.students) {
      //   setValidation({ ...validation, isStudentValidated: true });
      //   return;
      // }
      // setParentId(otp?.data?.parent);
      // setCurrencyCode(otp?.data?.currencyCode);
      // if (otp?.data?.otpVerified && otp?.data?.emailVerified) {
      //   if (otp?.data?.students?.length > 0) {
      //     const registerdStudents = otp?.data?.students.map((eachStudent) => ({
      //       name: eachStudent?.studentName,
      //       lastName: eachStudent?.lastName,
      //       userName: eachStudent?.userName,
      //       id: eachStudent?.id,
      //       school: eachStudent?.schoolName,
      //       pastRegisterdData: eachStudent,
      //     }));
      //     setOtpButtonText('loading');
      //   }
      //   swal('OTP already verified. Please proceed to next step');
      //   setValidation({ ...validation, isStudentValidated: true });
      // }
    } else if (otp?.statusCode && OtpTrigAcc) {
      if (otp?.message) {
        swal(otp?.message);
      }
    }
  }
  

    otpData();

  }, [otp]);

  useEffect( ()=> {
   const fetchData = async () => {
    const AllBoards = [
      {
        _id: '61c33124b6b2aa0009e0ddcf',
        name: 'Global Integrated Curriculum',
        logo: null,
        cutOffPercentage: 80,
      },
      {
        _id: '62d99a44fc1f87f16cef95bd',
        name: 'Central Board of Secondary Education',
        logo: null,
        cutOffPercentage: 60,
      },
    ];
    const gradeList = await authServices.getAllGrades({
      boardId: AllBoards[0]?._id,
    });
    if (gradeList?.data?.length > 0) {
      const gdata = gradeList?.data?.map((data) => ({
        value: data._id,
        label: data.name,
      }));
      setAllGradeValues(gdata);
    }

    const allCountryList = await authServices.getAllCountryList();
    if (allCountryList?.data?.length > 0) {
      setCountryList(allCountryList?.data);
    }
  }
     fetchData();


  }, []);

  useEffect( ()=>{
    const stateData =async () => {
    if (countryVal) {
      const allStateList = await authServices.getStateList(countryVal?.code);
      if (allStateList?.data?.length > 0) {
        setStateList(allStateList?.data);
      }
    }
  }
    stateData();
  }, [countryVal]);

  return (
    <div className='mydetails-main coursedetails-main'>
      <div className='row '>
        <div className='col-lg-6 mydetail-input'>
          <MyParentdetailsInput
            label='Student name*'
            type='text'
            name='full_name'
            id='full-name'
            disableFields={disableAllFields}
            placeHolder='Enter your first name and last name'
            value={fullnameVal}
            setValue={(value) => {
              value = value?.length > 32 ? value?.slice(0, 32) : value;
              setValidation((prevPerson) => ({
                ...prevPerson,
                fullName: false,
              }));
              setNameValidate('');
              setFullnameVal(value);
            }}
            onBlur={(e) => {
              if (nameRegex.test(e.target.value)) {
                if (
                  e.target.value.trim() &&
                  parentnameVal.trim() &&
                  e.target.value.trim() === parentnameVal.trim()
                )
                  setNameValidate(
                    'Parent name and student name should be different'
                  );
                else {
                  setNameValidate('');
                  setParentValidate('');
                }
              } else setNameValidate('Please enter a valid student name.');
            }}
          />
          {validation.fullName && (
            <span className='error-msg'>Full name is required. </span>
          )}
          {!validation.fullName && nameValidate !== '' ? (
            <span className='error-msg'>{nameValidate}</span>
          ) : (
            ''
          )}
        </div>
        <div className='col-lg-6 mydetail-input'>
          <MyParentdetailsInput
            label='Parent name*'
            type='text'
            name='full_name'
            id='full-name'
            placeHolder='Enter your first name and last name'
            value={parentnameVal}
            disableFields={disableAllFields}
            setValue={(value) => {
              value = value?.length > 32 ? value?.slice(0, 32) : value;
              //OnChange event
              setValidation((prevPerson) => ({
                ...prevPerson,
                parentName: false,
              }));
              setParentValidate('');
              setParentnameVal(value);
            }}
            onBlur={(e) => {
              if (nameRegex.test(e.target.value)) {
                if (
                  e.target.value.trim() &&
                  fullnameVal.trim() &&
                  e.target.value.trim() === fullnameVal.trim()
                )
                  setParentValidate(
                    'Parent name and student name should be different'
                  );
                else {
                  setNameValidate('');
                  setParentValidate('');
                }
              } else setParentValidate('Please enter a valid parent name.');
            }}
          />
          {validation.parentName && (
            <span className='error-msg'>Full name is required. </span>
          )}
          {!validation.parentName && parentValidate !== '' ? (
            <span className='error-msg'>{parentValidate}</span>
          ) : (
            ''
          )}
        </div>

        <div className='col-md-6 course-detail-select'>
          <div className='custom-dropdown book-demo-grade'>
            <div className='label-div'>Grade*</div>
            <Select
              id='grade'
              name='grade'
              options={allGradeValues}
              components={{
                IndicatorSeparator: () => null,
              }}
              isDisabled={disableAllFields}
              onChange={gradeChange}
              // styles={dropdownSingleValueStyles}
              value={selectedGrade}
              placeholder={
                <div className='select-placeholder-text'>Select grade</div>
              }
            />
          </div>

          {validation.grade && (
            <span className='error-msg'>Grade is required.</span>
          )}
        </div>
        <div className='col-md-6 course-detail-select'>
          <div className='custom-dropdown book-demo-grade'>
            <div className='label-div'>Student location*</div>
            <Select
              options={countryList?.map((itemm) => ({
                label: itemm?.country_label,
                value: itemm?._id,
                code: itemm?.country_code,
              }))}
              isDisabled={disableAllFields}
              components={{
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => {
                setValidation((prevPerson) => ({
                  ...prevPerson,
                  country: false,
                }));
                setStateList([]);
                setSelectedState('');
                // setCountryValidate('');
                setCountryVal(e);
              }}
              placeholder={
                <div className='select-placeholder-text'>Select Country</div>
              }
            />
          </div>
          {validation?.country && (
            <span className='error-msg'>Please select the country.</span>
          )}
        </div>
        <div className='col-md-6 course-detail-select'>
          <div className='custom-dropdown book-demo-grade'>
            <div className='label-div'>State*</div>
            <Select
              id='state'
              name='state'
              options={stateList?.map((itemm) => ({
                label: itemm?.state_label,
                value: itemm?._id,
              }))}
              isDisabled={disableAllFields}
              className='customSelects'
              value={selectedState}
              components={{
                IndicatorSeparator: () => null,
              }}
              onChange={(value) => {
                setSelectedState(value);
                setValidation((prevPerson) => ({
                  ...prevPerson,
                  state: false,
                }));
              }}
              placeholder='Select state'
              // styles={dropdownSingleValueStyles}
            />
          </div>
          {validation.state && (
            <span className='error-msg'>State is required.</span>
          )}
        </div>
        <div className='col-lg-6 mydetail-input'>
          <div className='mydetail-input-part'>
            <label htmlFor='detail-label'>Mobile*</label>
            <>
              <div className={'phone-input-custom'}>
                <PhoneInput
                  specialLabel=''
                  country='in'
                  value={phoneNumVal}
                  disabled={otpDisable}
                  // autoFormat={false}
                  countryCodeEditable={false}
                  inputStyle={{
                    fontSize: 20,
                    buttonStyle: {
                      display: 'none',
                    },
                  }}
                  placeholder='Enter your mobile number'
                  onChange={(e) => {
                    setValidation((prevPerson) => ({
                      ...prevPerson,
                      phoneNumber: false,
                    }));
                    setOtpButtonText('Send OTP');

                    setPhoneNumVal(e);
                  }}
                />
              </div>
              <button
                type='button'
                onClick={handleOtpClick}
                style={{
                  display: !otpDisable
                    ? (
                        phoneNumVal.slice(0, 2) === '91'
                          ? phoneNumVal.length >= 12
                          : phoneNumVal.length >= 11
                      )
                      ? 'block'
                      : 'none'
                    : 'none',
                }}
              >
                {getOtpText()}
              </button>
            </>
          </div>

          {otp?.status === 'success' && ApplyOtpCondition && (
            <span className='d-flex success-msg'>OTP sent</span>
          )}
          {validation.phoneNumber && (
            <span className='error-msg'>Phone number is required.</span>
          )}
          {otp?.statusCode === 400 && show403 && (
            <span className='error-msg'>
              This mobile number already registered.
            </span>
          )}
          {otp?.statusCode === 403 && show403 && (
            <span className='error-msg'>
              This mobile number already registered.
            </span>
          )}
        </div>
        {!(otp.status === 'success' && ApplyOtpCondition) ? (
          <div className='col-lg-6 mydetail-input'></div>
        ) : (
          ''
        )}
        {otp.status === 'success' && ApplyOtpCondition && (
          <OtpInputBox
            label='Enter OTP'
            phoneNumVal={phoneNumVal}
            isDisabled={otpDisable}
            type={'book-demo'}
          />
        )}

        {/* <div className='col-md-6 mydetail-input mydetail-input'>
          <MyParentdetailsInput
            label='Grade*'
            type='number'
            name='grade'
            id='grade-name'
            placeHolder='Enter grade'
            value={gradeVal}
            setValue={(value) => {
              value = value.replace(/^0/, '');
              value = value > 99 ? Number(value.toString().slice(0, 2)) : value;
              setValidation((prevPerson) => ({
                ...prevPerson,
                grade: false,
              }));
              setGradeValidate('');
              setGradeVal(value);
            }}
            onBlur={(e) => {
              if (numRegex.test(e.target.value)) setGradeValidate('');
              else setGradeValidate('Please enter a valid grade.');
            }}
          />
          {validation.grade && (
            <span className='error-msg'>Grade is required. </span>
          )}
          {!validation.grade && gradeValidate !== '' ? (
            <span className='error-msg'>{gradeValidate}</span>
          ) : (
            ''
          )}
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  otp: state.Auth.sendOtpBookDemo,
  verifyEmailData: state.Auth.verifyEmailData,
  verifyOtpBookDemoRes: state.Auth.verifyOtpBookDemo,
  registrationData: state.Auth.registrationData,
  docDeleteResponse: state.Auth.docDelete,
  docDeleteResponse: state.Auth.docDelete,
});

const mapDispatchToProps = (dispatch) => ({
  sendOtp: (data) => dispatch(sendOtpBookDemoAction(data)),
  verifyEmail: (data) => dispatch(verifyEmailAction(data)),
  updateRegistrationData: (data) => dispatch(updateRegistrationData(data)),
  deleteDocumentAction: (data) => dispatch(deleteDocument(data)),
  uploadTeacherDocumentUpload: (data) =>
    dispatch(uploadTeacherDocumentUploadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherForm);
