import React from 'react';
import { array, string, func } from 'prop-types';
import Mysubjectcountcommon from './Mysubjectcountcommon';

const Mysubjectcount = ({
  concepts,
  conceptsData,
  setQuestionsData,
  setChapterName,
  setConceptName,
  setSubConName,
  setConceptId,
  setChapterId,
  setSubConceptsList
}) => (
  <div className='mysubject-count'>
    <Mysubjectcountcommon
      conceptsArray={concepts}
      setQuestionsData={setQuestionsData}
      conceptsData={conceptsData}
      setChapterName={setChapterName}
      setConceptName={setConceptName}
      setSubConName={setSubConName}
      setChapterId={setChapterId}
                    setConceptId={setConceptId}
                    setSubConceptsList={setSubConceptsList}  
    />
  </div>
);

Mysubjectcount.propTypes = {
  concepts: array.isRequired,
  setQuestionsData: string.isRequired,
  conceptsData: string.isRequired,
  setChapterName: func.isRequired,
  setConceptName: func.isRequired,
  setSubConName: func.isRequired,
};

export default Mysubjectcount;
