import http from '../helpers/Axios';
const token = JSON.parse(localStorage.getItem('tautmore-user'));

// import config from '../helpers/config';
// import { fetchWrapper } from '../helpers/fetch-wrapper';

// export function longestStreak(params, token) {
//   return fetchWrapper.post(`${baseUrl}/brainGym/longest-streak`, params, token);
// }

export const getStudentOnlineClassCount = async (params, accessToken) => {
  http.defaults.headers.common['Authorization'] = accessToken;
  return http.get(
    '/my-subjects/online-class-count',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );
};

export const getsyllabusListing = async (params, accessToken) =>
  http.get(
    '/my-subjects/syllabus-listing',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );

export const getsyllabusListingOlympiad = async (params, accessToken) =>
  http.get(
    '/practice-olympiad/syllabus-listing',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );

export const takePracticeTest = async (data, accessToken) => {
  return http.post('/my-subjects/take-test', data, {
    headers: { Authorization: accessToken },
  });
};

export const takeOlympiadPracticeTest = async (data, accessToken) => {
  return http.post('/practice-olympiad/take-test', data, {
    headers: { Authorization: accessToken },
  });
};

export const takePracticeWithoutLogin = async (data) =>
  http.post('/practice-test/take-test', data, {
    headers: { Authorization: token?.data?.accessToken },
  });

export const getQuestionsInTestWLogin = async (params) =>
  http.get(
    '/practice-test/question-by-id',
    { params },
    {
      headers: { Authorization: token?.data?.accessToken },
    }
  );

export const getQuestionsInTest = async (params, accessToken) =>
  http.get(
    '/my-subjects/question-by-id',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );

export const getOlympiadQuestionsInTest = async (params, accessToken) =>
  http.get(
    '/practice-olympiad/question-by-id',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );

export const submitAnswerPracWLogin = async (data) =>
  http.post('/practice-test/attempt-question', data, {
    headers: { Authorization: token?.data?.accessToken },
  });

export const submitAnswerPrac = async (data, accessToken) =>
  http.post('/my-subjects/attempt-question', data, {
    headers: { Authorization: accessToken },
  });

export const submitOlympiadAnswerPrac = async (data, accessToken) =>
  http.post('/practice-olympiad/attempt-question', data, {
    headers: { Authorization: accessToken },
  });

export const getVideoAndContent = async (params, accessToken) => {
  return http.get(
    '/my-subjects/videos-and-contents',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );
};

export const getOlympiadVideoAndContent = async (params, accessToken) => {
  return http.get(
    '/practice-olympiad/videos-and-contents',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );
};

export const getTeacherSyllabus = async (params, accessToken) => {
  http.defaults.headers.common['Authorization'] = accessToken;
  return http.get(
    '/my-subjects/teachers/syllabus-listing',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );
};

export const getTeacherVideoAndContent = async (params, accessToken) => {
  http.defaults.headers.common['Authorization'] = accessToken;
  return http.get(
    '/my-subjects/teachers/videos-and-contents',
    { params },
    {
      headers: { Authorization: token?.data?.accessToken },
    }
  );
};

export const checkPracticeTestComplted = async (params, accessToken) =>
  http.get(
    '/my-subjects/completion-status',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );

export const resetPraticeTest = async (data, accessToken) =>
  http.post('/my-subjects/reset-test', data, {
    headers: {
      Authorization: accessToken ? accessToken : token?.data?.accessToken,
    },
  });

export const resetOlympiadPraticeTest = async (data, accessToken) =>
  http.post('/practice-olympiad/reset-test', data, {
    headers: {
      Authorization: accessToken ? accessToken : token?.data?.accessToken,
    },
  });

export const getPracticeTestResults = async (params, accessToken) =>
  http.get(
    '/my-subjects/stats',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );

export const getOlympiadPracticeTestResults = async (params, accessToken) =>
  http.get(
    '/practice-olympiad/stats',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );

export const completeTestNotificationTrigg = async (params, accessToken) =>
  http.get(
    '/my-subjects/practice-test/complete/withLogin',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );
export const completeOlympiadTestNotificationTrigg = async (
  params,
  accessToken
) =>
  http.get(
    '/my-subjects/practice-olympiad/complete/withLogin',
    { params },
    {
      headers: { Authorization: accessToken },
    }
  );
