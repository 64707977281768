import { object } from 'prop-types';
import React from 'react';
import { useState, useEffect } from 'react';
import Lottie from 'react-lottie';

import { ChooseCorrectPreview } from './choose-correct/chooseCorrectPreview';
import DragAndDropSolutionPreview from './DragAndDropQuestion/DragAndDropSolutionPreview';
import FillInTheBlankSolutionPreview from './FillTheBlanks/FillInTheBlanksPreview';
import FillInTheBlanksDragAndDropPreview from './FillTheBlanksDragAndDrop/FillDragAndDropPreview';
import { MultiSelectAnswer } from './MultiSelect/multiSelectSolutionPreview';
import { PassageAnswerPreview } from './Passage/passageAnswerPreview';
import { SelectAWordFromQuesPreview } from './selectAWordFromQues/SelectAWordFromQuesPreview';

import { findKeyByValue } from '../BrainGym/QueAnswer/questionHelper';
import { renderText } from '../BrainGym/QueAnswer/textHelper';

import rightaudio from '../../../../../assets/audio/test2.mp3';
import wrongaudio from '../../../../../assets/audio/wrong.mp3';
import { Success, Failure } from '../../../../../assets/animations';

import './practiceCustom.scss';

const Rightaudio = new Audio(rightaudio);
const Wrongaudio = new Audio(wrongaudio);

const successOptions = {
  loop: true,
  autoplay: true,
  animationData: Success,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const failureOptions = {
  loop: true,
  autoplay: true,
  animationData: Failure,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const confetti = {
  sucess: { icon: successOptions, audio: Rightaudio },
  failure: { icon: failureOptions, audio: Wrongaudio },
};

const RenderConfetti = ({ confetti }) => {
  const [showConfeti, setShowConfeti] = useState(true);
  useEffect(() => {
    confetti.audio.play();
    setTimeout(() => {
      // confetti.audio.pause();
      setShowConfeti(false);
    }, [2500]);
  }, []);

  return (
    showConfeti && (
      <div className='confetiPopup'>
        <Lottie options={confetti.icon} height={300} width={300} />
      </div>
    )
  );
};

const PracticeAttemptAnswers = ({ statusVal, setNextButtonClicked }) => {
  const questionInChest = statusVal?.questionInfo;
  const questionType = findKeyByValue(statusVal?.questionInfo?.solutionType);
  useEffect(() => {
    setNextButtonClicked(false);
  }, [statusVal]);

  return (
    <div>
      {statusVal && (
        <RenderConfetti
          confetti={
            statusVal?.isCorrect || statusVal?.marksObtained
              ? confetti.sucess
              : confetti.failure
          }
        />
      )}

      {questionType === 'match-the-following' ||
      questionType === 'drag-and-drop' ||
      questionType === 'select-the-word-to-match-the-correct-option' ? (
        <DragAndDropSolutionPreview
          questionInChest={questionInChest}
          statusVal={statusVal}
        />
      ) : questionType == 'multi' ? (
        <MultiSelectAnswer
          questionInChest={questionInChest}
          statusVal={statusVal}
        />
      ) : questionType == 'select-the-suitable-words' ? (
        <SelectAWordFromQuesPreview
          questionObject={questionInChest}
          statusVal={statusVal}
        />
      ) : questionType == 'fill-in-the-blanks' ? (
        <FillInTheBlankSolutionPreview
          questionObject={questionInChest}
          statusVal={statusVal}
        />
      ) : questionType == 'drag-and-match-the-correct-answer' ? (
        <FillInTheBlanksDragAndDropPreview
          questionInChest={questionInChest}
          statusVal={statusVal}
        />
      ) : questionType === 'choose-the-correct-answer' ||
        questionType === 'statement-and-support' ||
        questionType === 'two-columns-option' ||
        questionType === 'open-ended-questions' ||
        questionType === 'selecting-the-word-sentence' ||
        questionType === 'error-findings' ? (
        <ChooseCorrectPreview statusVal={statusVal} />
      ) : questionType == 'passage' ? (
        <div>
          <div className='col-12 mb-4'>
            <div className='question-box'>
              <h4>
                {questionInChest?.title == '' || questionInChest?.title == null
                  ? 'Passage type question'
                  : renderText(questionInChest?.title)}
              </h4>
              <div className='col-12 d-flex align-items-center justify-content-between'>
                <h2 className='col-11 '>
                  <span>{renderText(questionInChest?.description)}</span>
                </h2>
              </div>
            </div>
          </div>

          {questionInChest?.passageQuestions?.map((ques, pasIndex) => (
            <PassageAnswerPreview
              question={ques}
              resultQuestion={statusVal?.result?.passageQuestions[pasIndex]}
            />
          ))}
        </div>
      ) : (
        (<div>passage</div>)(<div>passage</div>)
      )}
    </div>
  );
};
PracticeAttemptAnswers.propTypes = {
  statusVal: object.isRequired,
};

export default PracticeAttemptAnswers;
