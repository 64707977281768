import React, { useState, useEffect } from 'react';
import Mysubjectstop from './Mysubjectstop';
import Mysubjectlist from './Mysubjectlist';
import {
  getStudentOnlineClassCount,
  getTeacherSyllabus,
} from '../../../../../services/studentMySubjects.service';
import SubTopic from './SubTopic/SubTopic';
import { WaitingLoader } from '../../../../components/waitingLoader';
import Mysubjectlisttop from './Mysubjectlisttop';
import { connect } from 'react-redux';

const _ = require('lodash');

const MySubjects = (token) => {
  const localData = JSON.parse(localStorage.getItem('taut-student'));

  // const [classCount, setClassCount] = useState({});
  const [concepts, setConcepts] = useState([]);
  const [conceptsCount, setConceptsCount] = useState('');
  const [conceptsCleared, setConceptsCleared] = useState();
  const [percentValue, setPercentValue] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [initialGrade, setInitialGrade] = useState(
    localData?.data?.subject?.[0]?.class?.name
  );
  const [initialGradeId, setInitalGradeId] = useState(
    localData?.data?.subject?.[0]?.class?._id
  );
  const [initialSubject, setInitialSubject] = useState(
    localData?.data?.subject?.[0]?._id
  );
  const [initailSubName, setInitialSubName] = useState(
    localData?.data?.subject?.[0]?.name
  );
  const [conceptsData, setConceptsData] = useState();
  const [startQuestions, setStartQuestions] = useState('');
  const [chapterName, setChapterName] = useState('');
  const [conceptName, setConceptName] = useState('');
  const [subConName, setSubConName] = useState('');
  const [conceptsLoading, setConceptsLoading] = useState('');
  const [conceptId, setConceptId] = useState('');
  const [chapterId, setChapterId] = useState('');
  const [subConceptsList, setSubConceptsList] = useState('');

  // const studentsOnlineCount = async () => {
  //   const params = {
  //     student: localData.data._id,
  //   };
  //   try {
  //     const res = await getStudentOnlineClassCount(params, token);
  //     if (res.status === 200) {
  //       setClassCount(res?.data?.data?.onlineClassInfo);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getStudentChaptersList = async () => {
    setConcepts([]);
    const params = {
      teacher: localData.data._id,
      subject: initialSubject,
      class: initialGradeId,
    };
    try {
      setConceptsLoading(true);
      const res = await getTeacherSyllabus(
        params,
        localData?.data?.accessToken
      );
      if (res.status === 200) {
        setConcepts(res?.data?.data?.syllabus);
        if (res?.data?.data?.syllabus?.length) setConceptsLoading(false);

        const inf = res?.data?.data?.syllabus;

        const conCount = inf?.map((d) =>
          _.sumBy(d.concepts, (item) => item.subConceptsCount)
        );
        const count = _.sumBy(conCount, (item) => item);
        const a = inf?.map((d) =>
          _.sumBy(d.concepts, (item) => item.completedSubConceptsCount)
        );
        const clear = _.sumBy(a, (item) => item);
        const percentVal = Math.round((clear / count) * 100);
        setConceptsCount(count);
        setConceptsCleared(clear);
        setPercentValue(percentVal);
        setConceptsData(inf?.[0].index);
      }
    } catch (error) {
      setConceptsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (localData?.data?.userType === 'teacher') {
      setSubjects(localData?.data?.subject);
      // studentsOnlineCount();
      getStudentChaptersList();
    }
  }, [initialSubject]);

  const handleChange = (data, concId, conName, subCoName, subCons) => {
    const resultArray = concepts[conceptsData - 1];
    setStartQuestions(data);
    setChapterId(resultArray.chapter);
    setConceptId(concId);
    setChapterName(resultArray.chapterName);
    setConceptName(conName);
    setSubConName(subCoName);
  };

  return (
    <div>
      {startQuestions === '' && (
        <div>
          <Mysubjectstop />
          <Mysubjectlisttop
            subjects={subjects}
            setInitialSubject={setInitialSubject}
            initailSubName={initailSubName}
            setInitialGrade={setInitialGrade}
            setInitalGradeId={setInitalGradeId}
            initialGrade={initialGrade}
          />

          {conceptsLoading ? (
            <WaitingLoader />
          ) : (
            <Mysubjectlist
              // classCount={classCount}
              conceptsCount={conceptsCount}
              conceptsCleared={conceptsCleared}
              percentValue={percentValue}
              concepts={concepts}
              subjects={subjects}
              setInitialSubject={setInitialSubject}
              initailSubName={initailSubName}
              setInitialSubName={setInitialSubName}
              setConceptsData={setConceptsData}
              conceptsData={conceptsData}
              setInitialGrade={setInitialGrade}
              setInitalGradeId={setInitalGradeId}
              initialGrade={initialGrade}
              initialGradeId={initialGradeId}
              setQuestionsData={setStartQuestions}
              setChapterName={setChapterName}
              setConceptName={setConceptName}
              setSubConName={setSubConName}
              setChapterId={setChapterId}
              setConceptId={setConceptId}
              setSubConceptsList={setSubConceptsList}
            />
          )}
        </div>
      )}

      {startQuestions !== '' && (
        <SubTopic
          startQuestions={startQuestions}
          initialSubject={initialSubject}
          chapterId={chapterId}
          conceptId={conceptId}
          initailSubName={initailSubName}
          chapterName={chapterName}
          conceptName={conceptName}
          subConName={subConName}
          setQuestionsData={setStartQuestions}
          subConceptsList={subConceptsList}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  token: state.Auth.Login?.data?.accessToken,
});

export default connect(mapStateToProps)(MySubjects);
