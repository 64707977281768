import React from 'react';
import { array, string, func } from 'prop-types';
import { useHistory } from 'react-router';

const Mysubjectcountcommon = ({
  conceptsArray,
  setQuestionsData,
  conceptsData,
  setChapterName,
  setConceptName,
  setSubConName,
  setChapterId,
  setConceptId,
  setSubConceptsList,
}) => {
  const history = useHistory();
  // const resultArray = conceptsArray[conceptsData - 1];
  const resultArray = conceptsArray.find(concept => concept.index === conceptsData);
  const handleChange = (data, concId, conName, subCoName, subCons) => {
    setQuestionsData(data);
    setChapterId(resultArray.chapter);
    setConceptId(concId);
    setChapterName(resultArray.chapterName);
    setConceptName(conName);
    setSubConName(subCoName);
    setSubConceptsList(subCons);
    history.push({
      pathname: '/teacher/subjects',
      search: '?subtopicteacher=true',
    });
  };

  return (
    <div>
      {resultArray && (
        <div>
          {resultArray.concepts.map((data) => (
            <div className='mysubject-count-common' key={data.conceptName}>
              <h5>{data.conceptName}</h5>
              <div className='row'>
                <div className='col-md-3 col-sm-6 col-12  sub-video'>
                  <p>
                    Sub-topics:
                    <span> {data?.subconcepts?.length}</span>
                  </p>
                </div>
                <div className='col-md-3 col-sm-6 col-12  sub-video'>
                  <p>
                    Video lessons: <span>{data.videos}</span>
                  </p>
                </div>
              </div>

              <div className='sub-list'>
                {data?.subconcepts.map((sublist) => (
                  <li
                    key={sublist.index}
                    onClick={() =>
                      handleChange(
                        sublist.subconcept,
                        data.concept,
                        data.conceptName,
                        sublist.subconceptName,
                        data
                      )
                    }
                    aria-hidden='true'
                  >
                    {' '}
                    {sublist.subconceptName}
                  </li>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
Mysubjectcountcommon.propTypes = {
  conceptsArray: array.isRequired,
  setQuestionsData: string.isRequired,
  conceptsData: string.isRequired,

  setChapterName: func.isRequired,
  setConceptName: func.isRequired,
  setSubConName: func.isRequired,
};

export default Mysubjectcountcommon;
