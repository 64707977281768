import { display } from '../../../../assets/icons/IconList';

const notification = [
  {
    title: 'Notifications',
    data: [
      {
        image: display,
        title: 'Upcoming class',
        desc: 'Your next class “Mathematics - Chapter 1” will begin in 30 mins',
        time: '5 mins ago',
        color: '#FFE7D9',
      },
      {
        image: display,
        title: 'Assignment',
        desc: 'You have not submitted your “Mathematics - Chapter 2” assignment',
        time: '14 mins ago',
        color: '#FFEDD4',
      },
      {
        image: display,
        title: 'Brain gym',
        desc: 'Complete the remaining brain gym chests and earn extra coins',
        time: '02:30 PM',
        color: '#DEFFCC',
      },
    ],
  },
];

export default notification;
