export const subject = ['Mathematics', 'Science', 'English'];

export const time = [
  'weekly',
  // 'daily',
  'monthly',
  'quarterly',
  'half-yearly',
  'annually',
];
export const FrameData = [
  // 'All time',
  'last_7_days',
  'last_30_days',
  'last_365_days',
];

export const RevenueTimeDat = [
  { value: 'last_7_days', name: 'last 7 days' },
  { value: 'last_30_days', name: 'last 30 days' },
  { value: 'last_365_days', name: 'last 365 days' },
];
// {
//     label: 'Daily',
//     value: 'daily',
// },
// {
//     label: 'Weekly',
//     value: 'weekly',
// },
// {
//     label: 'Monthly',
//     value: 'monthly',
// },
// {
//     label: 'Quarterly',
//     value: 'quarterly',
// },
// {
//     label: 'Half-yearly',
//     value: 'half-yearly',
// },
// {
//     label: 'Annually',
//     value: 'annually',
// }
