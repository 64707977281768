import React, { useState, useEffect } from 'react';
import { object, func, bool, array } from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';
import OnlineClassalert from '../../OnlineClassalert';
import MyClassesTitle from '../../../../components/dashboard/Rightsidepart/MyexamTitle';
import MyClassListCommon from './MyClassListCommon';
import SimpleSlider from '../../Slider';
import SubjectTab from './SubjectTab';
import ReschedulePopup from './ReschedulePopup';
import {
  getMyClassesAction,
  getMyClassesHistoryAction,
} from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import newTeacherClassIcon from '../../../../../assets/icons/teacherIcon/classes.svg';

const MyClass = ({
  lgShow,
  setLgShow,
  myClasses,
  getMyClasses,
  getMyClassesHistory,
  myClassesHistory,
  getProfile,
}) => {
  const [model, setModel] = useState(false);
  const [myClassesList, setMyClassesList] = useState([]);
  const [scheduleId, setScheduleId] = useState('');
  const [remainingTime, setRemainingTime] = useState('');
  const [selectedSub, setSelectedSub] = useState('');
  const [topHeaderData, setTopHeaderData] = useState([]);
  const [resetPage, setResetPage] = useState(false);

  useEffect(() => {
    if (myClasses?.data) {
      const cdata = myClasses?.data[0]?.response?.map((data) => ({
        grade: data?.gradeInfo?.name,
        subject: data?.subjectInfo.name,
        numberOfStudent: data?.numOfStudents,
        date: data?.scheduleInfo?.date,
        duration: data?.scheduleInfo?.duration,
        timeslot: data?.scheduleInfo?.timeSlot,
        startTime: data?.scheduleInfo?.startTime,
        id: data?.scheduleInfo?._id,
        // startUrl: data?.scheduleInfo?.startUrl,
        subjectInfo: data?.subjectInfo,
        scheduleInfo: data?.scheduleInfo,
        timezone: data?.timezone,
        subTopic: data?.chapterInfo?.topic,
        subChapter: data?.chapterInfo?.chapter,
        icons: data?.icons,
      }));
      setMyClassesList(cdata);
    }
  }, [myClasses]);

  useEffect(() => {
    if (!myClassesHistory?.data?.length > 0) {
      getMyClassesHistory();
    }
  }, [myClassesHistory]);
  useEffect(() => {
    // if (resetPage) {
    getMyClasses();

    // Schedule data refresh every 5 seconds
    const interval = setInterval(getMyClasses, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
    // }
  }, [resetPage]);

  const Loader = () => (
    <div
      style={{
        height: '100vh',
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation='border' variant='warning' />
    </div>
  );

  const EmptyMsg = () => <div> {/* <h3>No classes found.</h3>{' '} */}</div>;
  useEffect(() => {
    setTopHeaderData(
      myClassesList?.filter(
        (item) => `${item.grade}${item.subject}` === selectedSub
      )
    );
  }, [selectedSub]);

  return (
    <div>
      {!myClasses.data ? (
        <>
          <Loader />
        </>
      ) : (
        <div>
          <OnlineClassalert
            latestClass={myClassesList?.[0]}
            setRemainingTime={setRemainingTime}
            setResetPage={setResetPage}
          />

          <div className='myexam-main'>
            <MyClassesTitle
              title={`Upcoming classes (${
                myClassesList && myClasses?.data?.[0]?.count[0]?.count
                  ? myClasses?.data?.[0]?.count[0]?.count
                  : '0'
              })`}
              titleIcon={newTeacherClassIcon}
            />
            {myClassesList.length > 0 ? (
              <SubjectTab
                data={getProfile?.data?.subjects}
                setSelectedSub={setSelectedSub}
              />
            ) : (
              <EmptyMsg />
            )}
            <div className='row'>
              <div className='col-md-12 col-sm-12 pl-5 pr-5'>
                {myClassesList?.filter(
                  (item) => `${item.grade}${item.subject}` === selectedSub
                )?.length === 0 ? (
                  <p className='noClassAva-text'>
                    We're planning new classes for you. Once it is assigned,
                    it'll show up here.
                  </p>
                ) : (
                  <SimpleSlider
                    myClassesList={myClassesList?.filter(
                      (item) => `${item.grade}${item.subject}` === selectedSub
                    )}
                    handleSubjectModel={setLgShow}
                    handleModel={setModel}
                    setScheduleId={setScheduleId}
                    showSubjectModel={lgShow}
                    setTopHeaderData={setTopHeaderData}
                  />
                )}
              </div>
            </div>

            <MyClassesTitle
              title={`Completed classes (${
                myClassesHistory?.data?.[0]?.count?.length > 0
                  ? myClassesHistory?.data?.[0]?.count[0]?.count
                  : '0'
              })`}
              titleIcon={newTeacherClassIcon}
            />
            {myClassesHistory?.data?.[0]?.response?.filter(
              (item) =>
                `${item.gradeInfo.name}${item.subjectInfo.name}` === selectedSub
            )?.length > 0 ? (
              <div className='myexam-examlist'>
                {myClassesHistory?.data?.[0]?.response
                  ?.filter(
                    (item) =>
                      `${item.gradeInfo.name}${item.subjectInfo.name}` ===
                      selectedSub
                  )
                  ?.map((data, index) => (
                    <MyClassListCommon val={data} index={index} />
                  ))}
              </div>
            ) : (
              <h4 className='noClassAva-text'>
                When you teach a class, it will get reflected here.
              </h4>
            )}
          </div>
          <ReschedulePopup
            getMyClasses={getMyClasses}
            model={model}
            handleModel={setModel}
            scheduleId={scheduleId}
          />
        </div>
      )}
    </div>
  );
};
MyClass.propTypes = {
  lgShow: bool.isRequired,
  setLgShow: func.isRequired,
  myClasses: object.isRequired,
  getMyClasses: func.isRequired,
  myClassesHistory: array.isRequired,
  getMyClassesHistory: func.isRequired,
  getProfile: array.isRequired,
};

const mapStateToProps = (state) => ({
  myClasses: state.TeacherDashboard.myClasses,
  myClassesHistory: state.TeacherDashboard.myClassesHistory,
  getProfile: state.TeacherDashboard.getProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getMyClasses: () => dispatch(getMyClassesAction()),
  getMyClassesHistory: () => dispatch(getMyClassesHistoryAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyClass);
