import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../../assets/tempimages/Logo.svg';
import IstemLogo from '../../../assets/images/istemLogo.svg';

import LeadCaptureForm from '../../../Layout/leadCaptureForm';
import './header.scss';
import NavHeader from './NavHeader';
import MobileHeader from './MobileHeader';

const useScroll = () => {
  const elRef = useRef(null);
  const executeScroll = () => elRef?.current?.scrollIntoView();

  return [executeScroll, elRef];
};

function Header() {
  const history = useHistory();
  const isMobile =
    window.innerWidth > 0 ? window.innerWidth <= '991' : screen.width <= '991';
  // console.log(window.location.pathname);
  const myRef = useRef(null);
  const [executeScroll, elRef] = useScroll();
  useEffect(executeScroll, []); // Scroll on mount
  const [bookDemo, setBookDemo] = useState(false);
  const [showLeadCaptureForm, setShowLeadCaptureForm] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleMobile = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width <= '991') {
      // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      setShowLeadCaptureForm(true);
    } else {
      history.push('/login');
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // fade: true,
    // centerMode: true,
    // vertical: true,
    // verticalSwiping: true,
    arrows: false,
  };
  return (
    <div>
      {window.location.pathname === '/termsandcondition' ? (
        ' '
      ) : (
        <>
          <MobileHeader />
          <NavHeader />
        </>
      )}
      {showLeadCaptureForm ? (
        <LeadCaptureForm
          modal={showLeadCaptureForm}
          setModal={setShowLeadCaptureForm}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Header;
