import React from 'react';
import { string, number, object, array } from 'prop-types';
import TestQuestion from './TestQuestion';
import TestAnswerWrapper from './TestAnswerwrapper';
import { findKeyByValue } from '../../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/questionHelper';
import NewTypeQuest from './NewTypeQuest';

const QuestionAnswerWrapper = ({
  showOptions,
  description,
  options,
  qIndex,
  questionType,
  queDetails,
  questionOrientaion,
  solutionIndex,
}) => (
  <div>
    {(findKeyByValue(queDetails?.solutionType) ===
      'choose-the-correct-answer' ||
      findKeyByValue(queDetails?.solutionType) === 'statement-and-support' ||
      findKeyByValue(queDetails?.solutionType) === 'two-columns-option' ||
      findKeyByValue(queDetails?.solutionType) === 'open-ended-questions' ||
      findKeyByValue(queDetails?.solutionType) === 'error-findings' ||
      findKeyByValue(queDetails?.solutionType) === 'multi' ||
      findKeyByValue(queDetails?.solutionType) ===
      'selecting-the-word-sentence' ||
      findKeyByValue(queDetails?.solutionType) === 'drag-and-drop' ||
      findKeyByValue(queDetails?.solutionType) ===
      'select-the-word-to-match-the-correct-option' ||
      findKeyByValue(queDetails?.solutionType) === 'match-the-following' ||
      findKeyByValue(queDetails?.solutionType) ===
      'scrambled-and-unscrambled') && (
        <div>
          <TestQuestion
            description={description}
            qIndex={qIndex}
            queDetails={queDetails}
          />

          {showOptions && (
            <TestAnswerWrapper
              options={options}
              queDetails={queDetails}
              questionType={questionType}
              solutionIndex={solutionIndex}
              questionOrientaion={questionOrientaion}
              ansType='selectTileType'
              quesType='optionsType'
            />
          )}
        </div>
      )}

    {(findKeyByValue(queDetails?.solutionType) === 'fill-in-the-blanks' ||
      findKeyByValue(queDetails?.solutionType) ===
      'drag-and-match-the-correct-answer' ||
      findKeyByValue(queDetails?.solutionType) ===
      'select-the-suitable-words') && (
        <div>
          <NewTypeQuest
            queDetails={queDetails}
            questionType={questionType}
            description={description}
            qIndex={qIndex}
          />
        </div>
      )}
  </div>
);

QuestionAnswerWrapper.propTypes = {
  qIndex: number.isRequired,
  description: string.isRequired,
  queDetails: object.isRequired,
  options: array.isRequired,
  questionType: string.isRequired,
  questionOrientaion: string.isRequired,
  showOptions: string.isRequired,
};

export default QuestionAnswerWrapper;
