import config from '../helpers/config';
import { fetchWrapper } from '../helpers/fetch-wrapper';

const baseUrl = `${config.apiBaseUrl}`;

function startTest(params, token) {
  return fetchWrapper.post(`${baseUrl}/brain-gym/start-test`, params, token);
}

function chestInfo(params, token) {
  return fetchWrapper.post(`${baseUrl}/brainGym/chest-info`, params, token);
}

function getQuestionInChest(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/brainGym/question-in-chest`,
    params,
    token
  );
}

function attemptQuestion(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/brainGym/attempt-question`,
    params,
    token
  );
}

function completeChest(params, token) {
  return fetchWrapper.post(`${baseUrl}/brainGym/complete-chest`, params, token);
}

function masterDetails(params, token) {
  return fetchWrapper.post(`${baseUrl}/brainGym/master-details`, params, token);
}

function lastFiveAttempts(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/brainGym/last-5-attempts`,
    params,
    token
  );
}

function incorrectAnswer(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/brainGym/review-incorrect-answers`,
    params,
    token
  );
}

function longestStreak(params, token) {
  return fetchWrapper.post(`${baseUrl}/brainGym/longest-streak`, params, token);
}

function updateRemainingTime(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/brainGym/update-remaining-time`,
    params,
    token
  );
}
function getBriefCase(params, token) {
  return fetchWrapper.get(
    `${baseUrl}/brainGym/my-briefcase?student=${params.student}&&page_no=${params.page_no}`,
    token
  );
}

function getAllGamesList(token) {
  return fetchWrapper.get(
    `${baseUrl}/syllabus-management/list-all-games`,
    token
  );
}

export const brainGymServices = {
  startTest,
  chestInfo,
  getQuestionInChest,
  attemptQuestion,
  completeChest,
  masterDetails,
  lastFiveAttempts,
  incorrectAnswer,
  longestStreak,
  updateRemainingTime,
  getBriefCase,
  getAllGamesList,
};

export default {};
