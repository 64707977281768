import { array, func, object } from 'prop-types';
import React from 'react';
import { useEffect } from 'react';
import { findKeyByValue } from '../BrainGym/QueAnswer/questionHelper';
import DraggAndDropQuestion from './DragAndDropQuestion/DraggAndDropQuestion';
import FillInTheBlanksQuestion from './FillTheBlanks/FillInTheBlanks';
import FillInTheBlanksDragAndDrop from './FillTheBlanksDragAndDrop/FillDragAndDrop';
import MultiSelect from './MultiSelect/MultiSelect';
import PracticeQuesAnswerWrapper from './PracticeQuesAnswerWrapper';
import SelectAwordFromQues from './selectAWordFromQues/selectAwordFromQues';
import { startListening } from './startLearning';
import speaker from '../../../../../assets/images/speaker.png';
import { renderText, readText } from '../BrainGym/QueAnswer/textHelper';

const PracticeQueanspart = ({
  questionsInfo,
  selectedOption,
  setSelectedOption,
  setIsSubmitValid,
  setNextButtonClicked,
}) => {
  useEffect(() => {
    if (questionsInfo) setNextButtonClicked(false);
  }, [questionsInfo]);
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          {(findKeyByValue(questionsInfo?.solutionType) ===
            'match-the-following' ||
            findKeyByValue(questionsInfo?.solutionType) === 'drag-and-drop' ||
            findKeyByValue(questionsInfo?.solutionType) ===
              'select-the-word-to-match-the-correct-option') && (
            <DraggAndDropQuestion
              questionInChest={questionsInfo}
              selectedOption={selectedOption}
              setSelect={setSelectedOption}
              setIsSubmitValid={setIsSubmitValid}
            />
          )}

          {findKeyByValue(questionsInfo?.solutionType) === 'multi' && (
            <MultiSelect
              description={questionsInfo?.description}
              options={questionsInfo?.options}
              questionsInfo={questionsInfo}
              solutionType={findKeyByValue(questionsInfo?.solutionType)}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setIsSubmitValid={setIsSubmitValid}
            />
          )}
          {findKeyByValue(questionsInfo?.solutionType) ===
            'select-the-suitable-words' && (
            <SelectAwordFromQues
              questionObject={questionsInfo}
              itemId={questionsInfo._id}
              description={questionsInfo?.description}
              options={questionsInfo?.options}
              optionsId={questionsInfo?._id}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setIsSubmitValid={setIsSubmitValid}
            />
          )}

          {findKeyByValue(questionsInfo?.solutionType) ===
            'fill-in-the-blanks' && (
            <FillInTheBlanksQuestion
              questionInChest={questionsInfo}
              setSelect={setSelectedOption}
              selectedOption={selectedOption}
              setIsSubmitValid={setIsSubmitValid}
            />
          )}
          {findKeyByValue(questionsInfo?.solutionType) ===
            'drag-and-match-the-correct-answer' && (
            <FillInTheBlanksDragAndDrop
              questionInChest={questionsInfo}
              setSelect={setSelectedOption}
              selectedOption={selectedOption}
              setIsSubmitValid={setIsSubmitValid}
            />
          )}
          {(findKeyByValue(questionsInfo?.solutionType) ===
            'choose-the-correct-answer' ||
            findKeyByValue(questionsInfo?.solutionType) ===
              'statement-and-support' ||
            findKeyByValue(questionsInfo?.solutionType) ===
              'two-columns-option' ||
            findKeyByValue(questionsInfo?.solutionType) ===
              'open-ended-questions' ||
            findKeyByValue(questionsInfo?.solutionType) ===
              'selecting-the-word-sentence' ||
            findKeyByValue(questionsInfo?.solutionType) ===
              'error-findings') && (
            <PracticeQuesAnswerWrapper
              questionsInfo={questionsInfo}
              description={questionsInfo?.description}
              options={questionsInfo?.options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              showOptions={
                findKeyByValue(questionsInfo?.solutionType) !== 'passage'
              }
              setIsSubmitValid={setIsSubmitValid}
            />
          )}
          {findKeyByValue(questionsInfo?.solutionType) === 'passage' && (
            <div>
              <div className='col-12 mb-4'>
                <div className='question-box'>
                  <h4>
                    {questionsInfo?.title == '' || questionsInfo?.title == null
                      ? 'Passage type question'
                      : renderText(questionsInfo?.title)}
                  </h4>
                  <div className='col-12 d-flex align-items-center justify-content-between'>
                    <h2 className='col-11 '>
                      <span>{renderText(questionsInfo?.description)}</span>
                    </h2>
                    <button
                      type='button'
                      onClick={() =>
                        startListening(readText(questionsInfo?.description))
                      }
                    >
                      {' '}
                      <img
                        className='span-one speaker-btn'
                        src={speaker}
                        alt='speaker icon'
                      />
                    </button>
                  </div>
                </div>
              </div>

              {questionsInfo?.passageQuestions?.map((item, i) => (
                <PracticeQuesAnswerWrapper
                  key={item?._id}
                  questionsInfo={questionsInfo}
                  itemId={item._id}
                  data={item}
                  qIndex={i + 1}
                  description={item?.description}
                  passageDesc={questionsInfo?.description}
                  options={item?.options}
                  optionsId={item?._id}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  setIsSubmitValid={setIsSubmitValid}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PracticeQueanspart.propTypes = {
  questionsInfo: object.isRequired,
  selectedOption: array.isRequired,
  setSelectedOption: func.isRequired,
  setIsSubmitValid: func.isRequired,
};

export default PracticeQueanspart;
