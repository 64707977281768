import React from 'react';
import { string } from 'prop-types';
import { chevRight } from '../assets/icons/IconList';

const StickyButton = ({ content, onButtonPress }) => (
  <button
    type='button'
    id='book-a-demo-sticky-id'
    className='button-common book-a-demo-sticky mb-3'
    onClick={onButtonPress}
  >
    {content}
    {/* <span>{chevRight}</span> */}
  </button>
);

StickyButton.propTypes = {
  content: string.isRequired,
};
export default StickyButton;
