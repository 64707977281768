import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
// const db = firebase.firestore();
// const analytics = getAnalytics(app);
const messaging = getMessaging(firebaseApp);

export const getFireBaseToken = async (setTokenFound) => {
  let currentToken = '';

  try {
    currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID,
    });
    if (currentToken) {
      //   setTokenFound(true);
      return currentToken;
    } else {
      //   setTokenFound(false);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const onBackgroundMessageListener = () =>
  new Promise((resolve) => {
    onBackgroundMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// import firebase from 'firebase';

// const firebaseConfig = {
//   apiKey: "AIzaSyDEQGP3RZ68CEVjjXZ2rY7O159GnzeV2s8",
//   authDomain: "tautmore-e369e.firebaseapp.com",
//   projectId: "tautmore-e369e",
//   storageBucket: "tautmore-e369e.appspot.com",
//   messagingSenderId: "982018340568",
//   appId: "1:982018340568:web:0859816d584832801b89ad",
//   measurementId: "G-WWWJQGJHY1"
// };
