import { v4 as uuid } from 'uuid';
import { questionTypesData, questionTypesFormatData } from './mockData/data';

export const structurQuestionData = (data, si) => {
  const structuredData = data?.map((item, i) => {
    const optionData = {
      id: uuid(),
      index: i,
      name: 'option 1',
      value: item,
      text: item?.text,
      image: item?.image,
      checked: !!si.includes(i),
    };
    return optionData;
  });

  return structuredData;
};

export const destructureQuestionData = (data) =>
  data?.map((item) => ({
    index: item.index,
    image: item?.image,
    text: item.text,
  }));

export const destructureStatementData = (data, questiontype) => {
  if (
    questiontype === 'drag-and-drop' ||
    questiontype === 'select-the-word-to-match-the-correct-option'
  ) {
    return data?.map((item) => item?.value);
  }
  return null;
};

export const findSolutionIndex = (data) => {
  const solutionIndexes = data?.map((item, i) => {
    if (item.checked) {
      return i;
    }
    return null;
  });

  return solutionIndexes.filter((item) => typeof item === 'number');
};

export const checkIfImageLink = (text) =>
  text?.indexOf(
    'https://tm-admin-product-images.s3.us-east-2.amazonaws.com/images'
  ) > -1;

export const findKeyByValue = (value) => {
  const keys = Object.keys(questionTypesData);
  const key = keys.filter((item) => questionTypesData[item] === value);
  return key?.[0];
};

export const findKeyByType = (value) => {
  const keys = Object.keys(questionTypesFormatData);
  const key = keys.filter((item) => questionTypesFormatData[item] === value);
  return key?.[0];
};

export const structurePassageData = (data) => {
  const pData =
    data?.length &&
    data?.map((item) => {
      const passageData = {
        id: uuid(),
        index: data.length,
        description: item?.description,
        options: structurQuestionData(item?.options, item?.solutionIndex),
        solutionIndex: [],
      };
      return passageData;
    });

  return pData;
};

export const descructureDifficultyLevel = (value) => {
  const strArray = value.split('_');
  const level = strArray[1];
  const levelBase = level?.split('.')?.[0];
  return { level, levelBase };
};

export const descructureDifficultyType = (value) => {
  const strArray = value.split('_');
  const string = strArray[0];
  return string;
};

export const destructureDragAndDrop = (dragData) => {
  const statements = dragData?.map((statement, i) => ({
    index: i,
    image: statement.image,
    text: statement.text,
  }));

  const array = [];

  for (let i = 0; i < dragData.length; i += 1) {
    array.push(i);
  }

  function shuffle(arr) {
    const newArray = [...arr];
    for (let i = newArray.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = newArray[i];
      newArray[i] = newArray[j];
      newArray[j] = temp;
    }
    return newArray;
  }

  const solution = shuffle(array);

  const options = [];

  for (let i = 0; i < solution.length; i += 1) {
    options[solution[i]] = {
      image: dragData[i].statementImage,
      text: dragData[i].statementText,
    };
  }

  return { statements, solution, options };
};

export const structureDragAndDrop = (statement, optiondata, solutionIdx) => {
  const question = [];

  // solutionIdx.sort();

  for (let i = 0; i < statement?.length; i += 1) {
    const j = solutionIdx?.length > 0 && solutionIdx?.[i];
    const option = {
      id: uuid(),
      index: i,
      name: '',
      image: statement[i]?.image,
      text: statement[i]?.text,
      statementImage: optiondata[j]?.image,
      statementText: optiondata[j]?.text,
    };
    question.push(option);
  }

  return question;
};

export const checkDragDropSnunscramble = (text) => {
  let res = false;
  if (
    text === 'scrambled-and-unscrambled' ||
    text === 'drag-and-drop' ||
    text === 'select-the-word-to-match-the-correct-option' ||
    text === 'match-the-following'
  ) {
    res = true;
  } else {
    res = false;
  }
  return res;
};
