export const GET_ALL_COUNTRY_LIST = 'GET_ALL_COUNTRY_LIST';
export const UPDATE_ALL_COUNTRY_LIST = 'UPDATE_ALL_COUNTRY_LIST';

// export const GET_ALL_GRADES_LIST = 'GET_ALL_GRADES_LIST';
// export const UPDATE_ALL_GRADES = 'UPDATE_ALL_GRADES';

// export const GET_SYLLABUS = 'GET_SYLLABUS';
// export const UPDATE_SYLLABUS = 'UPDATE_SYLLABUS';

export const UPDATE_SYLLABUS = 'UPDATE_SYLLABUS';

export const GET_COUNTRY_BASED_ON_IP = 'GET_COUNTRY_BASED_ON_IP';
export const UPDATE_COUNTRY_BASED_ON_IP = 'UPDATE_COUNTRY_BASED_ON_IP';
