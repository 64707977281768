import * as actionTypes from './StudentDataTypes';

export const getStudentClassesAction = (data) => ({
  type: actionTypes.GET_STUDENT_CLASSES,
  payload: data,
});

export const getStudentClassesHistoryAction = (data) => ({
  type: actionTypes.GET_STUDENT_CLASSES_HISTORY,
  payload: data,
});
export const getStudentUnattendedClassesHistoryAction = (data) => ({
  type: actionTypes.GET_STUDENT_UNATTENDED_CLASSES_HISTORY,
  payload: data,
});

export const getStudentCoCurricularClassesAction = (data) => ({
  type: actionTypes.GET_STUDENT_COCURRICULAR_CLASSES,
  payload: data,
});

export const getStudentCoCurricularClassesHistoryAction = (data) => ({
  type: actionTypes.GET_STUDENT_COCURRICULAR_CLASSES_HISTORY,
  payload: data,
});
export const getStudentList = (data) => ({
  type: actionTypes.GET_STUDENT_LIST,
  payload: data,
});
export const getStudentListCurricular = (data) => ({
  type: actionTypes.GET_STUDENT_LIST_COCURRICULAR,
  payload: data,
});

export const getStudentUnattendedCoCurricularClassesHistoryAction = (data) => ({
  type: actionTypes.GET_STUDENT_UNATTENDED_COCURRICULAR_CLASSES_HISTORY,
  payload: data,
});

export const studentAttendedOnlineClass = (data) => ({
  type: actionTypes.STUDENT_ATTENDED_ONLINE_CLASS,
  payload: data,
});

export const studentAttendedCocurricularClass = (data) => ({
  type: actionTypes.STUDENT_ATTENDED_COCURRICULAR_CLASS,
  payload: data,
});

export const getStudentsEmiDetailsByEmiIDAction = (data) => ({
  type: actionTypes.GET_STUDENTS_EMI_DETAILS_BY_EMI_ID,
  payload: data,
});

export const updateStudentsEmiDetailsByEmiIDAction = (data) => ({
  type: actionTypes.UPDATE_STUDENTS_EMI_DETAILS_BY_EMI_ID,
  payload: data,
});

export default {};
