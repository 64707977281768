import React, { useEffect, useState, useRef } from 'react';
import { readText, renderText } from '../../BrainGym/QueAnswer/textHelper';
import { array, func, object } from 'prop-types';
import { startListening } from '../startLearning';
import speaker from '../../../../../../assets/images/speaker.png';
import { findKeyByValue } from '../../../Rightsidepart/components/QuestionTypes/questionHelper';

const FillInTheBlanksDragAndDrop = ({
  questionInChest,
  setSelect,
  setIsSubmitValid,
}) => {
  const [finalQuesText, setFinalQuesText] = useState('');
  const [finalAnsArray, setFinalAnsArray] = useState([]); //options to drag
  const [fillInTheBlankAnswer, setFillInTheBlankAnswer] = useState([]); //answerd ansers

  const [dragSource, setDragSource] = useState(-1);
  const [dragDestination, setDragDestination] = useState(-1);

  useEffect(() => {
    //FORMATING THE QUESTION AND ANSWERS
    const quoteadd = questionInChest.descImageElement.replace(
      /(&rsquo;)/gi,
      '’'
    );
    const string = quoteadd.replace(/(&nbsp;|<([^>]+)>)/gi, ''); //REMOVING ALL TAGS
    const splitString = string.split('insert_input');
    setFinalQuesText(splitString);

    //FORMATING ANSWERS
    // let shuffeldAns = questionInChest.dragTheTextShuffledElement;
    // shuffeldAns = shuffeldAns ? shuffeldAns?.replace(/(&rsquo;)/gi, '’') : '';
    // var cleanText = shuffeldAns?.replace(/(&nbsp;|<([^>]+)>)/gi, '');
    setFinalAnsArray([...questionInChest?.dragTheTextSolution]);
  }, [questionInChest]);

  useEffect(() => {
    let isAllQuestionsAnswerd = true;
    if (fillInTheBlankAnswer.length > 0) {
      fillInTheBlankAnswer.map((ans) => {
        if (ans?.name === '') {
          isAllQuestionsAnswerd = false;
        }
      });
      if (isAllQuestionsAnswerd) {
        setSelect([...fillInTheBlankAnswer]);
      }
    }
  }, [fillInTheBlankAnswer]);

  useEffect(() => {
    const fillInTheBlankAnswerCopy = fillInTheBlankAnswer?.filter(
      (item) => item !== undefined
    );
    if (
      fillInTheBlankAnswerCopy.length &&
      finalAnsArray?.length &&
      fillInTheBlankAnswerCopy.length == finalAnsArray.length
    )
      setIsSubmitValid(true);
    else setIsSubmitValid(false);
  }, [fillInTheBlankAnswer, finalAnsArray]);

  useEffect(() => {
    if (
      questionInChest?.solutionIndex?.length > 0 &&
      !questionInChest?.solutionIndex?.includes(-1) &&
      finalAnsArray?.length > 0
    ) {
      var oldAnswers = questionInChest?.solutionIndex.map((eachIndex) => ({
        name: finalAnsArray[eachIndex],
        index: eachIndex,
      }));
      setFillInTheBlankAnswer([...oldAnswers]);
    }
  }, [questionInChest, finalAnsArray]);
  const RenderQuestion = (splitString) => {
    let newStr;
    if (splitString) {
      splitString.map((subStr, i) => {
        //renderd only when its an initial render
        if (
          i < splitString.length - 1 &&
          questionInChest?.solutionIndex?.includes(-1) &&
          !fillInTheBlankAnswer[i] &&
          !fillInTheBlankAnswer[i]?.name?.length > 0
        ) {
          let ans = [...fillInTheBlankAnswer];
          ans.push({ name: '', index: '' });
          setFillInTheBlankAnswer([...ans]);
        }
        newStr = (
          <>
            <span>{newStr}</span>
            <span>{subStr}</span>
            {splitString.length - 1 === i ? null : (
              <input
                key={i}
                id={i}
                className='dropbox'
                placeholder=''
                defaultValue={fillInTheBlankAnswer[i]?.name}
                onChange={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(i)}
                onDragOver={(e) => e.preventDefault()}
                disabled={true}
              />
            )}
          </>
        );
      });
    }
    return <div>{newStr}</div>;
  };

  const renderTextWithBlanks = (inputString) => {
    const regex = /insert_input/g;
    // questionInChest.description;

    const parts = inputString.split(regex);
    const inputCount = parts.length - 1;
    const result = parts.map((part, index) => {
      if (index < inputCount) {
        return (
          <React.Fragment key={index}>
            {renderText(part)}
            <input
              key={index}
              id={index}
              className='dropbox'
              placeholder=''
              defaultValue={fillInTheBlankAnswer[index]?.name}
              onChange={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(index)}
              onDragOver={(e) => e.preventDefault()}
              disabled={true}
            />
            {/* <input
              type='text'
              // placeholder='insert_input'
              // pattern='^[^\s]+$'
              value={answers[index]}
              data-index={index}
              onChange={handleAnswerSubmit}
            /> */}
          </React.Fragment>
        );
      }
      return renderText(part);
    });
    // console.log('the lllooo', result);
    return result;
  };

  //DRAG AND DROP FUNCTIONALITY

  const handleDrag = (index) => {
    setDragSource(index);
  };

  const handleDrop = (ind) => {
    setDragDestination(ind);
    if (dragSource !== -1) {
      var newFinalAns = [...fillInTheBlankAnswer];
      newFinalAns[ind] = { name: '', index: '' };
      newFinalAns[ind].name = finalAnsArray[dragSource];
      newFinalAns[ind].index = dragSource;
      setFillInTheBlankAnswer([...newFinalAns]);
    }
  };

  //END DRAG AND DROP FUNCTIONALITY
  const handleListenOption = (dataaa) => {
    let kllll = dataaa?.map((item, index) => {
      const cleanText = item.replace(/<\/?[^>]+(>|$)/g, '');
      const removeNbsp = cleanText.replace(/&nbsp;/g, ' ');
      return removeNbsp;
    });
    if (kllll?.length) startListening(kllll.join(' '));
  };

  const [dragging, setDragging] = useState(false);
  const [pos, setPos] = useState({ index: 0, left: 0, top: 0 });
  // const ref = useRef(null);
  const DragDataRefs = questionInChest.dragTheTextSolution.map(() =>
    useRef(null)
  );

  const handleTouchStart = (e, i) => {
    setDragging(true);
    // e.preventDefault();
    const { left, top } = DragDataRefs[i].current.getBoundingClientRect();

    const touch = e.targetTouches[0];

    const x = touch.pageX;
    const y = touch.pageY;

    setPos({ index: i, left: x, top: y });
    handleDrag(i);
  };

  const handleTouchMove = (e, i) => {
    const touch = e.targetTouches[0];
    const { left, top } = DragDataRefs[i].current.getBoundingClientRect();
    if (dragging) {
      const x = touch.pageX;
      const y = touch.pageY;
      setPos({ index: i, left: x + 1, top: y + 1 });
    }
  };

  const handleTouchEnd = (e, i) => {
    setDragging(false);
    // e.preventDefault();
    const touch = e.changedTouches[0];
    const element = document.elementFromPoint(touch.pageX, touch.pageY);
    if (element && element.className.includes('dropbox')) {
      handleDrop(parseInt(element.id));
    }
  };

  return (
    <div>
      <div className='question-box mb-4'>
        <div className='d-flex justify-content-between gap-3'>
          <h4 className='title'>
            {questionInChest?.title == '' || questionInChest?.title == null
              ? findKeyByValue(questionInChest?.solutionType)
              : renderText(questionInChest?.title)}
          </h4>
          <span className=''>
            <button
              type='button'
              className='speaker-btn-wrapper'
              onClick={() =>
                startListening(
                  readText(
                    (questionInChest.title
                      ? questionInChest.title + '. '
                      : '') + questionInChest.description
                  )
                )
              }
            >
              {' '}
              <img
                className='span-one speaker-btn'
                src={speaker}
                alt='speaker icon'
              />
            </button>
          </span>
        </div>

        {/* <h4 className='title'></h4> */}

        <div className='fill-in-the-blanks'>
          {/* {RenderQuestion(finalQuesText)} */}
          {renderTextWithBlanks(questionInChest.description)}
        </div>
        <div className='fill-drag-option'>
          {/* RENDER ANSWER */}
          {finalAnsArray.map((ele, i) => (
            <div className='fill-in-the-blanks-shuffled' key={i + 30}>
              <div
                className='drag-item '
                draggable
                ref={DragDataRefs[i]}
                onTouchStart={(e) => handleTouchStart(e, i)}
                onTouchMove={(e) => handleTouchMove(e, i)}
                onTouchEnd={(e) => handleTouchEnd(e, i)}
                onDragStart={() => handleDrag(i)}
                onDragOver={(e) => e.preventDefault()}
                // style={{ touchAction: 'none' }}
                style={{
                  position:
                    dragging && pos.index === i ? 'absolute' : 'initial',
                  top: `${pos.top}px`,
                  left: `${pos.left}px`,
                  zIndex: 1000,
                  touchAction: 'none',
                }}
              >
                <span className='m-3 m-lg-5'>{ele}</span>
              </div>
            </div>
          ))}
          <span className='practice-test-audio '>
            <button
              type='button'
              onClick={() => handleListenOption(finalAnsArray)}
            >
              {' '}
              <img
                className='span-one speaker-btn'
                src={speaker}
                alt='speaker icon'
              />
            </button>
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
};

FillInTheBlanksDragAndDrop.propTypes = {
  questionInChest: object.isRequired,
  setSelect: func.isRequired,
  selectedOption: array.isRequired,
};

export default FillInTheBlanksDragAndDrop;
