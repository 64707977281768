import React from 'react';
import '../../teachers.scss';
import { func, object, string } from 'prop-types';
import Progressbar from '../../../../components/dashboard/Rightsidepart/Progressbar';
import './student.scss';
import ReactPaginate from 'react-paginate';
import defaultPic from '../../../../../assets/images/defaultPic.png';
import { useHistory } from 'react-router';

const Mystudentlisting = ({
  studentDataList,
  gradeName,
  setStudentId,
  setStudentIndData,
  studentPerformance,
  currentPage,
  onPageChange,
  studentsCount,
}) => {
  const calculatePer = (total, comp) => {
    const round = Math.round((comp / total) * 100);
    return round;
  };

  const history = useHistory();

  
  const setValues = (itemVal) => {
    setStudentId(itemVal._id);
    studentPerformance(itemVal._id);
    setStudentIndData(itemVal);
    history.push({
      pathname: '/teacher/students',
      search: '?grades=true',
    });
  };

  return (
    <div className='col-sm-12'>
      <div className='row'>
        {studentDataList?.length > 0 ? (
          studentDataList.map((item) => (
            <div className='col-sm-4 stdloopdiv' key={item.id}>
              <div
                className='innerdiv'
                aria-hidden
                onClick={() => setValues(item)}
              >
                <img
                  className='studenticon'
                  src={item?.profilePic ? item?.profilePic : defaultPic}
                  alt='studentimg'
                  style={{ borderRadius: '50%' }}
                />
                <p className='stdname'>{item.studentName}</p>
                <div className='row'>
                  <div className='col-sm-12'>
                    <p className='stdgrade'>Grade {gradeName}</p>
                  </div>
                </div>
                <Progressbar
                  className='updated-progress-bar-one '
                  peronevalue={
                    item.subConceptsCount !== 0
                      ? calculatePer(
                          item.subConceptsCount,
                          item.completedSubConceptsCount
                        )
                      : 0
                  }
                  perone
                  title='Subject completion'
                />
              </div>
            </div>
          ))
        ) : (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ color: '', fontWeight: '500' }}
          >
            No Student ...
          </div>
        )}

        {studentDataList?.length > 0 ? (
          <ReactPaginate
            previousLabel={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                class='bi bi-arrow-left'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'
                />
              </svg>
            }
            nextLabel={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                class='bi bi-arrow-right'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'
                />
              </svg>
            }
            breakLabel='...'
            breakClassName='break-me'
            pageCount={Math.ceil(studentsCount / 10)}
            containerClassName='d-flex w-100 align-items-center justify-content-center mt-5 studentListpagination'
            activeClassName='active'
            forcePage={currentPage - 1}
            onPageChange={(pageNo) => onPageChange(pageNo.selected + 1)}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

Mystudentlisting.propTypes = {
  studentDataList: object.isRequired,
  gradeName: string.isRequired,
  setStudentId: string.isRequired,
  setStudentIndData: object.isRequired,
  studentPerformance: func.isRequired,
};

export default Mystudentlisting;
