import React from 'react';
import { select } from '../../../../assets/icons/IconList';

const StepsCompleted = ({ step }) => {
  const stepdata = [
    {
      id: 1,
      title: 'Parent Details',
    },
    {
      id: 2,
      title: 'Add Student',
    },
    {
      id: 3,
      title: 'Select Course',
    },
    {
      id: 4,
      title: 'Pay Fees',
    },
    {
      id: 5,
      title: 'Success' + ' ',
    },
  ];
  return (
    <div>
      <div className='stepper-top text-center  step-width'>
        {stepdata.map((data) => (
          <div
            key={data.title}
            className={`stepper-above ${data.id === step ? 'active' : ''} ${
              data.id < step ? 'prev-step' : ''
            }`}
          >
            <button type='button'>{select}</button>
            <span> {data.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepsCompleted;
