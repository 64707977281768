import countryimg from '../../../../../../assets/images/country.png';

export const country = [
  {
    title: 'United State of America',
    image: countryimg,
  },
  {
    title: 'India',
    image: countryimg,
  },
  {
    title: 'Canada',
    image: countryimg,
  },
  {
    title: 'Australia',
    image: countryimg,
  },
];

export const state = [
  {
    title: 'state1',
  },
  {
    title: 'state2',
  },
  {
    title: 'state3',
  },
  {
    title: 'state4',
  },
];

export const grade = [
  {
    title: 'Pre - Kindergarten',
  },
  {
    title: 'Kindergarten',
  },
  {
    title: 'Grade1',
  },
  {
    title: 'Grade2',
  },
];

export const subject = [
  {
    title: 'Mathematics',
    label: 'label1',
  },
  {
    title: 'Science',
    label: 'label2',
  },
  {
    title: 'English',
    label: 'label3',
  },
];

export const exam = [
  {
    title: 'Half yearly',
    label: 'label4',
  },
  {
    title: 'Annualy',
    label: 'label5',
  },
];

export const activity = [
  {
    title: 'Dancing, Photography',
  },
  {
    title: 'Artist',
  },
];

export const payment = [
  {
    name: 'Payment details',
    data: [
      {
        title: 'Grade',
        type: 'Pre - Kindergarten',
        price: 'USD 30.00',
      },
      {
        title: 'Subjects',
        type: 'All subjects',
        price: 'USD 35.00',
      },
      {
        title: 'Brain gym',
        type: 'Daily',
        price: 'USD 10.00',
      },
      {
        title: 'Olympiad',
        type: 'Monthly, annually',
        price: 'USD 15.00',
      },
      {
        title: 'Co-curricular activities',
        type: 'Dancing, Photography',
        price: 'USD 21.00',
      },
    ],
  },
];
