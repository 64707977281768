import { fork, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './StudentDataTypes';
import { studentDataServices } from '../../services/studentData.service';

function* workerStudentClasses(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const body = data?.payload;
  const response = yield studentDataServices.getStudentClasses(body, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_STUDENT_CLASSES,
      payload: response,
    });
  }
}

function* workerStudentClassesHistory(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const body = data?.payload;
  const response = yield studentDataServices.getStudentClassesHistory(
    body,
    token
  );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_STUDENT_CLASSES_HISTORY,
      payload: response,
    });
  }
}
function* workerStudentJoinClass(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const body = data?.payload;
  const response = yield studentDataServices.studentJoinClass(body, token);
}
function* workerStudentJoinCoCurricularClass(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const body = data?.payload;
  const response = yield studentDataServices.studentJoinCocurricularClass(
    body,
    token
  );
}

function* workerStudentUnattendedClassesHistory(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const body = data?.payload;
  const response = yield studentDataServices.getStudentUnattendedClassesHistory(
    body,
    token
  );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_STUDENT_CLASSES_HISTORY,
      payload: response,
    });
  }
}

function* workerStudentUnattendedCoCurricularClassesHistory(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const body = data?.payload;
  const response =
    yield studentDataServices.getStudentUnattendedCocurricularClassesHistory(
      body,
      token
    );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_STUDENT_COCURRICULAR_CLASSES_HISTORY,
      payload: response,
    });
  }
}

function* workerStudentCoCurricularClassesHistory(data) {
  const state = yield select();
  const studentID = state.Auth.Login.data._id;
  const token = state.Auth.Login.data.accessToken;
  const body = data?.payload;
  const response =
    yield studentDataServices.getStudentCoCurricularClassesHistory(body, token);
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_STUDENT_COCURRICULAR_CLASSES_HISTORY,
      payload: response,
    });
  }
}

function* workerStudentCoCurricularClasses(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const body = data.payload;
  const response = yield studentDataServices.getStudentCoCurricularClasses(
    body,
    token
  );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_STUDENT_COCURRICULAR_CLASSES,
      payload: response,
    });
  }
}

function* workerStudentList(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentDataServices.getStudentList(
    data.payload,
    token
  );
  if (response.statusCode === 200) {
    yield put({
      type: actionTypes.GET_STUDENT_LIST_SUCCESS,
      payload: response?.data?.[0]?.response,
    });
  }
  if (response.statusCode === 401) {
    yield put({
      type: actionTypes.GET_STUDENT_LIST_ERROR,
    });
  }
}
function* workerStudentListCurricular(data) {
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentDataServices.getStudentListCurricular(
    data.payload,
    token
  );

  if (response.statusCode === 200) {
    yield put({
      type: actionTypes.GET_STUDENT_LIST_SUCCESS_COCURRICULAR,
      payload: response?.data?.[0]?.response,
    });
  }
  if (response.statusCode === 401) {
    yield put({
      type: actionTypes.GET_STUDENT_LIST_ERROR_COCURRICULAR,
    });
  }
}

function* workerGetStudentsEmiDetailsByEmiID(data) {
  const state = yield select();
  const payload = data?.payload;
  const token = state.Auth.Login.data.accessToken;

  const response =
    yield studentDataServices.getStudentsEmiDetailsByEmiIdService(
      payload?.emiId,
      token
    );
  if (response) {
    yield put({
      type: actionTypes.UPDATE_STUDENTS_EMI_DETAILS_BY_EMI_ID,
      payload: response?.data,
    });
  }
}

function* watcherStudentData() {
  yield takeLatest(actionTypes.GET_STUDENT_CLASSES, workerStudentClasses);
  yield takeLatest(
    actionTypes.GET_STUDENT_CLASSES_HISTORY,
    workerStudentClassesHistory
  );
  yield takeLatest(
    actionTypes.GET_STUDENT_UNATTENDED_CLASSES_HISTORY,
    workerStudentUnattendedClassesHistory
  );

  yield takeLatest(
    actionTypes.GET_STUDENT_COCURRICULAR_CLASSES,
    workerStudentCoCurricularClasses
  );
  yield takeLatest(
    actionTypes.GET_STUDENT_COCURRICULAR_CLASSES_HISTORY,
    workerStudentCoCurricularClassesHistory
  );
  yield takeLatest(
    actionTypes.GET_STUDENT_UNATTENDED_COCURRICULAR_CLASSES_HISTORY,
    workerStudentUnattendedCoCurricularClassesHistory
  );

  yield takeLatest(
    actionTypes.STUDENT_ATTENDED_ONLINE_CLASS,
    workerStudentJoinClass
  );
  yield takeLatest(
    actionTypes.STUDENT_ATTENDED_COCURRICULAR_CLASS,
    workerStudentJoinCoCurricularClass
  );

  yield takeLatest(actionTypes.GET_STUDENT_LIST, workerStudentList);
  yield takeLatest(
    actionTypes.GET_STUDENT_LIST_COCURRICULAR,
    workerStudentListCurricular
  );
  yield takeLatest(
    actionTypes.GET_STUDENTS_EMI_DETAILS_BY_EMI_ID,
    workerGetStudentsEmiDetailsByEmiID
  );
}

function* fetchAll() {
  yield fork(watcherStudentData);
}

export default fetchAll();
