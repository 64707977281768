import React from 'react';
import { object, string, func, array } from 'prop-types';
import { renderText, readText } from '../BrainGym/QueAnswer/textHelper';
import {
  checkDragDropSnunscramble,
  findKeyByValue,
} from '../BrainGym/QueAnswer/questionHelper';
import PracticeAnswerOption from './PracticeAnswerOption';
import { startListening } from './startLearning';
import speaker from '../../../../../assets/images/speaker.png';

const PracticeQuesAnswerWrapper = ({
  questionsInfo,
  itemId,
  qIndex,
  description,
  options,
  optionsId,
  selectedOption,
  setSelectedOption,
  setIsSubmitValid,
}) => {
  const questionType = findKeyByValue(questionsInfo?.solutionType);
  if (checkDragDropSnunscramble(questionType)) return null;
  const handleListenOption = (dataaa) => {
    let kllll = dataaa?.map((item, index) => {
      const cleanText = item.text.replace(/<\/?[^>]+(>|$)/g, '');
      const removeNbsp = cleanText.replace(/&nbsp;/g, ' ');
      return removeNbsp;
    });
    if (kllll?.length) startListening(kllll.join(' '));
  };
  return (
    <div>
      <div className='row' id={optionsId}>
        <div className='col-12'>
          {findKeyByValue(questionsInfo?.solutionType) === 'passage' ? (
            <div>
              <div className='question-box'>
                <h4>
                  Question
                  {findKeyByValue(questionsInfo?.solutionType) === 'passage' &&
                  qIndex > 0
                    ? `.${qIndex}`
                    : null}
                </h4>
                <div className='col-12 d-flex align-items-center justify-content-between'>
                  <h2 className='col-11'>
                    <span>{renderText(description)}</span>
                  </h2>
                  <button
                    type='button'
                    id={qIndex}
                    onClick={() => startListening(readText(description))}
                  >
                    {' '}
                    <img
                      className='span-one speaker-btn'
                      src={speaker}
                      alt='speaker icon'
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className='question-box'>
              <h4>
                {questionsInfo?.title == '' || questionsInfo?.title == null
                  ? findKeyByValue(questionsInfo?.solutionType)
                  : renderText(questionsInfo?.title)}
              </h4>
              <div className='col-12 d-flex align-items-center justify-content-between'>
                <h2 className='col-11'>
                  <span>{renderText(description)}</span>
                </h2>
                <button
                  type='button'
                  className='speaker-btn-wrapper'
                  onClick={() => startListening(readText(description))}
                >
                  {' '}
                  <img
                    className='span-one speaker-btn'
                    src={speaker}
                    alt='speaker icon'
                  />
                </button>
              </div>
            </div>
          )}
          <div className='col-12 d-flex align-items-center justify-content-between mt-4'>
            <span className='col-11 answer-title '>Select your answer</span>
            <button
              type='button'
              onClick={() => handleListenOption(options)}
              className='practice-test-audio'
            >
              {' '}
              <img
                className='span-one speaker-btn'
                src={speaker}
                alt='speaker icon'
              />
            </button>
          </div>

          <div className='col-xl-8 col-12'>
            <div
              className={
                questionsInfo?.questionOrientaion == 'vertical'
                  ? 'question-options-wrapper selectTyleType '
                  : 'question-options-wrapper selectTyleType'
              }
              id={optionsId}
            >
              {options?.map(
                (item, ind) =>
                  (item?.text !== '' || item?.image !== '') && (
                    <PracticeAnswerOption
                      questionsInfo={questionsInfo}
                      itemId={itemId}
                      item={item}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      ind={ind}
                      optionsId={optionsId}
                      setIsSubmitValid={setIsSubmitValid}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PracticeQuesAnswerWrapper.propTypes = {
  questionsInfo: object.isRequired,
  itemId: string.isRequired,
  qIndex: string.isRequired,
  selectedOption: string.isRequired,
  setSelectedOption: func.isRequired,
  description: string.isRequired,
  options: array.isRequired,
  optionsId: string.isRequired,
  setIsSubmitValid: func.isRequired,
};

export default PracticeQuesAnswerWrapper;
