import videoimg1 from '../../../../../../../assets/images/videoimg1.png';
import videoimg3 from '../../../../../../../assets/images/videoimg3.png';

export const syllabus = [
  {
    title: '1.1 How to count to 3',
    data: [
      '1.1.1 Learn to count',
      '1.1.2 Count objects upto 5',
      '1.1.3 Count shapes upto 7',
      '1.1.4 Represent 1-10',
      '1.1.5 Show numbers on ten frames',
    ],
  },
];

export const video = [
  {
    title: 'videos',
    desc: '1.1 How to count to 3',
    data: [
      {
        image: videoimg1,
        title: 'Counting is easy',
        time: '28 mins',
      },
      {
        image: videoimg1,
        title: "What is counting & why it's useful",
        time: '15 mins',
      },
      {
        image: videoimg3,
        title: 'Counting in real life',
        time: '7.8 mins',
      },
    ],
  },
];
