import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import Mystudentlisting from './Mystudentlisting';
import Spinner from 'react-bootstrap/Spinner';

import StudentDetail from './StudentDetail/StudentDetail';
import '../../teachers.scss';
import {
  getStudentsListing,
  getStudentPerformance,
  searchStudentsListing,
} from '../../../../../services/teacherAssignment';
import search from '../../../../../assets/images/search.png';
import studentIcon from '../../../../../assets/icons/teacherIcon/students.svg';
import { useHistory, useLocation } from 'react-router';

const localDataValue = JSON.parse(localStorage.getItem('taut-student'));
const Mystudents = () => {
  const [students, setStudents] = useState('home');
  const [activebtn, setActivebtn] = useState('');
  const [localData, setLocalData] = useState([]);
  const [subjectParam, setSubjectParam] = useState('');
  const [gradeData, setGrade] = useState('');
  const [studentDataList, setStudentDataList] = useState([]);
  const [gradeName, setGradeName] = useState('');
  const [studentsCount, setStudentsCount] = useState('');
  const [studentId, setStudentId] = useState('');
  const [studentIndData, setStudentIndData] = useState({});
  const [studPerformance, setStudPerformance] = useState([]);
  const [subName, setSubName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const stuColor = (name, subId, classId) => {
    setActivebtn(name.concat(classId.name));
    setSubjectParam(subId);
    setGrade(classId._id);
    setGradeName(classId.name);
    setSubName(name);
  };
  const localDataVal = JSON.parse(localStorage.getItem('taut-student'));

  useEffect(() => {
    const grades = query.get('grades');
    // const editleave = query.get('editleave');

    if (!grades) {
      setStudents('home');
      history.push('/teacher/students');
    }
    //  else if (applyleave) {
    //   setModel(true);
    //   setIsLeaveEdit(true);
    // }
  }, [location.search]);

  useEffect(() => {
    const localDataVal = JSON.parse(localStorage.getItem('taut-student'));
    const subLocal = localDataVal?.data?.subject?.[0];
    setLocalData(localDataVal?.data?.subject);
    setActivebtn(subLocal?.name.concat(subLocal.class.name));
  }, []);

  const studentPerformance = async (studid) => {
    const params = {
      teacher: localDataValue?.data?._id,
      student: studid,
      subject: subjectParam,
    };
    try {
      const res = await getStudentPerformance(params);
      setStudPerformance(res?.data?.data);
      setStudents('studentDetail');
    } catch (error) {
      console.log(error);
    }
  };

  const studentsListingData = async (id, pageNo) => {
    setLoader(true);
    const params = {
      teacher: id,
      grade: gradeData,
      subject: subjectParam,
      page_no: pageNo,
    };
    try {
      const res = await getStudentsListing(params);
      if (res.status === 200) {
        setLoader(false);

        setStudentDataList(res?.data?.data?.[0]?.response);
        setStudentsCount(res?.data?.data?.[0]?.count?.[0]?.count);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const localDataVal = JSON.parse(localStorage.getItem('taut-student'));

    setSubjectParam(localDataVal?.data?.subject?.[0]?._id);
    setSubName(localDataVal?.data?.subject?.[0]?.name);
    setGrade(localDataVal?.data?.subject?.[0]?.class?._id);
    setGradeName(localDataVal?.data?.subject?.[0]?.class?.name);
  }, []);

  useEffect(() => {
    const localDataVal = JSON.parse(localStorage.getItem('taut-student'));

    if (localDataVal?.data?._id && gradeData && subjectParam) {
      studentsListingData(localDataVal?.data?._id, currentPage);
    }
  }, [activebtn, currentPage]);
  useEffect(() => {}, []);
  const renderTabs = (sub) => (
    <Nav.Link
      eventKey={sub.name.concat(sub.class.name)}
      className={`toggle-maths-btn ${
        activebtn === sub.name.concat(sub.class.name)
          ? 'mathbtn-act'
          : 'mathbtn-un'
      } toggleBootstrap`}
      onClick={() => stuColor(sub.name, sub._id, sub.class)}
    >
      Grade {sub.class.name} - {sub.name}
    </Nav.Link>
  );
  useEffect(() => {
    setCurrentPage(1);
  }, [subName]);

  const handleSearch = async (value) => {
    setLoader(true);
    const localDataVal = JSON.parse(localStorage.getItem('taut-student'));
    const data = {
      grade: gradeData,
      subject: subjectParam,
      searchText: value,
      teacher: localDataVal?.data?._id,
    };
    try {
      const searchResult = await searchStudentsListing(data);
      setStudentDataList(searchResult?.data?.data?.[0]?.response);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  return (
    <>
      {students === 'home' && studentDataList && localData && (
        <div>
          <div className='row mt-4 mb-4'>
            <div className='col-sm-5 d-flex align-items-center'>
              <div className='me-2'>
                <img src={studentIcon} width='60px' alt='student'></img>
              </div>

              <h1 className='heading'>My students</h1>
            </div>
            <div className='col-sm-7'>
              <p className='totalstudents'>
                {studentsCount || '0'} Total students
              </p>
            </div>
          </div>
          <div className='row'>
            {activebtn ? (
              <div className='col-sm-12'>
                <Tab.Container defaultActiveKey={activebtn}>
                  <div className='row myexam-tab'>
                    <div className='col-md-9 col-sm-12 myexam-tab-left'>
                      <div className='dtoggle-bar'>
                        <Nav variant='pills'>
                          {localData.map((sub) => (
                            <Nav.Item className=''>
                              <div className='toggle-maths-div'>
                                {renderTabs(sub)}
                              </div>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </div>
                    </div>
                  </div>

                  <Tab.Content>
                    <div className='row justify-content-end'>
                      <div className='col-sm-4'>
                        <div className='dashboard-right-search custom-dashboard-right-search row'>
                          <div className='dash-right-search-common'>
                            <img src={search} alt='search' />
                            <input
                              className='assignQueSearch'
                              type='search'
                              placeholder='Search'
                              onChange={(e) => {
                                handleSearch(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {localData.map((sub) => (
                      <Tab.Pane eventKey={sub.name.concat(sub.class.name)}>
                        {loader ? (
                          <div className='d-flex w-100 align-items-center justify-content-center mt-5'>
                            <Spinner animation='border' variant='dark' />
                          </div>
                        ) : (
                          <Mystudentlisting
                            studentDataList={studentDataList}
                            gradeName={gradeName}
                            setStudentId={setStudentId}
                            setStudentIndData={setStudentIndData}
                            studentPerformance={studentPerformance}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                            studentsCount={studentsCount}
                          />
                        )}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
      {students === 'studentDetail' && (
        <StudentDetail
          setStudents={setStudents}
          subjectParam={subjectParam}
          studentId={studentId}
          studentIndData={studentIndData}
          studPerformance={studPerformance}
          gradeName={gradeName}
          subName={subName}
        />
      )}
    </>
  );
};

export default Mystudents;
