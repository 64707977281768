import { fork, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './MyAssignmentExamTypes';
import { studentAssignmentService } from '../../services/studentAssignment.service';

function* workerAssignmentUpcomingExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentAssignmentService.getStudentAssignmentList(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_MYASSIGNMENTS_UPCOMING_EXAM,
      payload: response,
    });
  }
}
function* workerPreviousExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentAssignmentService.getHistoryAssignments(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_PREVIOUS_MYASSIGNMENTS_EXAM,
      payload: response,
    });
  }
}
function* workerUnattemptedExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentAssignmentService.getUnattemptedAssignments(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_PREVIOUS_MYASSIGNMENTS_EXAM,
      payload: response,
    });
  }
}
function* workerMyReport(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentAssignmentService.viewAssignment(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_MY_MYASSIGNMENTS_REPORT,
      payload: response,
    });
  }
}

function* workerStartExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentAssignmentService.startAssignment(value, token);

  if (response) {
    yield put({
      type: actionTypes.UPDATE_START_MYASSIGNMENTS_EXAM,
      payload: response,
    });
  }
}
function* workerFinishExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentAssignmentService.finishAssignment(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_FINISH_MYASSIGNMENTS_EXAM,
      payload: response,
    });
  }
}

function* workerGetQuestionInExam(data) {
  const value = data?.payload;
  const state = yield select();
  const token = state.Auth.Login.data.accessToken;
  const response = yield studentAssignmentService.questionsInAssignment(
    value,
    token
  );

  if (response) {
    yield put({
      type: actionTypes.UPDATE_GET_QUESTION_IN_MYASSIGNMENTS_EXAM,
      payload: response?.data[0]?.response,
    });
  }
}

function* watcherMyExam() {
  yield takeLatest(
    actionTypes.MYASSIGNMENTS_UPCOMING_EXAM,
    workerAssignmentUpcomingExam
  );
  yield takeLatest(actionTypes.PREVIOUS_MYASSIGNMENTS_EXAM, workerPreviousExam);
  yield takeLatest(
    actionTypes.UNATTEEMPTED_MYASSIGNMENTS_EXAM,
    workerUnattemptedExam
  );
  yield takeLatest(actionTypes.START_MYASSIGNMENTS_EXAM, workerStartExam);
  yield takeLatest(actionTypes.FINISH_MYASSIGNMENTS_EXAM, workerFinishExam);
  yield takeLatest(
    actionTypes.GET_QUESTION_IN_MYASSIGNMENTS_EXAM,
    workerGetQuestionInExam
  );
  yield takeLatest(actionTypes.GET_MY_MYASSIGNMENTS_REPORT, workerMyReport);
}

function* fetchAll() {
  yield fork(watcherMyExam);
}

export default fetchAll();
