import React from 'react';
import { useState } from 'react';
// import studentIcon from '../../../../../assets/icons/teacherIcon/students.svg';

import RevenueFilter from './RevenueFilters';
import './Revenue.scss';
import { useEffect } from 'react';
import {
  getCompletedClassListing,
  getRevenueSummary,
} from '../../../../../services/teacherAssignment';
import { connect } from 'react-redux';
import moment from 'moment';
const token = JSON.parse(localStorage.getItem('taut-student'));

const RevenueMain = ({ subjectList, gradeList, teacherId, aceesToken }) => {
  const [gradeData, setGradeData] = useState({ _id: '', name: 'All Grades' });
  const [subdata, setSubdata] = useState({ _id: '', name: 'All Subjects' });
  const [timedata, setTimedata] = useState({ value: '', name: 'Date range' });
  const [startDateRange, setStartDateRange] = useState('');
  const [endDateRange, setEndDateRange] = useState('');
  const [classCompleted, setClassCompleted] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState([]);
  const [revenueLength, setRevenueLength] = useState([]);
  const [loading, setLoading] = useState(false);
  const [upcomingPage, setUpcomingPage] = useState(0);
  const [classesCompCount, setClassesCompCount] = useState(0);

  const getClassesCompleted = async () => {
    setLoading(true);
    try {
      const obj = {
        grade: gradeData?._id,
        subject: subdata?._id,
        page_no: upcomingPage + 1,
        timeFrame: timedata?.value,
        startDate: startDateRange
          ? moment(startDateRange).format('YYYY-MM-DD')
          : '',
        endDate: endDateRange ? moment(endDateRange).format('YYYY-MM-DD') : '',
      };

      const result = await getCompletedClassListing(obj, teacherId, aceesToken);
      if (result.status === 200) {
        setClassCompleted(result?.data.data.response);
        setClassesCompCount(result?.data?.data?.count[0]?.count);
        setLoading(false);
      }
    } catch (error) {}
  };

  const getRevenue = async () => {
    setLoading(true);
    const obj = {
      grade: gradeData?._id,
      subject: subdata?._id,
      timeFrame: timedata?.value,
      startDate: startDateRange
        ? moment(startDateRange).format('YYYY-MM-DD')
        : '',
      endDate: endDateRange ? moment(endDateRange).format('YYYY-MM-DD') : '',
    };
    try {
      const res = await getRevenueSummary(obj, teacherId, aceesToken);
      if (res.status === 200) {
        setTotalRevenue(res?.data?.data?.revenueSummary[0]);
        setRevenueLength(res?.data?.data?.revenueSummary);
        setLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getClassesCompleted();
  }, [
    gradeData,
    subdata,
    timedata,
    startDateRange,
    endDateRange,
    upcomingPage,
  ]);

  useEffect(() => {
    getRevenue();
  }, [gradeData, subdata, timedata, startDateRange, endDateRange]);
  return (
    <div>
      <div className='row'>
        <div className='myrevenue-main'>
          {classCompleted && (
            <RevenueFilter
              subjectList={subjectList}
              setSubdata={setSubdata}
              subdata={subdata}
              gradeList={gradeList}
              gradeData={gradeData}
              setGradeData={setGradeData}
              setTimedata={setTimedata}
              timedata={timedata}
              startDateRange={startDateRange}
              setStartDateRange={setStartDateRange}
              endDateRange={endDateRange}
              setEndDateRange={setEndDateRange}
              classCompleted={classCompleted}
              totalRevenue={totalRevenue}
              revenueLength={revenueLength}
              loading={loading}
              classesCompCount={classesCompCount}
              setUpcomingPage={setUpcomingPage}
              upcomingPage={upcomingPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subjectList: state?.Auth?.Login?.data?.subject,
  gradeList: state?.Auth?.Login?.data?.grade,
  teacherId: state.Auth.Login?.data?._id,
  aceesToken: state.Auth.Login?.data?.accessToken,
});

export default connect(mapStateToProps)(RevenueMain);
