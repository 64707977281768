import * as actionTypes from './MyAssignmentExamTypes';

const initialState = {
  upcomingExam: {},
  previousExam: {},
  startExam: [],
  questionInExam: [],
  myReport: {},
  allQuesAns: [],
  finishExam: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_MYASSIGNMENTS_UPCOMING_EXAM:
      return {
        ...state,
        upcomingExam: action.payload,
      };
    case actionTypes.UPDATE_PREVIOUS_MYASSIGNMENTS_EXAM:
      return {
        ...state,
        previousExam: action.payload,
      };
    case actionTypes.UPDATE_MY_MYASSIGNMENTS_REPORT:
      return {
        ...state,
        myReport: action.payload,
      };
    case actionTypes.UPDATE_START_MYASSIGNMENTS_EXAM:
      return {
        ...state,
        startExam: action.payload,
      };
    case actionTypes.UPDATE_FINISH_MYASSIGNMENTS_EXAM:
      return {
        ...state,
        finishExam: action.payload,
      };
    case actionTypes.CLEAR_FINISH_MYASSIGNMENTS_EXAM:
      return {
        ...state,
        finishExam: [],
      };
    case actionTypes.CLEAR_QUESTION_IN_MYASSIGNMENTS_EXAM:
      return {
        ...state,
        questionInExam: [],
      };
    case actionTypes.UPDATE_GET_QUESTION_IN_MYASSIGNMENTS_EXAM:
      return {
        ...state,
        questionInExam: action.payload,
      };
    case actionTypes.UPDATE_ALL_MYASSIGNMENTS_QUESTION_ANSWER:
      return {
        ...state,
        allQuesAns: action.payload,
      };
    case actionTypes.ADD_ALL_MYASSIGNMENTS_QUESTION_ANSWER:
      return {
        ...state,
        allQuesAns: [...state.allQuesAns, action.payload],
      };

    default:
      return { ...state };
  }
}
