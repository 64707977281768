import React, { useEffect } from 'react';
import { array, func, string } from 'prop-types';
import Modal from 'react-modal';
import { chevRight } from '../../../../../../assets/icons/IconList';
import VideoMapp from './VideoMap';
import './temp.scss';
import { useState } from 'react';
import { renderText } from '../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/textHelper';
import { useHistory, useLocation } from 'react-router';
import EmbeddedFile from '../../../../../components/dashboard/Rightsidepart/SubTopic/EmbadePdfAndDocs';

const VideoMap = ({
  video,
  openCurrentVideo,
  currVideo,
  playCurrentVideo,
  playVideo,
  content,
}) => {
  const [click, setClick] = useState(false);
  const [newContents, setNewContents] = useState([]);
  const [currentData, setCurrent] = useState();
  const [eBook, setEbook] = useState();
  const [mindMapData, setMindMap] = useState();
  const [videoList, setVideos] = useState();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      border: 0,
      backgroundColor: 'transparent',
    },
    overlay: {
      backgroundColor: '#000000bf',
      zIndex: 999,
    },
  };

  useEffect(() => {
    const subtopicteacher = query.get('subtopicteacher');
    const mindmapteacher = query.get('mindmapteacher');
    if (subtopicteacher && !mindmapteacher) {
      playCurrentVideo(null);
      setCurrent();
    }
    // else if(!subtopicteacher){
    //   history.push('/teacher/subjects');
    // }
  }, [location.search]);

  useEffect(() => {
    if (video?.length) {
      let newArray = [];
      let mindmap = [],
        videos = [],
        contentt = [];
      mindmap = video?.filter((iteemm) => iteemm?.type == 'mindmap');
      let newEbookPdfList = video?.filter((iteemm) => iteemm?.type === 'ebook');
      videos = video?.filter(
        (iteemm) => iteemm?.type !== 'mindmap' && iteemm?.type !== 'ebook'
      );
      videos = [{ data: videos, type: 'Videos' }];
      mindmap = [{ type: 'Mind Map', data: mindmap }];
      contentt = [{ type: 'e-books', data: newEbookPdfList }];
      newArray = [
        ...newArray,
        ...mindmap,
        ...contentt,
        ...videos,
        ...[{ type: 'Practice test' }],
      ];
      setNewContents([...newArray]);
    }
    // if (video?.length) {
    //   let newArray = [];
    //   let mindmap = [],
    //     videos = [],
    //     contentt = [];
    //   mindmap = video?.filter((iteemm) => iteemm?.type == 'mindmap');
    //   videos = video?.filter((iteemm) => iteemm?.type !== 'mindmap');
    //   videos = [{ data: videos, type: 'Videos' }];
    //   mindmap = [{ type: 'Mind Map', data: mindmap }];
    //   contentt = [{ type: 'e-books', data: content }];
    //   newArray = [...newArray, ...mindmap, ...contentt, ...videos];
    //   setNewContents([...newArray]);
    // }
  }, [content, video]);
  return video.length ? (
    <div className='tree video-tree video-container'>
      <div className='videos-treee'>
        <div className='side-line' />
        {newContents?.map((item, i) => (
          <>
            <div className='branch'>
              <div
                className='branch-line'
                style={{ paddingLeft: 29 * (i + 1) }}
              >
                {' '}
              </div>
              <div
                className='type'
                onClick={() => {
                  setClick(!click);
                  setCurrent(i);
                  if (i == 0) {
                    history.push({
                      pathname: '/teacher/subjects',
                      search: '?subtopicteacher=true&&mindmapteacher=true',
                    });
                    setMindMap(item?.data?.[0].link);
                  } else setMindMap();
                  if (i == 2) setVideos(item?.data);
                  else setVideos();
                  history.push({
                    pathname: '/teacher/subjects',
                    search: '?subtopicteacher=true&&mindmapteacher=true',
                  });
                }}
                onKeyPress={() => {}}
                role='button'
                tabIndex={0}
                aria-label='Play'
              >
                {item?.type == 'Practice test' ? (
                  ''
                ) : (
                  <button type='button' className='videolist-btn'>
                    {item?.type}
                    {/* <span>{chevRight}</span> */}
                  </button>
                )}
                <Modal
                  isOpen={currentData == 0 ? true : false}
                  onRequestClose={() => {
                    playCurrentVideo(null);
                    setCurrent();
                  }}
                  contentLabel='My dialog'
                  style={customStyles}
                >
                  <div>
                    <div className='d-flex justify-content-center align-items-center'>
                      <iframe
                        src={mindMapData}
                        width={window.innerWidth - 300 + 'px'}
                        height={window.innerHeight + 'px'}
                      ></iframe>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
            {currentData == i ? (
              item?.type == 'e-books' ? (
                <Modal
                  isOpen={currentData == 1 ? true : false}
                  onRequestClose={() => {
                    playCurrentVideo(null);
                    setCurrent();
                  }}
                  contentLabel='My dialog'
                  style={customStyles}
                  shouldCloseOnOverlayClick
                >
                  {/* {console.log(item, 'fgjhkl')} */}
                  <div className='modalContentMain'>
                    {item?.data?.[0].link && (
                      <div>
                        {/* <button
                          className='model-close-button p-0'
                          onClick={() => {
                            playCurrentVideo(null);
                            setCurrent();
                          }}
                          style={{ zIndex: '999', background: 'red' }}
                        >
                          <CloseSquareFilled
                            style={{ fontSize: '30px', color: '#ffffff' }}
                          />
                        </button> */}
                        <div>
                          <EmbeddedFile
                            playCurrentVideo={playCurrentVideo}
                            setCurrent={setCurrent}
                            pdfData={item?.data?.[0].link}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Modal>
              ) : item?.type == 'Videos' && currentData == 2 ? (
                <VideoMapp
                  video={videoList}
                  openCurrentVideo={openCurrentVideo}
                  currVideo={currVideo}
                  playCurrentVideo={playCurrentVideo}
                  playVideo={playVideo}
                />
              ) : item?.type == 'Mind Map' ? (
                ''
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </>
        ))}
      </div>
    </div>
  ) : (
    <h5>No Videos Found</h5>
  );
};

VideoMap.propTypes = {
  video: array.isRequired,
  openCurrentVideo: func.isRequired,
  currVideo: string.isRequired,
  playCurrentVideo: func.isRequired,
  playVideo: string.isRequired,
};

export default VideoMap;
