import React, { useState, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { object } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import right from '../../../../../../assets/images/right.png';
import wrong from '../../../../../../assets/images/wrong.png';
import minus from '../../../../../../assets/images/minus.png';
import plus from '../../../../../../assets/images/plus.png';
import { includes } from 'lodash';
import { renderText } from '../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/textHelper';
import { findKeyByValue } from '../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/questionHelper';
import MyExamReportOptions from '../../../../../components/dashboard/Rightsidepart/MyExamReportOptions';
import { PassageReportPreview } from '../../../../../components/dashboard/Rightsidepart/passageReport';
import { renderTextNew } from '../../../../../components/dashboard/Rightsidepart/BrainGym/QueAnswer/newTextHelper';

const MyexamanswerRightpart = ({ assignReport }) => {
  const [toggle, setToggle] = useState('');
  const [open, setOpen] = useState(false);
  const [anserdSolution, setAnserdSolution] = useState([]);

  const extractContent = (s) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const renderFillInTheBlanksQus = (rawText) => {
    const quoteadd = rawText?.replace(/(&rsquo;)/gi, '’');
    const string = quoteadd.replace(/(&nbsp;|<([^>]+)>)/gi, ''); //REMOVING ALL TAGS
    const finalString = string.replaceAll('insert_input', '______________');
    return finalString;
  };

  const handleClick = (val) => {
    setOpen(!open);
    if (toggle === val) {
      setToggle('');
    } else {
      setToggle(val);
    }
  };
  const data = assignReport;
  const checkCorrectOrNot = (id, solutionIndex, item) => {
    if (item?.solutionType == '13') {
      const result = data?.result?.find(
        (obj) => obj.question === id
      )?.fillInTheBlankSolution;
      if (result.length === 0) {
        return false;
      }
      const answers = item?.fillInTheBlankSolution;
      let is_same = true;

      result?.map((ans, i) => {
        if (ans.toLowerCase() !== answers[i].toLowerCase()) is_same = false;
      });
      return is_same;
    } else {
      const result = data?.result?.find(
        (obj) => obj.question === id
      )?.solutionIndex;
      const is_same =
        result?.length === solutionIndex.length &&
        result?.every((element, index) => element === solutionIndex[index]);
      return is_same;
    }
  };
  const givenanswer = (id) => {
    const result = data?.result?.find(
      (obj) => obj.question === id
    )?.solutionIndex;
    return result;
  };
  const answeredOrNot = (id, item) => {
    if (item?.solutionType == '13') {
      const result = data?.result?.find(
        (obj) => obj.question === id
      )?.fillInTheBlankSolution;

      const is_same = result.length === 0;
      return is_same;
    }
    const result = data?.result?.find(
      (obj) => obj.question === id
    )?.solutionIndex;
    const is_same = result < 0;
    return is_same;
  };

  const renderAnswer = (item, result) => {
    const givenanswerIndex = givenanswer(item?._id);
    const answeredIndex = givenanswerIndex[0];
    if (findKeyByValue(item.solutionType) === 'multi') {
      if (givenanswerIndex.includes(-1)) {
        return '';
      }
      return givenanswerIndex.map((key, index) => (
        <>
          {item?.options[key]?.text ? (
            <span className='d-flex'>
              {String.fromCharCode(key + 65)} <span className='opti'> . </span>
              {renderText(item?.options[key]?.text)}
            </span>
          ) : (
            <span className='d-flex'>
              {String.fromCharCode(key + 65)} <span className='opti'> . </span>
              <img src={item?.options[key]?.image} alt='true' />
            </span>
          )}
        </>
      ));
    }
    if (findKeyByValue(item.solutionType) === 'passage') {
      return item?.passageQuestions?.map((ques, pasIndex) => (
        <PassageReportPreview
          question={ques}
          solutionIndex={result?.passageQuestions?.[pasIndex]?.solutionIndex}
        />
      ));
    }

    if (item?.options[answeredIndex]?.text) {
      renderText(item?.options[answeredIndex]?.text);
    }
    if (item?.options[answeredIndex]?.image) {
      return <img src={item?.options[answeredIndex]?.image} alt='true' />;
    }
    return renderText(item?.options[answeredIndex]?.text);
  };
  const renderAnswerBasedOnType = (item, qType, result) => {
    switch (qType) {
      case '15':
        return extractContent(item.description)
          ?.split(' ')
          ?.filter((i, index) => givenanswer(item?._id)?.includes(index))
          .map((e) => <span key={e}>{e} </span>);
      case '16':
        return extractContent(item.description)?.split(' ')?.[
          givenanswer(item?._id)?.[0]
        ];
      case '20':
        return extractContent(item.description)
          ?.split(' ')
          ?.filter((i, index) => givenanswer(item?._id)?.includes(index))
          .map((e) => <span key={e}>{e} </span>);
      case '14': {
        let finalString = assignReport?.result?.filter(
          (result) => result.question === item._id
        );
        if (finalString[0]?.solutionIndex.includes(-1)) {
          return '';
        }
        finalString = (
          <>
            {finalString[0]?.dragTheTextSolution?.map((each) => (
              <span className='fillInAnswers'>{each}</span>
            ))}
          </>
        );
        return finalString;
      }
      case '13': {
        let finalString = assignReport?.result?.filter(
          (result) => result.question === item._id
        );
        if (finalString[0]?.fillInTheBlankSolution.length === 0) {
          return '';
        }
        finalString = (
          <>
            {finalString[0]?.fillInTheBlankSolution?.map((each) => (
              <span className='fillInAnswers'>{each}</span>
            ))}
          </>
        );
        return finalString;
      }
      default:
        return renderAnswer(item, result);
    }
  };
  const renderCollapsBasedOnType = (item, qType) => {
    switch (qType) {
      case '7':
        return item?.passageQuestions?.map((ques, pasIndex) => (
          <PassageReportPreview question={ques} type={'collapsed'} />
        ));
      case '15':
        return extractContent(item.description)
          ?.split(' ')
          ?.map((i, index) => {
            return (
              <button
                className={`${
                  item?.solutionIndex?.includes(index)
                    ? 'olympiad_active_btn'
                    : ''
                }`}
                style={{ marginRight: '5px' }}
                key={i}
              >
                {i}
              </button>
            );
          });
      case '16':
        return extractContent(item.description)
          ?.split(' ')
          ?.map((i, index) => {
            return (
              <button
                className={`${
                  item?.solutionIndex?.[0] === index
                    ? 'olympiad_active_btn'
                    : ''
                }`}
                style={{ marginRight: '5px' }}
                key={i}
              >
                {i}
              </button>
            );
          });
      case '20':
        return extractContent(item.description)
          ?.split(' ')
          ?.map((i, index) => {
            return (
              <button
                className={`${
                  item?.solutionIndex?.includes(index)
                    ? 'olympiad_active_btn'
                    : ''
                }`}
                style={{ marginRight: '5px' }}
                key={i}
              >
                {i}
              </button>
            );
          });
      case '14': {
        let finalString = (
          <span className='text'>
            <h5 className='answerRight pb-3'>Correct Answer</h5>
            {item?.dragTheTextSolution?.map((each) => (
              <span className='fillInAnswers mr-3'>{each}</span>
            ))}
          </span>
        );
        return finalString;
      }
      case '13': {
        let finalString = (
          <span className='text'>
            <h5 className='answerRight pb-3'>Correct Answer</h5>
            {item?.fillInTheBlankSolution?.map((each) => (
              <span className='fillInAnswers mr-3'>{each}</span>
            ))}
          </span>
        );
        return finalString;
      }

      case '3':
        return (
          <>
            <h5 className='answerRight pt-5 pb-3'>Correct Answer</h5>
            <MyExamReportOptions questionObject={item} />
          </>
        );
      case '10':
        return (
          <>
            <h5 className='answerRight pt-5 pb-3'>Correct Answer</h5>
            <MyExamReportOptions questionObject={item} />
          </>
        );
      case '18':
        return (
          <>
            <h5 className='answerRight pt-5 pb-3'>Correct Answer</h5>
            <MyExamReportOptions questionObject={item} />
          </>
        );

      default:
        return (
          <>
            <h5 className='answerRight  pb-3'>Correct Answer</h5>
            {item?.options?.map(
              (ele, i) =>
                (ele?.text !== '' || ele?.image !== '') && (
                  <MyExamReportOptions
                    questionObject={item}
                    item={ele}
                    selectedOption={item?.solutionIndex}
                    // setSelectedOption={setSelectedOption}
                    ind={i}
                  />
                )
            )}
          </>
        );
    }
  };
  return (
    <div className='myexam-answer-right'>
      <div>
        <h3>Check your answers and evaluate yourself</h3>
        {data?.questionDetails?.map((item, ind) => (
          <div key={item?._id} className='row exam-right-common'>
            <div className='col-md-11 col-sm-11 col-10 exam-right-center'>
              <div className='question'>
                <span className='index'>{ind + 1}.</span>
                <h5>
                  {item?.solutionType == '14' || item?.solutionType == '13'
                    ? renderFillInTheBlanksQus(item?.description)
                    : renderText(item?.description)}
                </h5>
              </div>
              {!answeredOrNot(item?._id) &&
              (findKeyByValue(item?.solutionType) === 'drag-and-drop' ||
                findKeyByValue(item?.solutionType) === 'match-the-following' ||
                findKeyByValue(item?.solutionType) ===
                  'select-the-word-to-match-the-correct-option') ? (
                <>
                  <h5
                    className={
                      checkCorrectOrNot(item?._id, item?.solutionIndex, item)
                        ? 'answerRight'
                        : 'answerWrong'
                    }
                  >
                    {answeredOrNot(item?._id)
                      ? 'Not Answered'
                      : 'You Answered : '}
                  </h5>

                  <table className='drag-detail-table'>
                    <thead>
                      <th>Statement</th>
                      <th>Options</th>
                    </thead>
                    <tbody>
                      {data?.result
                        ?.find((obj) => obj.question === item?._id)
                        ?.solutionIndex.map((option, index) => (
                          <tr>
                            <td>
                              {item?.statement[index]?.image ? (
                                <img
                                  src={item?.statement[index]?.image}
                                  alt='option_image'
                                />
                              ) : (
                                <span>{item?.statement[index]?.text}</span>
                              )}
                            </td>
                            {item?.options[option]?.image ? (
                              <td>
                                <img
                                  src={item?.options[option]?.image}
                                  alt='option_image'
                                />
                              </td>
                            ) : (
                              <td>
                                <span>{item?.options[option]?.text}</span>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              ) : findKeyByValue(item.solutionType) === 'passage' ? (
                <div
                  className={
                    checkCorrectOrNot(item?._id, item?.solutionIndex, item)
                      ? 'answerRight'
                      : 'answerWrong'
                  }
                >
                  {answeredOrNot(item?._id)
                    ? 'Not Answered'
                    : 'You Answered : '}
                  <div
                    className={
                      item?.options[givenanswer(item?._id)]?.image
                        ? 'image'
                        : 'text'
                    }
                  >
                    {renderAnswerBasedOnType(
                      item,
                      item?.solutionType,
                      data?.result?.[ind]
                    )}
                  </div>
                </div>
              ) : (
                <h5
                  className={
                    checkCorrectOrNot(item?._id, item?.solutionIndex, item)
                      ? 'answerRight'
                      : 'answerWrong'
                  }
                >
                  {answeredOrNot(item?._id, item)
                    ? 'Not Answered'
                    : 'You Answered : '}
                  <span
                    className={
                      item?.options[givenanswer(item?._id)]?.image
                        ? 'image'
                        : 'text'
                    }
                  >
                    {renderAnswerBasedOnType(item, item?.solutionType)}
                  </span>
                </h5>
              )}

              <Collapse in={ind === toggle}>
                <div id='example-collapse-text' className='option-main'>
                  <div className='question-options-wrapper'>
                    {renderCollapsBasedOnType(item, item?.solutionType)}
                    {item?.solutionDescription && (
                      <div className='option-wrapper-solution'>
                        <h4>Solution description</h4>
                        <div>{renderTextNew(item?.solutionDescription)}</div>
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </div>
            <div className='col-md-1 col-sm-1 col-2 exam-right-right'>
              <button
                type='button'
                onClick={() => handleClick(ind)}
                aria-controls='example-collapse-text'
                aria-expanded={open}
              >
                {ind === toggle ? (
                  <img src={minus} alt='minus' />
                ) : (
                  <img src={plus} alt='plus' />
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyexamanswerRightpart;
