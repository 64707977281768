import { func, object, array } from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { BsChevronDown } from 'react-icons/bs';
import search from '../../../../assets/images/search.png';
import calendar from '../../../../assets/images/calendar.png';
import notification from '../../../../assets/images/notification.png';
import {
  clearLoginAction,
  parentProfileAction,
  updateParentLoginStudentnAction,
  getTotalPoints,
} from '../../../../stores/Auth/AuthAction';
import Notificationmenu from './Notificationmenu';

import moment from 'moment';

import { startChestAction } from '../../../../stores/BrainGym/BrainGymAction';

const StyledDash = styled.div`
  background: #ffffff;
  font-family: 'proxima_nova_rgregular';
  height: 60px;
  margin: auto;
  padding-inline: 20px;
  box-shadow: 0px 0px 5px rgba(154, 154, 154, 0.1);
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
`;

const StyledPara = styled.p`
  margin: 0px 30px 0px 0px;
  color: #454c4e;
`;

const StyledNotification = styled.img`
  width: 34px;
`;

const notyIconStyle = {
  position: 'relative',
  display: 'inline',
  border: 'none',
  background: 'none',
};
const notyNumStyle = {
  position: 'absolute',
  right: '0',
  backgroundColor: 'rgb(29, 161, 242)',
  fontSize: '11px',
  color: 'white',
  display: 'inline',
  padding: '3px 5px',
  borderRadius: '20px',
};

const StyledCalendar = styled.img`
  width: 24px;
  margin-right: 10px;
`;

const StyledSpan = styled.span`
  height: 24px;
  width: 24px;
  background-color: #fca834;
  margin-right: 10px;
  border-radius: 50%;
`;

const DropdownContainer = styled(Dropdown)`
  height: auto;
  width: auto;
  border: none;
  background-color: transparent;

  .dropdown-btn {
    background-color: transparent;
    border: none;
    padding: 0px;
    color: #454c4e;
  }
  .dropdown-btn::after {
    display: none !important;
  }
  .dropdown-btn:focus {
    outline: none;
    box-shadow: none;
  }
`;

const SearchBox = styled.input`
  border: none;
  outline: none;
  padding: 10px;
`;

const SearchIcon = styled.img`
  width: 24px;
`;

const DashSearch = ({
  studendata,
  userData,
  updateParentLoginStudentn,
  Loginparent,
  count,
  lists,
  upcomingExamCount,
  setUpcomingPage,
  upcomingPage,
  subjectList,
  startChest,
  chests,
  getNotificationCount,
  getParentProfile,
  accessToken,
  pointsErned,
  getPoints,
}) => {
  const ref = useRef();
  const [show, setShow] = useState(false);

  useEffect(() => {
    getParentProfile({ parent_id: userData?.data?.parent });
  }, []);

  useEffect(() => {
    const shouldRemoveLocalStorage = location.pathname !== '/dashboard/subject';

    if (shouldRemoveLocalStorage) {
      // Remove localStorage logic here
      localStorage.removeItem('chapterIndex');
    }
  }, [location.pathname]);

  useEffect(() => {
    getPoints();
  }, [studendata]);

  // console.log(studendata, 'data');

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (show && ref.current && !ref.current.contains(e.target)) {
        // handleClose();
        setShow(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [show]);

  const selectStudent = (item) => {
    updateParentLoginStudentn(item);
  };
  useEffect(() => {
    if (subjectList && subjectList?.length > 0) {
      startChest(subjectList[0]?.subject?._id);
      // startChest(subjectList[0]?.subject);
    }
  }, [subjectList]);
  // useEffect(() => {
  //   if (chests?.length > 0) {
  //     const chest = chests?.find((data) => data.status !== 'COMPLETED');
  //     setCurrentChest(chest);
  //   } else {
  //     setCurrentChest([]);
  //   }
  // }, [chests]);
  const calculateScore = (chests) => {
    return _.sumBy(chests, function (o) {
      return o.scoreObtained;
    });
  };
  return (
    <>
      <StyledDash className='row'>
        {userData?.data?.userType === 'parent' ? (
          <div className='col-md-3 p-0 d-flex align-items-center'>
            <DropdownContainer>
              <Dropdown.Toggle className='dropdown-btn'>
                {studendata?.profilePic !== null ? (
                  <Link to='/dashboard/my-profile'>
                    <img
                      style={{
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%',
                      }}
                      src={studendata?.profilePic}
                      alt='profile'
                    />
                  </Link>
                ) : (
                  <Avatar
                    maxInitials={1}
                    size={50}
                    round
                    name={studendata?.studentName}
                  />
                )}{' '}
                {studendata?.studentName}
                <BsChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Loginparent?.students?.map((item) => (
                  <>
                    <Dropdown.Item
                      key='All'
                      onClick={() =>
                        selectStudent({
                          data: {
                            ...item,
                            userType: 'parent',
                            accessToken: accessToken,
                          },
                        })
                      }
                    >
                      {item?.profilePic !== null ? (
                        <Link to='/dashboard/my-profile'>
                          <img
                            style={{
                              height: '50px',
                              width: '50px',
                              borderRadius: '50%',
                            }}
                            src={item?.profilePic}
                            alt='profile'
                          />
                        </Link>
                      ) : (
                        <Avatar
                          maxInitials={1}
                          size={50}
                          round
                          name={item?.studentName}
                        />
                      )}{' '}
                      {item?.studentName}
                    </Dropdown.Item>
                  </>
                ))}
              </Dropdown.Menu>
            </DropdownContainer>
          </div>
        ) : (
          <div className='col-md-3 p-0 d-flex align-items-center '>
            <StyledCalendar src={calendar} alt='calendar' />
            <p className='m-0'>
              {' '}
              {moment(new Date()).format('MMMM Do, YYYY')}{' '}
            </p>
          </div>
          // <div>
          //   <SearchIcon src={search} alt='search' />
          //   <SearchBox type='search' placeholder='Search' />
          // </div>
        )}

        {userData?.data?.userType === 'parent' ? (
          <div className='col-md-3 p-0 d-flex align-items-center justify-content-center'>
            <StyledCalendar src={calendar} alt='calendar' />
            <p className='m-0'>
              {' '}
              {moment(new Date()).format('MMMM Do, YYYY')}{' '}
            </p>
          </div>
        ) : (
          ''
        )}
        {userData?.data?.userType === 'parent' ||
        userData?.data?.userType === 'student' ? (
          <div className='col-md-4 p-0 d-flex align-items-center justify-content-end'>
            <StyledSpan></StyledSpan>
            <StyledPara>
              {/* You have{' '} */}
              <span style={{ fontFamily: 'proxima_nova_rgbold' }}>
                {/* {calculateScore(chests)} */}
                {pointsErned ? pointsErned : 0}
              </span>{' '}
              points
            </StyledPara>
            <div ref={ref}>
              <button
                type='button'
                style={notyIconStyle}
                // onClick={() => setShow(!show)}
                onClick={() => setShow((oldState) => !oldState)}
              >
                <StyledNotification src={notification} alt='notification' />
                {count > 0 ? (
                  count > 99 ? (
                    <div style={notyNumStyle}>99+</div>
                  ) : (
                    <div style={notyNumStyle}>{count}</div>
                  )
                ) : null}
              </button>
              {/* {lists?.length ? ( */}
              <Notificationmenu
                className={show}
                lists={lists}
                handleClose={() => setShow(false)}
                upcomingExamCount={upcomingExamCount}
                setUpcomingPage={setUpcomingPage}
                upcomingPage={upcomingPage}
                getNotificationCount={getNotificationCount}
              />
              {/* ) : (
                ''
              )} */}
            </div>
          </div>
        ) : (
          ''
        )}
      </StyledDash>
    </>
  );
};
DashSearch.propTypes = {
  studendata: object.isRequired,
  userData: object.isRequired,
  Loginparent: object.isRequired,
  updateParentLoginStudentn: func.isRequired,
  startChest: func.isRequired,
  chests: array.isRequired,
};
const mapStateToProps = (state) => ({
  studendata: state.Auth.Login?.data,
  Loginparent: state.Auth.ParentProfile?.data?.parent,
  userData: state.Auth.Login,
  subjectList: state.Auth.Login?.data?.subjectsEnrolled,
  chests: state?.BrainGym?.chests?.data?.chests,
  accessToken: state.Auth.LoginParent?.data?.accessToken,
  pointsErned: state.Auth?.braingymPointsErned,
});
const mapDispatchToProps = (dispatch) => ({
  clearLogin: () => dispatch(clearLoginAction()),
  getParentProfile: (data) => dispatch(parentProfileAction(data)),
  startChest: (data) => dispatch(startChestAction(data)),
  getPoints: () => dispatch(getTotalPoints()),
  updateParentLoginStudentn: (data) =>
    dispatch(updateParentLoginStudentnAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DashSearch);
