import React from 'react';
import { renderText } from './BrainGym/QueAnswer/textHelper';
import right from '../../../../assets/images/right.png';
import wrong from '../../../../assets/images/wrong.png';
import { checkIcon } from '../../../../assets/icons/IconList';

export const PassageReportPreview = ({ question, solutionIndex, type }) => {
  return (
    <>
      {type !== 'collapsed' ? (
        <div>
          <div className='row'>
            <div className='col-12'>
              <div>
                <div className='question-box custom-question-box'>
                  <h4>
                    {' '}
                    <img
                      style={{
                        width: '35px',
                        height: '25px',
                        marginRight: '10px',
                      }}
                      src={
                        question.solutionIndex[0] === solutionIndex?.[0]
                          ? right
                          : wrong
                      }
                      alt='true'
                    />
                    Question
                  </h4>
                  <h2>
                    {' '}
                    <span>{renderText(question.description)}</span>
                  </h2>
                </div>
              </div>

              <div className='question-box custom-question-box'>
                <div className='col-sm-8'>
                  <div className='question-options-wrapper selectTyleType'>
                    {solutionIndex?.[0] == '-1' ? (
                      <h5 className='answerWrong'>Not Answered</h5>
                    ) : (
                      <div
                        className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                  ${
                    question.solutionIndex[0] === solutionIndex?.[0]
                      ? 'successBack'
                      : 'failure'
                  }`}
                      >
                        <button type='button'>
                          <div className={`col-sm-8 customAlignBtnPrac`}>
                            {question?.options?.[solutionIndex?.[0]]
                              ?.imageElement === null && (
                              <div className='questionHtlm-right'>
                                <p className='d-flex align-items-center'>
                                  {String.fromCharCode(solutionIndex?.[0] + 65)}
                                  . &nbsp;
                                  {renderText(
                                    question?.options?.[solutionIndex?.[0]]
                                      ?.text
                                  )}{' '}
                                </p>
                              </div>
                            )}
                            {question?.options?.[solutionIndex?.[0]]
                              ?.imageElement !== '' && (
                              <div className='questionHtlm-right'>
                                <p className='d-flex align-items-center'>
                                  {String.fromCharCode(solutionIndex?.[0] + 65)}
                                  .&nbsp;
                                  {renderText(
                                    question?.options?.[solutionIndex?.[0]]
                                      ?.imageElement
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h5
            style={{
              color: '#43aa8b',
              fontFamily: 'proxima_nova_rgbold',
              margin: '10px 0px 0px 0px',
              display: 'flex',
            }}
          >
            Correct Answer
          </h5>
          <div className='row'>
            <div className='col-12'>
              <div>
                <div className=''>
                  <h4>Question</h4>
                  <h2>
                    {' '}
                    <span>{renderText(question.description)}</span>
                  </h2>
                </div>
              </div>

              {question.options.map((item, i) => (
                <div
                  className={`option-wrapper-tile-type d-flex align-items-center
       justify-content-between p-4
           ${question.solutionIndex[0] === i ? 'true' : ''}
          `}
                >
                  <div
                    className='optionsbtn d-flex align-items-center
      justify-content-between'
                  >
                    {String.fromCharCode(i + 65)}{' '}
                    <span className='opti'> . </span>
                    {item?.image && (
                      <div className='questionHtlm-left'>
                        <img src={item?.image} alt='option_image' />
                      </div>
                    )}
                    {item?.text !== '' && (
                      <div className='questionHtlm-right'>
                        <span>{renderText(item?.text)}</span>
                      </div>
                    )}
                    <div
                      className='icon--check-wrapper d-flex align-items-center
       justify-content-center'
                    >
                      {checkIcon}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
