import React, { useState } from 'react';
import stars1 from '../../../../../assets/icons/practiceTest/brightstars1.svg';
import stars2 from '../../../../../assets/icons/practiceTest/brightstars2.svg';
import success from '../../../../../assets/icons/practiceTest/success.svg';
import lightstar1 from '../../../../../assets/icons/practiceTest/lightstars1.svg';
import lightstar2 from '../../../../../assets/icons/practiceTest/lightstarts2.svg';
import LeadCaptureForm from '../../../../../Layout/leadCaptureForm';
import bottomedgestar from '../../../../../assets/icons/practiceTest/bottom-edge-star.svg';
import bottomstar from '../../../../../assets/icons/practiceTest/bottom-star.svg';
import { chevRight } from '../../../../../assets/icons/IconList';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import close from '../../../../../assets/images/close.png';

const AchiverPopUP = ({
  practiceComplete,
  subject,
  noquestion,
  statusVal,
  totalmarks,
}) => {
  const [showLeadCaptureForm, setShowLeadCaptureForm] = useState(false);
  const history = useHistory();

  const handleMobile = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width <= '991') {
      // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      setShowLeadCaptureForm(true);
    } else {
      history.push('/login');
    }
  };

  const closepopup = () => {
    history.push(`/chapters/${subject}`);
  };
  return (
    <div className='congrats-popup-main'>
      <div className='right-answer-container'>
        <div className='close-top'>
          <button type='button' className='close-btn' onClick={closepopup}>
            <img src={close} alt='close' />
          </button>
        </div>
        <div className='achiver-popup-modal-bg'>
          <div className='achiver-popup-modal'>
            <div className=''>
              <img
                src={stars1}
                className='achiver-star-bright'
                alt='web-logo'
              />
            </div>
            <div className='achiver-start-light'>
              <img src={lightstar1} />
            </div>

            <div>
              {noquestion ? (
                <p className='noquest-tag'>{practiceComplete}</p>
              ) : (
                <>
                  <div className=''>
                    <img className='achiver-success-img' src={success} />
                  </div>
                  <div className='achiver-txt'>Great achiever !</div>
                  <div className='achiver-total'>
                    Total score:{' '}
                    <span>
                      {statusVal?.totalMarksObtained
                        ? statusVal?.totalMarksObtained
                        : totalmarks}
                    </span>
                  </div>
                  <p className='p-tag'>
                    You have exhausted free practice tests
                  </p>

                  <div className=''>
                    <img src={lightstar2} className='achiver-start-light-2' />
                  </div>
                  <div className=''>
                    <img src={stars2} className='achiver-star-bright-2' />
                  </div>
                  <div className='achiver-modal-sub'>
                    <div>
                      <div className='subscribe-txt'>
                        {/* {practiceComplete} */}
                        Please subscribe to access all classroom content
                      </div>
                      <div className='d-block'>
                        <button
                          type='button'
                          className='sub-btn '
                          onClick={handleMobile}
                        >
                          Subscribe now
                          <span>
                            <chevRight />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div>
                <img src={bottomedgestar} className='bottom-edge-star' />
              </div>
              <div>
                <img src={bottomstar} className='bottom-star' />
              </div>
            </div>
          </div>
          {showLeadCaptureForm ? (
            <LeadCaptureForm
              modal={showLeadCaptureForm}
              setModal={setShowLeadCaptureForm}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default AchiverPopUP;
