import React, { useEffect, useState } from 'react';
import { string, func } from 'prop-types';
import MyconceptHead from './ConceptHaed';
import Myconceptdesc from './ConceptDesc';
import '../../../../../pages/Olympiad/style/olympiad.scss';
import './concepts.scss';
import { connect } from 'react-redux';
import { getTeacherVideoAndContent } from '../../../../../../services/studentMySubjects.service';
import { useHistory, useLocation } from 'react-router';

const SubTopic = ({
  startQuestions,
  initialSubject,
  chapterId,
  conceptId,
  initailSubName,
  chapterName,
  conceptName,
  subConName,
  setQuestionsData,
  subConceptsList,
  handleChange,
  studentId,
  setConceptId,
  setChapterId,
  setSubConceptsList,
}) => {
  const localData = JSON.parse(localStorage.getItem('taut-student'));

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [video, setVideo] = useState([]);
  const [content, setContent] = useState([]);
  const [testResults, setTestResults] = useState(null);

  const videoContentData = async () => {
    try {
      const params = {
        teacher: localData?.data?._id,
        subject: initialSubject,
        subconcept: startQuestions,
      };
      const res = await getTeacherVideoAndContent(
        params,
        localData?.data?.accessToken
      );
      setVideo(res?.data?.data?.videos);
      setContent(res?.data?.data?.contents);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const subtopicteacher = query.get('subtopicteacher');
    // const mindmapteacher = query.get('mindmapteacher');
    if (!subtopicteacher) {
      setQuestionsData('');
    }
    // else if(!subtopicteacher){
    //   history.push('/teacher/subjects');
    // }
  }, [location.search]);

  useEffect(() => {
    videoContentData();
  }, []);

  useEffect(() => {
    if (subConName) videoContentData();
  }, [subConName]);

  return (
    <div className='Olympiad-main sub-topic-wrapper'>
      <div className='dashboard-main open'>
        <div className='row row-main dashboard-main-row sub-dashboard-main-row'>
          <div className='col-sm-12'>
            <div className='myconcept-main row'>
              <button
                type='button'
                style={{ textAlign: 'left' }}
                className='assignQueBack'
                onClick={() => {
                  history.push({ pathname: '/teacher/subjects' });
                  setQuestionsData('');
                }}
              >
                Go back
              </button>
              <div className='col-md-12'>
                <div className='myconcept-left'>
                  <MyconceptHead
                    initailSubName={initailSubName}
                    chapterName={chapterName}
                    conceptName={conceptName}
                    subConName={subConName}
                    subConceptsList={subConceptsList}
                    handleChange={handleChange}
                    subConcData={subConceptsList?.subconcepts?.find(
                      (data) => data.subconcept == startQuestions
                    )}
                    setQuestionsData={setQuestionsData}
                  />
                  <Myconceptdesc
                    chapterId={chapterId}
                    conceptId={conceptId}
                    startQuestions={startQuestions}
                    initialSubject={initialSubject}
                    video={video}
                    content={content}
                    subConName={subConName}
                    initailSubName={initailSubName}
                    chapterName={chapterName}
                    conceptName={conceptName}
                    subConcData={subConceptsList?.subconcepts?.find(
                      (data) => data.subconcept == startQuestions
                    )}
                    testResults={testResults}
                    setTestResults={setTestResults}
                    setChapterId={setChapterId}
                    setConceptId={setConceptId}
                    setSubConceptsList={setSubConceptsList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SubTopic.propTypes = {
  startQuestions: string.isRequired,
  initialSubject: string.isRequired,
  chapterId: string.isRequired,
  conceptId: string.isRequired,
  initailSubName: string.isRequired,
  chapterName: string.isRequired,
  conceptName: string.isRequired,
  subConName: string.isRequired,
  setQuestionsData: func.isRequired,
};

const mapStateToProps = (state) => ({
  studentId: state.Auth.Login?.data?._id,
  userData: state.Auth.Login,
});

export default connect(mapStateToProps)(SubTopic);
