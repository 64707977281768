import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import config from '../../../../../../helpers/config';

// import '@react-pdf/renderer';
import AWS from 'aws-sdk';
import { CloseSquareFilled } from '@ant-design/icons';
import './embedpdf.scss';
import Spinner from 'react-bootstrap/Spinner';

AWS.config.update({
  accessKeyId: config?.awsAcess,
  secretAccessKey: config.awsSecret,
  region: 'us-east-1',
});
const s3 = new AWS.S3();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EmbeddedFile = ({
  playCurrentVideo,
  setCurrent,
  pdfData,
  isOlympiad,
  handleOlympiadPdfClose,
}) => {
  //   const [documentData, setDocumentData] = useState(null);
  const [documentURL, setDocumentURL] = useState('');
  const [isPDF, setIsPDF] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchDocument = async () => {
      const bucketName = 'tautmore-e-books';
      const fileName = pdfData;

      const params = {
        Bucket: bucketName,
        Key: fileName,
      };

      try {
        const data = await s3.getObject(params).promise();

        if (data?.$response?.httpResponse?.statusCode === 200) {
          const documentData = new Blob([data.Body]);

          // setDocumentData(documentData);
          const documentURL = URL.createObjectURL(documentData);
          setDocumentURL(documentURL);

          const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
          setIsPDF(fileType === 'pdf');
          setLoader(false);
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchDocument();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      {loader ? (
        <div className='col-12 text-center my-4'>
          <Spinner animation='border' variant='dark' />
        </div>
      ) : (
        <div>
          {documentURL && (
            <div className=''>
              {/* {numPages > 0 && numPages === numPages - 1 && ( */}
              <button
                className='model-close-button p-0'
                onClick={() => {
                  if (isOlympiad) {
                    handleOlympiadPdfClose();
                  } else {
                    playCurrentVideo(null);
                    setCurrent();
                  }
                }}
                style={{ zIndex: '999', background: 'red' }}
              >
                <CloseSquareFilled
                  style={{ fontSize: '30px', color: '#ffffff' }}
                />
              </button>
              {/* )} */}
              <div className='modal-content modalContentScroll'>
                <Document
                  file={documentURL}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <div key={`page_${index + 1}`} className='pdf-page'>
                      <Page
                        pageNumber={index + 1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                      {/* {index === numPages - 1 && (
                        <button
                          className='model-close-button p-0'
                          onClick={() => {
                            playCurrentVideo(null);
                            setCurrent();
                          }}
                          style={{ zIndex: '999', background: 'red' }}
                        >
                          <CloseSquareFilled
                            style={{ fontSize: '30px', color: '#ffffff' }}
                          />
                        </button>
                      )} */}
                    </div>
                  ))}
                </Document>
              </div>
            </div>
          )}
        </div>
      )}

      <style>{`

        .pdf-page {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ccc;
            // padding: 10px;
          }
  
          
        .pdf-page .react-pdf__Page__canvas {
          width:100% !important;
          height:auto !important;
        }
      `}</style>
    </div>
  );
};

export default EmbeddedFile;
