import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bool, func, string, object } from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import defaultPic from '../../../assets/images/defaultPic.png';

import sidebarLogo from '../../../assets/images/sidebarlogo.png';
import {
  sidebardata,
  accountData,
  teachersidebardata,
  teachersidebardataWithoutCoCuri,
  teachersidebardataWitCoCuri,
  teachersidebardataCoCuriAndCur,
} from './mockData/SidebarData';
import { menu } from '../../../assets/icons/IconList';
import LogoutIcon from '../../../assets/icons/newLeftDashboardIcons/Logout.svg';
import ProfileIcon from '../../../assets/icons/teacherIcon/my-profile.svg';
import { clearLoginAction } from '../../../stores/Auth/AuthAction';
import {
  getProfileAction,
  getMyClassesAction,
  myLeavesListAction,
} from '../../../stores/TeacherDashboard/TeacherDashboardAction';

const Sidemenu = ({
  open,
  setOpen,
  clearLogin,
  viewType,
  myProfile,
  getMyProfile,
  getMyClasses,
  myLeavesList1,
  studendata,
}) => {
  useEffect(() => {
    getMyProfile();
    getMyClasses();
    // const data = {
    //   status: '',
    //   pageNumber: 1,
    //   limit: 5,
    // };

    // myLeavesList1(data);
  }, []);

  const myprofileDetail = myProfile?.data;

  const history = useHistory();
  const logOut = () => {
    localStorage.clear();
    clearLogin();
    history.push('/');
  };
  const toggleTrueFalse = () => {
    setOpen(!open);
  };

  const renderAccountData = () => {
    if (!accountData?.data) {
      return null;
    }

    return accountData?.data?.map((data) => (
      <li key={data?.title}>
        <img src={data?.image} alt='dash_list_img' />
        {data?.title}
      </li>
    ));
  };

  const renderList = () => {
    let updatedList = { ...teachersidebardata };
    if (!studendata?.co_curricular?.length > 0 && studendata?.subject.length > 0) {
      updatedList = { ...teachersidebardataWithoutCoCuri };
    }
    if (studendata?.co_curricular?.length > 0 && studendata?.subject.length == 0) {
      updatedList = { ...teachersidebardataWitCoCuri };
    }
    if(studendata?.subject.length > 0 && studendata?.co_curricular?.length > 0){
      updatedList = { ...teachersidebardataCoCuriAndCur };
    }
    return (
      studendata?.userType === 'teacher' ? updatedList : sidebardata
    )?.data?.map((data) => (
      <li
        key={data?.title}
        className={viewType && viewType === data?.to ? 'active' : ''}
      >
        <Link to={data?.to}>
          <img src={data?.image} alt='dash_list_img' />
          {data?.title}
        </Link>
      </li>
    ));
  };

  return (
    <div className=' dashboard-left col-sm-3'>
      <div className='logo-profile'>
        <Link to='/'>
          <img src={sidebarLogo} alt='logo' className='dashboard-logo' />
        </Link>
      </div>
      <div className='dashboard-profile'>
        <div className='row'>
          <div
            className='col-sm-3 dashboard-profile-left text-center'
            aria-hidden='true'
          >
            <Link to='/teacher/profile'>
              <img
                src={
                  myprofileDetail?.profilePicture
                    ? myprofileDetail?.profilePicture
                    : defaultPic
                }
                alt='profile'
              />
            </Link>
          </div>
          <div className='col-sm-6 dashboard-profile-center'>
            <Link to='/teacher/profile'>
              <h5>{myprofileDetail?.fullName}</h5>
              <p>{myprofileDetail?.id}</p>
            </Link>
          </div>
          <div
            className='col-sm-3 text-center dashboard-profile-right'
            onClick={toggleTrueFalse}
            aria-hidden='true'
          >
            {menu}
          </div>
        </div>
      </div>

      <div className='dashboard-overview dash-list-common'>
        <div
          key={
            (studendata?.userType === 'teacher'
              ? teachersidebardata
              : sidebardata
            )?.title
          }
        >
          <h5>
            {
              (studendata?.userType === 'teacher'
                ? teachersidebardata
                : sidebardata
              )?.title
            }
          </h5>
          <ul>{renderList()}</ul>
        </div>
        <div key={accountData?.title}>
          <h5>{accountData?.title}</h5>
          <ul>
            {renderAccountData()}
            <li className='' aria-hidden='true'>
              <Link to='/teacher/profile'>
                <img src={ProfileIcon} alt='dash_list_img' />
                My Profile
              </Link>
            </li>
            <li onClick={() => logOut()} className='' aria-hidden='true'>
              <img src={LogoutIcon} alt='dash_list_img' />
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

Sidemenu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
  clearLogin: func.isRequired,
  viewType: string.isRequired,
  myProfile: object.isRequired,
  getMyProfile: func.isRequired,
  getMyClasses: func.isRequired,
  myLeavesList1: func.isRequired,
};

const mapStateToProps = (state) => ({
  myProfile: state.TeacherDashboard.getProfile,
  studendata: state.Auth.Login?.data,
});

const mapDispatchToProps = (dispatch) => ({
  clearLogin: () => dispatch(clearLoginAction()),
  getMyProfile: () => dispatch(getProfileAction()),
  getMyClasses: () => dispatch(getMyClassesAction()),
  myLeavesList1: (data) => dispatch(myLeavesListAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
