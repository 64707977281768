import config from '../helpers/config';
import { fetchWrapper } from '../helpers/fetch-wrapper';

const baseUrl = `${config.apiBaseUrl}`;

function login(params) {
  return fetchWrapper.post(`${baseUrl}/students/login`, params);
}
function register(params) {
  return fetchWrapper.post(`${baseUrl}/students/register`, params);
}
function studentChangePass(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/students/change-password`,
    params,
    token
  );
}
function studentProfilePic(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/students/profile-pic-upload`,
    params,
    token
  );
}

function parentProfile(params, token) {
  return fetchWrapper.post(`${baseUrl}/parent/parent-profile`, params, token);
}

function parentChangePass(params, token) {
  return fetchWrapper.post(`${baseUrl}/parent/change-password`, params, token);
}
function parentProfilePic(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/parent/profile-pic-upload`,
    params,
    token
  );
}
function coCurricularActivities(token) {
  return fetchWrapper.get(
    `${baseUrl}/students/co-curricular-activities?`,
    token
  );
}
function sendOtp(params) {
  return fetchWrapper.post(`${baseUrl}/students/send-otp`, params);
}
function sendOtpParent(params) {
  return fetchWrapper.post(`${baseUrl}/parent/send-otp`, params);
}

function sendOtpTeacher(params) {
  return fetchWrapper.post(`${baseUrl}/teachers/send-otp`, params);
}

function verifyEmailTeacher(email) {
  return fetchWrapper.get(
    `${baseUrl}/teachers/check-user-exist?emailID=${email}`
  );
}
export function teacherEmailVerifiedOrnot(id) {
  return fetchWrapper.get(`${baseUrl}/teachers/check-email-is-verified/${id}`);
}

function sendOtpBookDemo(params) {
  return fetchWrapper.post(`${baseUrl}/parent/book-demo/send-otp`, params);
}

function verifyOtpBookDemo(params) {
  return fetchWrapper.post(`${baseUrl}/parent/book-demo/verify-otp`, params);
}
export function submitBookDemo(params) {
  return fetchWrapper.post(`${baseUrl}/parent/book-demo/submit`, params);
}

function verifyEmail(email) {
  return fetchWrapper.get(
    `${baseUrl}/teachers/check-user-exist?emailID=${email}`
  );
}

function verifyOtp(params) {
  return fetchWrapper.post(`${baseUrl}/students/verify-otp`, params);
}
function verifyOtpParent(params) {
  return fetchWrapper.post(`${baseUrl}/parent/verify-otp`, params);
}
function registerParent(params) {
  return fetchWrapper.post(`${baseUrl}/parent/register`, params);
}
function addGuardian(params) {
  return fetchWrapper.post(`${baseUrl}/parent/addGuardian`, params);
}

function verifyOtpTeacher(params) {
  return fetchWrapper.post(`${baseUrl}/teachers/verify-otp`, params);
}

function uploadTeacher(payload) {
  return fetchWrapper.post(
    `${baseUrl}/teachers/teacher-document-upload/${payload?.id}`,
    payload?.data
  );
}

function getAllGrades(params) {
  return fetchWrapper.get(`${baseUrl}/class/grades-by-board/${params.boardId}`);
}

function getAllBoards() {
  return fetchWrapper.get(`${baseUrl}/board/all-boards`);
}
function getUniqueSubjects(token) {
  return fetchWrapper.get(`${baseUrl}/syllabus/unique-subjects`, token);
}
function studentMyprofile(value, token) {
  return fetchWrapper.get(`${baseUrl}/students/my-profile`, token);
}

function getSubjectsByclass(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/syllabus/subjects-by-class`,
    params,
    token
  );
}

function registerTeacher(params) {
  return fetchWrapper.post(`${baseUrl}/teachers`, params);
}
function getTimeSlots() {
  return fetchWrapper.get(`${baseUrl}/teachers/timeslot`);
}

function getUniversities() {
  return fetchWrapper.get(`${baseUrl}/admin/universities-list`);
}

function getQualifications() {
  return fetchWrapper.get(`${baseUrl}/admin/qualification-list`);
}

function getAllSubjects() {
  return fetchWrapper.get(`${baseUrl}/syllabus/unique-subjects-dropdown`);
}

function getCountryList() {
  return fetchWrapper.get(`${baseUrl}/admin/countries-list`);
}

function getAllCountryList() {
  return fetchWrapper.get(`${baseUrl}/admin/all-countries-list`);
}

function getStateList(countryCode) {
  return fetchWrapper.get(
    `${baseUrl}/admin/state-list?country_code=${countryCode}`
  );
}

function getTimezoneList(countryCode) {
  return fetchWrapper.get(
    `${baseUrl}/admin/timezone-by-country-code?countryCode=${countryCode}`
  );
}

function deleteDocument(fileName) {
  return fetchWrapper.remove(
    `${baseUrl}/teachers/teacher-document/${fileName}`
  );
}

function forgotPasswordTeacher(email) {
  return fetchWrapper.post(`${baseUrl}/${email?.data?.userType}/verify-email`, {
    input: email?.data?.emailval,
  });
}

function createOrderPaypal(params) {
  return fetchWrapper.post(
    `${baseUrl}/payments/paypal/v1/create-order`,
    params
  );
}
function capturePaypal(params) {
  return fetchWrapper.post(
    `${baseUrl}/payments/paypal/capture-payment`,
    params
  );
}
function forgotPassword(params, userType) {
  return fetchWrapper.post(`${baseUrl}/${userType}/reset-password`, params);
}

function profileFileUpload(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/teachers/teacher-doc-upload`,
    params,
    token
  );
}
function profileFileDelete(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/teachers/delete-document`,
    params,
    token
  );
}
function getUpdatedStudentData(studentId, token) {
  return fetchWrapper.get(
    `${baseUrl}/students/get-user-data?userId=${studentId}&userType=student`,
    token
  );
}

function getAllExamTypes(token) {
  return fetchWrapper.get(`${baseUrl}/admin/all-exam-types`, token);
}

function addFirebaseToken(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/FCM/add-update-FCM-token`,
    params,
    token
  );
}

function renewSubscription(id, params, token) {
  return fetchWrapper.post(
    `${baseUrl}/subscription/renew-student-subscription/${id}`,
    params,
    { token }
  );
}

function getAllMonthsSlabs() {
  return fetchWrapper.get(`${baseUrl}/subscription/month-slabs`);
}

function getCurricularPricing(params) {
  return fetchWrapper.get(
    `${baseUrl}/subscription/get-curricular-pricing/${params.subjectId}/${params.months}/${params.countryCode}/${params.classPerWeek}`
  );
}

function getCoCurricularPricing(params) {
  return fetchWrapper.get(
    `${baseUrl}/subscription/get-cocurricular-pricing/${params.subjectId}/${params.months}/${params.countryCode}/${params.classPerWeek}`
  );
}

function getOlympiadPricing(params) {
  return fetchWrapper.get(
    `${baseUrl}/subscription/get-olympiad-pricing/${params.subjectId}/${params.months}/${params.countryCode}`
  );
}

function createSubscriptionOrder(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/payments/razorpay/create-order`,
    params,
    { token }
  );
}

function getSubscriptionOrder(id,token) {
  console.log(id);
  return fetchWrapper.get(
    `${baseUrl}/payments/razorpay/get/orderDetails?linkId=${id}`,{token}
  );
}

function applyDiscountRenew(params, data, token) {
  return fetchWrapper.post(
    `${baseUrl}/subscription/apply-coupon/${params.parentID}/${params.studentID}`,
    data,
    { token }
  );
}

function addMoreSubscription(id, params, token) {
  return fetchWrapper.post(
    `${baseUrl}/subscription/add-student-subscription/${id}`,
    params,
    { token }
  );
}

function getParentStudentSubscription(params) {
  return fetchWrapper.get(
    `${baseUrl}/subscription/get-student-subscription/${params?.parent}/${params.student}`
  );
}



// function getParentStudentSubscription(params) {
//   return fetchWrapper.get(
//     `${baseUrl}/subscription/get-student-subscription//${params?.parent}/${params.student}`
//   );
// }

function deleteStudent(params) {
  return fetchWrapper.remove(
    `${baseUrl}/subscription/remove-student-subscription/${params.studentId}`
  );
}

function checkEmailVerified(params) {
  return fetchWrapper.get(
    `${baseUrl}/parent/check-email-is-verified/${params?.parentId}`
  );
}

function getAllClassByCountry(params) {
  if (params?.countryId && params?.subject) {
    return fetchWrapper.get(
      `${baseUrl}/class/grades-by-subject-and-country/${params?.countryId}/${params?.subject}`
    );
  } else {
    console.log(
      'ERROR : CANT FIND COUNTRY OR SUBJECT IN SERVICE getAllClassByCountry '
    );
  }
}

function getLocationByIp() {
  return fetchWrapper.get('https://ipapi.co/json/');
}
function getPoints(params, token) {
  return fetchWrapper.get(
    `${baseUrl}/brainGym/my-briefcase?student=${params.student}&&page_no=${params.page_no}`,
    token
  );
}

function getOpenPracticeCountryList() {
  return fetchWrapper.get(`${baseUrl}/admin/open-practice-countries-list`);
}

function getOrderDetails(orderId, token) {
  return fetchWrapper.get(
    `${baseUrl}/payments/order-details/${orderId}`,
    token
  );
}

function getOrderDetailsDownloadLink(invoiceId, token) {
  return fetchWrapper.get(
    `${baseUrl}/payments/zoho/invoice-details/${invoiceId}`,
    token
  );
}

export const authServices = {
  login,
  register,
  coCurricularActivities,
  sendOtp,
  verifyOtp,
  getAllGrades,
  getUniqueSubjects,
  getSubjectsByclass,
  registerTeacher,
  getTimeSlots,
  getUniversities,
  getQualifications,
  getCountryList,
  getStateList,
  getTimezoneList,
  getAllSubjects,
  deleteDocument,
  sendOtpTeacher,
  verifyEmailTeacher,
  verifyEmail,
  verifyOtpTeacher,
  forgotPasswordTeacher,
  sendOtpParent,
  verifyOtpParent,
  registerParent,
  addGuardian,
  createOrderPaypal,
  capturePaypal,
  studentMyprofile,
  studentChangePass,
  studentProfilePic,
  parentProfile,
  parentChangePass,
  parentProfilePic,
  forgotPassword,
  profileFileUpload,
  profileFileDelete,
  getUpdatedStudentData,
  getAllExamTypes,
  addFirebaseToken,
  renewSubscription,
  getAllMonthsSlabs,
  getCurricularPricing,
  getCoCurricularPricing,
  getOlympiadPricing,
  createSubscriptionOrder,
  getSubscriptionOrder,
  deleteStudent,
  getAllBoards,
  getParentStudentSubscription,
  addMoreSubscription,
  applyDiscountRenew,
  checkEmailVerified,
  getAllClassByCountry,
  uploadTeacher,
  sendOtpBookDemo,
  verifyOtpBookDemo,
  submitBookDemo,
  teacherEmailVerifiedOrnot,
  getAllCountryList,
  getLocationByIp,
  getPoints,
  getOpenPracticeCountryList,
  getOrderDetails,
  getOrderDetailsDownloadLink,
  // forgotUserPasswordTeacher
};

export default {};
