import * as actionTypes from './MyOlympiadExamTypes';

const initialState = {
  upcomingExam: {},
  previousExam: {},
  startExam: [],
  questionInExam: [],
  myReport: {},
  allQuesAns: [],
  finishExam: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_OLYMPIAD_UPCOMING_EXAM:
      return {
        ...state,
        upcomingExam: action.payload,
      };
    case actionTypes.UPDATE_PREVIOUS_OLYMPIAD_EXAM:
      return {
        ...state,
        previousExam: action.payload,
      };
    case actionTypes.UPDATE_MY_OLYMPIAD_REPORT:
      return {
        ...state,
        myReport: action.payload,
      };
    case actionTypes.UPDATE_START_OLYMPIAD_EXAM:
      return {
        ...state,
        startExam: action.payload,
      };
    case actionTypes.UPDATE_FINISH_OLYMPIAD_EXAM:
      return {
        ...state,
        finishExam: action.payload,
      };

    case actionTypes.UPDATE_GET_QUESTION_IN_OLYMPIAD_EXAM:
      return {
        ...state,
        questionInExam: action.payload,
      };
    case actionTypes.UPDATE_ALL_OLYMPIAD_QUESTION_ANSWER:
      return {
        ...state,
        allQuesAns: action.payload,
      };
    case actionTypes.ADD_ALL_OLYMPIAD_QUESTION_ANSWER:
      return {
        ...state,
        allQuesAns: [...state.allQuesAns, action.payload],
      };

    default:
      return { ...state };
  }
}
