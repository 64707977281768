import React, { useState } from 'react';
import { func, object, string, number, bool } from 'prop-types';
import ExamSidebar from './ExamSidebar';
import ExamRightsidepart from './ExamRightsidepart';

const ExamBody = ({
  questionInExamData,
  setEachTimeOn,
  eachcurrenttime,
  setEachtime,
  selectedQuestion,
  setSelectedQuestion,
  allQuesAns,
  updateQueAns,
  addQueAns,
  isOlympiad,
}) => {
  const [selectedOption, setSelectedOption] = useState([]);
  return (
    <div className='row olympiad-bottom'>
      <div className='col-md-3 col-12'>
        <ExamSidebar
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          questionInExamData={questionInExamData}
          allQuesAns={allQuesAns}
          isOlympiad={isOlympiad}
        />
      </div>
      <div className='col-md-9 col-12'>
        <ExamRightsidepart
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          questionInExamData={questionInExamData}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setEachTimeOn={setEachTimeOn}
          eachcurrenttime={eachcurrenttime}
          setEachtime={setEachtime}
          allQuesAns={allQuesAns}
          addQueAns={addQueAns}
          updateQueAns={updateQueAns}
        />
      </div>
    </div>
  );
};
ExamBody.propTypes = {
  questionInExamData: object.isRequired,
  setEachTimeOn: func.isRequired,
  eachcurrenttime: string.isRequired,
  setEachtime: func.isRequired,
  selectedQuestion: number.isRequired,
  setSelectedQuestion: func.isRequired,
  allQuesAns: object.isRequired,
  updateQueAns: func.isRequired,
  addQueAns: func.isRequired,
  isOlympiad: bool,
};

export default ExamBody;
