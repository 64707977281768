import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { func, bool, string, array, object } from 'prop-types';
import { Modal } from 'react-bootstrap';
import './calendar.scss';
import Spinner from 'react-bootstrap/Spinner';
import { cancelLeaveAction } from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import { chevRight } from '../../../../../assets/icons/IconList';

const LeaveConfirmPopup = ({
  model,
  handleModel,
  fromDate,
  toDate,
  cancelLeave,
  selectedLeave,
  cancelLeaveResponse,
  Login,
  myLeavesList1,
  totalAppliedLeave,
}) => {
  const [cancelLoading, setCancelLoading] = useState(false);

  useEffect(() => {
    if (cancelLeaveResponse.status === 'success') {
      setCancelLoading(false);
    }
  }, [cancelLeaveResponse]);
  const token = Login?.data?.accessToken;
  const changeLeave = () => {
    const data = {
      id: selectedLeave,
      token,
    };
    cancelLeave(data);
    setCancelLoading(true);

    const data2 = {
      status: '',
      pageNumber: 1,
      limit: 10,
    };
    myLeavesList1(data2);
  };

  const hideModel = () => {
    cancelLeave('id');
    handleModel(false);
  };

  const continueList = () => {
    cancelLeave('id');
    handleModel(false);
  };

  return (
    <Modal show={model} onHide={hideModel} className='confirm-leave-popup'>
      {!cancelLeaveResponse?.status && <Modal.Header closeButton />}
      <Modal.Body>
        {!cancelLeaveResponse?.status ? (
          <div>
            <h3>Do you want to cancel this leave?</h3>
            {totalAppliedLeave === 1 ? (
              <p>
                Leave Date: <span>{fromDate}</span>
              </p>
            ) : (
              <div>
                <p style={{ fontSize: 20 }}>From: {fromDate}</p>
                <h4>To: {toDate}</h4>
              </div>
            )}
            <p>
              Total Days: <span>{totalAppliedLeave}</span>
            </p>
          </div>
        ) : (
          <div className='leave-cancel'>
            <h3>
              Leave cancelled for {totalAppliedLeave}{' '}
              {totalAppliedLeave === 1 ? 'day' : 'days!'}
            </h3>
            {totalAppliedLeave === 1 ? (
              <p style={{ fontSize: 20 }}>Date : {fromDate}</p>
            ) : (
              <p>
                From {fromDate} to {toDate}
              </p>
            )}
            <button
              type='button'
              className='button-common'
              onClick={continueList}
            >
              Continue {/* <span>{chevRight}</span> */}
            </button>
          </div>
        )}
      </Modal.Body>
      {!cancelLeaveResponse?.status && (
        <Modal.Footer>
          <button
            type='button'
            className='button-common red'
            onClick={changeLeave}
          >
            {cancelLoading ? (
              <Spinner animation='border' variant='light' />
            ) : (
              'Yes'
            )}
            {/* <span>{chevRight}</span> */}
          </button>
          <button
            type='button'
            className='button-common'
            onClick={() => handleModel(false)}
          >
            No {/* <span>{chevRight}</span> */}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

LeaveConfirmPopup.propTypes = {
  model: bool.isRequired,
  handleModel: func.isRequired,
  fromDate: string.isRequired,
  toDate: string.isRequired,
  cancelLeave: func.isRequired,
  selectedLeave: string.isRequired,
  cancelLeaveResponse: array.isRequired,
  Login: object.isRequired,
  myLeavesList1: func.isRequired,
  totalAppliedLeave: string.isRequired,
};

const mapStateToProps = (state) => ({
  cancelLeaveResponse: state.TeacherDashboard.cancelLeave,
  Login: state.Auth.Login,
});

const mapDispatchToProps = (dispatch) => ({
  cancelLeave: (data) => dispatch(cancelLeaveAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveConfirmPopup);
