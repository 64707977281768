import React from 'react';
import { func, object, string } from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import profile from '../../../../../../assets/images/sidebar-profile.png';
import displaygreen from '../../../../../../assets/images/displaygreen.png';
import defaultPic from '../../../../../../assets/images/defaultPic.png';
import { addIcon } from '../../../../../../assets/icons/IconList';
import noteblue from '../../../../../../assets/images/noteblue.png';
import { useHistory } from 'react-router';

const arrow = '<';

const StudentDetailHead = ({
  setStudents,
  studentIndData,
  studPerformance,
  gradeName,
}) => {
  const history = useHistory();

  return (
    <div>
      <button
        type='button'
        className='back-btn'
        onClick={() => {
          setStudents('home');
          history.push('/teacher/students');
        }}
      >
        <span>{arrow}</span> Go back
      </button>
      <div className='head'>
        <div className='row'>
          <div className='col-md-4 head-left'>
            <div className='row'>
              <div className='col-md-3 col-sm-3 col-12'>
                <img
                  src={
                    studentIndData?.profilePic
                      ? studentIndData?.profilePic
                      : defaultPic
                  }
                  alt='profile'
                  width={100}
                  height={100}
                  style={{
                    borderRadius: '50%',
                    height: '90px',
                    width: '90px',
                    objectFit: 'fill',
                  }}
                />
              </div>
              <div className='col-md-9 col-sm-9 col-12'>
                <h4>{studentIndData?.studentName}</h4>
                <p>Grade {gradeName}</p>
                <div className='row'>
                  <div className='col-md-9 col-sm-9 text-left'>
                    Syllabus completion
                  </div>
                  <div className='col-md-3 col-sm-3 text-right'>
                    {studentIndData.subConceptsCount !== 0
                      ? Math.round(
                          (studentIndData.completedSubConceptsCount /
                            studentIndData.subConceptsCount) *
                            100
                        )
                      : 0}{' '}
                    %
                  </div>
                </div>
                <ProgressBar
                  now={
                    studentIndData.subConceptsCount !== 0
                      ? Math.round(
                          (studentIndData.completedSubConceptsCount /
                            studentIndData.subConceptsCount) *
                            100
                        )
                      : 0
                  }
                />
              </div>
            </div>
          </div>
          <div className='col-md-8 head-right'>
            <div className='row'>
              <div className='col-md-4 col-sm-4 orange d-flex'>
                {addIcon}
                <div className='text ms-2'>
                  <h4>
                    {studPerformance?.pendingAssignmentCount
                      ? studPerformance?.pendingAssignmentCount
                      : 0}
                  </h4>
                  <p>Assignments pending</p>
                </div>
              </div>

              {studPerformance?.recentExamInfo !== null && (
                <div className=' col-sm-4 green d-flex'>
                  <img
                    style={{ height: '60px', width: '60px' }}
                    src={noteblue}
                    alt='display'
                  />
                  <div className='text ms-2'>
                    <h4>
                      {studPerformance?.recentExamInfo?.marksObtained} /{' '}
                      {studPerformance?.recentExamInfo?.totalMarks}
                    </h4>
                    <p>Recent exam score</p>
                  </div>
                </div>
              )}
              <div className=' col-sm-4 blue d-flex'>
                {addIcon}
                <div className='text ms-2'>
                  <h4>
                    {studPerformance?.remainingClassCount
                      ? studPerformance?.remainingClassCount
                      : 0}{' '}
                    /{' '}
                    {studPerformance?.totalOnlineClassCount
                      ? studPerformance?.totalOnlineClassCount
                      : 0}
                  </h4>
                  <p>Classes pending</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StudentDetailHead.propTypes = {
  setStudents: func.isRequired,
  studentIndData: object.isRequired,
  studPerformance: object.isRequired,
  gradeName: string.isRequired,
};

export default StudentDetailHead;
