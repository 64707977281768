import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import {
  finishAssignementExamAction,
  updateAssignementAllQueAnsAction,
  getQuestionInExamAction,
  myAssignementReportAction,
  addAssignementAllQueAnsAction,
  clearMyAssignementReportAction,
  startExamAction,
  clearAssignementExamAction,
} from '../../../../../stores/MyAssignments/MyAssignmentExamAction';
import FinishExamPopup from './FinishExamPopup';
import CompletedPopup from './CompletedPopup';
import { findKeyByValue } from '../BrainGym/QueAnswer/questionHelper';
import ExamSection from '../components/Exam/ExamSection';
import Spinner from 'react-bootstrap/Spinner';
import IntelliScorePopUp from './IntelliScorePopUp';

const MyOlympiadExamSection = ({
  startexam,
  questionInexam,
  questionInExamData,
  finishexam,
  finishexamData,
  startExamData,
  updateQueAns,
  allQuesAns,
  myReport,
  addQueAns,
  myReportData,
  clearMyReport,
  clearMyExam,
}) => {
  var url_string = window.location.href;
  var url = new URL(url_string);
  var attempted = url.searchParams.get('attempted');
  const [eachtime, setEachtime] = useState(0);
  const [eachtimeOn, setEachTimeOn] = useState(true);
  const [eachcurrenttime, setEachcurrenttime] = useState();
  const [time, setTime] = useState();
  const [totaltime, setTotalTime] = useState();
  const [examName, setExamName] = useState('');
  const [currenttime, setCurrenttime] = useState();
  const [finishTest, setFinishTest] = useState('step1');
  const [modal, setModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [completedMessage, setCompletedMessage] = useState('');
  const [isIntelliLoading, setisIntelliLoading] = useState(false);

  useEffect(() => {
    let interval = null;
    if (questionInExamData?.length > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1000);
      }, 1000);
      const setQueAnsData = questionInExamData?.map((data) => {
        let que = {};
        if (findKeyByValue(data?.solutionType) !== 'passage') {
          if (findKeyByValue(data?.solutionType) == 'fill-in-the-blanks') {
            que = {
              question: data?._id,
              fillInTheBlankSolution: [],
              timeTakenInSecs: 0,
              Bookmarked: false,
            };
          } else if (
            findKeyByValue(data?.solutionType) ===
            'drag-and-match-the-correct-answer'
          ) {
            que = {
              question: data?._id,
              solutionIndex: [-1],
              dragTheTextSolution: [-1],
              timeTakenInSecs: 0,
              Bookmarked: false,
            };
          } else {
            que = {
              question: data?._id,
              solutionIndex: [-1],
              timeTakenInSecs: 0,
              Bookmarked: false,
            };
          }
        } else {
          que = {
            question: data?._id,
            passageQuestions: data?.passageQuestions?.map((item) => {
              const items = {
                question: item?._id,
                solutionIndex: [-1],
              };
              return items;
            }),
            timeTakenInSecs: 0,
            Bookmarked: false,
          };
        }

        return que;
      });
      updateQueAns(setQueAnsData);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [questionInExamData]);

  useEffect(() => {
    setCurrenttime(
      `${`0${Math.floor((time / 60000) % 60)}`.slice(-2)}:${`0${Math.floor(
        (time / 1000) % 60
      )}`.slice(-2)}`
    );
  }, [time]);

  useEffect(() => {
    let intervalEach = null;
    setEachTimeOn(true);
    if (questionInExamData?.length > 0 && eachtimeOn) {
      intervalEach = setInterval(() => {
        setEachtime((prevTimeEach) => prevTimeEach + 1000);
      }, 1000);
    } else {
      clearInterval(intervalEach);
    }
    return () => clearInterval(intervalEach);
  }, [eachtimeOn, questionInExamData]);

  useEffect(() => {
    setEachcurrenttime(eachtime / 1000);
  });

  useEffect(() => {
    startexam({
      assignment: params.id,
      trial: attempted,
    });

    questionInexam({
      assignment: params.id,
    });
    clearMyExam();
    clearMyReport();
  }, [params.id]);

  useEffect(() => {
    if (startExamData.statusCode === 200) {
      setCompleted(false);
    } else if (startExamData.statusCode === 404) {
      setCompletedMessage(startExamData.message);
      setCompleted(true);
    }
  }, [startExamData]);

  useEffect(() => {
    if (startExamData?.data && questionInExamData.length) {
      setIsLoading(false);
    } else if (completed === true) {
      setIsLoading(false);
    }
  }, [startExamData, questionInExamData]);

  useEffect(() => {
    if (startExamData?.data) {
      const TotalTime = startExamData?.data?.durationInMins;
      setTime(TotalTime * 60 * 1000);
      setTotalTime(TotalTime * 60 * 1000);
      setExamName(startExamData?.data?.examName);
    }
  }, [startExamData]);
  useEffect(() => {
    if (finishTest === 'step5' && finishexamData?.statusCode == 200) {
      myReport({
        assignment: params?.id,
      });
    }
  }, [finishTest, finishexamData]);
  useEffect(() => {
    if (
      finishTest === 'step3' ||
      (time === 0 && finishTest === 'step1') ||
      (time === 0 && finishTest === 'step3')
    ) {
      setFinishTest('step4');
      finishexam({
        assignment: startExamData?.data?._id,
        timeTakenInSecs: (totaltime - time) / 1000,
        results: allQuesAns,
      });
    }
  }, [finishTest, time]);

  return (
    <div>
      {finishTest !== 'step3' && finishTest !== 'step4' ? (
        isLoading ? (
          <Spinner
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
            }}
            animation='border'
            role='status'
            variant='warning'
          />
        ) : (
          <div>
            {completed === true ? (
              <CompletedPopup completedMessage={completedMessage} />
            ) : (
              <div>
                {questionInExamData?.length ? (
                  <ExamSection
                    questionInExamData={questionInExamData}
                    time={time}
                    setTime={setTime}
                    currenttime={currenttime}
                    setCurrenttime={setCurrenttime}
                    eachtime={eachtime}
                    setEachtime={setEachtime}
                    eachtimeOn={eachtimeOn}
                    setEachTimeOn={setEachTimeOn}
                    eachcurrenttime={eachcurrenttime}
                    setEachcurrenttime={setEachcurrenttime}
                    finishTest={finishTest}
                    setFinishTest={setFinishTest}
                    setModal={setModal}
                    modal={modal}
                    examName={examName}
                    selectedQuestion={selectedQuestion}
                    setSelectedQuestion={setSelectedQuestion}
                    allQuesAns={allQuesAns}
                    addQueAns={addQueAns}
                    updateQueAns={updateQueAns}
                  />
                ) : null}{' '}
              </div>
            )}
          </div>
        )
      ) : !isIntelliLoading ? (
        <Spinner
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
          }}
          animation='border'
          role='status'
          variant='warning'
        />
      ) : (
        ''
      )}
      {finishTest === 'step2' && (
        <FinishExamPopup
          setFinishTest={setFinishTest}
          setModal={setModal}
          modal={modal}
          allQuesAns={allQuesAns}
          setSelectedQuestion={setSelectedQuestion}
        />
      )}
      {finishTest === 'step4' && finishexamData?.statusCode == 200 ? (
        <IntelliScorePopUp
          setFinishTest={setFinishTest}
          startReport={params?.id}
          startExamData={startExamData}
          myReportData={myReportData}
          myReport={myReport}
          clearMyReport={clearMyReport}
          setLoading={setisIntelliLoading}
          isLoading={isIntelliLoading}
        />
      ) : (
        ''
      )}
    </div>
  );
};

MyOlympiadExamSection.propTypes = {
  startexam: func.isRequired,
  studentId: string.isRequired,
  questionInexam: func.isRequired,
  updateQueAns: func.isRequired,
  questionInExamData: object.isRequired,
  startExamData: object.isRequired,
  finishexamData: object.isRequired,
  allQuesAns: object.isRequired,
  myReport: func.isRequired,
  addQueAns: func.isRequired,
  myReportData: object.isRequired,
  clearMyReport: func.isRequired,
};

const mapStateToProps = (state) => ({
  studentId: state.Auth.Login?.data?._id,
  questionInExamData: state.studentAssignment.questionInExam,
  startExamData: state.studentAssignment.startExam,
  allQuesAns: state.studentAssignment.allQuesAns,
  finishexamData: state.studentAssignment.finishExam,
  myReportData: state.studentAssignment.myReport?.data,
});
const mapDispatchToProps = (dispatch) => ({
  startexam: (data) => dispatch(startExamAction(data)),
  questionInexam: (data) => dispatch(getQuestionInExamAction(data)),
  finishexam: (data) => dispatch(finishAssignementExamAction(data)),
  updateQueAns: (data) => dispatch(updateAssignementAllQueAnsAction(data)),
  myReport: (data) => dispatch(myAssignementReportAction(data)),
  addQueAns: (data) => dispatch(addAssignementAllQueAnsAction(data)),
  clearMyReport: (data) => dispatch(clearMyAssignementReportAction(data)),
  clearMyExam: (data) => dispatch(clearAssignementExamAction(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyOlympiadExamSection);
