import React from 'react';
// import Logo from '../../../assets/images/Logo.png';
import Logo from '../../../assets/tempimages/Logo.svg';
import linkedInimg from '../../../assets/tempimages/linkedenicon.svg';
import imstaicon from '../../../assets/tempimages/instagram@1.5x.svg';
import twitterimg from '../../../assets/tempimages/twitter (1)@1.5x.svg';
import facebookimg from '../../../assets/tempimages/facebook (1)@1.5x.svg';
import appStore from '../../../assets/images/appstore.png';
import googlePlay from '../../../assets/images/googplePlay.png';
import './footer.scss';
import {
  twitterIcon,
  faceBook,
  instaGram,
  linkedIn,
} from '../../../assets/icons/IconList';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import moment from 'moment';

function Footer({ executeScroll, executeScrollQestion }) {
  const date = new Date();
  return (
    <>
      <div className='stickyFooterMobile'>
        <div className='stickyFooterInside'>
          <a href='https://apps.apple.com/us/app/tautmore/id1612243202'>
            <img src={appStore} alt='app store' />
          </a>

          <a href='https://play.google.com/store/apps/details?id=com.tautmore'>
            <img src={googlePlay} alt='google play' />
          </a>
        </div>
      </div>
      <div className='footer-main'>
        {window.location.pathname === '/termsandcondition' ? (
          ' '
        ) : (
          <div className=' custom-container m-auto'>
            <div className='footer-top'>
              <div className='row'>
                <div className='col-sm-12 col-md-5 footer-top-left'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-9'>
                      <Link to='/'>
                        <img src={Logo} alt='website_logo' />
                      </Link>
                      <p>
                        TautMore is the first-ever truly global k-12 EdTech
                        organization, built for your kid’s wholesome
                        development. TautMore exploits Artificial Intelligence,
                        Machine Learning, Virtual Reality, and Learner
                        Psychology to deliver genuinely joyful, and personalized
                        learning experiences for your kids.
                      </p>
                      <ul>
                        <li>
                          <a
                            href='https://twitter.com/tautmoreapp'
                            target='_blank'
                          >
                            <img src={twitterimg} alt='' />

                            {/* {twitterIcon} */}
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://www.facebook.com/tautmoreapp'
                            target='_blank'
                          >
                            <img src={facebookimg} alt='' />

                            {/* {faceBook} */}
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://www.instagram.com/tautmore.learning/'
                            target='_blank'
                          >
                            <img src={imstaicon} alt='' />

                            {/* {instaGram} */}
                          </a>
                        </li>
                        <li>
                          <a
                            href='https://www.linkedin.com/company/tautmore/'
                            target='_blank'
                          >
                            <img src={linkedInimg} alt='' />
                            {/* {linkedIn} */}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* <div className='col-sm-12 col-md-4' /> */}
                  </div>
                </div>

                <div className='col-sm-12 col-md-7 footer-top-right'>
                  <div className='row'>
                    <div className='col-6 col-md-9'>
                      <h4>Grades</h4>
                      <div className='row'>
                        <div className='col-sm-4 col-md-4 col-12 '>
                          <ul>
                            <li>Kindergarten</li>
                            <li>L K G</li>
                            <li>U K G</li>
                            <li>Grade 1</li>
                            <li>Grade 2</li>
                          </ul>
                        </div>
                        <div className='col-sm-4 col-md-4 col-12 '>
                          <ul>
                            <li>Grade 3</li>
                            <li>Grade 4</li>
                            <li>Grade 5</li>
                            <li>Grade 6</li>
                            <li>Grade 7</li>
                          </ul>
                        </div>
                        <div className='col-sm-4 col-md-4 col-12 '>
                          <ul>
                            <li>Grade 8</li>
                            <li>Grade 9</li>
                            <li>Grade 10</li>
                            <li>Grade 11</li>
                            <li>Grade 12</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='col-6 col-md-3'>
                      <h4>Company</h4>
                      <ul>
                        <li>
                          <Link className='footer_style' to={'/aboutus'}>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link className='footer_style' to={'/contactus'}>
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link className='footer_style' to={'/faqs'}>
                            FAQs
                          </Link>
                        </li>
                        <li>
                          <Link className='footer_style' to={'/blogs'}>
                            Blogs
                          </Link>
                        </li>
                        <li>
                          <Link
                            className='footer_style'
                            // onClick={executeScroll}
                            to={'/parentaboutus'}
                          >
                            <span
                            // className='size_changes'
                            >
                              For
                            </span>{' '}
                            Parents
                          </Link>
                        </li>
                        <li>
                          <Link className='footer_style' to={'/teacherAboutUs'}>
                            <span className='size_changes'>For</span> Teachers
                          </Link>
                        </li>
                        <li>
                          <Link className='footer_style' to={'/schools'}>
                            <span className='size_changes'>For</span> Schools
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className='row'>
                    <div className='col-sm-3 col-md-3 col-6 '>
                      <h4>Grades</h4>
                      <ul>
                        <li>Kindergarten</li>
                        <li>L K G</li>
                        <li>U K G</li>
                        <li>Grade 1</li>
                        <li>Grade 2</li>
                      </ul>
                    </div>
                    <div className='col-sm-3 col-md-3 col-6 '>
                      <h4 style={{ visibility: 'hidden' }}>u</h4>
                      <ul>
                        <li>Grade 3</li>
                        <li>Grade 4</li>
                        <li>Grade 5</li>
                        <li>Grade 6</li>
                        <li>Grade 7</li>
                      </ul>
                    </div>
                    <div className='col-sm-3 col-md-3 col-6 '>
                      <h4 style={{ visibility: 'hidden' }}>h</h4>
                      <ul>
                        <li>Grade 8</li>
                        <li>Grade 9</li>
                        <li>Grade 10</li>
                        <li>Grade 11</li>
                        <li>Grade 12</li>
                      </ul>
                    </div>
                    <div className='col-sm-3 col-md-3 col-6'>
                      <h4>Company</h4>
                      <ul>
                        <li>
                          <Link className='footer_style' to={'/aboutus'}>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link className='footer_style' to={'/contactus'}>
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link className='footer_style' to={'/faqs'}>
                            FAQs
                          </Link>
                        </li>
                        <li>
                          <Link
                            className='footer_style'
                            // onClick={executeScroll}
                            to={'/parentaboutus'}
                          >
                            <span
                            // className='size_changes'
                            >
                              For
                            </span>{' '}
                            Parents
                          </Link>
                        </li>
                        <li>
                          <Link className='footer_style' to={'/teacherAboutUs'}>
                            <span className='size_changes'>For</span> Teachers
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='footer-bottom'>
          <div className=' custom-container m-auto'>
            <div className='row'>
              <div className='col-sm-7 col-12 text-left'>
                <p>
                  © {moment(date).format('YYYY')} Ultimate Learning Corp. All
                  rights reserved.
                </p>
              </div>
              <div className='col-sm-5 col-12 text-right'>
                <p>
                  <Link
                    className='fotter_text'
                    target='_blank'
                    to={'/refund-policy.html'}
                  >
                    Refund policy
                  </Link>{' '}
                  |{' '}
                  <Link
                    className='fotter_text'
                    target='_blank'
                    to={'/privacy-policy.html'}
                  >
                    Privacy policy
                  </Link>{' '}
                  |{' '}
                  <Link
                    className='fotter_text'
                    target='_blank'
                    to={'/termsandcondition.html'}
                  >
                    Terms & Condition
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
