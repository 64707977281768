import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import { errowRight } from '../../../assets/icons/IconList';
import SubjectData from './mockData/SubjectlistData';
import { connect } from 'react-redux';
// import DropdownCountry from '../Chapters/customdropdown/customDropdownStudent';
// import config from '../../../helpers/config';
// import {
//   getCountries,
//   getAllClassByCountry,
// } from '../../../stores/Auth/AuthAction';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
//import 'swiper/swiper.min.css';
import 'swiper/css';
//import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/css/free-mode';
import { Spinner } from 'react-bootstrap';

import Select from 'react-select';
import { Dropdown } from 'react-bootstrap';
import { da } from 'date-fns/locale';
const Subjectlist = ({
  subdata,
  setSelectedLink,
  gradeValue,
  isChapterPage,
  getAllCountriesAction,
  countries,
  getAllClassByCountry,
  setSelectedGrade,
  selectedGrade,
  handleClick,
  colors,
  selectedColor,
  handleliColours,
  setLiColours,
  liColours,
  licolors,
  setSelectedColor,
  // uiInfo,
  // setUiInfo,
  // testHome,
}) => {
  const [uiChange, setUiChange] = useState();
  const [selectedSubject, setSelectedSubjected] = useState('');
  const [selectedImg, setSelectedImg] = useState();
  const [subjects, setSubjects] = useState(
    SubjectData.map((allSub) => {
      return { value: allSub.title, label: allSub.title };
    })
  );

  const [grade, setGrade] = useState();
  const divClass = (data) =>
    subdata === data.title ? 'active sub-list-each' : 'sub-list-each';
  const link = (data) => `/chapters/${data.title}`;

  const changeSubject = (data) => {
    setSelectedSubjected(data);
  };

  const handleUIChange = (data) => {
    setUiChange(true);
    setSelectedSubjected(data);
    // setUiInfo(true);
  };

  const handleOnClick = () => {
    setUiChange(true);
  };

  const changeClass = (data, i) => {
    setSelectedLink({
      num: i,
      class: data.value,
      label: data.label,
    });
    setSelectedGrade(data.label);
  };

  useEffect(() => {
    if (isChapterPage) {
      setUiChange(true);
    }
  }, []);

  useEffect(() => {
    if (subdata) {
      SubjectData.map((eachSub) => {
        if (subdata === eachSub.title) {
          setSelectedSubjected(eachSub);
          setSelectedColor(colors[0]);
        }
      });
    }
  }, [subdata]);

  return (
    <div>
      <div className={`sub-list-main ${subdata && 'mainactive'} `}>
        {SubjectData?.map((data) => (
          <div className={`${divClass(data)}`} key={data.key}>
            <Link to={link(data)} className=''>
              <img src={data.image} alt='webbsite_log' />
              <h5>{data.title}</h5>
            </Link>
          </div>
        ))}
      </div>
      {!uiChange ? (
        <div className=' '>
          <Swiper
            freeMode={true}
            grabCursor={true}
            modules={[FreeMode]}
            className='swiper-container'
            slidesPerView={3}
            spaceBetween={10}
            onSlideChange={() => console.log('slide change')}

            // onSwiper={() => setUiChange(true)}
            // on={() => setUiChange(true)}
          >
            {SubjectData?.map((data, i) => (
              <SwiperSlide key={data.key} onClick={() => handleUIChange(data)}>
                <Link to={link(data)} className='d-flex' key={data.key}>
                  <img src={data.image} alt='webbsite_log' />
                  <h5>{data.title}</h5>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className='w-100 col-sm-12  sub-grade-select  p-2 g-2'>
          {/* <Select
                className='subconcept-select'
                classNamePrefix='select'
                value={subjects.value}
                // onChange={changeDays}
                options={subjects}
                placeholder='subject'
              /> */}
          <div className=' col-lg-6 drop-left '>
            <Dropdown className=''>
              <Dropdown.Toggle>
                {' '}
                <img src={selectedSubject?.image} />
                {selectedSubject?.title}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {SubjectData?.map((data, i) => (
                  <Dropdown.Item
                    key={data.key}
                    onClick={() => changeSubject(data)}
                  >
                    <Link to={link(data)} className='d-flex' key={i}>
                      <img src={data.image} alt='webbsite_log' />
                      {data.title}
                    </Link>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className=' col-lg-6  drop_right'>
            <Dropdown className=''>
              <Dropdown.Toggle
                style={{ color: selectedColor, borderColor: selectedColor }}
              >
                {gradeValue?.length === 0 ? (
                  'No Class Found'
                ) : selectedGrade ? (
                  'Class ' + selectedGrade
                ) : (
                  <Spinner animation='border' variant='dark' />
                )}{' '}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {gradeValue?.length === 0
                  ? 'No Class Found'
                  : gradeValue?.length &&
                    gradeValue?.map((data, i) => (
                      <Dropdown.Item
                        key={i}
                        style={{ color: colors[i % colors.length] }}
                        onClick={() => {
                          changeClass(data, i),
                            handleClick(colors[i % colors?.length]);
                          handleliColours(licolors[i % licolors.length]);
                        }}
                      >
                        {data.label}
                      </Dropdown.Item>
                    ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
};
Subjectlist.propTypes = {
  subdata: string,
};

Subjectlist.defaultProps = {
  subdata: '',
};

export default Subjectlist;
