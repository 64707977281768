export const UPCOMING_EXAM = 'UPCOMING_EXAM';
export const UPDATE_UPCOMING_EXAM = 'UPDATE_UPCOMING_EXAM';
export const PREVIOUS_EXAM = 'PREVIOUS_EXAM';
export const UNATTEEMPTED_EXAM = 'UNATTEEMPTED_EXAM';
export const UPDATE_PREVIOUS_EXAM = 'UPDATE_PREVIOUS_EXAM';
export const START_EXAM = 'START_EXAM';
export const UPDATE_START_EXAM = 'UPDATE_START_EXAM';
export const FINISH_EXAM = 'FINISH_EXAM';
export const UPDATE_FINISH_EXAM = 'UPDATE_FINISH_EXAM';

export const GET_QUESTION_IN_EXAM = 'GET_QUESTION_IN_EXAM';
export const UPDATE_GET_QUESTION_IN_EXAM = 'UPDATE_GET_QUESTION_IN_EXAM';
export const GET_MY_REPORT = 'GET_MY_REPORT';
export const UPDATE_MY_REPORT = 'UPDATE_MY_REPORT';
export const MY_REPORT_ANALYTICS = 'MY_REPORT_ANALYTICS';
export const UPDATE_MY_REPORT_ANALYTICS = 'UPDATE_MY_REPORT_ANALYTICS';
export const ALL_QUESTION_ANSWER = 'ALL_QUESTION_ANSWER';
export const UPDATE_ALL_QUESTION_ANSWER = 'UPDATE_ALL_QUESTION_ANSWER';
export const ADD_ALL_QUESTION_ANSWER = 'ADD_ALL_QUESTION_ANSWER';
