import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  getQuestionsInTest,
  takePracticeTest,
  submitAnswerPrac,
  completeTestNotificationTrigg,
} from '../../../../../services/studentMySubjects.service';
import { connect } from 'react-redux';
import PracticeQueanspart from './PracticeQueanspart';
import PracticeAttemptAnswers from './PracticeAttemptAnswers';
import { findKeyByValue } from '../BrainGym/QueAnswer/questionHelper';
import TestHeader from './TestHeader';
import { chevRightIcon } from './icons';
import { chevRight } from '../../../../../assets/icons/IconList';
import CongratsPopup from './CongratsPopUp';
import FinishedPopup from './FinishedPopUp';
import { WaitingLoader } from '../../../waitingLoader';

const localData = JSON.parse(localStorage.getItem('taut-student'));
const PracticeTestQuestions = ({
  userType,
  parentData,
  selectedStudent,
  token,
}) => {
  let history = useHistory();
  const { subjectp, chapterp, conceptp, subConceptp } = useParams();

  const [questionsInfo, setQuestionsInfo] = useState();
  const [practiceId, setPracticeId] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [statusVal, setstatus] = useState([]);
  const [stat, setStat] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [isSubmitValid, setIsSubmitValid] = useState(false);
  const [topBar, setTopBar] = useState(false);
  const [takeTestData, setTakeTestData] = useState('');
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [timeTakenToAnswer, setTimeTakenToAnswer] = useState(0);
  const [submitCliked, setSubmitCliked] = useState(false);

  useEffect(() => {
    if (questionsInfo?._id) {
      const timer = setInterval(() => {
        setTimeTakenToAnswer(timeTakenToAnswer + 1);
      }, 1000);
      return () => clearInterval(timer); //This is important
    }
  });

  const getToken = () => {
    // if(userType){
    //   if(userType==="parent"){
    //   }
    // }
    // return selectedStudent?.accessToken;
  };

  const getPracticeQuestions = async () => {
    const params = {
      practiceTestId: practiceId,
      student: selectedStudent._id,
    };
    try {
      const res = await getQuestionsInTest(params, token);
      setQuestionsInfo(res?.data?.data?.questionInfo);
      setTimeTakenToAnswer(0);
      setQuestionType(
        findKeyByValue(res?.data?.data?.questionInfo?.solutionType)
      );
      setTopBar(false);
    } catch (error) {
      console.log(error.response.status);
      if (error.response.status === 410) {
        setStat('FINISHED');
      }
    }
  };
  const attemptAnswer = async (qId, qtype) => {
    setSubmitCliked(true);
    try {
      if (qtype === 'passage') {
        const resultData = [];
        selectedOption.map((data) =>
          resultData.push({
            question: data.qid,
            solutionIndex: [data.answer],
          })
        );
        const params = {
          student: selectedStudent._id,
          practiceTest: practiceId,
          result: {
            question: qId,
            passageQuestions: resultData,
            timeTakenInSecs: timeTakenToAnswer,
          },
        };
        const res = await submitAnswerPrac(params, token);
        localStorage.setItem('drag-data', JSON.stringify(res?.data?.data));
        setstatus(res?.data?.data);
        setStat(res?.data?.data?.status);
        setTopBar(true);
      }
      if (qtype === 'fill-in-the-blanks') {
        const params = {
          student: selectedStudent._id,
          practiceTest: practiceId,
          result: {
            question: qId,
            fillInTheBlankSolution: selectedOption,
            timeTakenInSecs: timeTakenToAnswer,
          },
        };
        const res = await submitAnswerPrac(params, token);
        localStorage.setItem('drag-data', JSON.stringify(res?.data?.data));
        setstatus(res?.data?.data);
        setStat(res?.data?.data?.status);
        setTopBar(true);
      }
      if (qtype === 'drag-and-match-the-correct-answer') {
        const params = {
          student: selectedStudent._id,
          practiceTest: practiceId,
          result: {
            question: qId,
            dragTheTextSolution: selectedOption.map((opt) => opt?.name),
            timeTakenInSecs: timeTakenToAnswer,
          },
        };
        const res = await submitAnswerPrac(params, token);
        localStorage.setItem('drag-data', JSON.stringify(res?.data?.data));
        setstatus(res?.data?.data);
        setStat(res?.data?.data?.status);
        setTopBar(true);
      } else {
        const params = {
          student: selectedStudent._id,
          practiceTest: practiceId,
          result: {
            question: qId,
            solutionIndex: selectedOption,
            timeTakenInSecs: timeTakenToAnswer,
          },
        };
        const res = await submitAnswerPrac(params, token);
        localStorage.setItem('drag-data', JSON.stringify(res?.data?.data));
        setstatus(res?.data?.data);
        setStat(res?.data?.data?.status);
        setTopBar(true);
      }
      setSubmitCliked(false);
    } catch (error) {
      console.log(error);
      setSubmitCliked(false);
    }
  };

  const submitAnswer = (qId, qtype) => {
    attemptAnswer(qId, qtype);
  };

  const GetNextQuestion = async () => {
    setNextButtonClicked(true);
    setTimeTakenToAnswer(0);
    setIsSubmitValid(false);
    if (stat === 'IN-PROGRESS') {
      setstatus({});
      setStat('');
      setSelectedOption([]);
      setQuestionsInfo('');
      getPracticeQuestions();
    }
    if (stat === 'COMPLETED') {
      try {
        const params = {
          practiceTestId: practiceId,
        };
        const res = await completeTestNotificationTrigg(params, token);
        if (res) {
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const takeTest = async () => {
    const params = {
      student: selectedStudent._id,
      syllabus: {
        subject: subjectp,
        chapter: chapterp,
        concept: conceptp,
        subConcept: subConceptp,
      },
    };

    try {
      const res = await takePracticeTest(params, token);
      setPracticeId(res?.data?.data?._id);
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 404) {
        setTakeTestData(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (practiceId) {
      getPracticeQuestions();
    }
  }, [practiceId]);

  const buttonClass = isSubmitValid
    ? 'button-wrapper-active'
    : 'button-wrapper';

  useEffect(() => {
    takeTest();
  }, []);
  return (
    <div className='Olympiad-main'>
      <div className='row justify-content-center'>
        <div className='col-sm-11'>
          {!nextButtonClicked &&
            questionsInfo?._id &&
            takeTestData === '' &&
            !submitCliked && (
              <TestHeader
                takeTestData={takeTestData}
                statusVal={statusVal}
                topBar={topBar}
                timeTakenToAnswer={parseInt(timeTakenToAnswer)}
                stat={stat}
              />
            )}
          {takeTestData && (
            <div>
              <h3 className='test-header' style={{ textAlign: 'center' }}>
                {takeTestData}
              </h3>

              <button
                type='button'
                style={{ textAlign: 'left' }}
                className='assignQueBack'
                onClick={() => {
                  history.push('/dashboard/subject');
                }}
              >
                Go back
              </button>
            </div>
          )}

          {stat === '' && !questionsInfo?._id && takeTestData === '' && (
            <WaitingLoader />
          )}
          {stat === '' && !submitCliked && questionsInfo?._id && (
            <div>
              <PracticeQueanspart
                setNextButtonClicked={setNextButtonClicked}
                questionsInfo={questionsInfo}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                setIsSubmitValid={setIsSubmitValid}
              />

              <div className='row'>
                <div className='col-12'>
                  <div className='col-6 d-flex align-items-center justify-content-between'>
                    <button
                      type='button'
                      className='submit-answer-button mt-0'
                      onClick={() =>
                        submitAnswer(questionsInfo._id, questionType)
                      }
                      disabled={!isSubmitValid}
                    >
                      <div
                        className={`${buttonClass} d-flex align-items-center justify-content-center`}
                      >
                        <span>Submit</span>
                        {/* <div className='icon-wrapper d-flex align-items-center justify-content-center'>
                          {chevRightIcon()}
                        </div> */}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {((stat === 'IN-PROGRESS' && !submitCliked) ||
            (stat === 'COMPLETED' &&
              statusVal &&
              !nextButtonClicked &&
              !submitCliked)) && (
            <div>
              <PracticeAttemptAnswers
                statusVal={statusVal}
                setNextButtonClicked={setNextButtonClicked}
              />
              <div className='row'>
                <div className='col-12'>
                  <div className='col-6 d-flex align-items-center justify-content-between'>
                    <button
                      type='button'
                      onClick={() => GetNextQuestion()}
                      className='button-common mr-2 mt-0'
                    >
                      {stat === 'COMPLETED' ? 'Submit Test' : 'Next question'}
                      {/* <span>{chevRight}</span> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {submitCliked && stat == '' ? <WaitingLoader /> : ''}
          {stat === 'COMPLETED' && nextButtonClicked && (
            <CongratsPopup statusVal={statusVal} />
          )}
          {stat === 'FINISHED' && <FinishedPopup />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedStudent: state.Auth.Login?.data,
  token: state.Auth.Login?.data?.accessToken,
});
const mapDispatchToProps = (dispatch) => ({
  // clearLogin: () => dispatch(clearLoginAction()),
  // getParentProfile: (data) => dispatch(parentProfileAction(data)),
  // startChest: (data) => dispatch(startChestAction(data)),
  // getPoints: () => dispatch(getTotalPoints()),
  // updateParentLoginStudentn: (data) =>
  //   dispatch(updateParentLoginStudentnAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PracticeTestQuestions);
