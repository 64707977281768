import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import config from '../../../../helpers/config';
import {
  sendOtpParentAction,
  updateRegistrationData,
  verifyEmailAction,
  verifyOtpAction,
  getGeoLocation,
} from '../../../../stores/Auth/AuthAction';

import { authServices } from '../../../../services/auth.services';
import PhoneInput from 'react-phone-input-2';
import Countdown, { zeroPad } from 'react-countdown';
import Mydetailsinput from '../../auth/register/Mydetailsinput';
import Mydetailotpinput from '../../auth/register/Mydetailotpinput';
import MyParentdetailsInput from './MyParentdetailsInput';
import OtpInputBox from '../components/OtpInputBox';
import Loading from './Loading';
import swal from 'sweetalert';
import { v4 as uuid } from 'uuid';

const MygenericDetails = ({
  setFullnameVal,
  fullnameVal,

  validation,
  setValidation,

  emailVal,
  setEmailVal,

  phoneNumVal,
  setPhoneNumVal,

  setStudents,
  setParentId,
  setCurrencyCode,
  currencyCode,

  sendOtp,
  otp,

  verifyEmail,
  verifyEmailData,

  verifyOtpParentData,

  disableFields,
  setDisableFields,
  otpDisable,

  registrationData,
  updateRegistrationData,

  getGeoLocationAction,
  geoLocationData,
}) => {
  const [otpButtonText, setOtpButtonText] = useState('Send OTP');
  const [otpButtonpopup, setOtpButtonpopup] = useState('');
  const [show403, setShow403] = useState(false);
  const [emailvalidate, setEmailValidate] = useState(false);
  const [nameValidate, setNameValidate] = useState('');
  const [showResend, setShowResend] = useState(false);
  // const [OtpTrig, setOtpTrig] = useState(false);
  const [OtpTrigAcc, setOtpTrigAcc] = useState(false);

  const emailRegex =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

  const ApplyOtpCondition =
    otpButtonText !== 'Send OTP' && otpButtonText !== 'loading';

  let countryCode;
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setOtpButtonText('Resend OTP');
    }
    // Render a countdown
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const getOtpText = () => {
    if (disableFields) {
      return '';
    }
    if (otpButtonText === 'Send OTP') {
      return 'Send OTP';
    }
    if (otpButtonText === 'Timer') {
      return (
        <Countdown
          renderer={renderer}
          date={Date.now() + 30000}
          zeroPadTime={3}
        />
      );
    }
    if (otpButtonText === 'loading') {
      return <Loading />;
    }
    return 'Resend OTP';
  };

  const handleOtpClick = () => {
    if (otpButtonText !== 'Timer') {
      if (
        emailRegex.test(emailVal) &&
        fullnameVal !== '' &&
        nameRegex.test(fullnameVal)
      ) {
        const data = {
          parentName: fullnameVal,
          email: emailVal,
          phone: phoneNumVal,
        };
        sendOtp(data);
        setShow403(false);
        setOtpTrigAcc(true);
        // setOtpTrig(!OtpTrig);
        setEmailValidate(false);
      } else {
        if (!emailRegex.test(emailVal)) {
          setEmailValidate(true);
        }
        if (fullnameVal === '') {
          setValidation((prevPerson) => ({
            ...prevPerson,
            fullName: true,
          }));
        } else if (!nameRegex.test(fullnameVal)) {
          setNameValidate('Name should not contains number and character');
        }
      }
    }
  };

  useEffect(() => {
    getGeoLocationAction();
  }, []);

  useEffect(() => {
    if (OtpTrigAcc) {
      if (otp.status === 'success') {
        setOtpButtonText('Timer');
      } else if (otp?.statusCode === 403) {
        setShow403(true);
        setOtpButtonText('Send OTP');
      } else {
        setOtpButtonText('loading');
      }
    }
  }, [otp]);

  const onEmailchange = (e) => {
    setEmailVal(e);
    // if (emailRegex.test(e)) {
    //   verifyEmail(e);
    // }
  };

  useEffect(() => {
    if (verifyOtpParentData.statusCode === 200) {
      setValidation({ ...validation, isParentValidated: true });
    }
  }, [verifyOtpParentData]);

  async function handleCountry(registerdStudents) {
    const countryList = await authServices.getCountryList();

    if (countryList) {
      const cdata = countryList?.data.map((data) => ({
        id: data._id,
        name: data.country_label,
        label: data.country_label,
        code: data.country_code,
        flag: data.flag,
        currency_code: data.currency_code,
      }));
      let updatingStudentsData = registerdStudents.map(async (eachStudent) => {
        let updatingStudent = eachStudent;
        const pastCountryName = eachStudent.pastRegisterdData.country;
        const updatingCountryIndex = cdata.findIndex(
          (eachCountry) => eachCountry.name === pastCountryName
        );
        const updatingCountry = cdata[updatingCountryIndex];

        updatingStudent.country = updatingCountry;

        const allStateList = await authServices.getStateList(
          updatingCountry?.code
        );
        if (allStateList?.data?.length > 0) {
          const cdata = allStateList?.data.map((data) => ({
            value: data.state_label,
            label: data.state_label,
            state_code: data.state_code,
            id: data._id,
          }));
          cdata.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          );
          const pastStateName = eachStudent.pastRegisterdData?.state;
          const updatingStateIndex = cdata.findIndex(
            (eachState) => eachState.value === pastStateName
          );
          const updatingState = cdata[updatingStateIndex];
          updatingStudent.state = updatingState;
        }

        const timezoneList = await authServices.getTimezoneList(
          updatingCountry?.code
        );
        if (timezoneList?.statusCode === 200) {
          if (timezoneList?.data?.timezones?.length > 0) {
            const cdata = timezoneList?.data?.timezones.map((item) => ({
              label: item?.label,
              value: item?.timezone,
              key: item?.timezone,
            }));
            const pastTimezone = eachStudent?.pastRegisterdData?.timezone;
            const updatingTimeZoneIndex = cdata.findIndex(
              (eachTimeZone) => eachTimeZone.value === pastTimezone
            );
            const updatingTimeZone = cdata[updatingTimeZoneIndex];
            updatingStudent.timezone = updatingTimeZone;
          }
        }

        return updatingStudent;
      });
      updatingStudentsData = await Promise.all(updatingStudentsData);
    }
  }
  async function handleBoard(registerdStudents) {
    const allBoards = await authServices.getAllBoards();
    if (allBoards?.data?.length > 0) {
      const gdata = allBoards?.data?.map((data) => ({
        value: data._id,
        label: data.name,
      }));

      const allMonthsSlabs = await authServices.getAllMonthsSlabs();

      let updatingStudentsData = registerdStudents.map(async (eachStudent) => {
        let updatingStudent = eachStudent;
        const pastBoard = eachStudent.pastRegisterdData.board;
        const updatingBoardIndex = gdata.findIndex(
          (eachBoard) => eachBoard.value === pastBoard
        );
        const updatingBoard = gdata[updatingBoardIndex];

        updatingStudent.board = updatingBoard;

        const gradeList = await authServices.getAllGrades({
          boardId: updatingBoard?.value,
        });
        if (gradeList?.data?.length > 0) {
          const gdata = gradeList?.data?.map((data) => ({
            value: data._id,
            label: data.name,
          }));
          const pastGrade = eachStudent.pastRegisterdData.grade;
          const updatingGradeIndex = gdata.findIndex(
            (eachGrade) => eachGrade.value === pastGrade
          );
          const updatingGrade = gdata[updatingGradeIndex];

          updatingStudent.grade = updatingGrade;

          const subjectList = await authServices.getSubjectsByclass({
            classId: updatingGrade.value,
          });

          const sdata = subjectList?.data?.map((eachSubject) => ({
            label: eachSubject?.name,
            value: eachSubject?._id,
          }));

          //Subjects

          const pastSubjects = eachStudent.pastRegisterdData.subjectsEnrolled;
          let updatingSubjects = pastSubjects.map(async (eachSubject) => {
            const updatingSubjectIndex = sdata.findIndex(
              (eachSub) => eachSub.value === eachSubject.subject
            );
            const updatingEachSubject = sdata[updatingSubjectIndex];

            const updatingPriceData = await authServices.getCurricularPricing({
              subjectId: updatingEachSubject.value,
              months: eachSubject?.months,
              countryCode: eachStudent.pastRegisterdData.currencyCode,
              numberOfClass: null,
              classPerWeek: null,
            });
            if (updatingPriceData) {
              let classSlab = {};
              let selectedClassSlabIndex = 0;
              updatingPriceData?.data?.slabs?.forEach((each, index) => {
                if (each.classPerWeek === eachSubject.classPerWeek) {
                  selectedClassSlabIndex = index;
                  classSlab = each;
                }
              });
              const pastMoths = eachSubject?.months;
              const updatingMonthIndex =
                allMonthsSlabs?.data?.curricular?.months.findIndex(
                  (eachMonth) => eachMonth.value === pastMoths
                );
              const updatingMothsValue =
                allMonthsSlabs?.data?.curricular?.months[updatingMonthIndex];

              return {
                id: uuid(),
                subject: updatingEachSubject.value,
                subjectValue: updatingEachSubject,
                months: eachSubject?.months,
                monthsValue: updatingMothsValue,
                classSlab: classSlab,
                selectedClassSlabIndex: selectedClassSlabIndex,
                allClassSlab: updatingPriceData?.data?.slabs,
                examsAvaileble: eachSubject?.examType,
                price: classSlab?.totalPrice,
                itemId: classSlab?.itemId,
              };
            }
          });

          const pastOlampiadSubjects =
            eachStudent.pastRegisterdData.olympiadSubscription;
          let updatingOlampiadSubjects = pastOlampiadSubjects.map(
            async (eachSubject) => {
              const updatingSubjectIndex = sdata.findIndex(
                (eachSub) => eachSub.value === eachSubject.subject
              );
              const updatingEachSubject = sdata[updatingSubjectIndex];

              const updatingPriceData = await authServices.getOlympiadPricing({
                subjectId: updatingEachSubject.value,
                months: eachSubject?.months,
                countryCode: eachStudent.pastRegisterdData.currencyCode,
              });
              if (updatingPriceData) {
                const pastMoths = eachSubject?.months;
                const updatingMonthIndex =
                  allMonthsSlabs?.data?.olympiad?.months.findIndex(
                    (eachMonth) => eachMonth.value === pastMoths
                  );
                const updatingMothsValue =
                  allMonthsSlabs?.data?.olympiad?.months[updatingMonthIndex];
                return {
                  id: uuid(),
                  subject: updatingEachSubject.value,
                  subjectValue: updatingEachSubject,
                  months: eachSubject?.months,
                  monthsValue: updatingMothsValue,
                  examsAvaileble: eachSubject?.examType,
                  price: updatingPriceData?.data?.totalPrice,
                  itemId: updatingPriceData?.data?.itemId,
                };
              }
            }
          );

          const cocuricularSubjectList =
            await authServices.coCurricularActivities();

          const csdata = cocuricularSubjectList?.data?.map((eachSubject) => ({
            label: eachSubject?.activityName,
            value: eachSubject?._id,
          }));

          const pastCocuricularSubjects =
            eachStudent.pastRegisterdData.cocurricularsEnrolled;
          let updatingCocuricularSubjects = pastCocuricularSubjects.map(
            async (eachSubject) => {
              const updatingCocuricularSubjectIndex = csdata.findIndex(
                (eachSub) => eachSub.value === eachSubject.cocurricularActivity
              );
              const updatingEachCocuricularSubject =
                csdata[updatingCocuricularSubjectIndex];

              const updatingCocuricularPriceData =
                await authServices.getCoCurricularPricing({
                  subjectId: updatingEachCocuricularSubject.value,
                  months: eachSubject?.months,
                  countryCode: eachStudent.pastRegisterdData.currencyCode,
                  numberOfClass: null,
                  classPerWeek: null,
                });
              if (updatingCocuricularPriceData) {
                let classSlab = {};
                let selectedClassSlabIndex = 0;
                updatingCocuricularPriceData?.data?.slabs?.forEach(
                  (each, index) => {
                    if (each.classPerWeek === eachSubject.classPerWeek) {
                      selectedClassSlabIndex = index;
                      classSlab = each;
                    }
                  }
                );

                const pastMoths = eachSubject?.months;
                const updatingMonthIndex = allMonthsSlabs?.data[
                  'co-curricular'
                ]?.months.findIndex(
                  (eachMonth) => eachMonth.value === pastMoths
                );
                const updatingMothsValue =
                  allMonthsSlabs?.data['co-curricular']?.months[
                    updatingMonthIndex
                  ];

                return {
                  id: uuid(),
                  cocurricularActivity: updatingEachCocuricularSubject.value,
                  subjectValue: updatingEachCocuricularSubject,
                  activityDisplayValue: updatingEachCocuricularSubject,
                  months: eachSubject?.months,
                  monthsValue: updatingMothsValue,
                  classSlab: classSlab,
                  selectedClassSlabIndex: selectedClassSlabIndex,
                  allClassSlab: updatingCocuricularPriceData?.data?.slabs,
                  price: classSlab?.totalPrice,
                  itemId: updatingCocuricularPriceData?.data?.itemId,
                };
              }
            }
          );

          updatingSubjects = await Promise.all(updatingSubjects);

          updatingStudent.subjectsEnrolled = updatingSubjects;

          updatingOlampiadSubjects = await Promise.all(
            updatingOlampiadSubjects
          );

          updatingStudent.olympiadSubscription = updatingOlampiadSubjects;

          updatingCocuricularSubjects = await Promise.all(
            updatingCocuricularSubjects
          );

          updatingStudent.cocurricularsEnrolled = updatingCocuricularSubjects;
          updatingStudent.registerd = true;
        }

        return updatingStudent;
      });

      updatingStudentsData = await Promise.all(updatingStudentsData);
    }
  }

  async function handlePastRegisterdData(registerdStudents) {
    // let updatedStudentsData = [];
    // const handleStudntsData = (students)=>{
    //   updatedStudentsData = [...students];
    // }

    const res = await Promise.all([
      handleCountry(registerdStudents),
      handleBoard(registerdStudents),
    ]);
    if (res) {
      return registerdStudents;
    }
    // const  boardList
    //   const countryList } =
    //   boardList:,
    //   CountryList: authServices.getCountryList(),
    // };

    // if (countryList && boardList) {
    //   console.log('all country', countryList, boardList);
    // }
  }

  //  async (pastStudentData)=>{

  //   try {

  //     const allCountry = new Promise((resolve, reject) => {
  //       authServices.getCountryList().then()=>
  //       {};
  //     });
  //     Promise.all([authServices.getCountryList(), authServices.getAllBoards()]).then((response) => {

  //       // authServices.getTimezoneList(data.payload);
  //       // authServices.getAllGrades(params);
  //     });

  //     // const allCountryList = await  authServices.getCountryList();
  //     // const allBoardList = await authServices.getAllBoards();

  //     // authServices.getStateList(data.payload);

  //     // authServices.getAllBoards();

  //     // authServices.getSubjectsByclass(data?.payload);

  //     // if (res.data) {
  //     //   // console.log('thePriceData', res.data);
  //     //    res.data;
  //     //   //  updateRegistrationData({
  //     //   //   ...registrationData,
  //     //   //   students: registerdStudents,
  //     //   // });
  //     // }
  //   } catch (error) {
  //     // console.log('the price error', error);
  //   }

  //  }

  useEffect( ()=>{
    const otpData = async () => {
    if (otp.statusCode === 200) {
      if (registrationData?.students) {
        setValidation({ ...validation, isParentValidated: true });
        return;
      }
      // console.log('validation', validation);
      setParentId(otp?.data?.parent);

      if (otp?.data?.otpVerified && otp?.data?.emailVerified) {
        if (otp?.data?.students?.length > 0) {
          setCurrencyCode(otp?.data?.students[0].currencyCode);
          countryCode = otp?.data?.students[0].currencyCode;
          const registerdStudents = otp?.data?.students.map((eachStudent) => ({
            name: eachStudent?.studentName,
            lastName: eachStudent?.lastName,
            userName: eachStudent?.userName,
            id: eachStudent?.id,
            school: eachStudent?.schoolName,
            pastRegisterdData: eachStudent,
          }));
          setOtpButtonText('loading');

          const res = await handlePastRegisterdData(registerdStudents);
          if (res) {
            let updatedRegistrationData = registrationData;

            updatedRegistrationData.students = res;
            (updatedRegistrationData.isEmailVerified = true),
              updateRegistrationData({
                ...updatedRegistrationData,
                currencyCode: countryCode,
              });
          }
        }
        swal('OTP already verified. Please proceed to next step');
        setValidation({ ...validation, isParentValidated: true });
      }

      // setValidation({ ...validation, isParentValidated: true });
    } else if (otp?.statusCode) {
      if (otp?.message) {
        swal(otp?.message);
      }
    }
  }
  otpData();
  }, [otp]);

  return (
    <div className='mydetails-main'>
      <h3 className='text-center'>Parent Details</h3>
      <div className='row '>
        <div className='col-md-6 mydetail-input'>
          <MyParentdetailsInput
            label='Parent/Guardian Name*'
            type='text'
            name='full_name'
            id='full-name'
            placeHolder='Enter your first name and last name'
            value={fullnameVal}
            disableFields={disableFields || otpButtonText !== 'Send OTP'}
            setValue={(value) => {
              //OnChange event
              setValidation((prevPerson) => ({
                ...prevPerson,
                fullName: false,
              }));
              setNameValidate('');
              setFullnameVal(value);
            }}
            onBlur={(e) => {
              if (nameRegex.test(e.target.value)) setNameValidate('');
              else setNameValidate('Please enter a valid name.');
            }}
          />
          {validation.fullName && (
            <span className='error-msg'>Full name is required. </span>
          )}
          {!validation.fullName && nameValidate !== '' ? (
            <span className='error-msg'>{nameValidate}</span>
          ) : (
            ''
          )}
        </div>

        <div className='col-md-6 mydetail-input'>
          <MyParentdetailsInput
            label='Email*'
            type='email'
            name='email_id'
            placeHolder='Enter your email ID'
            id='email'
            value={emailVal}
            disableFields={disableFields || otpButtonText !== 'Send OTP'}
            setValue={(e) => {
              setValidation((prevPerson) => ({
                ...prevPerson,
                emailId: false,
              }));
              setEmailValidate(false);
              onEmailchange(e);
            }}
            onBlur={() => {
              if (!emailRegex.test(emailVal)) {
                setEmailValidate(true);
              } else setEmailValidate(false);
            }}
          />
          {validation.emailId && (
            <span className='error-msg'>Email is required. </span>
          )}
          {emailvalidate ? (
            <span className='error-msg'>Please enter valid Email.</span>
          ) : (
            ''
          )}
          {verifyEmailData.statusCode === 200 && (
            <span className='error-msg'>This email is already registered.</span>
          )}
        </div>

        <div className='col-md-6 mydetail-input'>
          <div className='mydetail-input-part'>
            <label htmlFor='detail-label'>Mobile*</label>
            <>
              <div
                className={
                  disableFields
                    ? 'phone-input-custom disable'
                    : 'phone-input-custom'
                }
              >
                <PhoneInput
                  specialLabel=''
                  country={geoLocationData?.country_code?.toLowerCase() || 'in'}
                  onlyCountries={["in","us"]}
                  countryCodeEditable={false}
                  preferredCountries={['in', 'us']}
                  value={phoneNumVal}
                  disabled={disableFields}
                  enableSearch={true}
                  // disableDropdown={true}
                  inputStyle={{
                    fontSize: 20,
                    buttonStyle: {
                      display: 'none',
                    },
                  }}
                  placeholder='Enter your mobile number'
                  onChange={(e) => {
                    setValidation((prevPerson) => ({
                      ...prevPerson,
                      phoneNumber: false,
                    }));
                    setOtpButtonText('Send OTP');
                    // setShow403(false);
                    setPhoneNumVal(e);
                  }}
                />
              </div>
              <button
                type='button'
                disabled={disableFields}
                onClick={handleOtpClick}
                style={{
                  display: !otpDisable
                    ? (
                        phoneNumVal.slice(0, 2) === '91'
                          ? phoneNumVal.length >= 12
                          : phoneNumVal.length >= 11
                      )
                      ? 'block'
                      : 'none'
                    : 'none',
                }}
              >
                {getOtpText()}
              </button>
            </>
          </div>

          {otp?.status === 'success' && ApplyOtpCondition && (
            <span className='success-msg'>OTP sent</span>
          )}
          {validation.phoneNumber && (
            <span className='error-msg'>Phone number is required.</span>
          )}
          {otp?.statusCode === 400 && show403 && (
            <span className='error-msg'>
              This mobile number is already registered.
            </span>
          )}
          {otp?.statusCode === 403 && show403 && (
            <span className='error-msg'>
              This mobile number or email already registered.
            </span>
          )}
        </div>

        {otp.status === 'success' && ApplyOtpCondition && (
          <OtpInputBox
            label='Enter OTP'
            phoneNumVal={phoneNumVal}
            isDisabled={otpDisable}
            // renderInput={(props) => (
            //   <input
            //     {...props}
            //     style={{
            //       width: '50px',
            //       height: '50px',
            //       textAlign: 'center',
            //       fontSize: '18px',
            //       border: '1px solid #ccc',
            //       borderRadius: '4px',
            //     }}
            //     onChange={(e) => handleInputChange(Number(props.id?.split('-')[1] || 0), e.target.value)}
            //   />
            // )}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  otp: state.Auth.otpParent,
  verifyEmailData: state.Auth.verifyEmailData,
  verifyOtpParentData: state.Auth.verifyOtpParent,
  registrationData: state.Auth.registrationData,
  geoLocationData: state.Auth.geoLocationData,
});

const mapDispatchToProps = (dispatch) => ({
  sendOtp: (data) => dispatch(sendOtpParentAction(data)),
  verifyEmail: (data) => dispatch(verifyEmailAction(data)),
  updateRegistrationData: (data) => dispatch(updateRegistrationData(data)),
  getGeoLocationAction: () => dispatch(getGeoLocation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MygenericDetails);
