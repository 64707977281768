import moment from 'moment';
import { func, string } from 'prop-types';
import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { useHistory } from 'react-router';
import { formatDateAllNumbers } from '../../../../helpers/utility';
import { all } from 'redux-saga/effects';
import { student } from '../../../../assets/icons/IconList';

const currentDate = new Date();

const SubjectsExpiredPopup = ({
  modal,
  setModal,
  title,
  stepdata,
  subExpiredList,
  olympiadExpiredList,
  coCurExpiredList,
  allStudentData,
  studentType,
}) => {
  const history = useHistory();
  const setstepdata = () => {
    history.push('/dashboard/subscriptions');
    setModal();
  };
  const noclick = () => {
    setModal(false);
  };

  return (
    <div className='popup'>
      {/* <button on>X</button> */}
      <Modal
        isOpen
        show={modal}
        toggle={setModal}
        className='  subscription_expire_modal'
      >
        <Modal.Header closeButton onClick={() => setModal(false)} />
        <ModalTitle className='title' toggle={setModal}>
          <div>{title}</div>
        </ModalTitle>

        <ModalBody className=''>
          {studentType === 'student' ? (
            <div className='w-100 d-flex flex-column '>
              {subExpiredList?.length ? (
                <>
                  {subExpiredList?.map((item) =>
                    moment(item.expiryDate).format() <
                    moment(currentDate).format() ? (
                      <>
                        <div
                          className='d-flex justify-content-space-between mt-1'
                          style={{ justifyContent: 'space-between' }}
                        >
                          <div className='subject-name'>
                            {item?.subject?.name}
                          </div>
                          <div
                            className='expired_popup_type'
                            style={
                              item?.subject?.name !== 'Mathematics'
                                ? { marginLeft: '22px' }
                                : { marginLeft: '0px' }
                            }
                          >
                            Online Class
                          </div>

                          <div className='expired_popup_endDate'>
                            Ended on -{' '}
                            {/* {moment(item?.expiryDate).format('DD - MM - YYYY')} */}
                            {formatDateAllNumbers(item?.expiryDate)}
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )
                  )}
                </>
              ) : (
                ''
              )}

              {olympiadExpiredList?.length ? (
                <>
                  {olympiadExpiredList?.map((item) =>
                    moment(item.expiryDate).format() <
                    moment(currentDate).format() ? (
                      <>
                        <div
                          className='d-flex justify-content-space-between mt-1'
                          style={{ justifyContent: 'space-between' }}
                        >
                          <div className='subject-name'>
                            {item?.subject?.name}
                          </div>
                          <div
                            className='expired_popup_type'
                            style={
                              item?.subject?.name !== 'Mathematics'
                                ? { marginLeft: '24px' }
                                : { marginLeft: '0px' }
                            }
                          >
                            Olympiad
                          </div>

                          <div className='expired_popup_endDate'>
                            Ended on -{' '}
                            {/* {moment(item?.expiryDate).format('DD - MM - YYYY')} */}
                            {formatDateAllNumbers(item?.expiryDate)}
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )
                  )}
                </>
              ) : (
                ''
              )}

              {coCurExpiredList?.length ? (
                <>
                  {coCurExpiredList?.map((item) =>
                    moment(item?.expiryDate).format() <
                    moment(currentDate).format() ? (
                      <>
                        <div
                          className='d-flex justify-content-space-between mt-1'
                          style={{ justifyContent: 'space-between' }}
                        >
                          <div className='subject-name'>
                            {item?.cocurricularActivity?.activityName}
                          </div>
                          <div
                            className='expired_popup_type'
                            style={
                              item?.subject?.name !== 'Mathematics'
                                ? { marginLeft: '60px' }
                                : { marginLeft: '16px' }
                            }
                          >
                            Co-curricular
                          </div>

                          <div className='expired_popup_endDate'>
                            Ended on -{' '}
                            {/* {moment(item?.expiryDate).format('DD - MM - YYYY')} */}
                            {formatDateAllNumbers(item?.expiryDate)}
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )
                  )}
                </>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className='w-100 d-flex flex-column '>
              {allStudentData?.length ? (
                <>
                  {allStudentData?.map((allStu, index) => (
                    <div className='w-100 '>
                      {allStu.subjectsEnrolled.length &&
                      allStu.subjectsEnrolled.map((item) => {
                        if (
                          moment(item.expiryDate).format() <
                          moment(currentDate).format()
                        ) {
                          return true;
                        } else return false;
                      }) &&
                      allStu.olympiadSubscription.length &&
                      allStu.olympiadSubscription.map((item) => {
                        if (
                          moment(item.expiryDate).format() <
                          moment(currentDate).format()
                        ) {
                          return true;
                        } else return false;
                      }) &&
                      allStu.cocurricularsEnrolled?.length &&
                      allStu.cocurricularsEnrolled.map((item) => {
                        if (
                          moment(item.expiryDate).format() <
                          moment(currentDate).format()
                        ) {
                          return true;
                        } else return false;
                      }) ? (
                        <div
                          className='d-flex justify-content-space-between  subscription_expire_modal_name '
                          style={{ justifyContent: 'space-between' }}
                        >
                          {allStu.studentName}
                        </div>
                      ) : (
                        ''
                      )}

                      {allStu.subjectsEnrolled?.length ? (
                        <div className='w-100 '>
                          {allStu.subjectsEnrolled.map((item, i) =>
                            moment(item.expiryDate).format() <
                            moment(currentDate).format() ? (
                              <>
                                <div
                                  className='d-flex justify-content-space-between mt-1'
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <div className='subject-name'>
                                    {item?.subject?.name}
                                  </div>
                                  <div
                                    className='expired_popup_type'
                                    style={
                                      item?.subject?.name !== 'Mathematics'
                                        ? { marginLeft: '60px' }
                                        : { marginLeft: '16px' }
                                    }
                                  >
                                    Online class
                                  </div>

                                  <div className='expired_popup_endDate'>
                                    Ended on -{' '}
                                    {/* {moment(item?.expiryDate).format(
                                      'DD - MM - YYYY'
                                    )} */}
                                    {formatDateAllNumbers(item?.expiryDate)}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ' '
                            )
                          )}
                        </div>
                      ) : (
                        ''
                      )}

                      {allStu.olympiadSubscription?.length ? (
                        <div className='w-100 '>
                          {allStu.olympiadSubscription.map((item, i) =>
                            moment(item.expiryDate).format() <
                            moment(currentDate).format() ? (
                              <>
                                <div
                                  className='d-flex justify-content-space-between mt-1'
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <div className='subject-name'>
                                    {item?.subject?.name}
                                  </div>
                                  <div
                                    className='expired_popup_type'
                                    style={
                                      item?.subject?.name !== 'Mathematics'
                                        ? { marginLeft: '39px' }
                                        : { marginLeft: '-5px' }
                                    }
                                  >
                                    Olympiad
                                  </div>

                                  <div className='expired_popup_endDate'>
                                    Ended on -{' '}
                                    {/* {moment(item?.expiryDate).format(
                                      'DD - MM - YYYY'
                                    )} */}
                                    {formatDateAllNumbers(item?.expiryDate)}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )
                          )}
                        </div>
                      ) : (
                        ''
                      )}

                      {allStu.cocurricularsEnrolled?.length ? (
                        <div className='w-100 '>
                          {allStu.cocurricularsEnrolled.map((item, i) =>
                            moment(item.expiryDate).format() <
                            moment(currentDate).format() ? (
                              <>
                                <div
                                  className='d-flex justify-content-space-between mt-1'
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <div className='subject-name'>
                                    {item?.activityName}
                                  </div>
                                  <div
                                    className='expired_popup_type'
                                    style={
                                      item?.subject?.name !== 'Mathematics'
                                        ? { marginLeft: '74px' }
                                        : { marginLeft: '20px' }
                                    }
                                  >
                                    Co-curricular
                                  </div>

                                  <div className='expired_popup_endDate'>
                                    Ended on -{' '}
                                    {/* {moment(item?.expiryDate).format(
                                      'DD - MM - YYYY'
                                    )} */}
                                    {formatDateAllNumbers(item?.expiryDate)}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                </>
              ) : (
                ''
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            // color='primary'
            className='subscription_expire_modal_button'
            onClick={() => {
              setstepdata();
            }}
          >
            <span> Subscribe now</span>
          </button>
          {/* <Button color='secondary' onClick={() => noclick()}>
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
};
// Popup.propTypes = {
//   // setStep: func.isRequired,
//   setModal: func.isRequired,
//   modal: string.isRequired,
//   title: string.isRequired,
//   stepdata: string.isRequired,
// };

export default SubjectsExpiredPopup;
