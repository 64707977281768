import React, { useEffect } from 'react';
import '../../components/dashboard/dashboard.scss';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { func, bool, object } from 'prop-types';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import moment from 'moment';
import Gradeicon from '../../../assets/images/gradeicon.svg';
import Studenticon from '../../../assets/images/students.svg';
import Clockicon from '../../../assets/images/Shape.svg';
import Studentimg from '../../../assets/images/studentimg.png';
import Calendericon from '../../../assets/images/calendaricon.svg';
import stopwatch from '../../../assets/images/clock2.png';
import defaultPic from '../../../assets/images/defaultPic.png';

import Progressbar from '../../components/dashboard/Rightsidepart/Progressbar';
import './teachers.scss';

import { getStudentList } from '../../../stores/StudentData/StudentDataAction';
import { useHistory } from 'react-router';

function SubjectModel({ handleSubjectModel, showSubjectModel, modalInfo }) {
  const dateformatted = moment(modalInfo?.date).format('dddd, MMMM Do');
  const dispatch = useDispatch();
  const { myClasses } = useSelector((state) => state?.TeacherDashboard);
  const { studentList } = useSelector((state) => state?.StudentData);
  const history = useHistory();

  useEffect(() => {
    if (myClasses?.data?.[0]?.response?.[0]) {
      dispatch(
        getStudentList(myClasses?.data?.[0]?.response?.[0]?.scheduleInfo?._id)
      );
    }
  }, [myClasses]);
  return (
    <>
      <Modal
        size='lg'
        show={showSubjectModel}
        onHide={() => {handleSubjectModel(false);
        history.push('/teacher/classes')
        }}
        aria-labelledby='example-modal-sizes-title-lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>
            {modalInfo?.subject}
          </Modal.Title>
        </Modal.Header>
        <div className='col-sm-12 modelsecdiv'>
          <div className='row'>
            <div className='col-sm-5 floatleft d-flex align-items-center'>
              <div>
                <img className='Clockicon2' src={Calendericon} alt='clock' />
              </div>
              <div>
                {dateformatted} | {modalInfo?.timeslot}
              </div>
            </div>

            <div className='col-sm-2 floatleft d-flex align-items-center'>
              <div>
                <img className='Clockicon2' src={Gradeicon} alt='clock' />
              </div>
              <div>{modalInfo?.grade}</div>
            </div>
            <div className='col-sm-2 padd0 d-flex align-items-center'>
              <div>
                <img className='Clockicon2' src={Studenticon} alt='clock' />
              </div>
              <div>{modalInfo?.numberOfStudent} students</div>
            </div>
            <div className='col-sm-3 floatleft d-flex align-items-center'>
              <div>
                <img className='Clockicon2' src={stopwatch} alt='clock' />
              </div>
              <div>{modalInfo?.duration} minutes</div>
            </div>
          </div>
        </div>
        <div className='tabsection'>
          <Tabs defaultActiveKey='first'>
            <Tab eventKey='first' title='Students'>
              <div className='col-sm-12 secondtabcontent floatleft'>
                <div className='row'>
                  {studentList?.map((item) => (
                    <div className='col-sm-6 divpadd floatleft' key={item._id}>
                      <div className='col-sm-12 floatleft studentlistdiv'>
                        <div className='row'>
                          <div className='col-sm-2 floatleft'>
                            <img
                              className='Clockicon'
                              src={
                                item.profilePic ? item.profilePic : defaultPic
                              }
                              alt='studentimg'
                            />
                          </div>
                          <div className='col-sm-10 progressbarsection floatleft'>
                            <div className='studentname'>
                              {item.studentName}
                            </div>
                            <div className='studentgrade'>
                              Grade {item.gradeInfo.name}
                            </div>
                          </div>
                        </div>
                        <div className='col-sm-12 floatleft'>
                          <Progressbar
                            className='progress-bar-one'
                            peronevalue={Math.floor(
                              (item.completedSubConceptsCount * 100) /
                                item.subConceptsCount
                            )}
                            perone
                            title='Subject completion'
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <Modal.Body />
      </Modal>
    </>
  );
}

SubjectModel.propTypes = {
  handleSubjectModel: func.isRequired,
  showSubjectModel: bool.isRequired,

  modalInfo: object.isRequired,
};

export default SubjectModel;
