export const viewTypeData = {
  DASHBOARD: '/teacher',
  CLASSES: '/teacher/classes',
  STUDENTS: '/teacher/students',
  SUBJECTS: '/teacher/subjects',
  CALENDAR: '/teacher/calendar',
  PAYMENTS: '/teacher/payments',
  PROFILE: '/teacher/profile',
  MYCONCEPTS: '/teacher/concepts',
  MYASSIGNMENTS: '/teacher/assignments',
  ZOOMCALL: './teacher/zoomCall',
  REVENUE: '/teacher/revenue',
  COCURRICULAR: '/teacher/co-curricular',
  ZOOMCALLSTUDENT: '/student/zoomCallStudent',
};

export default {};
