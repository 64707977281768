import React from 'react';
import { object } from 'prop-types';
import { useEffect, useState } from 'react';
import { FailureAudio, SuccessAudio } from '../../../../audioSignal';
import { renderText } from '../../components/QuestionTypes/textHelper';
import ReactHtmlParser from 'react-html-parser';
import { findKeyByValue } from '../../../Rightsidepart/components/QuestionTypes/questionHelper';
import { renderTextNew } from '../../BrainGym/QueAnswer/newTextHelper';

const FillInTheBlankSolutionPreview = ({
  questionObject,
  statusVal,
  // play,
  // setPlay,
}) => {
  const result = statusVal?.result;
  const [finalQuesText, setFinalQuesText] = useState('');
  const [finalQuesTextAnswer, setFinalQuesTextAnswer] = useState('');
  const [fillInTheBlankAnswer, setFillInTheBlankAnswer] = useState([]);

  useEffect(() => {
    if (
      questionObject?.fillInTheBlankSolution?.length > 0 &&
      !questionObject?.fillInTheBlankSolution?.includes(-1)
    ) {
      setFillInTheBlankAnswer([...questionObject?.fillInTheBlankSolution]);
    }
  }, [questionObject]);

  const RenderQuestion = (splitString, type) => {
    let newStr;
    if (splitString) {
      splitString.map((subStr, i) => {
        if (i < splitString.length - 1 && fillInTheBlankAnswer[i] !== '') {
          let ans = [...fillInTheBlankAnswer];
          ans.push('');
          setFillInTheBlankAnswer([...ans]);
        }
        newStr = (
          <>
            <span>{newStr}</span>
            <span>{subStr}</span>
            {splitString.length - 1 === i ? null : (
              <span
                className={
                  type == 'question'
                    ? 'olympiad_active_btn'
                    : `olympiad_active_btn ${
                        statusVal?.marksObtained
                          ? ''
                          : 'olympiad_active_incorrect_btn'
                      }`
                }
              >
                {type == 'question'
                  ? questionObject?.fillInTheBlankSolution?.[i]
                  : result?.fillInTheBlankSolution?.[i]}
              </span>
            )}
          </>
        );
      });
    }
    return <div>{newStr}</div>;
  };

  const renderTextWithBlanks = (inputString, type) => {
    const regex = /insert_input/g;
    // questionInChest.description;

    const parts = inputString.split(regex);
    const inputCount = parts.length - 1;
    const finalFormat = parts.map((part, index) => {
      if (index < inputCount) {
        return (
          <React.Fragment key={index}>
            {renderText(part)}
            {/* <input
              type='text'
              // placeholder='insert_input'
              // pattern='^[^\s]+$'
              value={answers[index]}
              data-index={index}
              onChange={handleAnswerSubmit}
            /> */}{' '}
            <span
              className={
                type == 'question'
                  ? 'olympiad_active_btn'
                  : `olympiad_active_btn ${
                      statusVal?.marksObtained
                        ? ''
                        : 'olympiad_active_incorrect_btn'
                    }`
              }
            >
              {type == 'question'
                ? questionObject?.fillInTheBlankSolution?.[index]
                : result?.fillInTheBlankSolution?.[index]}
            </span>{' '}
          </React.Fragment>
        );
      }
      return renderText(part);
    });
    // console.log('the lllooo', result);
    return finalFormat;
  };

  useEffect(() => {
    // const quoteadd = questionObject.descImageElement.replace(
    //   /(&rsquo;)/gi,
    //   '’'
    // );
    // const string = quoteadd.replace(/(&nbsp;|<([^>]+)>)/gi, '');
    // const splitString = string.split('insert_input');
    const finalFormate = renderTextWithBlanks(
      questionObject.description,
      'question'
    );
    const finalFormateee = renderTextWithBlanks(
      questionObject.description,
      'answer'
    );

    setFinalQuesText(finalFormate);
    setFinalQuesTextAnswer(finalFormateee);
  }, [questionObject]);

  const compare2Strings = (s1, s2) => {
    s1 = s1?.replace(/\s+/g, '')?.toUpperCase();
    s2 = s2?.replace(/\s+/g, '')?.toUpperCase();
    return s1.localeCompare(s2) == 0 ? true : false;
  };
  // useEffect(() => {
  //   if (
  //     !compare2Strings(
  //       questionObject?.fillInTheBlankSolution?.[0],
  //       result?.fillInTheBlankSolution?.[0]
  //     ) ||
  //     compare2Strings(
  //       questionObject?.fillInTheBlankSolution?.[0],
  //       result?.fillInTheBlankSolution?.[0]
  //     )
  //   ) {
  //     setPlay(true);
  //   }
  // }, []);
  return (
    <>
      <div>
        <div className='question-box custom-question-box'>
          <h4 className='d-flex'>
            {questionObject?.title == '' || questionObject?.title == null
              ? findKeyByValue(questionObject?.solutionType)
              : renderText(questionObject?.title)}
          </h4>

          <div>
            {!statusVal?.marksObtained ? (
              <>
                {/* {play && <FailureAudio />} */}

                <span className='answer-title customAnswerTitle answer-wrong'>
                  Your below answer is incorrect
                </span>
              </>
            ) : (
              <>
                {/* {play && <SuccessAudio />} */}
                <span className='answer-title answer-correct'>
                  Your answer is correct
                </span>
              </>
            )}
            <div className='fill-in-the-blanks'>{finalQuesTextAnswer}</div>
          </div>
        </div>
      </div>
      {!statusVal?.marksObtained && (
        <>
          <div>
            <div className='question-box mt-4 mb-4'>
              <span className='answer-correct answer-title'>
                Correct Answer
              </span>
              <div className='fill-in-the-blanks'>{finalQuesText}</div>
            </div>
          </div>
        </>
      )}
      {statusVal.questionInfo.solutionDescription ? (
        <div className='option-wrapper-solution mb-4 mt-0'>
          <h4>Solution Description</h4>
          <div>{renderTextNew(statusVal.questionInfo.solutionDescription)}</div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

FillInTheBlankSolutionPreview.propTypes = {
  questionObject: object.isRequired,
  statusVal: object.isRequired,
};

export default FillInTheBlankSolutionPreview;
