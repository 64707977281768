import React, { useState } from 'react';
import { array, func, string } from 'prop-types';
import Mysubjectcounttop from './Mysubjectcounttop';
import Mysubjectcount from './Mysubjectcount';

const Mysubjectlist = ({
  concepts,

  setConceptsData,
  conceptsData,

  setQuestionsData,
  setChapterName,
  setConceptName,
  setSubConName,
  setConceptId,
  setChapterId,
  setSubConceptsList,
}) => {
  const [show, setShow] = useState(true);
  const [hide, setHide] = useState(false);
  return (
    <div>
      <div className='row'>
        <div className='col-md-8'></div>
        <div className='col-md-4' />
      </div>
      {concepts?.length ? (
        <div className='row'>
          <div className='col-md-12 mysubject-count-left'>
            <Mysubjectcounttop
              concepts={concepts}
              setConceptsData={setConceptsData}
            />
            <Mysubjectcount
              show={show}
              setShow={setShow}
              hide={hide}
              setHide={setHide}
              concepts={concepts}
              conceptsData={conceptsData}
              setQuestionsData={setQuestionsData}
              setChapterName={setChapterName}
              setConceptName={setConceptName}
              setSubConName={setSubConName}
              setChapterId={setChapterId}
              setConceptId={setConceptId}
              setSubConceptsList={setSubConceptsList}
            />
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          Syllabus not uploaded please contact administrator
        </div>
      )}
    </div>
  );
};

Mysubjectlist.propTypes = {
  concepts: array.isRequired,
  subjects: array.isRequired,
  setInitialSubject: func.isRequired,
  initailSubName: string.isRequired,
  setConceptsData: func.isRequired,
  conceptsData: string.isRequired,
  setInitialGrade: string.isRequired,
  setInitalGradeId: string.isRequired,
  initialGrade: string.isRequired,
  setQuestionsData: string.isRequired,
  setChapterName: func.isRequired,
  setConceptName: func.isRequired,
  setSubConName: func.isRequired,
};

export default Mysubjectlist;
