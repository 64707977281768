import React, { useEffect, useState } from 'react';
import '../../../../components/dashboard/dashboard.scss';
import '../../teachers.scss';
import Tabs from 'react-bootstrap/Tabs';
import { connect } from 'react-redux';
import { func, array } from 'prop-types';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import { getPaymentHistoryAction } from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import Studentimg from '../../../../../assets/images/studentimg.png';
import Mypaymentsstudentdata from './mockData/Mypaymentsstudent';

const MyPayments = ({
  getPaymentHistory,
  getPaymentTeacherRes,
  getProfile,
}) => {
  useEffect(() => {
    getPaymentHistory(getProfile?.data?._id);
  }, [getProfile]);

  const [myPayments, setMyPayments] = useState([]);

  const Loader = () => (
    <div
      style={{
        height: '100vh',
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation='border' variant='warning' />
    </div>
  );

  useEffect(() => {
    if (getPaymentTeacherRes.data) {
      const cdata = getPaymentTeacherRes?.data?.salaryHistory?.map(
        (item, ind) => ({
          id: ind + 1,
          amount: item.salary ? item.salary : 'USD 00',
          created_on: moment(item.Date).format('MMMM Do YYYY'),
          working_days: '25 Days',
          status: 'pending',
          details: 'report',
        })
      );
      setMyPayments(cdata);
    }
  }, [getPaymentTeacherRes]);

  const renderSubjects = () => {
    if (!getPaymentTeacherRes?.data) {
      return null;
    }

    return getPaymentTeacherRes?.data?.subject.map((item) => item.name);
  };

  return (
    <>
      {!getPaymentTeacherRes.data ? (
        <Loader />
      ) : (
        <>
          <div className='col-sm-12 topdivpad'>
            <div className='row divbg'>
              <div className='col-sm-3'>
                <div className='row'>
                  <div className='col-sm-3'>
                    <img
                      className='Clockicon'
                      src={Studentimg}
                      alt='studentimg'
                    />
                  </div>
                  <div className='col-sm-9'>
                    <p className='paymentid'>{getProfile?.data?.id}</p>
                    <p className='payeename'>
                      {getPaymentTeacherRes?.data?.teacherName}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-3'>
                <p className='paymentid'>Subjects</p>
                <p className='payeename'>{renderSubjects()}</p>
              </div>
              <div className='col-sm-2'>
                <p className='paymentid'>This month</p>
              </div>
              <div className='col-sm-2'>
                <p className='paymentid'>Overall earned</p>
              </div>
              <div className='col-sm-2'>
                <p className='paymentid'>Total classes</p>
                <p className='payeename'>
                  112 <span className='paymentid'>(24 this month)</span>{' '}
                </p>
              </div>
            </div>
          </div>

          <div className='tabsection mypaymenttab'>
            <Tabs defaultActiveKey='first'>
              <Tab eventKey='first' title='Payment history'>
                <div className='firsttabcontent'>
                  <div className='col-sm-12 paymentsection'>
                    <table className='gfg'>
                      <tr>
                        <th> </th>
                        <th className='tableheadings'>AMOUNT</th>
                        <th className='tableheadings'>CREATED ON</th>
                        <th className='tableheadings'>WORKING DAYS</th>
                        <th className='tableheadings'>STATUS</th>
                        <th> </th>
                      </tr>
                      {myPayments.map((item) => (
                        <tr className='tablerow' key={item.id}>
                          <td>{item.id}</td>
                          <td className='td tabledatastyle'>{item.amount}</td>
                          <td className='tabledatastyle'>{item.created_on}</td>
                          <td className='tabledatastyle'>
                            {item.working_days}
                          </td>
                          <td>
                            <div className='dotdiv'>
                              {item.status === 'pending' ? (
                                <div className='dot1'>
                                  <span className='dothide'>hi</span>{' '}
                                </div>
                              ) : (
                                <div className='dot2'>
                                  <span className='dothide'>hi</span>{' '}
                                </div>
                              )}
                            </div>
                            <div className=''>
                              {item.status === 'pending' ? (
                                <span className='pending'> Pending</span>
                              ) : (
                                <span className='processed'>Processed</span>
                              )}
                            </div>
                          </td>
                          <td className='viewdetails'>{item.details}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </Tab>

              <Tab eventKey='second' title='Students'>
                <div className='secondtabcontent'>
                  <div className='col-sm-12 paymentsection'>
                    <table className='gfg'>
                      <tr>
                        <th> </th>
                        <th className='tableheadings'>ID NO.</th>
                        <th className='tableheadings'>NAME</th>
                        <th className='tableheadings'>SUBJECTS</th>
                        <th className='tableheadings'>GRADE</th>
                        <th className='tableheadings'>STATUS</th>
                        <th className='tableheadings'>VIEW</th>
                        <th> </th>
                      </tr>
                      {Mypaymentsstudentdata.map((item) => (
                        <tr className='tablerow' key={item.id}>
                          <td>{item.id}</td>
                          <td className='paymentstdid'>{item.id_number}</td>
                          <td className='td tabledatastyle'>{item.name}</td>
                          <td className='tabledatastyle'>{item.subjects}</td>
                          <td className='tabledatastyle'>{item.grade}</td>
                          <td>
                            <div className='dotdiv'>
                              {item.status === 'active' ? (
                                <div className='dot1'>
                                  <span className='dothide'>hi</span>{' '}
                                </div>
                              ) : (
                                <div className='dot2'>
                                  <span className='dothide'>hi</span>{' '}
                                </div>
                              )}
                            </div>
                            <div className=''>
                              {item.status === 'active' ? (
                                <span className='pending'> Active</span>
                              ) : (
                                <span className='processed'>Inactive</span>
                              )}
                            </div>
                          </td>
                          <td className='viewdetails'>{item.view}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
MyPayments.propTypes = {
  getPaymentHistory: func.isRequired,
  getPaymentTeacherRes: array.isRequired,
  getProfile: array.isRequired,
};

const mapStateToProps = (state) => ({
  getPaymentTeacherRes: state.TeacherDashboard.getPaymentTeacher,
  getProfile: state.TeacherDashboard.getProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentHistory: (data) => dispatch(getPaymentHistoryAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPayments);
