import React from 'react';
import { string, bool, array, func, object } from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import exam from '../../../../assets/images/exam.png';
import { alltime } from '../mockData/MyexamExamlist';
import info from '../../../../assets/images/info.svg';

const MyexamTitle = ({
  title,
  select,
  subjectList,
  setSubdata,
  subdata,
  allExamTypes,
  selectedExamType,
  setSelectedExamType,
  type,
  timedata,
  setTimedata,
  showUnAttempted,
  setShowUnAttempted,
  section,
  setSelectedSubject,
  selectedSubject,
  olympiad,
  titleIcon,
}) => {
  const changeSubject = (val) => {
    setSubdata(val);
  };
  const changeExamType = (val) => {
    setSelectedExamType(val);
  };
  const changeTime = (val) => {
    setTimedata(val);
  };

  return (
    <div className='row myexam-title d-flex align-items-center '>
      {!type ? (
        <div
          className={`col-12 myexam-title-center d-flex align-items-center `}
        >
          <span className='myexam-title-left'>
            <img src={titleIcon ? titleIcon : exam} alt='exam' />
          </span>
          <h3 className='col-5'>{title}</h3>
          {select && (
            <div className='col-sm-5 col-12 myexam-title-last justify-content-end'>
              <Dropdown className=''>
                <Dropdown.Toggle>{subdata?.name}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    key='All'
                    onClick={() =>
                      changeSubject({ _id: '', name: 'All Subject' })
                    }
                  >
                    All Subject
                  </Dropdown.Item>
                  {subjectList?.map((data) => (
                    <Dropdown.Item
                      key={data?.subject?._id}
                      onClick={() => changeSubject(data?.subject)}
                    >
                      {data?.subject?.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {!section && (
                <Dropdown className=''>
                  <Dropdown.Toggle>{selectedExamType}</Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      key='All'
                      onClick={() => changeExamType('All Exams')}
                    >
                      All Exams
                    </Dropdown.Item>
                    {allExamTypes?.map((data) => (
                      <Dropdown.Item
                        key={data}
                        onClick={() => changeExamType(data)}
                      >
                        {data}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
          {/* {olympiad?
        
 <div className='col-sm-6 olymp-info_text'>
 <img src={info} width='20px' className='' />
 <span className='info_text '>
 The below listed exams are for the date from 12-02-2023 to 16-02-2023
 </span>
</div>:''  
        } */}
        </div>
      ) : (
        <div className={`col-12 myexam-title-center d-flex align-items-center`}>
          <span className='myexam-title-left'>
            <img src={titleIcon ? titleIcon : exam} alt='exam' />
          </span>
          <h3 id='previous' className='col-4'>
            {title}
          </h3>

          <div className=' col-2 form-check form-switch custom-show-unattempted'>
            <input
              class='form-check-input'
              type='checkbox'
              id='flexSwitchCheckChecked'
              onChange={(e) => {
                setShowUnAttempted((pre) => !pre);
              }}
              checked={showUnAttempted}
            />
            <label class='form-check-label' htmlFor='flexSwitchCheckChecked'>
              Show Unattempted
            </label>
          </div>
          {section && (
            <div className='col-sm-5 col-12 myexam-title-last justify-content-end'>
              <Dropdown className=''>
                <Dropdown.Toggle>{selectedSubject?.name}</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    key='All'
                    onClick={() =>
                      setSelectedSubject({ _id: '', name: 'All Subject' })
                    }
                  >
                    All Subject
                  </Dropdown.Item>
                  {subjectList?.map((data) => (
                    <Dropdown.Item
                      key={data?.subject?._id}
                      onClick={() => setSelectedSubject(data?.subject)}
                    >
                      {data?.subject?.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          {type && !section && (
            <div className='col-sm-5 col-12 myexam-title-last'>
              <Dropdown className=''>
                <Dropdown.Toggle>{selectedExamType}</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    key='All'
                    onClick={() => changeExamType('All Exams')}
                  >
                    All Exams
                  </Dropdown.Item>
                  {allExamTypes?.map((data) => (
                    <Dropdown.Item
                      key={data}
                      onClick={() => changeExamType(data)}
                    >
                      {data}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className=''>
                <Dropdown.Toggle>{timedata?.name}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {alltime.map((data) => (
                    <Dropdown.Item
                      key={data?.name}
                      onClick={() => changeTime(data)}
                    >
                      {data?.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          {select && (
            <div className='col-sm-5 col-12 myexam-title-last'>
              <Dropdown className=''>
                <Dropdown.Toggle>{subdata?.name}</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    key='All'
                    onClick={() =>
                      changeSubject({ _id: '', name: 'All Subject' })
                    }
                  >
                    All Subject
                  </Dropdown.Item>
                  {subjectList?.map((data) => (
                    <Dropdown.Item
                      key={data?.subject?._id}
                      onClick={() => changeSubject(data?.subject)}
                    >
                      {data?.subject?.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className=''>
                <Dropdown.Toggle>{selectedExamType}</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    key='All'
                    onClick={() => changeExamType('All Exams')}
                  >
                    All Exams
                  </Dropdown.Item>
                  {allExamTypes?.map((data) => (
                    <Dropdown.Item
                      key={data}
                      onClick={() => changeExamType(data)}
                    >
                      {data}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MyexamTitle.propTypes = {
  title: string.isRequired,
  select: bool,
  subjectList: array.isRequired,
  subdata: object.isRequired,
  setSubdata: func.isRequired,
  allExamTypes: object.isRequired,
  selectedExamType: array.isRequired,
  setSelectedExamType: func.isRequired,
  setTimedata: func,
  showUnAttempted: bool,
  setShowUnAttempted: func,
};

MyexamTitle.defaultProps = {
  select: false,
};

export default MyexamTitle;
