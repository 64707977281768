import React from 'react';
import Mysubject from '../../../../../assets/icons/teacherIcon/subjects.svg';

const subjectimg = {
  width: '50px',
  height: '50px',
  marginRight: '10px',
};
const Mysubjectstop = () => (
  <div className=' row'>
    <div className='col-md-4 mysubject-top-left col-12'>
      <h4 className='heading'>
        <img style={subjectimg} src={Mysubject} alt='my_subject' />
        My subjects
      </h4>
    </div>
  </div>
);

export default Mysubjectstop;
