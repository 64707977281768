export const START_ASSIGNMENT = 'START_ASSIGNMENT';
export const UPDATE_START_ASSIGNMENT = 'UPDATE_START_ASSIGNMENT';
export const FINISH_ASSIGNMENT = 'FINISH_ASSIGNMENT';
export const UPDATE_FINISH_ASSIGNMENT = 'UPDATE_FINISH_ASSIGNMENT';

export const GET_QUESTION_IN_ASSIGNMENT = 'GET_QUESTION_IN_ASSIGNMENT';
export const UPDATE_GET_QUESTION_IN_ASSIGNMENT =
  'UPDATE_GET_QUESTION_IN_ASSIGNMENT';
export const ALL_QUESTION_ANSWER_ASSIGNMENT = 'ALL_QUESTION_ANSWER_ASSIGNMENT';
export const UPDATE_ALL_QUESTION_ANSWER_ASSIGNMENT =
  'UPDATE_ALL_QUESTION_ANSWER_ASSIGNMENT';
export const ADD_ALL_QUESTION_ANSWER_ASSIGNMENT =
  'ADD_ALL_QUESTION_ANSWER_ASSIGNMENT';
