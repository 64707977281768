import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { func, string, bool, object } from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

import Logo from '../../../../../assets/tempimages/Logo.svg';
import { eye, noteye, backArrow } from '../../../../../assets/icons/IconList';
import { login } from '../../../../../stores/Auth/AuthAction';
import Popup from '../Popup';

import { encodeString } from '../../../../../helpers/utility';
import SubjectsExpiredPopup from '../SubjectsExpired';

const ParentLogin = ({
  loginAction,
  loginMessage,
  isLoginLoading,
  userData,
}) => {
  const [emailval, setEmailval] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordval, setPasswordval] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showpass, setShowpass] = useState(false);
  const [modal, setModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // if (userData?.data?.userType === 'teacher') {
    //   return history.push('/teacher/classes');
    // }
    if (userData?.data?.userType === 'student') {
      history.push('/dashboard');
    }
    if (userData?.data?.userType === 'parent') {
      history.push('/dashboard');
    }
    // if (userData?.statusCode === 402) {
    //   setModal(!modal);
    // }
    // if (userData?.statusCode === 401) {
    //   setModal(!modal);
    // }
  }, [userData]);
  const handleEmailChange = (e) => {
    setEmailError('');
    setEmailval(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPasswordError('');
    setPasswordval(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (emailval === '') {
      setEmailError('Please enter your email or username');
    }

    if (passwordval === '') {
      setPasswordError('Please enter your password');
    }

    if (emailval !== '' && passwordval !== '') {
      const data = {
        userName: emailval,
        password: encodeString(passwordval),
        userType: 'parent',
      };
      loginAction(data);
    }
  };

  const [customErrMsg, setCustomErrMsg] = useState('');
  const [isFirstTimeRender, setisFirstTimeRender] = useState(true);

  // useEffect(() => {
  //   if (loginMessage === 'No data found') {
  //     setCustomErrMsg('Please wait for us to review your application');
  //   } else if (loginMessage === 'Invalid login credentials.') {
  //     setCustomErrMsg('Invalid login credentials.');
  //   }
  //   setCustomErrMsg;
  // }, [loginMessage]);

  useEffect(() => {
    if (isFirstTimeRender) {
      setisFirstTimeRender(false);
    } else {
      if (userData.statusCode !== 200) {
        if (
          userData.statusCode === 401 ||
          userData.statusCode === 404 ||
          userData.statusCode === 410
        ) {
          setCustomErrMsg(userData?.message);
        }
      }
    }
  }, [userData]);

  const handlePush = () => {
    history.push('/newregister');
  };

  return (
    <div className='login-page' key={'parent'}>
      {/* {userData?.statuscode === 402 && ( */}
      {/* <Popup
        title='Payment is not done,continue for paymet '
        setModal={setModal}
        modal={modal}
      /> */}

      <div className='login-main'>
        <div className='login-head text-center'>
          <Link to='/'>
            <img src={Logo} alt='logo' />
          </Link>
          <h3>Login as parent/student</h3>
          <p style={{ color: 'red' }}>{customErrMsg}</p>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor='email'>
            Email or Username*
            <input
              type='text'
              onChange={handleEmailChange}
              value={emailval}
              className={`${emailError && 'error'}`}
              placeholder='Enter your email or username'
            />
          </label>
          {emailError && (
            <p className='error-msg'>
              <span>
                <b>!</b>
              </span>
              {emailError}
            </p>
          )}
          <label htmlFor='email' className='password-label'>
            Password*
            <input
              type={`${showpass ? 'text' : 'password'}`}
              onChange={handlePasswordChange}
              value={passwordval}
              className={`${emailError && 'error'}`}
              placeholder='Enter your password'
            />
            <button type='button' onClick={() => setShowpass(!showpass)}>
              {showpass ? eye : noteye}
            </button>
          </label>
          {passwordError && (
            <p className='error-msg'>
              <span>
                <b>!</b>
              </span>
              {passwordError}
            </p>
          )}
          <div className='row remember-forgot'>
            <div className='col-sm-6 col-xs-12'>
              {/* <label htmlFor='remember'>
                    Remember me
                    <input type='checkbox' id='remember' />
                    <span className='checkmark' />
                  </label> */}
            </div>
            <div className='col-sm-6 col-xs-12 text-right'>
              <span className='green'>
                <Link to='/forgotPassword' className='green'>
                  Forgot password?
                </Link>
              </span>
            </div>
          </div>
          <div className='text-center'>
            <button type='submit' value='Login' className='submit-btn'>
              {' '}
              {isLoginLoading ? (
                <Spinner animation='border' variant='light' />
              ) : (
                'Login'
              )}{' '}
              {/* <span>{chevRight}</span> */}
            </button>
            <div>
              <p className='not-register '>
                <Link to='/login' className='green'>
                  <span style={{ marginRight: '4px' }}>{backArrow}</span>
                  <span>Back</span>
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginMessage: state.Auth.Login.message,
  userData: state.Auth.Login,
  isLoginLoading: state.Auth.isLoginLoading,
});

const mapDispatchToProps = (dispatch) => ({
  loginAction: (data) => dispatch(login(data)),
});

ParentLogin.defaultProps = {
  loginMessage: '',
};

ParentLogin.propTypes = {
  loginAction: func.isRequired,
  loginMessage: string,
  isLoginLoading: bool.isRequired,
  userData: object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentLogin);
