import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { func, string } from 'prop-types';
import close from '../../../../../assets/images/close.png';
import './MyAssignment.scss';
import { intelliReport } from '../../../../../services/studentAssignment';
import { useHistory } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import MyexamAnswer from '../MyexamAnswer';
import { WaitingLoader } from '../../../waitingLoader';

const studentData = JSON.parse(localStorage.getItem('taut-student'));
const IntelliScorePopUp = ({
  setFinishTest,
  startReport,
  myReportData,
  myReport,
  clearMyReport,
  setLoading,
  isLoading,
}) => {
  const history = useHistory();

  const [exam, setExam] = useState(false);
  const [score, setScore] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    getIntelliReport();
    clearMyReport();
  }, [startReport]);

  const showreport = () => {
    history.push({
      pathname: '/dashboard/assignments',
      search: '?assignid&&viewrecord=true',
    });
    setExam(!exam);
  };
  const closepopup = () => {
    setFinishTest('step1');
    history.push('/dashboard/assignments');
    setScore('');
    setMessage('');
  };

  const getIntelliReport = async () => {
    setLoading(true);
    try {
      const res = await intelliReport({ assignment: startReport });
      if (res?.status === 200) {
        setScore({ ...res?.data?.data?.assignmentInfo?.[0] });
        let negativeMsg = [
          `this is surprising. You're capable of doing way better than this. Let's focus on conceptual foundations`,
          ` you can easily improve the results with focused efforts and a li'l more revision.`,
          `to get a satisfactory score, we recommend revising the basics and practicing more for a better understanding and clarity of the concepts.`,
        ];
        let normalMsg = [
          ` willpower & conscientious efforts can make us excel in academics & life. You can get an outstanding score with more revision, and practice.`,
          `you're almost there. For significant improvement in the score — focus a little more, revise the basics, and practice a lot. Reach out to your faculty in case you need assistance.`,
        ];
        let positiveMsg = [
          `Admirable. There is scope for improvement. Keep making strides to unlock your full potential.`,
          `Fascinating. Are you on a mission to make us a fan of your smart work?`,
        ];
        let highyPositiveMsg = [
          `Impressive!! This is absolutely brilliant. Keep up the good work.`,
          `Wow!! Your zeal & passion for learning is damn infectious. Keep thriving.`,
          `Let's sharpen our focus.`,
        ];
        let val = '';
        val = res?.data?.data?.assignmentInfo[0];
        setLoading(false);

        if (val?.accuracy < 25) {
          setMessage(
            negativeMsg[Math.floor(Math.random() * negativeMsg.length)]
          );
        } else if (val?.accuracy > 25 && val?.accuracy < 50) {
          setMessage(normalMsg[Math.floor(Math.random() * normalMsg.length)]);
        } else if (val?.accuracy > 50 && val?.accuracy < 75) {
          setMessage(
            positiveMsg[Math.floor(Math.random() * positiveMsg.length)]
          );
        } else {
          setMessage(
            highyPositiveMsg[
              Math.floor(Math.random() * highyPositiveMsg.length)
            ]
          );
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return isLoading ? (
    <WaitingLoader />
  ) : (
    <div className='congrats-popup-main'>
      <div className='right-answer-container right-answer-container-new'>
        <div className='close-top'>
          <button type='button' className='close-btn' onClick={closepopup}>
            <img src={close} alt='close' />
          </button>
        </div>
        <div className='right-answer-modal right-answer-modal-new'>
          <div className='col-sm-12 progressWithContent'>
            <div className='col-sm-3 floatLetft'>
              <div className='progressBarAssign'>
                <CircularProgressbar
                  value={score.accuracy}
                  text={score.accuracy}
                  styles={buildStyles({
                    textColor: '#FFFFFF',
                    trailColor: 'rgb(162 177 242)',
                  })}
                />
              </div>
            </div>
            <div className='col-sm-9 floatLetft'>
              <h3 className='intelliScoreHead'>
                Your score is {score.accuracy} out of 100
              </h3>
              <p className='intelliScoreSub'>
                {studentData?.data?.studentName}! {message}
              </p>
            </div>
          </div>
          <h2 className='glimpseTake'>Take a glimpse at your preparation</h2>
          <div className='col-sm-12' style={{ marginTop: '25px' }}>
            <div className='assignLayout'>
              <p className='total-number-of-ques' style={{ color: '#20282A' }}>
                Total number of questions
              </p>
              <p
                className='total-number-of-ques-numbers'
                style={{ color: '#20282A' }}
              >
                {score.totalQuestions}
              </p>
            </div>
            <div className='assignLayout'>
              <p className='total-number-of-ques' style={{ color: '#43AA8B' }}>
                Correct answers
              </p>
              <p
                className='total-number-of-ques-numbers'
                style={{ color: '#43AA8B' }}
              >
                {score.rightAnswers}
              </p>
            </div>
            <div className='assignLayout seperateBorder'>
              <p className='total-number-of-ques' style={{ color: '#FCA834' }}>
                Wrong answers
              </p>
              <p
                className='total-number-of-ques-numbers'
                style={{ color: '#FCA834' }}
              >
                {score.totalQuestions - score.rightAnswers}
              </p>
            </div>
          </div>

          <div className='col-sm-12 rightAnsAvgSpeed'>
            <div className='col-sm-6 floatLetft borderRight'>
              <p className='right-answers'>Right answers</p>
              <p className='right-ans-marks'>
                {score.rightAnswers} out of {score.totalQuestions}
              </p>
            </div>
            <div className='col-sm-6 floatLetft'>
              <p className='right-answers'>Average speed</p>
              <p className='right-ans-marks'>
                {(score.timeTakenInSecs / score.totalQuestions).toFixed(2)} sec
                / question
              </p>
            </div>
          </div>
          <button
            type='button'
            className='assignViewReport'
            onClick={showreport}
          >
            View Report
          </button>
        </div>
      </div>
      {exam && (
        <div className='dashboard-main'>
          <div className='row-main'>
            <MyexamAnswer
              setExam={setExam}
              prevExamId={startReport}
              myReportData={myReportData}
              myReport={myReport}
              clearMyReport={clearMyReport}
              type={'assignment'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

IntelliScorePopUp.propTypes = {
  setFinishTest: func.isRequired,
  startReport: string.isRequired,
};

export default IntelliScorePopUp;
