const Mypaymentsstudentdata = [
  {
    id: 1,
    id_number: 'CT - 19001',
    name: 'jonson p',
    subjects: 'maths',
    grade: 'Grade 1',
    status: 'inactive',
    view: 'Details',
  },
  {
    id: 2,
    id_number: 'CT - 19002',
    name: 'rajkumar',
    subjects: 'maths, english',
    grade: 'Grade 2',
    status: 'active',
    view: 'Details',
  },
  {
    id: 3,
    id_number: 'CT - 19003',
    name: 'anitha',
    subjects: 'maths',
    grade: 'Grade 3',
    status: 'active',
    view: 'Details',
  },
  {
    id: 4,
    id_number: 'CT - 19004',
    name: 'watson',
    subjects: 'Physics',
    grade: 'Grade 2',
    status: 'inactive',
    view: 'Details',
  },
];

export default Mypaymentsstudentdata;
