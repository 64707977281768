import React, { useEffect, useState } from 'react';
import { findKeyByValue } from '../../BrainGym/QueAnswer/questionHelper';
import { renderText } from '../../BrainGym/QueAnswer/textHelper';
import { arrayareEqual } from '../../../../../../helpers/utility';
import { FailureAudio, SuccessAudio } from '../../../../audioSignal';
import ReactHtmlParser from 'react-html-parser';
import { renderTextNew } from '../../BrainGym/QueAnswer/newTextHelper';

const DragAndDropSolutionPreview = ({ questionInChest, statusVal }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 1200) setIsMobile(true);
    // console.log('window.innerWidth', window.innerWidth, isMobile);
  }, [window.innerWidth]);

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div>
            <div className='question-box custom-question-box'>
              <h4 className='d-flex'>
                {statusVal?.questionInfo?.title == '' ||
                statusVal?.questionInfo?.title == null
                  ? findKeyByValue(statusVal?.questionInfo?.solutionType)
                  : renderText(statusVal?.questionInfo?.title)}
              </h4>
              <h2>
                {' '}
                <span>{renderText(statusVal.questionInfo.description)}</span>
              </h2>
            </div>
          </div>

          {!arrayareEqual(
            statusVal.questionInfo.solutionIndex,
            statusVal.result.solutionIndex
          ) ? (
            <>
              <span className='answer-title customAnswerTitle answer-wrong pb-2'>
                Your below answer is incorrect
              </span>
              <div className='question-box custom-question-box p-0'>
                {/* {play && <FailureAudio />} */}
                {/* <div className='row'>
                  <div className='col-12'>
                    <div className='question-box custom-question-box'>
                      <div className='col-sm-12'> */}
                {questionInChest?.questionOrientaion === 'horizontal' &&
                  !isMobile && (
                    <div className='preview-table-main'>
                      <table className='drag-detail-table horizontal'>
                        <thead>
                          <th>Statement</th>
                          {questionInChest?.statement?.length > 0 &&
                            questionInChest?.statement?.map((option, key) => (
                              <td>
                                <div className='horizontal-cell answered-section'>
                                  <span className='opti'>
                                    {String.fromCharCode(key + 65)}.
                                  </span>
                                  {option?.text && (
                                    <span className='vertical-text'>
                                      {option?.text}
                                    </span>
                                  )}
                                  {option?.image !== '' && (
                                    <img
                                      className='vertical-text'
                                      src={option?.image}
                                      alt='option_image'
                                    />
                                  )}
                                </div>
                              </td>
                            ))}
                        </thead>
                        <tbody>
                          <tr>
                            <th>Options</th>
                            {questionInChest?.statement?.length > 0 &&
                              questionInChest?.statement?.map((option, key) => (
                                <td>
                                  <div className='horizontal-cell answered-section'>
                                    <span className='opti'>{key + 1}.</span>
                                    <span className='vertical-text'>
                                      {
                                        questionInChest?.options[
                                          statusVal?.result?.solutionIndex[key]
                                        ]?.text
                                      }
                                      {questionInChest?.options[
                                        statusVal?.result?.solutionIndex[key]
                                      ]?.image && (
                                        <img
                                          className='vertical-text'
                                          src={
                                            questionInChest?.options[
                                              statusVal?.result?.solutionIndex[
                                                key
                                              ]
                                            ]?.image
                                          }
                                        />
                                      )}
                                    </span>
                                  </div>
                                </td>
                              ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                {(questionInChest?.questionOrientaion === 'vertical' ||
                  isMobile) && (
                  <table className='drag-detail-table vertical'>
                    <thead>
                      <th>Statement</th>
                      <th>Options</th>
                    </thead>
                    <tbody>
                      {questionInChest?.statement?.length > 0 &&
                        questionInChest?.statement?.map((option, key) => (
                          <tr>
                            <td className='left-part'>
                              <div
                                className='vertical-cell answered-section'
                                // style={{ padding: '2rem' }}
                              >
                                <span className='opti'>
                                  {String.fromCharCode(key + 65)}.
                                </span>
                                {option?.text && (
                                  <span className='vertical-text'>
                                    {option?.text}
                                  </span>
                                )}
                                {option?.image !== '' && (
                                  <img
                                    src={option?.image}
                                    className='vertical-text'
                                    alt='option_image'
                                  />
                                )}
                              </div>
                            </td>
                            <td className='right-part'>
                              <div className='vertical-cell answered-section'>
                                <span className='opti'>{key + 1}. </span>
                                <span className='right-part-val vertical-text'>
                                  <span className='right-part-val-common'>
                                    {questionInChest?.options[
                                      statusVal?.result?.solutionIndex[key]
                                    ]?.image && (
                                      <img
                                        src={
                                          questionInChest?.options[
                                            statusVal?.result?.solutionIndex[
                                              key
                                            ]
                                          ]?.image
                                        }
                                        alt='option_image'
                                      />
                                    )}
                                    <div>
                                      {
                                        questionInChest?.options[
                                          statusVal?.result?.solutionIndex[key]
                                        ]?.text
                                      }{' '}
                                    </div>
                                  </span>
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                {/* </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </>
          ) : (
            <>
              {/* {play && <SuccessAudio />} */}
              <span className='answer-title answer-correct pb-2'>
                Your answer is correct
              </span>
              <div className='question-box custom-question-box p-0'>
                <div className='col-sm-12'>
                  {questionInChest?.questionOrientaion === 'horizontal' &&
                    !isMobile && (
                      <div className='preview-table-main'>
                        <table className='drag-detail-table horizontal'>
                          <thead>
                            <th>Statement</th>
                            {questionInChest?.statement?.length > 0 &&
                              questionInChest?.statement?.map((option, key) => (
                                <td>
                                  <div className='horizontal-cell answered-section'>
                                    <span className='opti'>
                                      {String.fromCharCode(key + 65)}.
                                    </span>
                                    {option?.text && (
                                      <span className='vertical-text'>
                                        {option?.text}
                                      </span>
                                    )}
                                    {option?.image !== '' && (
                                      <img
                                        className='vertical-text'
                                        src={option?.image}
                                        alt='option_image'
                                      />
                                    )}
                                  </div>
                                </td>
                              ))}
                          </thead>
                          <tbody>
                            <tr>
                              <th>Options</th>
                              {questionInChest?.statement?.length > 0 &&
                                questionInChest?.statement?.map(
                                  (option, key) => (
                                    <td>
                                      <div className='horizontal-cell answered-section'>
                                        <span className='opti'>
                                          {key + 1}.{' '}
                                        </span>
                                        <span className='vertical-text'>
                                          {renderText(
                                            questionInChest?.options?.filter(
                                              (op) => op?.index == option?.index
                                            )?.[0]?.text
                                          )}
                                          {questionInChest?.options?.filter(
                                            (op) => op?.index == option?.index
                                          )?.[0]?.image && (
                                            <img
                                              className='vertical-text'
                                              src={
                                                questionInChest?.options?.filter(
                                                  (op) =>
                                                    op?.index == option?.index
                                                )?.[0]?.image
                                              }
                                            />
                                          )}
                                        </span>
                                      </div>
                                    </td>
                                  )
                                )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  {(questionInChest?.questionOrientaion === 'vertical' ||
                    isMobile) && (
                    <table className='drag-detail-table vertical'>
                      <thead>
                        <th>Statement</th>
                        <th>Options</th>
                      </thead>
                      <tbody>
                        {questionInChest?.statement?.length > 0 &&
                          questionInChest?.statement?.map((option, key) => (
                            <tr>
                              <td className='left-part'>
                                <div className='vertical-cell'>
                                  <span className='opti'>
                                    {String.fromCharCode(key + 65)}.
                                  </span>
                                  {option?.text && (
                                    <span className='vertical-text'>
                                      {option?.text}
                                    </span>
                                  )}
                                  {option?.image !== '' && (
                                    <img
                                      src={option?.image}
                                      alt='option_image'
                                    />
                                  )}
                                </div>
                              </td>
                              <td className='right-part'>
                                <div className='vertical-cell'>
                                  <span className='opti'>{key + 1}. </span>
                                  <span className='right-part-val'>
                                    <span className='right-part-val-common'>
                                      {questionInChest?.options?.filter(
                                        (op) => op?.index == option?.index
                                      )?.[0]?.image && (
                                        <img
                                          src={
                                            questionInChest?.options?.filter(
                                              (op) => op?.index == option?.index
                                            )?.[0]?.image
                                          }
                                          alt='option_image'
                                        />
                                      )}
                                      <div>
                                        {renderText(
                                          questionInChest?.options?.filter(
                                            (op) => op?.index == option?.index
                                          )?.[0]?.text
                                        )}
                                      </div>
                                    </span>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {!arrayareEqual(
        statusVal.questionInfo.solutionIndex,
        statusVal.result.solutionIndex
      ) ? (
        <>
          <span className='answer-correct answer-title pb-2'>
            Correct Answer
          </span>
          <div className='col-sm-12 question-box custom-question-box p-0'>
            {questionInChest?.questionOrientaion === 'horizontal' &&
              !isMobile && (
                <div className='preview-table-main'>
                  <table className='drag-detail-table horizontal'>
                    <thead>
                      <th>Statement</th>
                      {questionInChest?.statement?.length > 0 &&
                        questionInChest?.statement?.map((option, key) => (
                          <td>
                            <div className='horizontal-cell answered-section'>
                              <span className='opti'>
                                {String.fromCharCode(key + 65)}.
                              </span>
                              {option?.text && (
                                <span className='vertical-text'>
                                  {option?.text}
                                </span>
                              )}
                              {option?.image !== '' && (
                                <img
                                  className='vertical-text'
                                  src={option?.image}
                                  alt='option_image'
                                />
                              )}
                            </div>
                          </td>
                        ))}
                    </thead>
                    <tbody>
                      <tr>
                        <th>Options</th>
                        {questionInChest?.statement?.length > 0 &&
                          questionInChest?.statement?.map((option, key) => (
                            <td>
                              <div className='horizontal-cell answered-section'>
                                <span className='opti'>{key + 1}.</span>
                                <span className='vertical-text'>
                                  {renderText(
                                    questionInChest?.options?.filter(
                                      (op) => op?.index == option?.index
                                    )?.[0]?.text
                                  )}
                                  {questionInChest?.options?.filter(
                                    (op) => op?.index == option?.index
                                  )?.[0]?.image && (
                                    <img
                                      src={
                                        questionInChest?.options?.filter(
                                          (op) => op?.index == option?.index
                                        )?.[0]?.image
                                      }
                                    />
                                  )}
                                </span>
                              </div>
                            </td>
                          ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            {(questionInChest?.questionOrientaion === 'vertical' ||
              isMobile) && (
              <table className='drag-detail-table vertical'>
                <thead>
                  <th>Statement</th>
                  <th>Options</th>
                </thead>
                <tbody>
                  {questionInChest?.statement?.length > 0 &&
                    questionInChest?.statement?.map((option, key) => (
                      <tr>
                        <td className='left-part'>
                          <div
                            className='vertical-cell answered-section'
                            // style={{ padding: '2rem' }}
                          >
                            <span className='opti'>
                              {String.fromCharCode(key + 65)}.
                            </span>
                            {option?.text && (
                              <span className='vertical-text'>
                                {option?.text}
                              </span>
                            )}
                            {option?.image !== '' && (
                              <img src={option?.image} alt='option_image' />
                            )}
                          </div>
                        </td>
                        <td className='right-part'>
                          <div className='vertical-cell answered-section'>
                            <span className='opti'>{key + 1}. </span>
                            <span className='right-part-val right-part-val-common vertical-text'>
                              {questionInChest?.options?.filter(
                                (op) => op?.index == option?.index
                              )?.[0]?.image && (
                                <img
                                  className='vertical-text'
                                  src={
                                    questionInChest?.options?.filter(
                                      (op) => op?.index == option?.index
                                    )?.[0]?.image
                                  }
                                  alt='option_image'
                                />
                              )}
                              <div>
                                {renderText(
                                  questionInChest?.options?.filter(
                                    (op) => op?.index == option?.index
                                  )?.[0]?.text
                                )}
                              </div>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      ) : (
        ''
      )}
      {statusVal.questionInfo.solutionDescription ? (
        <div className='option-wrapper-solution mb-4 mt-0'>
          <h4>Solution Description</h4>
          <div>{renderTextNew(statusVal.questionInfo.solutionDescription)}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default DragAndDropSolutionPreview;
