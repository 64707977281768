import * as actionTypes from './StudentDataTypes';

const initialState = {
  studentClasses: [],
  studentUpcomingClassData: [],
  studentCoCurricularClasses: [],
  studentClassesHistory: [],
  studentPreviousClassData: [],
  studentCoCurricularClassesHistory: [],
  studentList: [],
  studentCoCurricularUpcomingClasses: [],
  studentCoCurricularPreviousClassesHistory: [],
  studentListCoCurricular: [],
  studentsEMI: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_GET_STUDENT_CLASSES:
      return {
        ...state,
        studentUpcomingClassData: action.payload,
        studentClasses: [...action.payload.data[0].response],
      };

    case actionTypes.UPDATE_GET_STUDENT_CLASSES_HISTORY:
      return {
        ...state,
        studentClassesHistory: [...action.payload.data[0].response],
        studentPreviousClassData: action.payload,
      };

    case actionTypes.UPDATE_GET_STUDENT_COCURRICULAR_CLASSES_HISTORY:
      return {
        ...state,
        studentCoCurricularClassesHistory: [...action.payload?.data[0]?.response],
        studentCoCurricularPreviousClassesHistory: action.payload,
      };

    case actionTypes.UPDATE_GET_STUDENT_COCURRICULAR_CLASSES:
      return {
        ...state,
        studentCoCurricularClasses: [...action?.payload?.data[0]?.response],
        studentCoCurricularUpcomingClasses: action?.payload,
      };
    case actionTypes.GET_STUDENT_LIST:
      return {
        ...state,
      };
    case actionTypes.GET_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        studentList: action.payload,
      };
    case actionTypes.GET_STUDENT_LIST_ERROR:
      return {
        ...state,
      };
    case actionTypes.GET_STUDENT_LIST_COCURRICULAR:
      return {
        ...state,
      };
    case actionTypes.GET_STUDENT_LIST_SUCCESS_COCURRICULAR:
      return {
        ...state,
        studentListCoCurricular: action.payload,
      };
    case actionTypes.GET_STUDENT_LIST_ERROR_COCURRICULAR:
      return {
        ...state,
      };
    case actionTypes.UPDATE_STUDENTS_EMI_DETAILS_BY_EMI_ID:
      return {
        ...state,
        studentsEMI: action.payload,
      };
    default:
      return { ...state };
  }
}
