import config from '../helpers/config';
import { fetchWrapper } from '../helpers/fetch-wrapper';

const baseUrl = `${config.apiBaseUrl}`;

function studentDashboard(params, token) {
  return fetchWrapper.get(
    `${baseUrl}/students/dashboard?student=${params?.student}`,
    token
  );
}
function myReportAnalytics(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/my-report-analytics`,
    params,
    token
  );
}
function cumulativeReport(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/cumulative-report`,
    params,
    token
  );
}
function leaderboardByAccuracy(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/leaderboard-by-accuracy`,
    params,
    token
  );
}
function leaderboardBySpeedAndAccuracy(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/exam-results/leaderboard-by-speed-and-accuracy`,
    params,
    token
  );
}

function leaderboardBySpeedAndAccuracyOlympiad(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/leaderboard-by-speed-and-accuracy`,
    params,
    token
  );
}

function leaderboardByAccuracyOlympiad(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/olympiad-exams-results/leaderboard-by-accuracy`,
    params,
    token
  );
}

function brainGymSetTime(params, token) {
  return fetchWrapper.post(
    `${baseUrl}/brainGym/update-start-time`,
    params,
    token
  );
}

export const studentDashboardServices = {
  studentDashboard,
  myReportAnalytics,
  cumulativeReport,
  leaderboardByAccuracy,
  leaderboardByAccuracyOlympiad,
  leaderboardBySpeedAndAccuracy,
  leaderboardBySpeedAndAccuracyOlympiad,
  brainGymSetTime,
};

export default {};
