import React, { Suspense, lazy } from 'react';
import RouteEnum from './constants/RouteEnum';
const Home = lazy(() => import('./views/pages/home/Home'));
const Subject = lazy(() => import('./views/pages/subject/Subject'));
const Grades = lazy(() => import('./views/pages/grades/Grades'));
const Register = lazy(() => import('./views/components/auth/register/Index'));
import Chapters from './views/pages/Chapters';
import PracticeTest from './views/pages/PracticeTest/PracticeTest';
const Dashboard = lazy(() => import('./views/components/dashboard/Dashboard'));
import UserTypeSelection from './views/components/newRegistration/UserTypeSelection';
// import Olympiad from './views/pages/Olympiad/Olympiad';
import Login from './views/components/auth/login/Login';
const ForgotPassword = lazy(() =>
  import('./views/components/auth/ForgotPassword/ForgotPassword')
);
const Homepage = lazy(() =>
  import('./views/components/skeleton/Homepage/Homepage')
);
import Teachers from './views/pages/Teachers/Teachers';
import ExamDetails from './views/pages/Teachers/ExamDetails';
// import ZoomClassContainer from './views/pages/Teachers/Rightsidepart/MyClass/ZoomClassContainer';
const Olympiad = lazy(() => import('./views/pages/Olympiad/Olympiad'));
// const ZoomClassCoCurricular = lazy(() =>
//   import('./views/pages/Teachers/Rightsidepart/CoCurricular/ZoomContainer')
// );
// const ZoomClassStudent = lazy(() =>
//   import('./views/components/dashboard/Rightsidepart/MyClass/ZoomClassStudent')
// );
// import ZoomClassStudentCoCurricular from './views/components/dashboard/Rightsidepart/CoCurricular/ZoomClassStudentCoCurricular';

const studentPayment = lazy(() =>
  import('./views/components/auth/register/PayFeesStudentParent')
);
import PracticeTestQuestions from './views/components/dashboard/Rightsidepart/PracticeTest/PracticeTestQuestions';
// import HelpDesk from './views/pages/HelpDesk';
// import Complaint from './views/pages/HelpDesk/complaint';
const MyProfile = lazy(() =>
  import('./views/components/dashboard/Rightsidepart/MyProfile/MyProfile')
);

import ForgotPasswordOtp from './views/components/auth/ForgotPassword/ForgotPasswordOtp';
const MyExamSection = lazy(() =>
  import('./views/components/dashboard/Rightsidepart/MyExam/MyExamSection')
);
const MyOlympiadExamSection = lazy(() =>
  import(
    './views/components/dashboard/Rightsidepart/Olympiad/MyOlympiadExamSection'
  )
);
import MyAssignmentExamSection from './views/components/dashboard/Rightsidepart/MyAssignment/MyAssignmentExamSection';

const Termsacon = lazy(() =>
  import('./views/components/termsandpolicy/Termsacon')
);
import PrivacyPolicy from './views/components/termsandpolicy/PrivacyPolicy';
import PracticeWithoutLogin from './views/components/dashboard/Rightsidepart/PracticeTest/PracticeWithoutLogin';
const Aboutus = lazy(() => import('./views/pages/aboutus/Aboutus'));
const Schools = lazy(() => import(`./views/pages/schools/header`));
const Contactus = lazy(() => import('./views/pages/contactus/Contactus'));
const ParentAboutus = lazy(() =>
  import('./views/pages/parentaboutus/ParentAboutus')
);
const TeacherAboutUs = lazy(() =>
  import('./views/pages/teacherMain/TeacherAboutus')
);
import Faqs from './views/pages/faqs/Faqs';
const ForgotPasswordEmail = lazy(() =>
  import('./views/components/auth/ForgotPassword/ForgotPasswordEmail')
);
const SetNewPassword = lazy(() =>
  import('./views/components/auth/ForgotPassword/SetNewPassword')
);
import ParentDetails from './views/components/newRegistration/ParentDetails';
// import newregistration from './views/components/newRegistration/newregistration';
const Newregistration = lazy(() =>
  import('./views/components/newRegistration/Newregistration')
);
// import StudentCourseDetail from './views/components/newRegistration/StudentCourseDetail';
const Blogs = lazy(() => import('./views/pages/Blogs/Blogs'));
const FullBlogContent = lazy(() => import('./views/pages/Blogs/FullBlogPage'));
const OlympiadPracticeTest = lazy(() =>
  import(
    './views/components/dashboard/Rightsidepart/PracticeTest/OlympiadPracticeTest'
  )
);

const BookDemo = lazy(() => import('./views/pages/BookDemo'));
const SubDetails = lazy(()=>import ('./views/components/paymentPage/payement'))
const routes = [
  {
    key: 13,
    path: RouteEnum.EXAMDETAILS,
    component: ExamDetails,
  },
  {
    key: 1,
    path: RouteEnum.HOME,
    component: Home,
  },
  {
    key: 2,
    path: RouteEnum.SUBJECT,
    component: Subject,
  },
  {
    key: 3,
    path: RouteEnum.GRADES,
    component: Grades,
  },
  {
    key: 4,
    path: RouteEnum.CHAPTERS,
    component: Chapters,
  },
  {
    key: 5,
    path: RouteEnum.PRACTICE_TEST,
    component: PracticeTest,
  },
  {
    key: 6,
    path: RouteEnum.DASHBOARDHOME,
    component: Dashboard,
  },
  {
    key: 7,
    path: RouteEnum.DASHBOARD,
    component: Dashboard,
  },

  {
    key: 8,
    path: RouteEnum.REGISTER,
    component: Register,
  },
  {
    key: 50,
    path: RouteEnum.NEWREGISTERHOME,
    component: Newregistration,
  },
  {
    key: 51,
    path: RouteEnum.NEWREGISTER,
    component: Newregistration,
  },
  {
    key: 52,
    path: RouteEnum.BLOGS,
    component: Blogs,
  },
  {
    key: 53,
    path: RouteEnum.FULLBLOGCONTENT,
    component: FullBlogContent,
  },
  // {
  //   key: 52,
  //   path: RouteEnum.NEWEXP,
  //   component: StudentCourseDetail,
  // },

  // {
  //   KEY: 8,
  //   path: RouteEnum.USERTYPE,
  //   component: UserTypeSelection,
  // },
  // {
  //   KEY: 8,
  //   path: RouteEnum.PARENTDETAILS,
  //   component: ParentDetails,
  // },

  {
    key: 9,
    path: RouteEnum.OLYMPIAD,
    component: Olympiad,
  },
  {
    key: 10,
    path: RouteEnum.LOGIN,
    component: Login,
  },
  {
    key: 11,
    path: RouteEnum.HOMESKELETON,
    component: Homepage,
  },
  {
    key: 6,
    path: RouteEnum.TEACHERSHOME,
    component: Teachers,
  },
  {
    key: 12,
    path: RouteEnum.TEACHERS,
    component: Teachers,
  },
  // {
  //   key: 14,
  //   path: RouteEnum.ZOOMCALL,
  //   component: ZoomClassContainer,
  // },
  // {
  //   key: 14,
  //   path: RouteEnum.ZOOMCALLCOCURRICULAR,
  //   component: ZoomClassCoCurricular,
  // },
  {
    key: 15,
    path: RouteEnum.PRACTICETEST_STUDENT,
    component: PracticeTestQuestions,
  },
  // {
  //   key: 30,
  //   path: RouteEnum.ZOOMCALLSTUDENT,
  //   component: ZoomClassStudent,
  // },
  // {
  //   key: 31,
  //   path: RouteEnum.ZOOMCALLSTUDENTCOCURRICULAR,
  //   component: ZoomClassStudentCoCurricular,
  // },
  {
    key: 32,
    path: RouteEnum.ForgotPassword,
    component: ForgotPassword,
  },
  // {
  //   key: 33,
  //   path: RouteEnum.myProfile,
  //   component: MyProfile,
  // },
  {
    key: 34,
    path: RouteEnum.PAYMENT,
    component: studentPayment,
  },
  {
    key: 35,
    path: RouteEnum.FORGOTPASSWORDOTP,
    component: ForgotPasswordOtp,
  },
  {
    key: 36,
    path: RouteEnum.EXAMSECTION,
    component: MyExamSection,
  },
  {
    key: 37,
    path: RouteEnum.OLYMPIADEXAMSECTION,
    component: MyOlympiadExamSection,
  },
  {
    key: 37,
    path: RouteEnum.ASSIGNMENTEXAMSECTION,
    component: MyAssignmentExamSection,
  },

  {
    key: 38,
    path: RouteEnum.TERMSANDCONDITION,
    component: Termsacon,
  },
  // {
  //   key: 39,
  //   path: RouteEnum.PRIVACYPOLICY,
  //   component: PrivacyPolicy,
  // },
  {
    key: 39,
    path: RouteEnum.PRACTICE_WITHOUT_LOGIN,
    component: PracticeWithoutLogin,
  },
  {
    key: 40,
    path: RouteEnum.ABOUTUS,
    component: Aboutus,
  },
  {
    key: 41,
    path: RouteEnum.CONTACTUS,
    component: Contactus,
  },
  {
    key: 42,
    path: RouteEnum.FAQS,
    component: Faqs,
  },
  {
    key: 43,
    path: RouteEnum.PARENTABOUTUS,
    component: ParentAboutus,
  },
  {
    key: 43,
    path: RouteEnum.TEACHERABOUTUS,
    component: TeacherAboutUs,
  },
  {
    key: 44,
    path: RouteEnum.ForgotPasswordConfirmation,
    component: ForgotPasswordEmail,
  },
  {
    key: 45,
    path: RouteEnum.SetNewPassword,
    component: SetNewPassword,
  },
  {
    key: 46,
    path: RouteEnum.PRACTICE_TEST_OLYMPIAD,
    component: OlympiadPracticeTest,
  },
  {
    key: 47,
    path: RouteEnum.BOOK_DEMO,
    component: BookDemo,
  },
  {
    key: 48,
    path: RouteEnum.SCHOOLS,
    component: Schools,
  },
  {
    key: 49,
    path: RouteEnum.PAYMENTD,
    component: SubDetails,
  },
 
];

export default routes;
