import * as actionTypes from './TeacherDashboardTypes';

const initialState = {
  myLeavesList: [],
  applyLeave: [],
  editLeave: [],
  cancelLeave: [],
  getProfile: [],
  changeName: [],
  changePassword: [],
  changePhoneNumber: [],
  changeQualification: [],
  changeUniversity: [],
  myClasses: [],
  chapterBySubject: [],
  conceptByChapter: [],
  subconceptByConcept: [],
  myClassesHistory: [],
  teacherSlotsPerDateData: [],
  teacherSlotsCoCurricularPerDateData: [],
  rescheduleclassData: [],
  rescheduleCoCurricularClassData: [],
  teachingHistory: [],
  myCoCurricularClasses: null,
  updateProfilePic: [],
  sendPassword: [],
  getPaymentTeacher: [],
  updatePassword: [],
  updatePhoneNumberSendOtpResponse: null,
  updatePhoneNumberVerifyOtpResponse: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_UPDATE_PASSWORD:
      return {
        ...state,
        updatePassword: action.payload,
      };

    case actionTypes.UPDATE_GET_MY_CLASSES:
      return {
        ...state,
        myClasses: action.payload,
      };

    case actionTypes.UPDATE_GET_MY_CO_CURRICULAR_CLASSES:
      return {
        ...state,
        myCoCurricularClasses: action.payload,
      };

    case actionTypes.UPDATE_MY_LEAVES_LIST:
      return {
        ...state,
        myLeavesList: action.payload,
      };

    case actionTypes.UPDATE_GET_MY_CLASSES_HISTORY:
      return {
        ...state,
        myClassesHistory: action.payload,
      };

    case actionTypes.UPDATE_GET_CHAPTER_BY_SUBJECT:
      return {
        ...state,
        chapterBySubject: action.payload,
      };

    case actionTypes.UPDATE_GET_CONCEPT_BY_CHAPTER:
      return {
        ...state,
        conceptByChapter: action.payload,
      };

    case actionTypes.UPDATE_GET_SUB_CONCEPTS:
      return {
        ...state,
        subconceptByConcept: action.payload,
      };
    case actionTypes.UPDATE_APPLY_LEAVE:
      return {
        ...state,
        applyLeave: action.payload,
      };
    case actionTypes.UPDATE_EDIT_LEAVE:
      return {
        ...state,
        editLeave: action.payload,
      };
    case actionTypes.UPDATE_CANCEL_LEAVE:
      return {
        ...state,
        cancelLeave: action.payload,
      };
    case actionTypes.UPDATE_GET_PROFILE:
      return {
        ...state,
        getProfile: action.payload,
      };
    case actionTypes.UPDATE_CHANGE_NAME:
      return {
        ...state,
        changeName: action.payload,
      };
    case actionTypes.UPDATE_CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: action.payload,
      };
    case actionTypes.UPDATE_CHANGE_PHONE_NUM:
      return {
        ...state,
        changePhoneNumber: action.payload,
      };
    case actionTypes.UPDATE_CHANGE_QUALIFICATION:
      return {
        ...state,
        changeQualification: action.payload,
      };
    case actionTypes.UPDATE_CHANGE_UNVIERSITY:
      return {
        ...state,
        changeUniversity: action.payload,
      };
    case actionTypes.UPDATE_TEACHER_SLOTS_PER_DATE:
      return {
        ...state,
        teacherSlotsPerDateData: action.payload,
      };
    case actionTypes.UPDATE_COCURRICULAR_TEACHER_SLOTS_PER_DATE:
      return {
        ...state,
        teacherSlotsCoCurricularPerDateData: action.payload,
      };
    case actionTypes.UPDATE_RESCHEDULE_COCURRICULAR_CLASS:
      return {
        ...state,
        rescheduleCoCurricularClassData: action.payload,
      };
    case actionTypes.UPDATE_RESCHEDULE_CLASS:
      return {
        ...state,
        rescheduleclassData: action.payload,
      };
    case actionTypes.UPDATE_ADD_HISTORY:
      return {
        ...state,
        teachingHistory: action.payload,
      };

    case actionTypes.UPDATE_SET_PROFILE_PIC:
      return {
        ...state,
        updateProfilePic: action.payload,
      };

    case actionTypes.UPDATE_SEND_PASSWORD_LINK:
      return {
        ...state,
        sendPassword: action.payload,
      };

    case actionTypes.UPDATE_GET_PAYMENT_HISTORY:
      return {
        ...state,
        getPaymentTeacher: action.payload,
      };
    // case actionTypes.UPDATE_PHONE_SEND_OTP:
    //   return {
    //     ...state,
    //     updatePhoneNumberSendOtpResponse: action.payload,
    //   };
    // case actionTypes.UPDATE_PHONE_VERIFY_OTP:
    //   return {
    //     ...state,
    //     updatePhoneNumberVerifyOtpResponse: action.payload,
    //   };
    default:
      return { ...state };
  }
}
