import React, { useEffect } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { chevRight, chevLeft } from '../../../../../../assets/icons/IconList';
import { renderText, readText } from '../../BrainGym/QueAnswer/textHelper';
import OlympiadQuesAnsWrapper from '../../Olympiad/OlympiadQuesAnsWrapper';
import { findKeyByValue } from '../QuestionTypes/questionHelper';
import DraggAndDropQuestion from '../QuestionTypes/DragAndDrop/DraggAndDropQuestion';
import MultiSelectQuestion from '../QuestionTypes/MultiSelect/MultiSelect';
import FillInTheBlanks from '../QuestionTypes/FillTheBlanks/FillInTheBlanks';
import FillInTheBlanksDragAndDrop from '../QuestionTypes/FillTheBlanksDragAndDrop/FillDragAndDrop';
import speaker from '../../../../../../assets/images/speaker.png';
import { startListening } from '../Exam/startLearning';
import bookmarkimg from '../../../../../../assets/images/bookmark.png';
import bookmarkfilledimg from '../../../../../../assets/images/bookmarkfilled2.png';

const ExamQuestionSection = ({
  updateQueAns,
  addQueAns,
  questionInExamData,
  selectedQuestion,
  setSelectedQuestion,
  selectedOption,
  setSelectedOption,
  allQuesAns,
  setEachTimeOn,
  eachcurrenttime,
  setEachtime,
  setBookmark,
  Bookmark,
}) => {
  const questionObject = questionInExamData[selectedQuestion];
  useEffect(() => {
    if (
      allQuesAns?.length > 0 &&
      allQuesAns[selectedQuestion]?.passageQuestions
    ) {
      setSelectedOption(allQuesAns[selectedQuestion]?.passageQuestions);
    } else if (
      questionType === 'fill-in-the-blanks' &&
      allQuesAns?.length > 0 &&
      allQuesAns[selectedQuestion]
    ) {
      setSelectedOption(allQuesAns[selectedQuestion]?.fillInTheBlankSolution);
    } else if (allQuesAns?.length > 0 && allQuesAns[selectedQuestion]) {
      setSelectedOption(allQuesAns[selectedQuestion]?.solutionIndex);
    } else {
      setSelectedOption([]);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (allQuesAns?.length > 0 && allQuesAns[selectedQuestion]) {
      const allQnA = allQuesAns;
      if (
        'passageQuestions' in allQuesAns?.[selectedQuestion] &&
        allQuesAns[selectedQuestion]?.passageQuestions?.length > 0
      ) {
        allQnA[selectedQuestion].passageQuestions =
          questionObject.passageQuestions.map((item) => ({
            question: item._id,
            solutionIndex: selectedOption?.filter(
              (i) => i.question === item._id
            )?.[0]?.solutionIndex || [-1],
          }));
        allQnA[selectedQuestion].timeTakenInSecs = eachcurrenttime;
      } else if (questionType === 'fill-in-the-blanks') {
        allQnA[selectedQuestion].fillInTheBlankSolution =
          selectedOption?.length > 0 ? selectedOption : [];
        allQnA[selectedQuestion].timeTakenInSecs = eachcurrenttime;
      } else if (questionType === 'drag-and-match-the-correct-answer') {
        var textSolution;
        var indexSolution;
        if (selectedOption?.length > 0 && !selectedOption.includes(-1)) {
          textSolution = selectedOption.map((item) => item.name);
          indexSolution = selectedOption.map((item) => item.index);
          allQnA[selectedQuestion].dragTheTextSolution = textSolution;
          allQnA[selectedQuestion].solutionIndex = indexSolution;
        } else {
          allQnA[selectedQuestion].solutionIndex = [-1];
          allQnA[selectedQuestion].dragTheTextSolution = [-1];
        }
      } else {
        allQnA[selectedQuestion].solutionIndex =
          selectedOption?.length > 0 ? [...selectedOption] : [-1];
        allQnA[selectedQuestion].timeTakenInSecs = eachcurrenttime;
      }

      updateQueAns(allQnA);
    }
  }, [selectedOption]);

  const handleNext = () => {
    if (selectedQuestion + 1 < questionInExamData?.length) {
      setSelectedQuestion(selectedQuestion + 1);
      setEachTimeOn(false);
      setEachtime(0);
    }
  };
  const handlePrev = () => {
    if (selectedQuestion > 0) {
      setSelectedQuestion(selectedQuestion - 1);
      setEachTimeOn(false);
      setEachtime(0);
    }
  };
  const addBookmark = (selectedque) => {
    const allQnA = allQuesAns;
    allQnA[selectedque].Bookmarked = !allQnA[selectedque].Bookmarked;
    setBookmark(!Bookmark);
    updateQueAns(allQnA);
  };
  const questionType = findKeyByValue(questionObject?.solutionType);
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          {questionType === 'fill-in-the-blanks' && (
            <FillInTheBlanks
              key={questionObject._id}
              questionInChest={questionObject}
              setSelect={setSelectedOption}
              selectedOption={selectedOption}
              selectedQuestion={selectedQuestion}
              addBookmark={addBookmark}
              Bookmark={Bookmark}
              allQuesAns={allQuesAns}
            />
          )}
          {questionType === 'drag-and-match-the-correct-answer' && (
            <FillInTheBlanksDragAndDrop
              key={questionObject._id}
              questionInChest={questionObject}
              setSelect={setSelectedOption}
              selectedOption={selectedOption}
              selectedQuestion={selectedQuestion}
              addBookmark={addBookmark}
              Bookmark={Bookmark}
              allQuesAns={allQuesAns}
            />
          )}
          {(questionType === 'match-the-following' ||
            questionType === 'drag-and-drop' ||
            questionType === 'select-the-word-to-match-the-correct-option') && (
            <DraggAndDropQuestion
              key={questionObject._id}
              questionInChest={questionObject}
              setSelect={setSelectedOption}
              selectedOption={selectedOption}
              selectedQuestion={selectedQuestion}
              addBookmark={addBookmark}
              Bookmark={Bookmark}
              allQuesAns={allQuesAns}
            />
          )}
          {questionType === 'multi' && (
            <MultiSelectQuestion
              key={questionObject._id}
              description={questionObject?.description}
              options={questionObject?.options}
              questionInExamData={questionInExamData}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setEachTimeOn={setEachTimeOn}
              eachcurrenttime={eachcurrenttime}
              setEachtime={setEachtime}
              showOptions={true}
              addBookmark={addBookmark}
              Bookmark={Bookmark}
              addQueAns={addQueAns}
              updateQueAns={updateQueAns}
              allQuesAns={allQuesAns}
            />
          )}
          {(questionType === 'choose-the-correct-answer' ||
            questionType === 'statement-and-support' ||
            questionType === 'two-columns-option' ||
            questionType === 'open-ended-questions' ||
            questionType === 'selecting-the-word-sentence' ||
            questionType === 'error-findings') && (
            <OlympiadQuesAnsWrapper
              key={questionObject._id}
              description={questionObject?.description}
              options={questionObject?.options}
              questionInExamData={questionInExamData}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setEachTimeOn={setEachTimeOn}
              eachcurrenttime={eachcurrenttime}
              setEachtime={setEachtime}
              showOptions={
                findKeyByValue(questionObject?.solutionType) !== 'passage'
              }
              addBookmark={addBookmark}
              Bookmark={Bookmark}
              addQueAns={addQueAns}
              updateQueAns={updateQueAns}
              allQuesAns={allQuesAns}
            />
          )}
          {findKeyByValue(questionObject?.solutionType) === 'passage' && (
            <div className='question-box mb-2'>
              <div className='d-flex justify-content-between'>
                <h4>
                  Read the below passage and answer the following questions.
                </h4>
                <span>
                  <button
                    type='button'
                    id={selectedQuestion}
                    onClick={() =>
                      startListening(
                        readText(
                          'Read the below passage and answer the following questions' +
                            questionObject.description
                        )
                      )
                    }
                  >
                    {' '}
                    <img
                      className='span-one speaker-btn'
                      src={speaker}
                      alt='speaker icon'
                    />
                  </button>
                  <button
                    className='text-right'
                    onClick={() => addBookmark(selectedQuestion)}
                    type='button'
                  >
                    <img
                      className={
                        allQuesAns[selectedQuestion]?.Bookmarked
                          ? 'bookmarkfilled-img'
                          : 'bookmark-img'
                      }
                      src={
                        allQuesAns[selectedQuestion]?.Bookmarked
                          ? bookmarkfilledimg
                          : bookmarkimg
                      }
                      alt='bookmark'
                    />
                  </button>
                </span>
              </div>
              <h2>
                <span>
                  {' '}
                  {questionObject.description
                    ? renderText(questionObject.description)
                    : ''}
                </span>
              </h2>
            </div>
          )}
          {findKeyByValue(questionObject?.solutionType) === 'passage' &&
            questionObject?.passageQuestions?.map((item, i) => (
              <OlympiadQuesAnsWrapper
                key={item._id}
                data={item}
                qIndex={i + 1}
                itemId={item._id}
                description={item?.description}
                options={item?.options}
                optionsId={item?._id}
                questionInExamData={questionInExamData}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                setEachTimeOn={setEachTimeOn}
                eachcurrenttime={eachcurrenttime}
                setEachtime={setEachtime}
                addBookmark={addBookmark}
                Bookmark={Bookmark}
                addQueAns={addQueAns}
                updateQueAns={updateQueAns}
                allQuesAns={allQuesAns}
              />
            ))}
          {findKeyByValue(questionObject?.solutionType) ===
            'select-the-suitable-words' && (
            <OlympiadQuesAnsWrapper
              data={questionObject}
              itemId={questionObject._id}
              description={questionObject?.description}
              options={questionObject?.options}
              optionsId={questionObject?._id}
              questionInExamData={questionInExamData}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              setEachTimeOn={setEachTimeOn}
              eachcurrenttime={eachcurrenttime}
              setEachtime={setEachtime}
              addBookmark={addBookmark}
              allQuesAns={allQuesAns}
            />
          )}
          <div className='subscribe-for-more-exam'>
            {selectedQuestion > 0 ? (
              <button
                type='button'
                className='prev'
                onClick={() => handlePrev()}
              >
                <span>{chevLeft}</span>Previous Question
              </button>
            ) : null}
            {questionInExamData?.length === selectedQuestion + 1 ? null : (
              <button
                type='button'
                className='next'
                onClick={() => handleNext()}
              >
                Next Question{/* <span>{chevRight}</span> */}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
ExamQuestionSection.propTypes = {
  questionInExamData: object.isRequired,
  selectedQuestion: string.isRequired,
  setSelectedQuestion: func.isRequired,
  selectedOption: string.isRequired,
  setSelectedOption: func.isRequired,
  updateQueAns: func.isRequired,
  allQuesAns: array.isRequired,
  setEachTimeOn: func.isRequired,
  eachcurrenttime: string.isRequired,
  setEachtime: func.isRequired,
  setBookmark: func.isRequired,
  Bookmark: bool.isRequired,
  addQueAns: func.isRequired,
};

export default ExamQuestionSection;
