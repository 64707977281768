import React, { useEffect, useState } from 'react';
import {
  takePracticeWithoutLogin,
  getQuestionsInTestWLogin,
  submitAnswerPracWLogin,
} from '../../../../../services/studentMySubjects.service';
import '../BrainGym/QueAnswer/style.scss';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import PracticeQueanspart from './PracticeQueanspart';
import PracticeAttemptAnswers from './PracticeAttemptAnswers';
import { findKeyByValue } from '../BrainGym/QueAnswer/questionHelper';
import { WaitingLoader } from '../../../waitingLoader';
import TestHeader from './WithoutLoginHeader';
import { chevRightIcon } from './icons';
import { chevRight } from '../../../../../assets/icons/IconList';
import Layout from '../../../../../Layout/Layout';
import Subjectlist from '../../../../pages/home/Subjectlist';
import FinishedPopupWithoutLogin from './FinishedWithoutLogin';
import PracticeTestHeaderInfoSection from './openPracticeTestComponents/PracticeTestHeaderInfoSection';
import AchiverPopUP from './AchiverPopUP';

const PracticeWithoutLogin = () => {
  const [cookies] = useCookies(['cookie-name']);
  const { subId, conId } = useParams();
  const [practiceId, setPracticeId] = useState('');
  const [takeTestData, setTakeTestData] = useState('');
  const [questionsInfo, setQuestionsInfo] = useState();
  const [statusVal, setstatus] = useState([]);
  const [stat, setStat] = useState('');
  const [topBar, setTopBar] = useState(false);
  const [questionType, setQuestionType] = useState('');
  const [timeTakenToAnswer, setTimeTakenToAnswer] = useState(0);
  const [submitCliked, setSubmitCliked] = useState(false);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [isSubmitValid, setIsSubmitValid] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [practiceComplete, setPracticeComplete] = useState('');
  const [takeTestResponse, setTakeTestResponse] = useState({});
  const [noquestion, setNoquestion] = useState(false);
  const [totalmarks, setTotalmarks] = useState('');

  const [isTestReachMaxLimit, setIsTestReachMaxLimit] = useState(false);

  const takeTest = async () => {
    const params = {
      sessionId: cookies.practice,
      subjectName: subId,
    };
    try {
      const res = await takePracticeWithoutLogin(params);

      setTakeTestResponse(res);
      setTotalmarks(res?.data?.data?.totalMarksObtained);
      setPracticeId(res?.data?.data?._id);
    } catch (error) {
      // console.log(error.response);
      if (error.response.status === 404) {
        setTakeTestData(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    takeTest();
  }, []);

  const getPracticeQuestions = async () => {
    document.body.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
    const params = {
      practiceTestId: practiceId,
      sessionId: cookies.practice,
      subConcept: conId,
    };
    try {
      const res = await getQuestionsInTestWLogin(params);
      setQuestionsInfo(res?.data?.data?.questionInfo);
      setTimeTakenToAnswer(0);
      setQuestionType(
        findKeyByValue(res?.data?.data?.questionInfo?.solutionType)
      );
      setTopBar(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 410) {
        setStat('FINISHED');
        setIsTestReachMaxLimit(true);

        setPracticeComplete(error.response.data.message);
      } else if (error.response.status === 404) {
        setStat('FINISHED');
        setNoquestion(true);
        setPracticeComplete(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (questionsInfo?._id) {
      const timer = setInterval(() => {
        setTimeTakenToAnswer(timeTakenToAnswer + 1);
      }, 1000);
      return () => clearInterval(timer); //This is important
    }
  });

  const attemptAnswer = async (qId, qtype) => {
    document.body.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
    setSubmitCliked(true);
    try {
      if (qtype === 'passage') {
        const resultData = [];
        selectedOption.map((data) =>
          resultData.push({
            question: data.qid,
            solutionIndex: [data.answer],
          })
        );
        const params = {
          sessionId: cookies.practice,
          practiceTest: practiceId,
          result: {
            question: qId,
            passageQuestions: resultData,
            timeTakenInSecs: timeTakenToAnswer,
          },
        };
        const res = await submitAnswerPracWLogin(params);
        localStorage.setItem('drag-data', JSON.stringify(res?.data?.data));
        setstatus(res?.data?.data);
        setStat(res?.data?.data?.status);
        setTopBar(true);
      } else if (qtype === 'fill-in-the-blanks') {
        const params = {
          sessionId: cookies.practice,
          practiceTest: practiceId,
          result: {
            question: qId,
            fillInTheBlankSolution: selectedOption,
            timeTakenInSecs: timeTakenToAnswer,
          },
        };
        const res = await submitAnswerPracWLogin(params);
        localStorage.setItem('drag-data', JSON.stringify(res?.data?.data));
        setstatus(res?.data?.data);
        setStat(res?.data?.data?.status);
        setTopBar(true);
      } else if (qtype === 'drag-and-match-the-correct-answer') {
        const params = {
          sessionId: cookies.practice,
          practiceTest: practiceId,
          result: {
            question: qId,
            dragTheTextSolution: selectedOption.map((opt) => opt?.name),
            timeTakenInSecs: timeTakenToAnswer,
          },
        };
        const res = await submitAnswerPracWLogin(params);
        localStorage.setItem('drag-data', JSON.stringify(res?.data?.data));
        setstatus(res?.data?.data);
        setStat(res?.data?.data?.status);
        setTopBar(true);
      } else {
        const params = {
          sessionId: cookies.practice,
          practiceTest: practiceId,
          result: {
            question: qId,
            solutionIndex: selectedOption,
            timeTakenInSecs: timeTakenToAnswer,
          },
        };
        const res = await submitAnswerPracWLogin(params);
        localStorage.setItem('drag-data', JSON.stringify(res?.data?.data));
        setstatus(res?.data?.data);
        setStat(res?.data?.data?.status);
        setTopBar(true);
      }
      setSubmitCliked(false);
    } catch (error) {
      console.log(error);
      setSubmitCliked(false);
    }
  };

  const submitAnswer = (qId, qtype) => {
    attemptAnswer(qId, qtype);
  };

  const GetNextQuestion = () => {
    setNextButtonClicked(true);
    setTimeTakenToAnswer(0);
    setIsSubmitValid(false);

    // setstatus({});
    setStat('');
    setSelectedOption([]);
    setQuestionsInfo('');
    getPracticeQuestions();
  };

  const buttonClass = isSubmitValid
    ? 'button-wrapper-active'
    : 'button-wrapper';

  useEffect(() => {
    if (practiceId) {
      getPracticeQuestions();
    }
  }, [practiceId]);

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <Layout>
      {/* <Subjectlist /> */}
      {!submitCliked && questionsInfo?._id && (
        <PracticeTestHeaderInfoSection
          takeTestResponse={takeTestResponse}
          statusVal={statusVal}
          timeTakenToAnswer={parseInt(timeTakenToAnswer)}
          topBar={topBar}
        />
      )}
      {/* Olympiad-main-practice */}
      <div className='  openPracticeStyleWrapper container-fluid '>
        <div className='row justify-content-center openpracticeBody'>
          <div className=' col-12 col-lg-11 mb-5'>
            {!submitCliked && questionsInfo?._id && (
              <TestHeader
                takeTestData={takeTestData}
                takeTestResponse={takeTestResponse}
                statusVal={statusVal}
                topBar={topBar}
                timeTakenToAnswer={parseInt(timeTakenToAnswer)}
                // stat={stat}
              />
            )}

            {takeTestData && (
              <div>
                <h3 className='test-header' style={{ textAlign: 'center' }}>
                  {takeTestData}
                </h3>

                <button
                  type='button'
                  style={{ textAlign: 'left' }}
                  className='assignQueBack'
                  // onClick={() => {
                  //   console.log('history.goBack()');
                  //   history.push('/dashboard/subject');
                  // }}
                >
                  Go back
                </button>
              </div>
            )}

            {stat === '' && !questionsInfo?._id && takeTestData === '' && (
              <div style={{ minHeight: '100vh' }}>
                <WaitingLoader />
              </div>
            )}

            {stat === '' && !submitCliked && questionsInfo?._id && (
              <div>
                <PracticeQueanspart
                  setNextButtonClicked={setNextButtonClicked}
                  questionsInfo={questionsInfo}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  setIsSubmitValid={setIsSubmitValid}
                />

                <div className='row submitSection'>
                  <div className='col-12'>
                    <div className='col-lg-6 col-12 d-flex align-items-center justify-content-between m-auto m-lg-0'>
                      <button
                        type='button'
                        className='submit-answer-button mt-0'
                        onClick={() =>
                          submitAnswer(questionsInfo._id, questionType)
                        }
                        disabled={!isSubmitValid}
                      >
                        <div
                          className={`${buttonClass} d-flex align-items-center justify-content-center`}
                        >
                          <span>Submit</span>
                          {/* <div className='icon-wrapper d-flex align-items-center justify-content-center'>
                            {chevRightIcon()}
                          </div> */}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {submitCliked && stat == '' ? (
              <div style={{ minHeight: '100vh' }}>
                <WaitingLoader />
              </div>
            ) : (
              ''
            )}

            {((stat === 'IN-PROGRESS' && !submitCliked) ||
              (stat === 'COMPLETED' &&
                statusVal &&
                !nextButtonClicked &&
                !submitCliked)) && (
              <div>
                <PracticeAttemptAnswers
                  statusVal={statusVal}
                  setNextButtonClicked={setNextButtonClicked}
                  // statusVal={JSON.parse(localStorage.getItem('drag-data'))}
                />
                <div className='row submitSection'>
                  <div className='col-12'>
                    <div className='col-lg-6 col-12 d-flex align-items-center justify-content-between m-auto m-lg-0'>
                      <button
                        type='button'
                        onClick={() => GetNextQuestion()}
                        className='submit-answer-button mt-0'
                      >
                        <div
                          className={`${buttonClass} d-flex align-items-center justify-content-center`}
                        >
                          <span>Next question</span>
                        </div>

                        {/* <span>{chevRight}</span> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {stat === 'FINISHED' && (
              // <FinishedPopupWithoutLogin
              //   practiceComplete={practiceComplete}
              //   subject={subId}
              //   isTestCompleted={isTestReachMaxLimit}
              // />
              <AchiverPopUP
                practiceComplete={practiceComplete}
                subject={subId}
                noquestion={noquestion}
                statusVal={statusVal}
                totalmarks={totalmarks}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PracticeWithoutLogin;
