import * as actionTypes from './BrainGymTypes';

export const startChestAction = (data) => ({
  type: actionTypes.START_CHEST,
  payload: data,
});

export const getQuestionInChestAction = (data) => ({
  type: actionTypes.GET_QUESTION_IN_CHEST,
  payload: data,
});

export const attemptQuestionAction = (data) => ({
  type: actionTypes.ATTEMPT_QUESTION,
  payload: data,
});
export const getQuestionInChestEmptyAction = (data) => ({
  type: actionTypes.GET_QUESTION_IN_CHEST_EMPTY,
  payload: data,
});
export const attemptQuestionEmptyAction = (data) => ({
  type: actionTypes.ATTEMPT_QUESTION_EMPTY,
  payload: data,
});

export const completeChestAction = (data) => ({
  type: actionTypes.COMPLETE_CHEST,
  payload: data,
});
export const getChestInfo = (data) => ({
  type: actionTypes.GET_CHEST_INFO,
  payload: data,
});
export const getAllShell = (data) => ({
  type: actionTypes.GET_ALL_SHELL,
  payload: data,
});
export const getIncorrectAns = (data) => ({
  type: actionTypes.GET_INCORRECT_ANSWER,
  payload: data,
});
export function getLastFiveChestAction(data) {
  return {
    type: actionTypes.GET_LAST_FIVE_CHEST,
    payload: data,
  };
}
export function getLongestStreakAction(data) {
  return {
    type: actionTypes.GET_LONGEST_STREAK,
    payload: data,
  };
}

export function clearShellandIncorrect(data) {
  return {
    type: actionTypes.CLEAR_SHELL_AND_QUESTION,
    payload: data,
  };
}

export function clearCompleteChestData() {
  return {
    type: actionTypes.UPDATE_COMPLETE_CHEST,
    payload: [],
  };
}

export default {};
