import React, { useEffect, useState } from 'react';
import './myprofile.scss';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { object, func, array } from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';
import defaultPic from '../../../../../assets/images/defaultPic.png';
import totalScore from '../../../../../assets/images/totalScore.png';
import editIcon from '../../../../../assets/images/pencil (2).svg';
import {
  getProfileAction,
  changeNameAction,
  updateProfilePicAction,
  sendPasswordAction,
} from '../../../../../stores/TeacherDashboard/TeacherDashboardAction';
import {
  getAllUniversities,
  getAllQualifications,
} from '../../../../../stores/Auth/AuthAction';
import ChangeNamePopUp from './ChangeDetails/ChangeNamePopUp';
import ChangePasswordPopUp from './ChangeDetails/ChangeEmailPopUp';
import ChangePhoneNumPopUp from './ChangeDetails/ChangePhoneNum';
import ChangeQualPopUp from './ChangeDetails/ChangeQualification';
import ChangeUnivPopUp from './ChangeDetails/ChangeUniversity';
import { authServices } from '../../../../../services';
import { documentListIcon } from '../../../../../assets/icons/IconList';
import { toast } from 'react-toastify';
import config from '../../../../../helpers/config';
import { useHistory, useLocation } from 'react-router';

const MyProfile = ({
  myProfile,
  getMyProfile,
  getUniversityList,
  getAllQualificationList,
  universitiesList,
  qualificationsList,
  updateProfilePic,
  updateProfileRes,
}) => {
  const [model, setModel] = useState(false);
  const [modelEmail, setModelEmail] = useState(false);
  const [modelPhone, setModelPhone] = useState(false);
  const [modelQual, setModelQual] = useState(false);
  const [modelUniv, setModelUniv] = useState(false);
  const localData = JSON.parse(localStorage.getItem('taut-student'));
  const myprofileDetail = myProfile?.data;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const profilepass = query.get('profilepass');

    if (!profilepass) {
      setModelEmail(false);
      history.push('/teacher/profile');
    }
    // else if (braingym){
    //   setViewBrain('workout');
    // }
  }, [location.search]);

  useEffect(() => {
    if (myProfile.data) {
      setUploadedFiles(myProfile?.data?.upload);
    }
  });

  const [uploadingProfilePic, setUploadingProfilePic] = useState(false);

  useEffect(() => {
    if (updateProfileRes?.status === 'success') {
      setUploadingProfilePic(false);
      getMyProfile();
    }
  }, [updateProfileRes]);

  const [imageSizeError, setImageSizeError] = useState(false);
  const [imageTypeError, setImageTypeError] = useState(false);
  const lastWord = (words) => {
    var n = words.split('.');
    return n[n.length - 1];
  };
  const uploadProfilePic = (event) => {
    setImageSizeError(false);
    setImageTypeError(false);
    const fileUploaded = event.target.files[0];
    if (lastWord(fileUploaded?.name) === 'pdf') {
      setImageTypeError(true);
    } else {
      if (fileUploaded.size < 2000000) {
        setImageSizeError(false);
        setImageTypeError(false);
        const fileString = new Date() + fileUploaded.name;
        const url = `${config.adminImageUrl}/images/`;
        fetch(
          `${config.apiBaseUrl}/teachers/presigned-url-pic-upload?bucketKey=${fileString}&contentType=${fileUploaded.type}`,
          {
            method: 'GET',
          }
        )
          .then((response) => response.json())
          .then((imgRes) => {
            const messageFromReq = imgRes?.message;
            fetch(messageFromReq, { method: 'PUT', body: fileUploaded });

            updateProfilePic({
              id: myprofileDetail?._id,
              url: `${url}${fileString}`,
            });
            setUploadingProfilePic(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setImageSizeError(true);
        setImageTypeError(false);
      }
    }
  };
  const convertToBase64Url = (file) =>
    new Promise((resolve, reject) => {
      if (!file) reject();
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve({ url: reader.result, name: file?.name?.split('.')[0] });
      reader.readAsDataURL(file);
    });
  const uploadFile = async (file) => {
    const { url, name } = await convertToBase64Url(file);
    let token = localData?.data?.accessToken;
    let params = {
      fileName: name,
      base64File: url,
    };
    const response = await authServices.profileFileUpload(params, token);
    if (response?.statusCode) {
      getMyProfile();
    }
  };
  const handleDeleteBtn = async (name, url) => {
    const filename = url;
    const extension = filename.substring(
      filename.lastIndexOf('.') + 1,
      filename.length
    );
    let token = localData?.data?.accessToken;
    let params = {
      fileName: name,
    };
    const response = await authServices.profileFileDelete(params, token);
    if (response?.statusCode) {
      getMyProfile();
    }
  };

  const hiddenFileInput = React.useRef(null);

  const timeData = myprofileDetail?.timeslot?.map((item) => ({
    monday: item.monday
      .toString()
      .split(',')
      .map((data) =>
        data.replace(' AM', '').replace('0', '').replace(' 0', ' ')
      ),
    tuesday: item.tuesday
      .toString()
      .split(',')
      .map((data) =>
        data.replace(' AM', '').replace('0', '').replace(' 0', ' ')
      ),
    wednesday: item.wednesday
      .toString()
      .split(',')
      .map((data) =>
        data.replace(' AM', '').replace('0', '').replace(' 0', ' ')
      ),
    thursday: item.thursday
      .toString()
      .split(',')
      .map((data) =>
        data.replace(' AM', '').replace('0', '').replace(' 0', ' ')
      ),
    friday: item.friday
      .toString()
      .split(',')
      .map((data) =>
        data.replace(' AM', '').replace('0', '').replace(' 0', ' ')
      ),
    saturday: item.saturday
      .toString()
      .split(',')
      .map((data) =>
        data.replace(' AM', '').replace('0', '').replace(' 0', ' ')
      ),
  }));

  const fileTypes = ['JPG', 'PDF', 'docx'];

  const [universities, setUniversities] = useState([{ value: 1, label: '' }]);

  useEffect(() => {
    if (!universitiesList?.data) {
      getUniversityList();
    }
    if (universitiesList?.data?.length > 0) {
      const cdata = universitiesList?.data.map((data) => ({
        value: data.universityName,
        label: data.universityName,
        id: data._id,
      }));
      setUniversities(cdata);
    }
  }, [universitiesList]);

  const [qualification1, setQualification1] = useState([
    { value: 1, label: '' },
  ]);

  useEffect(() => {
    if (!qualificationsList?.data) {
      getAllQualificationList();
    }
    if (qualificationsList?.data?.length > 0) {
      const cdata = qualificationsList?.data.map((data) => ({
        value: data.qualificationName,
        label: data.qualificationName,
        id: data._id,
      }));
      setQualification1(cdata);
    }
  }, [qualificationsList]);

  useEffect(() => {}, [imageTypeError]);
  const renderTimeSlots = () => {
    if (!timeData) {
      return null;
    }
    const stx = { color: 'rgba(0,0,0,0.6)', fontSize: 20 };

    return timeData.map((data, i, arr) => (
      <div className='timeSlots-wrapper'>
        <div className='timeSlots-innerDiv'>
          <h5 style={stx}>Mon</h5>
          <h5>
            ({`${data?.monday.join(', ')}${!arr.length - 1 === i ? ',' : ''}`})
          </h5>
        </div>
        <div className='timeSlots-innerDiv'>
          <h5 style={stx}>Tue</h5>
          <h5>
            ({`${data?.tuesday.join(', ')}${!arr.length - 1 === i ? ',' : ''}`})
          </h5>
        </div>
        <div className='timeSlots-innerDiv'>
          <h5 style={stx}>Wed</h5>
          <h5>
            (
            {`${data?.wednesday.join(', ')}${!arr.length - 1 === i ? ',' : ''}`}
            )
          </h5>
        </div>
        <div className='timeSlots-innerDiv'>
          <h5 style={stx}>Thu</h5>
          <h5>
            ({`${data?.thursday.join(', ')}${!arr.length - 1 === i ? ',' : ''}`}
            )
          </h5>
        </div>
        <div className='timeSlots-innerDiv'>
          <h5 style={stx}>Fri</h5>
          <h5>
            ({`${data?.friday.join(', ')}${!arr.length - 1 === i ? ',' : ''}`})
          </h5>
        </div>
        <div className='timeSlots-innerDiv'>
          <h5 style={stx}>Sat</h5>
          <h5>
            ({`${data?.saturday.join(', ')}${!arr.length - 1 === i ? ',' : ''}`}
            )
          </h5>
        </div>
      </div>
    ));
  };

  const Loader = () => (
    <div
      style={{
        height: '100vh',
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation='border' variant='warning' />
    </div>
  );

  const renderSubjects = () => {
    if (!myprofileDetail) {
      return null;
    }
    return myprofileDetail.subject.map((data, i, arr) => (
      <h5 className='sub-common'>{`${data.name}${
        !i + 1 === arr.length ? ',' : ''
      }`}</h5>
    ));
  };
  const renderSubjects2 = () => {
    if (!myprofileDetail) {
      return null;
    }
    return myprofileDetail?.co_curricular?.map((data, i, arr) => (
      <h5 className='sub-common'>{`${data.activityName}${
        !i + 1 === arr.length ? ',' : ''
      }`}</h5>
    ));
  };

  const renderDocs = () => {
    if (myprofileDetail?.length === 0) {
      return <p>Loading</p>;
    }

    if (myprofileDetail?.upload.length === 0) {
      return <p>No Documents to show</p>;
    }

    if (typeof myprofileDetail?.upload === 'string') {
      return <p>No Documents to show</p>;
    }

    return myprofileDetail?.upload?.map((item) => (
      <div className='doc-list'>
        <div className='d-flex mb-2'>
          <div className='me-5'>
            <span className='me-2'>{documentListIcon}</span>
            <span>{item.name}</span>
          </div>
          <div>
            <a
              style={{ textDecoration: 'none', color: '#43AA8B' }}
              href={item.url || ''}
              target='_blank'
              rel='noreferrer'
            >
              View
            </a>
          </div>
          <div>
            <button
              className='ms-3'
              onClick={() => handleDeleteBtn(item.name, item.url)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    ));
  };

  const onProfilePhotoEditClick = () => {
    hiddenFileInput.current.click();
  };

  const renderQual = () => {
    if (!myprofileDetail?.qualification) {
      return null;
    }

    return myprofileDetail?.qualification.map((item) => item.qualificationName);
  };

  return (
    <>
      {!myProfile.data ? (
        <Loader />
      ) : (
        <>
          <div className='container-teacher-profile'>
            <div className='position-relative'>
              <div className='profile-pic-and-name'>
                {uploadingProfilePic ? (
                  <Spinner
                    style={{ marginRight: '25px' }}
                    animation='border'
                    variant='success'
                  />
                ) : (
                  <img
                    src={
                      myprofileDetail?.profilePicture
                        ? myprofileDetail?.profilePicture
                        : defaultPic
                    }
                    alt='student-img'
                  />
                )}
                <img
                  className='edit-icon'
                  src={editIcon}
                  alt='student-img'
                  onClick={() => onProfilePhotoEditClick()}
                />
                <input
                  type='file'
                  ref={hiddenFileInput}
                  onChange={uploadProfilePic}
                  style={{ display: 'none' }}
                />
                <div className='name-and-id'>
                  <h5>{myprofileDetail?.fullName}</h5>
                  <h6>{myprofileDetail?.id}</h6>
                </div>

                <div className='position-absolute top-100 start-50 bottom-0 translate-middle'>
                  {imageSizeError ? (
                    <p className='text-danger'>
                      Image size should be less than 2mb
                    </p>
                  ) : null}
                  {imageTypeError ? (
                    <p className='text-danger'>Pdf image type is not allowed</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='teacher-details'>
              <div className='heading-and-icon'>
                <h4>TEACHING DETAILS</h4>
                <img src={totalScore} alt='student-img' />
              </div>
              <div className='space-between-top'>
                <div className='details'>
                  <h5 className='faded'>Subjects</h5>
                  {renderSubjects()}
                </div>
                {myprofileDetail?.co_curricular ? (
                  <div className='details'>
                    <h5 className='faded'>Subjects</h5>
                    {renderSubjects2()}
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className='details-and-change-button'>
                <div className='timings-slot-div'>
                  <div className='details'>
                    <h5 className='faded mb-2'>Timings</h5>
                    {renderTimeSlots()}
                  </div>
                </div>
              </div>
            </div>

            <div className='teacher-details'>
              <div className='heading-and-icon'>
                <h4>MY ACCOUNT </h4>
              </div>

              <div className='details-and-change-button'>
                <div className='space-between'>
                  <div className='details'>
                    <h5 className='faded'>My name</h5>
                    <h5>{myprofileDetail?.fullName}</h5>
                  </div>
                </div>

                <div className='space-between'>
                  <div className='details'>
                    <h5 className='faded'>Email ID</h5>
                    <h5>{myprofileDetail?.emailID}</h5>
                  </div>
                </div>
              </div>

              <div className='details-and-change-button'>
                <div className='space-between'>
                  <div className='details'>
                    <h5 className='faded'>Password</h5>
                    <h5>********</h5>
                  </div>
                  <button
                    onClick={() => {
                      setModelEmail(true);
                      history.push({
                        pathname: '/teacher/profile',
                        search: '?profilepass=true',
                      });
                    }}
                    className='button-green'
                    type='button'
                  >
                    CHANGE
                  </button>
                </div>

                <div className='space-between'>
                  <div className='details'>
                    <h5 className='faded'>Phone number</h5>
                    <h5>{myprofileDetail?.phoneNumber}</h5>
                  </div>
                  <button
                    onClick={() => {
                      setModelPhone(true);
                      // history.push({
                      //   pathname: '/teacher/profile',
                      //   search: '?profilepass=true',
                      // });
                    }}
                    className='button-green'
                    type='button'
                  >
                    CHANGE
                  </button>
                </div>
              </div>
            </div>

            <div className='teacher-details'>
              <div className='heading-and-icon'>
                <h4>MY EDUCATION </h4>
              </div>

              <div className='details-and-change-button'>
                <div className='space-between'>
                  <div className='details'>
                    <h5 className='faded'>Qualification</h5>
                    <h5>{renderQual()?.join(', ')}</h5>
                  </div>
                </div>

                <div className='space-between'>
                  <div className='details'>
                    <h5 className='faded'>University</h5>
                    <h5>{myprofileDetail?.university.universityName}</h5>
                  </div>
                </div>
              </div>

              <div className='details-and-change-button'>
                <div className='space-between'>
                  <div className='details'>
                    <h5 className='faded'>Documents</h5>
                  </div>
                </div>
              </div>

              {renderDocs()}

              <div className='upload-container'>
                <FileUploader
                  label='Upload document here'
                  className='AA'
                  handleChange={uploadFile}
                  name='file'
                  types={fileTypes}
                  maxSize={15}
                  onSizeError={() => {
                    toast.error('Maximum file size 15 MB');
                  }}
                />
              </div>
            </div>
            <ChangeNamePopUp
              id={myProfile?.data?._id}
              model={model}
              handleModel={setModel}
            />
            <ChangePasswordPopUp
              id={myProfile?.data?._id}
              model={modelEmail}
              handleModel={setModelEmail}
            />
            <ChangePhoneNumPopUp
              id={myProfile?.data?._id}
              model={modelPhone}
              handleModel={setModelPhone}
            />
            <ChangeQualPopUp
              qualificationsList={qualification1}
              id={myProfile?.data?._id}
              model={modelQual}
              handleModel={setModelQual}
            />
            <ChangeUnivPopUp
              universitiesList={universities}
              id={myProfile?.data?._id}
              model={modelUniv}
              handleModel={setModelUniv}
            />
          </div>
        </>
      )}
    </>
  );
};

MyProfile.propTypes = {
  myProfile: object.isRequired,
  getMyProfile: func.isRequired,
  getAllQualificationList: func.isRequired,
  getUniversityList: array.isRequired,
  universitiesList: array.isRequired,
  qualificationsList: array.isRequired,

  updateProfileRes: array.isRequired,
  updateProfilePic: func.isRequired,
  sendPassword: func.isRequired,
  sendPasswordRes: array.isRequired,
};

const mapStateToProps = (state) => ({
  myProfile: state.TeacherDashboard.getProfile,
  universitiesList: state.Auth.universities,
  qualificationsList: state.Auth.qualifications,
  updateProfileRes: state.TeacherDashboard.updateProfilePic,
});

const mapDispatchToProps = (dispatch) => ({
  getMyProfile: () => dispatch(getProfileAction()),
  changeName: (data) => changeNameAction(data),
  getUniversityList: () => dispatch(getAllUniversities()),
  getAllQualificationList: () => dispatch(getAllQualifications()),
  updateProfilePic: (data) => dispatch(updateProfilePicAction(data)),
  sendPassword: (data) => dispatch(sendPasswordAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
