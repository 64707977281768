import { object } from 'prop-types';
import React from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import close from '../../../../../assets/images/close.png';
import clock from '../../../../../assets/images/clock2.png';
import intelligence from '../../../../../assets/images/intelligence.png';
import clipboard from '../../../../../assets/images/clipboard.png';
import trophy from '../../../../../assets/images/trophy2.png';
import { formatSecToMin } from '../../../../../helpers/utility';

const CongratsPopup = ({ statusVal, handleCancel, isViewReport }) => {
  const history = useHistory();
  const search = useLocation().search;
  const isOlympiad = new URLSearchParams(search).get('olympiad');

  const closepopup = () => {
    if (handleCancel) handleCancel();
    else if (isOlympiad) {
      history.goBack();
    } else history.push('/dashboard/subject');
  };
  return (
    <div className='congrats-popup-main'>
      <div className='right-answer-container'>
        <div className='close-top'>
          <button type='button' className='close-btn' onClick={closepopup}>
            <img src={close} alt='close' />
          </button>
        </div>
        <div className='right-answer-modal-bg' />
        <div className='right-answer-modal'>
          <div className='congrats-detail'>
            {!statusVal?.totalMarksObtained ? (
              <div className='retake-test-container'>
                <h2>Sorry! None of the Questions Are correctly Answered</h2>
                {!isViewReport && (
                  <div>
                    <Link to='#'>
                      <button
                        type='button'
                        className='retake-btn mb-3'
                        onClick={() => closepopup()}
                      >
                        Click To Retake Test
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              <div className='retake-test-container'>
                <img src={trophy} alt='trophy' />
                <h2>Congrats !</h2>
                <h4>
                  You have scored <span>{statusVal?.totalMarksObtained}</span>{' '}
                  marks in your Practice test
                </h4>
                {!isViewReport && (
                  <div>
                    <Link to='#'>
                      <button
                        type='button'
                        className='retake-btn mb-3'
                        onClick={() => closepopup()}
                      >
                        Click To Retake Test
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            )}

            <div className='congrats-detail-sub'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='congrats-detail-sub-part'>
                    <img src={clock} alt='stopwatch' />
                    <div className='congrats-detail-sub-part-right'>
                      <p>Time taken </p>
                      <h5>
                        {formatSecToMin(statusVal?.totalTimeTakenInSecs)}{' '}
                        {statusVal?.totalTimeTakenInSecs > 3600
                          ? 'Hrs'
                          : statusVal?.totalTimeTakenInSecs > 60
                          ? 'Mins'
                          : 'Secs'}{' '}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='congrats-detail-sub-part'>
                    <img src={intelligence} alt='stopwatch' />
                    <div className='congrats-detail-sub-part-right'>
                      <p>Accuracy</p>
                      <h5>{statusVal?.accuracy}%</h5>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='congrats-detail-sub-part'>
                    <img src={clipboard} alt='stopwatch' />
                    <div className='congrats-detail-sub-part-right'>
                      <p>Right answers</p>
                      <h5>
                        {statusVal?.rightAnswers} out of{' '}
                        {statusVal?.totalQuestions}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CongratsPopup.propTypes = {
  statusVal: object.isRequired,
};

export default CongratsPopup;
