import React, { useEffect, useState } from 'react';
import { gradeLinks } from './data';

const Chapterslink = ({
  gradeValue,
  selectedLink,
  setSelectedLink,
  setSelectedGrade,
  handleClick,
  colors,
  selectedColor,
  handleliColours,
  setLiColours,
  liColours,
  licolors,
}) => {
  useEffect(() => {
    handleliColours(licolors[0]);
  }, []);
  return (
    <ul className='links-list'>
      {gradeValue.map((link, linkIdx) => (
        <li
          key={linkIdx}
          //  className={linkIdx === selectedLink.num ? 'active' : ''}
          style={
            linkIdx === selectedLink.num
              ? { backgroundColor: liColours }
              : { backgroundColor: '' }
          }
          onClick={() => handleliColours(licolors[linkIdx % licolors.length])}
        >
          <button
            type='button'
            style={{ color: colors[linkIdx % colors.length] }}
            onClick={() => {
              setSelectedLink({
                num: linkIdx,
                class: link.value,
                label: link.label,
              }),
                setSelectedGrade(link.label),
                handleClick(colors[linkIdx % colors.length]);
            }}
            // className={linkIdx === selectedLink ? 'active' : ''}
          >
            Class {link.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Chapterslink;
