import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import config from '../../helpers/config';
import {
  sendOtpParentAction,
  updateRegistrationData,
  verifyEmailAction,
  verifyOtpAction,
  deleteDocument,
  sendOtpBookDemoAction,
  uploadTeacherDocumentUploadAction,
} from '../../stores/Auth/AuthAction';

import { FileUploader } from 'react-drag-drop-files';

import UploadingIcon from '../../assets/images/upload.svg';
import PhoneInput from 'react-phone-input-2';
import Countdown, { zeroPad } from 'react-countdown';
import MyParentdetailsInput from '../components/MyParentdetailsInput';
import OtpInputBox from '../components/OtpInputBox';
import Loading from '../../views/components/newRegistration/components/Loading';
import swal from 'sweetalert';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import { country } from '../../views/components/dashboard/Rightsidepart/RenewSubscription/mockData/Renewdata';

const baseUrl = config.apiBaseUrl;

const LeadForm = ({
  setFullnameVal,
  fullnameVal,

  validation,
  setValidation,

  phoneNumVal,
  setPhoneNumVal,

  sendOtp,
  otp,

  disableFields,
  setDisableFields,
  otpDisable,

  verifyOtpBookDemoRes,

  // registrationData,
  // updateRegistrationData,

  // docDeleteResponse,
  // deleteDocumentAction,
  // setFileName,
  // fileName,
  // otpTeacherRes,
  // uploadTeacherDocumentUpload,
  // countryList,
}) => {
  const [otpButtonText, setOtpButtonText] = useState('Send OTP');
  const [otpButtonpopup, setOtpButtonpopup] = useState('');
  const [show403, setShow403] = useState(false);
  const [emailvalidate, setEmailValidate] = useState(false);
  const [nameValidate, setNameValidate] = useState('');
  const [parentValidate, setParentValidate] = useState('');
  const [gradeValidate, setGradeValidate] = useState('');
  const [countryValidate, setCountryValidate] = useState('');
  const [OtpTrigAcc, setOtpTrigAcc] = useState(false);
  const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  const numRegex = /^\d+$/;
  const ApplyOtpCondition =
    otpButtonText !== 'Send OTP' && otpButtonText !== 'loading';

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setOtpButtonText('Resend OTP');
    }
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  const getOtpText = () => {
    if (disableFields) {
      return '';
    }
    if (otpButtonText === 'Send OTP') {
      return 'Send OTP';
    }
    if (otpButtonText === 'Timer') {
      return (
        <Countdown
          renderer={renderer}
          date={Date.now() + 30000}
          zeroPadTime={3}
        />
      );
    }
    if (otpButtonText === 'loading') {
      return <Loading />;
    }
    return 'Resend OTP';
  };
  const handleOtpClick = () => {
    if (otpButtonText !== 'Timer') {
      if (fullnameVal !== '') {
        const data = {
          parentName: fullnameVal,
          studentName: null,
          phone: phoneNumVal,
          type: 'lead-signup',
        };
        sendOtp(data);
        setShow403(false);
        setOtpTrigAcc(true);
      } else {
        if (fullnameVal === '') {
          setValidation((prevPerson) => ({
            ...prevPerson,
            fullName: true,
          }));
        } else if (!nameRegex.test(fullnameVal)) {
          setNameValidate('Name should not contains number and character');
        }
      }
    }
  };
  useEffect(() => {
    if (OtpTrigAcc) {
      if (otp.status === 'success') {
        if (otp.message === 'OTP already verified') {
          swal(otp?.message);
          setValidation({ ...validation, isStudentValidated: true });
        } else {
          setOtpButtonText('Timer');
        }
      } else if (otp?.statusCode === 403) {
        setShow403(true);
        setOtpButtonText('Send OTP');
      } else {
        // setOtpButtonText('loading');
      }
    }
  }, [otp]);

  useEffect(() => {
    if (verifyOtpBookDemoRes.statusCode === 200) {
      let obj = { ...validation };
      //  if (gradeVal)
      obj = { ...obj, isStudentValidated: true };
      // if (!gradeVal) obj = { ...obj, isStudentValidated: true, grade: true };
      // if (countryVal)
      //   obj = { ...obj, isStudentValidated: true, country: false };
      // if (!countryVal)
      //   obj = { ...obj, isStudentValidated: true, country: true };
      setValidation({ ...obj });
    }
  }, [verifyOtpBookDemoRes]);

  useEffect(()=>{
    const otpVerify = async () => {
    if (otp.statusCode === 200) {
      // if (registrationData?.students) {
      //   setValidation({ ...validation, isStudentValidated: true });
      //   return;
      // }
      // setParentId(otp?.data?.parent);
      // setCurrencyCode(otp?.data?.currencyCode);
      // if (otp?.data?.otpVerified && otp?.data?.emailVerified) {
      //   if (otp?.data?.students?.length > 0) {
      //     const registerdStudents = otp?.data?.students.map((eachStudent) => ({
      //       name: eachStudent?.studentName,
      //       lastName: eachStudent?.lastName,
      //       userName: eachStudent?.userName,
      //       id: eachStudent?.id,
      //       school: eachStudent?.schoolName,
      //       pastRegisterdData: eachStudent,
      //     }));
      //     setOtpButtonText('loading');
      //   }
      //   swal('OTP already verified. Please proceed to next step');
      //   setValidation({ ...validation, isStudentValidated: true });
      // }
    } else if (otp?.statusCode) {
      if (otp?.message && OtpTrigAcc) {
        swal(otp?.message);
      }
    }
  }
    otpVerify();
  }, [otp]);
  return (
    <div className='mydetails-main coursedetails-main'>
      <div className='row '>
        <div className='col-lg-6 mydetail-input'>
          <MyParentdetailsInput
            label='Full name*'
            type='text'
            name='full_name'
            id='full-name'
            placeHolder='Enter your first name and last name'
            value={fullnameVal}
            setValue={(value) => {
              value = value?.length > 32 ? value?.slice(0, 32) : value;
              setValidation((prevPerson) => ({
                ...prevPerson,
                fullName: false,
              }));
              setNameValidate('');
              setFullnameVal(value);
            }}
            onBlur={(e) => {
              if (nameRegex.test(e.target.value)) {
                setNameValidate('');
                setParentValidate('');
                setValidation({ ...validation, isStudentValidated: false });
              } else setNameValidate('Please enter a valid name.');
            }}
          />
          {validation.fullName && (
            <span className='error-msg'>Full name is required. </span>
          )}
          {!validation.fullName && nameValidate !== '' ? (
            <span className='error-msg'>{nameValidate}</span>
          ) : (
            ''
          )}
        </div>
        {/* <div className='col-lg-6 mydetail-input'>
          <MyParentdetailsInput
            label='Parent name*'
            type='text'
            name='full_name'
            id='full-name'
            placeHolder='Enter your first name and last name'
            value={parentnameVal}
            setValue={(value) => {
              value = value?.length > 32 ? value?.slice(0, 32) : value;
              //OnChange event
              setValidation((prevPerson) => ({
                ...prevPerson,
                parentName: false,
              }));
              setParentValidate('');
              setParentnameVal(value);
            }}
            onBlur={(e) => {
              if (nameRegex.test(e.target.value)) {
                if (
                  e.target.value.trim() &&
                  fullnameVal.trim() &&
                  e.target.value.trim() === fullnameVal.trim()
                )
                  setParentValidate(
                    'Parent name and student name should be different'
                  );
                else {
                  setNameValidate('');
                  setParentValidate('');
                }
              } else setParentValidate('Please enter a valid parent name.');
            }}
          />
          {validation.parentName && (
            <span className='error-msg'>Full name is required. </span>
          )}
          {!validation.parentName && parentValidate !== '' ? (
            <span className='error-msg'>{parentValidate}</span>
          ) : (
            ''
          )}
        </div> */}
        <div className='col-lg-6 mydetail-input'>
          <div className='mydetail-input-part'>
            <label htmlFor='detail-label'>Mobile*</label>
            <>
              <div className={'phone-input-custom'}>
                <PhoneInput
                  specialLabel=''
                  country='in'
                  value={phoneNumVal}
                  countryCodeEditable={false}
                  inputStyle={{
                    fontSize: 20,
                    buttonStyle: {
                      display: 'none',
                    },
                  }}
                  placeholder='Enter your mobile number'
                  onChange={(e) => {
                    setValidation((prevPerson) => ({
                      ...prevPerson,
                      phoneNumber: false,
                      isStudentValidated: false,
                    }));
                    setOtpButtonText('Send OTP');
                    setShow403(false);
                    setPhoneNumVal(e);
                  }}
                />
              </div>
              <button
                type='button'
                onClick={handleOtpClick}
                style={{
                  display: !otpDisable
                    ? (
                        phoneNumVal.slice(0, 2) === '91'
                          ? phoneNumVal.length >= 12
                          : phoneNumVal.length >= 11
                      )
                      ? 'block'
                      : 'none'
                    : 'none',
                }}
              >
                {getOtpText()}
              </button>
            </>
          </div>

          {otp?.status === 'success' && ApplyOtpCondition && (
            <span className='d-flex success-msg'>OTP sent</span>
          )}
          {validation.phoneNumber && (
            <span className='error-msg'>Phone number is required.</span>
          )}
          {otp?.statusCode === 400 && show403 && (
            <span className='error-msg'>
              This mobile number is already registered.
            </span>
          )}
          {otp?.statusCode === 403 && show403 && (
            <span className='error-msg'>
              This mobile number already registered.
            </span>
          )}
        </div>
        {!(otp.status === 'success' && ApplyOtpCondition) ? (
          <div className='col-lg-6 mydetail-input'></div>
        ) : (
          ''
        )}
        {otp.status === 'success' && ApplyOtpCondition && (
          <OtpInputBox
            label='Enter OTP'
            phoneNumVal={phoneNumVal}
            isDisabled={otpDisable}
            type={'book-demo'}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  otp: state.Auth.sendOtpBookDemo,
  verifyEmailData: state.Auth.verifyEmailData,
  verifyOtpBookDemoRes: state.Auth.verifyOtpBookDemo,
  registrationData: state.Auth.registrationData,
  docDeleteResponse: state.Auth.docDelete,
  docDeleteResponse: state.Auth.docDelete,
});

const mapDispatchToProps = (dispatch) => ({
  sendOtp: (data) => dispatch(sendOtpBookDemoAction(data)),
  verifyEmail: (data) => dispatch(verifyEmailAction(data)),
  updateRegistrationData: (data) => dispatch(updateRegistrationData(data)),
  deleteDocumentAction: (data) => dispatch(deleteDocument(data)),
  uploadTeacherDocumentUpload: (data) =>
    dispatch(uploadTeacherDocumentUploadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadForm);
