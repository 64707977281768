import React, { useState, useEffect } from 'react';
import { func, string } from 'prop-types';

import { useHistory } from 'react-router';
import exam from '../../../../../../assets/images/exam.png';
import { getAssignments } from '../../../../../../services/teacherAssignment';
import AssignmentsCommon from './AssignmentsCommon';
import ReactPaginate from 'react-paginate';

const Assignments = ({
  subjectParam,
  studentId,
  setAssignment,
  setAssignmentId,
  StudentName,
}) => {
  const history = useHistory();
  const localDataVal = JSON.parse(localStorage.getItem('taut-student'));
  const [assignmentList, setAssignmentList] = useState([]);
  const [totalPage, setTotalPage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const newAssignment = () => {
    localStorage.setItem('student', studentId);
    localStorage.setItem('subject', subjectParam);
    // history.push('/teacher/assignments');
    history.push({
      pathname: '/teacher/assignments',
      search: '?grades=true&&assignmentsteacher=true',
    });
  };

  const getAssignmentsList = async (pageNo) => {
    const params = {
      teacher: localDataVal?.data?._id,
      student: studentId,
      subject: subjectParam,
      page_no: pageNo,
    };
    try {
      const res = await getAssignments(params);
      if (res.status === 200) {
        setAssignmentList(res?.data?.data[0]?.response);
        setTotalPage(res?.data?.data[0]?.count?.[0]?.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (pageNo) => {
    setCurrentPage(pageNo);
    getAssignmentsList(pageNo);
  };

  useEffect(() => {
    getAssignmentsList(1);
  }, []);

  return (
    <div className='assignment-main'>
      <div className='row'>
        <div className='col-sm-7'>
          <h3 className='title'>
            <img src={exam} alt='Assignments' /> Assignments
          </h3>
        </div>
        <div className='col-md-5 text-right d-flex align-items-center justify-content-end'>
          <button type='button' className='leave' onClick={newAssignment}>
            ADD NEW ASSIGNMENT
          </button>
        </div>
      </div>

      <div className='assignment-common-main row'>
        {assignmentList?.length > 0 ? (
          <AssignmentsCommon
            assignmentList={assignmentList}
            setAssignment={setAssignment}
            setAssignmentId={setAssignmentId}
          />
        ) : (
          <div className='noClassAva-text'>
            Please add & allocate new assignment(s) for
            <span
              style={{
                color: '#fca834',
                fontWeight: '500',
              }}
            >
              &nbsp;{StudentName}.
            </span>
          </div>
        )}
      </div>
      {assignmentList?.length > 0 ? (
        <ReactPaginate
          previousLabel={
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              class='bi bi-arrow-left'
              viewBox='0 0 16 16'
            >
              <path
                fill-rule='evenodd'
                d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'
              />
            </svg>
          }
          nextLabel={
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              class='bi bi-arrow-right'
              viewBox='0 0 16 16'
            >
              <path
                fill-rule='evenodd'
                d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'
              />
            </svg>
          }
          breakLabel='...'
          breakClassName='break-me'
          pageCount={Math.ceil(totalPage / 10)}
          containerClassName='d-flex w-100 align-items-center justify-content-center mt-5 studentListpagination'
          activeClassName='active'
          forcePage={currentPage - 1}
          onPageChange={(pageNo) => onPageChange(pageNo.selected + 1)}
        />
      ) : (
        ''
      )}
    </div>
  );
};

Assignments.propTypes = {
  subjectParam: string.isRequired,
  studentId: string.isRequired,
  setAssignment: func.isRequired,
  setAssignmentId: func.isRequired,
};

export default Assignments;
