import React, { useEffect } from 'react';
import { renderText } from '../../BrainGym/QueAnswer/textHelper';
import { renderTextNew } from '../../BrainGym/QueAnswer/newTextHelper';
import { checkIcon } from '../../../../../../assets/icons/IconList';
import { FailureAudio, SuccessAudio } from '../../../../audioSignal';
import ReactHtmlParser from 'react-html-parser';
import '../practiceCustom.scss';
import { findKeyByValue } from '../../BrainGym/QueAnswer/questionHelper';

export const ChooseCorrectPreview = ({ statusVal }) => {
  // useEffect(() => {
  //   if (
  //     statusVal.questionInfo.solutionIndex[0] !==
  //       statusVal.result.solutionIndex[0] ||
  //     statusVal.questionInfo.solutionIndex[0] ==
  //       statusVal.result.solutionIndex[0]
  //   ) {
  //     setPlay(true);
  //   }
  // }, []);
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          <div>
            <div className='question-box custom-question-box'>
              <h4 className='d-flex'>
                {statusVal?.questionInfo?.title == '' ||
                statusVal?.questionInfo?.title == null
                  ? findKeyByValue(statusVal?.questionInfo?.solutionType)
                  : renderText(statusVal?.questionInfo?.title)}
              </h4>

              <h2>
                {' '}
                <span>{renderText(statusVal.questionInfo.description)}</span>
              </h2>
            </div>
          </div>

          <div className='question-box custom-question-box'>
            {statusVal.questionInfo.solutionIndex[0] !==
            statusVal.result.solutionIndex[0] ? (
              <>
                {/* {play && <FailureAudio />} */}
                <span className='answer-title customAnswerTitle answer-wrong'>
                  Your below answer is incorrect
                </span>
              </>
            ) : (
              <>
                {/* {play && <SuccessAudio />} */}
                <span className='answer-title answer-correct'>
                  Your answer is correct
                </span>
              </>
            )}
            <div className='col-xl-8'>
              <div
                className={
                  statusVal.questionInfo?.questionOrientaion == 'vertical'
                    ? 'question-options-wrapper selectTyleType '
                    : 'question-options-wrapper selectTyleType'
                }
              >
                {statusVal.questionInfo.options.map((item, ind) => (
                  <div
                    className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                                          ${
                                            statusVal.result
                                              .solutionIndex[0] === ind &&
                                            ind ==
                                              statusVal.questionInfo
                                                .solutionIndex[0]
                                              ? 'successBack'
                                              : statusVal.result
                                                  .solutionIndex[0] === ind
                                              ? 'failureBack'
                                              : ''
                                          }`}
                  >
                    <button
                      type='button'
                      className={`optionsbtn ${
                        statusVal.result.solutionIndex[0] === ind &&
                        ind == statusVal.questionInfo.solutionIndex[0]
                          ? 'successTextColor'
                          : statusVal.result.solutionIndex[0] === ind
                          ? 'answer-wrong'
                          : ''
                      }`}
                    >
                      {/* <div
                        className={`col-sm-8 customAlignBtnPrac 
                        
                        `}
                      > */}
                      {/* {' '} */}
                      <span className='opti'>
                        {String.fromCharCode(item.index + 65)} .{' '}
                      </span>
                      {item?.imageElement === null && (
                        <div className='questionHtlm-right'>
                          <span
                            className={`${
                              statusVal.result.solutionIndex[0] === ind &&
                              ind == statusVal.questionInfo.solutionIndex[0]
                                ? 'successTextColor'
                                : statusVal.result.solutionIndex[0] === ind
                                ? 'answer-wrong'
                                : ''
                            }`}
                          >
                            {renderText(item?.text)}{' '}
                          </span>
                        </div>
                      )}
                      {item?.imageElement !== '' && (
                        <div className='questionHtlm-right'>
                          <span
                            className={`${
                              statusVal.result.solutionIndex[0] === ind &&
                              ind == statusVal.questionInfo.solutionIndex[0]
                                ? 'successTextColor'
                                : statusVal.result.solutionIndex[0] === ind
                                ? 'answer-wrong'
                                : ''
                            }`}
                          >
                            {renderText(item?.imageElement)}
                          </span>
                        </div>
                      )}
                      {/* </div> */}
                      {/* <div className='col-sm-4 customAEndBtnPrac'> */}
                      {statusVal.result.solutionIndex[0] === ind ? (
                        statusVal.questionInfo.solutionIndex[0] !==
                        statusVal.result.solutionIndex[0] ? (
                          <div className='icon--check-wrapper failurebg customIcon--check-wrapper'>
                            {checkIcon}
                          </div>
                        ) : (
                          <div className='icon--check-wrapper successbg customIcon--check-wrapper'>
                            {checkIcon}
                          </div>
                        )
                      ) : (
                        ''
                      )}
                      {/* </div> */}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {statusVal.questionInfo.solutionIndex[0] !==
      statusVal.result.solutionIndex[0] ? (
        <>
          <div className='question-box col-12 mb-4'>
            <div className='col-xl-8 '>
              <span className='answer-correct answer-title'>
                Correct Answer
              </span>
              <div
                className={
                  statusVal.questionInfo?.questionOrientaion == 'vertical'
                    ? 'question-options-wrapper selectTyleType '
                    : 'question-options-wrapper selectTyleType'
                }
              >
                {statusVal.questionInfo.options.map((item, i) => (
                  <div
                    className={`option-wrapper-tile-type d-flex align-items-center justify-content-between p-4
                                    ${
                                      statusVal?.questionInfo
                                        ?.solutionIndex[0] === i
                                        ? 'successBack'
                                        : ''
                                    }`}
                  >
                    <button
                      type={`button optionsbtn ${
                        statusVal.questionInfo.solutionIndex[0] === i
                          ? 'successTextColor'
                          : ''
                      }`}
                    >
                      {/* <div className={`col-sm-8 customAlignBtnPrac `}> */}

                      <span className='opti'>
                        {String.fromCharCode(item.index + 65)} .{' '}
                      </span>
                      {item?.imageElement === null && (
                        <div className='questionHtlm-right'>
                          <span
                            className={`${
                              statusVal.questionInfo.solutionIndex[0] === i
                                ? 'successTextColor'
                                : ''
                            }`}
                          >
                            {renderText(item?.text)}{' '}
                          </span>
                        </div>
                      )}
                      {item?.imageElement !== '' && (
                        <div className='questionHtlm-right'>
                          <span
                            className={`${
                              statusVal.questionInfo.solutionIndex[0] === i
                                ? 'successTextColor'
                                : ''
                            }`}
                          >
                            {renderText(item?.imageElement)}
                          </span>
                        </div>
                      )}
                      {statusVal.questionInfo.solutionIndex[0] === i ? (
                        <div className='icon--check-wrapper successbg customIcon--check-wrapper'>
                          {checkIcon}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* </div> */}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      {statusVal.questionInfo.solutionDescription ? (
        <div className='option-wrapper-solution mb-4 mt-0'>
          <h4>Solution Description</h4>
          <div>{renderTextNew(statusVal.questionInfo.solutionDescription)}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
