import { object } from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import myexamans1 from '../../../../assets/images/myexamans1.png';
import myexamans2 from '../../../../assets/images/myexamans2.png';
import myexamans3 from '../../../../assets/images/myexamans3.png';
import myexamans4 from '../../../../assets/images/myexamans4.png';
import myexamans5 from '../../../../assets/images/myexamans5.png';
import myexamans6 from '../../../../assets/images/myexamans6.png';
import { formatSecToMin } from '../../../../helpers/utility';

const MyexamanswerLeftpart = ({ myReportData, type }) => {
  const [convertTime1, setConvertTime1] = useState('');
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  const formatDateTime = (dateval) => {
    const dateObj = new Date(dateval);
    const month = months[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const output = `${day} ${month} ${year}`;
    return output;
  };
  const finalTime = () => {
    const currentTime = myReportData?.data?.completedOn;
    const dateObj = new Date(currentTime);
    const time = `${dateObj.getHours()}:${dateObj.getMinutes()}`;
    tConvert(time);
  };

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    const timeString = time.join('');
    setConvertTime1(timeString); // return adjusted time or original string
    return;
  }

  useEffect(() => {
    finalTime();
  }, [myReportData?.data?.completedOn]);

  return (
    <div className='myexam-answer-left'>
      <div className='row Olympiad test on'>
        <div className='col-md-3 myexam-answer-sub-left'>
          <img src={myexamans1} alt='' />
        </div>
        <div className='col-md-9 myexam-answer-sub-right'>
          <p>Exam</p>
          <h5>
            {type == 'assignment'
              ? myReportData?.data?.subconceptName
              : myReportData?.data?.examInfo?.name}
          </h5>
        </div>
      </div>
      <div className='row Olympiad test on'>
        <div className='col-md-3 myexam-answer-sub-left'>
          <img src={myexamans2} alt='' />
        </div>
        <div className='col-md-9 myexam-answer-sub-right'>
          <p>Total points</p>
          <h5>
            {Math.floor(
              type == 'assignment'
                ? myReportData?.data?.inteliScore
                : myReportData?.data?.marksObtained
            )}{' '}
            Points
          </h5>
        </div>
      </div>
      <div className='row Olympiad test on'>
        <div className='col-md-3 myexam-answer-sub-left'>
          <img src={myexamans3} alt='' />
        </div>
        <div className='col-md-9 myexam-answer-sub-right'>
          <p>Date & time</p>
          <h5>{`${formatDateTime(
            myReportData?.data?.completedOn
          )} ${convertTime1}`}</h5>
        </div>
      </div>
      <div className='row Olympiad test on'>
        <div className='col-md-3 myexam-answer-sub-left'>
          <img src={myexamans4} alt='' />
        </div>
        <div className='col-md-9 myexam-answer-sub-right'>
          <p>Time spent</p>
          <h5>
            {formatSecToMin(myReportData?.data?.timeTakenInSecs)}{' '}
            {myReportData?.data?.timeTakenInSecs > 3600
              ? 'Hrs'
              : myReportData?.data?.timeTakenInSecs > 60
              ? 'Mins'
              : 'Secs'}{' '}
          </h5>
        </div>
      </div>
      <div className='row Olympiad test on'>
        <div className='col-md-3 myexam-answer-sub-left'>
          <img src={myexamans5} alt='' />
        </div>
        <div className='col-md-9 myexam-answer-sub-right'>
          <p>Accuracy</p>
          <h5>{myReportData?.data?.accuracy} %</h5>
        </div>
      </div>
      <div className='row Olympiad test on'>
        <div className='col-md-3 myexam-answer-sub-left'>
          <img src={myexamans6} alt='' />
        </div>
        <div className='col-md-9 myexam-answer-sub-right'>
          <p>Right Answers</p>
          <h5>
            {myReportData?.data?.rightAnswers} out of{' '}
            {myReportData?.data?.questionDetails.length}
          </h5>
        </div>
      </div>
    </div>
  );
};
MyexamanswerLeftpart.propTypes = {
  myReportData: object.isRequired,
};
export default MyexamanswerLeftpart;
