import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../../assets/tempimages/Logo.svg';
import IstemLogo from '../../../assets/images/istemLogo.svg';
import LeadCaptureForm from '../../../Layout/leadCaptureForm';
import './header.scss';

const useScroll = () => {
  const elRef = useRef(null);
  const executeScroll = () => elRef?.current?.scrollIntoView();

  return [executeScroll, elRef];
};

const NavHeader = () => {
  const history = useHistory();
  const isMobile =
    window.innerWidth > 0 ? window.innerWidth <= '991' : screen.width <= '991';
  // console.log(window.location.pathname);
  const myRef = useRef(null);
  const [executeScroll, elRef] = useScroll();
  useEffect(executeScroll, []); // Scroll on mount
  const [bookDemo, setBookDemo] = useState(false);
  const [showLeadCaptureForm, setShowLeadCaptureForm] = useState(false);
  const handleMobile = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width <= '991') {
      // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      setShowLeadCaptureForm(true);
    } else {
      history.push('/newregister');
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // fade: true,
    // centerMode: true,
    // vertical: true,
    // verticalSwiping: true,
    arrows: false,
  };

  return (
    <div className='Header' ref={elRef}>
      {/* <div className='Header'> */}
      <div className=' custom-container m-auto'>
        <div className='row'>
          <div className='col-sm-4 header-left d-flex'>
            <Link to='/'>
              <img src={Logo} alt='website_log' />
            </Link>

            <div className='istem-logo'>
              <Slider {...settings}>
                <div>
                  <img
                    src={IstemLogo}
                    // className='m-auto'
                    // className='istem-logo'
                    alt='website_log'
                  />
                </div>

                <div>
                  <img
                    src={IstemLogo}
                    // className='m-auto'
                    // className='istem-logo'
                    alt='website_log'
                  />
                </div>
                <div>
                  <img
                    src={IstemLogo}
                    // className='m-auto'
                    // className='istem-logo'
                    alt='website_log'
                  />
                </div>
              </Slider>
            </div>
            {/* <img src={IstemLogo} className='istem-logo' alt='website_log' /> */}
          </div>
          <div className='col-sm-8 header-right'>
            <ul>
              <li>
                <Link className='header_main_style' to={'/aboutus'}>
                  About Us
                </Link>
              </li>

              <li>
                <Link className='header_main_style' to={'/contactus'}>
                  Contact Us
                </Link>
              </li>

              {/* <li>
                    <Link className='header_main_style' to={'/faqs'}>
                      FAQs
                    </Link>
                  </li> */}

              <li>
                <Link className='header_main_style' to={'/parentaboutus'}>
                  Parents
                </Link>
              </li>

              <li>
                <Link className='header_main_style' to={'/teacherAboutUs'}>
                  Teachers
                </Link>
              </li>

              <li>
                <Link className='header_main_style' to={'/schools'}>
                  Schools
                </Link>
              </li>

              {/* <li>About us</li> */}

              {/* <Link to='/login'> */}
              {isMobile ? (
                ''
              ) : (
                <button
                  type='button'
                  className='login-signin'
                  onClick={() => {
                    history.push('/login');
                  }}
                >
                  {'Login'}
                </button>
              )}
              <button
                type='button'
                className='login-signin m-2'
                onClick={handleMobile}
              >
                Signup
              </button>
              {/* </Link> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
