import React, { useEffect, useState } from 'react';
import MultiSelectOptions from './MultiSelectOptions';
import { readText, renderText } from '../../BrainGym/QueAnswer/textHelper';
import { startListening } from '../startLearning';
import speaker from '../../../../../../assets/images/speaker.png';

const MultiSelectQuestion = ({
  questionsInfo,
  selectedOption,
  setSelectedOption,
  description,
  options,
  solutionType,
  setIsSubmitValid,
}) => {
  const [optionss, setOptions] = useState([]);
  const [selectedOptionss, setSelectedOptionss] = useState([]);

  useEffect(() => {
    setOptions(options);
  }, [options, questionsInfo]);

  useEffect(() => {
    setSelectedOption(selectedOptionss);
    if (selectedOptionss?.length == questionsInfo?.answerLength)
      setIsSubmitValid(true);
    else setIsSubmitValid(false);
  }, [selectedOptionss]);

  const handleSelect = (index) => {
    var newSelectedOptions =
      selectedOptionss?.length === 1
        ? selectedOptionss.includes(-1)
          ? []
          : selectedOptionss
        : selectedOptionss;
    if (selectedOptionss?.includes(index)) {
      let ress = [];
      newSelectedOptions = selectedOptionss.filter((ele) => ele !== index);
      ress = optionss.map((op, indd) => {
        if (indd == index) return { ...op, check: false };
        else return op;
      });
      setOptions([...ress]);
      setSelectedOptionss([...newSelectedOptions]);
    } else {
      let ress = [];
      newSelectedOptions.push(index);
      setSelectedOptionss([...newSelectedOptions]);
      ress = optionss.map((op, indd) => {
        if (indd == index) return { ...op, check: true };
        else return op;
      });
      setOptions([...ress]);
    }
  };
  const handleListenOption = (dataaa) => {
    let kllll = dataaa?.map((item, index) => {
      const cleanText = item.text.replace(/<\/?[^>]+(>|$)/g, '');
      const removeNbsp = cleanText.replace(/&nbsp;/g, ' ');
      return removeNbsp;
    });
    if (kllll?.length) startListening(kllll.join(' '));
  };
  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          {solutionType === 'passage' ? (
            <div>
              <div className='question-box'>
                <h4>
                  Question
                  {solutionType === 'passage'}
                </h4>
                <h2>
                  {' '}
                  <span>{renderText(description)}</span>
                </h2>
              </div>
            </div>
          ) : (
            <div className='question-box'>
              <h4 className='d-flex'>
                {questionsInfo?.title == '' || questionsInfo?.title == null
                  ? 'Multi select question'
                  : renderText(questionsInfo?.title)}
              </h4>
              <div className='col-12 d-flex align-items-center justify-content-between'>
                <h2 className='col-11'>
                  {' '}
                  <span>{renderText(description)}</span>
                </h2>
                <button
                  type='button'
                  className='speaker-btn-wrapper'
                  onClick={() => startListening(readText(description))}
                >
                  {' '}
                  <img
                    className='span-one speaker-btn'
                    src={speaker}
                    alt='speaker icon'
                  />
                </button>
              </div>
            </div>
          )}
          <div className='col-12 d-flex align-items-center justify-content-between mt-4'>
            <span className='col-11 answer-title'>Select your answer</span>
            {optionss[0]?.image ? (
              ''
            ) : (
              <span className='practice-test-audio'>
                <button
                  type='button'
                  className='speaker-btn-wrapper'
                  onClick={() => handleListenOption(optionss)}
                >
                  {' '}
                  <img
                    className='span-one speaker-btn'
                    src={speaker}
                    alt='speaker icon'
                  />
                </button>
              </span>
            )}
          </div>

          <div
            className={
              questionsInfo?.questionOrientaion == 'vertical'
                ? 'question-options-wrapper selectTyleType '
                : 'question-options-wrapper selectTyleType'
            }
          >
            {optionss?.map(
              (item, ind) =>
                (item?.text !== '' || item?.image !== '') && (
                  <MultiSelectOptions
                    handleSelect={handleSelect}
                    item={item}
                    selectedOption={selectedOption}
                    ind={ind}
                    check={item?.check}
                  />
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiSelectQuestion;
